<kt-portlet>
	<kt-portlet-body>
	  <div class="kt-form kt-margin-b-30">
		<div class="kt-form__filtration">
		  <div class="row align-items-center">
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  Jual Emas
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile" style="text-align: right;">
				<a href="javascript:" (click)="backTo()">Kembali</a>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="mat-table__wrapper">
		<mat-table class="lmat-elevation-z8"
				  #table
				  [dataSource]="dataSource"
				  matSort
				  #sort1="matSort"
				  matSortActive="created_at"
				  matSortDirection="desc"
				  matSortDisableClear>
		  <ng-container matColumnDef="created_at">
			<mat-header-cell *matHeaderCellDef>Transaction Date</mat-header-cell>
			<mat-cell *matCellDef="let allusersell">
			  {{allusersell?.created_at}}
			</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="id">
			<mat-header-cell *matHeaderCellDef>Invoice</mat-header-cell>
			<mat-cell *matCellDef="let allusersell">{{allusersell?.id}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="gold_amount">
			<mat-header-cell *matHeaderCellDef>Gold Amount</mat-header-cell>
			<mat-cell *matCellDef="let allusersell">{{allusersell?.gold_amount}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="amount">
			<mat-header-cell *matHeaderCellDef>IDR Amount</mat-header-cell>
			<mat-cell *matCellDef="let allusersell">{{allusersell?.amount}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="base_reward">
			<mat-header-cell *matHeaderCellDef>Add-On Reward</mat-header-cell>
			<mat-cell *matCellDef="let allusersell">{{allusersell?.base_reward}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="status">
			<mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
			<mat-cell *matCellDef="let allusersell">{{allusersell?.status}}</mat-cell>
		  </ng-container>
		  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
		<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
		<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
	  </div>
	  <div class="mat-table__bottom">
		<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
		<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
	  </div>
	</kt-portlet-body>
</kt-portlet>
