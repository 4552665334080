// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { SuborganizationAllsState } from '../_reducers/suborganizationall.reducers';
import { SuborganizationAllModel } from '../_models/suborganizationall.model';

export const selectSuborganizationAllsState = createFeatureSelector<SuborganizationAllsState>('suborganizationalls');

export const selectSuborganizationAllById = (suborganizationallId: number) => createSelector(
    selectSuborganizationAllsState,
    suborganizationallsState => suborganizationallsState.entities[suborganizationallId]
);

export const selectSuborganizationAllsPageLoading = createSelector(
    selectSuborganizationAllsState,
    suborganizationallsState => suborganizationallsState.listLoading
);

export const selectSuborganizationAllsActionLoading = createSelector(
    selectSuborganizationAllsState,
    suborganizationallsState => suborganizationallsState.actionsloading
);

export const selectLastCreatedSuborganizationAllId = createSelector(
    selectSuborganizationAllsState,
    suborganizationallsState => suborganizationallsState.lastCreatedSuborganizationAllId
);

export const selectSuborganizationAllsShowInitWaitingMessage = createSelector(
    selectSuborganizationAllsState,
    suborganizationallsState => suborganizationallsState.showInitWaitingMessage
);

export const selectSuborganizationAllsInStore = createSelector(
    selectSuborganizationAllsState,
    suborganizationallsState => {
        const items: SuborganizationAllModel[] = [];
        each(suborganizationallsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SuborganizationAllModel[] = httpExtension.sortArray(items, suborganizationallsState.lastQuery.sortField, suborganizationallsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, suborganizationallsState.totalCount, '');
    }
);
