// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { ActivityModel } from '../_models/activity.model';

export enum ActivityActionTypes {
    ActivityOnServerCreated = '[Edit Activity Dialog] Activity On Server Created',
    ActivityCreated = '[Edit Activity Dialog] Activity Created',
    ActivityUpdated = '[Edit Activity Dialog] Activity Updated',
    ActivitysStatusUpdated = '[Activity List Page] Activitys Status Updated',
    OneActivityDeleted = '[Activitys List Page] One Activity Deleted',
    ManyActivitysDeleted = '[Activitys List Page] Many Activity Deleted',
    ActivitysPageRequested = '[Activitys List Page] Activitys Page Requested',
    ActivitysPageLoaded = '[Activitys API] Activitys Page Loaded',
    ActivitysPageCancelled = '[Activitys API] Activitys Page Cancelled',
    ActivitysPageToggleLoading = '[Activitys] Activitys Page Toggle Loading',
    ActivityActionToggleLoading = '[Activitys] Activitys Action Toggle Loading'
}

export class ActivityOnServerCreated implements Action {
    readonly type = ActivityActionTypes.ActivityOnServerCreated;
    constructor(public payload: { activity: ActivityModel }) { }
}

export class ActivityCreated implements Action {
    readonly type = ActivityActionTypes.ActivityCreated;
    constructor(public payload: { activity: ActivityModel }) { }
}

export class ActivityUpdated implements Action {
    readonly type = ActivityActionTypes.ActivityUpdated;
    constructor(public payload: {
        partialActivity: Update<ActivityModel>, // For State update
        activity: ActivityModel // For Server update (through service)
    }) { }
}

export class ActivitysStatusUpdated implements Action {
    readonly type = ActivityActionTypes.ActivitysStatusUpdated;
    constructor(public payload: {
        activitys: ActivityModel[],
        status: number
    }) { }
}

export class OneActivityDeleted implements Action {
    readonly type = ActivityActionTypes.OneActivityDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyActivitysDeleted implements Action {
    readonly type = ActivityActionTypes.ManyActivitysDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ActivitysPageRequested implements Action {
    readonly type = ActivityActionTypes.ActivitysPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class ActivitysPageLoaded implements Action {
    readonly type = ActivityActionTypes.ActivitysPageLoaded;
    constructor(public payload: { activitys: ActivityModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class ActivitysPageCancelled implements Action {
    readonly type = ActivityActionTypes.ActivitysPageCancelled;
}

export class ActivitysPageToggleLoading implements Action {
    readonly type = ActivityActionTypes.ActivitysPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ActivityActionToggleLoading implements Action {
    readonly type = ActivityActionTypes.ActivityActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ActivityActions = ActivityOnServerCreated
| ActivityCreated
| ActivityUpdated
| ActivitysStatusUpdated
| OneActivityDeleted
| ManyActivitysDeleted
| ActivitysPageRequested
| ActivitysPageLoaded
| ActivitysPageCancelled
| ActivitysPageToggleLoading
| ActivityActionToggleLoading;
