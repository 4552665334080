// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { TncComponent } from './views/pages/tnc/tnc.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'home',
				loadChildren: () => import('app/views/pages/home/home.module').then(m => m.HomeModule)
			},
			{
				path: 'transaction',
				loadChildren: () => import('app/views/pages/transaction/transaction.module').then(m => m.TransactionModule),
			},
			{
				path: 'user',
				loadChildren: () => import('app/views/pages/user/user.module').then(m => m.UserModule),
			},
			{
				path: 'report',
				loadChildren: () => import('app/views/pages/report/report.module').then(m => m.ReportModule),
			},
			{
				path: 'access',
				loadChildren: () => import('app/views/pages/access/access.module').then(m => m.AccessModule),
			},
			{
				path: 'bigorder',
				loadChildren: () => import('app/views/pages/big-order/big-order.module').then(m => m.BigOrderModule),
			},
			{
				path: 'kartanu',
				loadChildren: () => import('app/views/pages/kartanu/kartanu.module').then(m => m.KartanuModule),
			},
			{
				path: 'user-management',
				loadChildren: () => import('app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
			},
      {
        path: 'tnc',
        loadChildren: () => import('app/views/pages/tnc/tnc.module').then(m => m.TncModule),
      },
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					'type': 'error-v6',
					'code': 403,
					'title': '403... Access forbidden',
					'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
