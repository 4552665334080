import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import { selectGoldZakatsInStore, selectGoldZakatsPageLoading, selectGoldZakatsShowInitWaitingMessage } from '../_selectors/goldzakat.selectors';

export class GoldZakatsDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(
      select(selectGoldZakatsPageLoading),
    );

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectGoldZakatsShowInitWaitingMessage)
    );

    this.store.pipe(
      select(selectGoldZakatsInStore),
    ).subscribe((response: QueryResultsModel) => {
      this.paginatorTotalSubject.next(response.totalCount);
      this.entitySubject.next(response.data);
    });
  }
}
