// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsDetailShipmentModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { DetailShipmentModel } from '../_models/detailshipment.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class DetailShipmentService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new detailshipment to the server
  createDetailShipment(detailshipment: DetailShipmentModel): Observable<DetailShipmentModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<DetailShipmentModel>(BASE_URL, detailshipment, { headers: httpHeaders});
  }

  // READ
  getAllDetailShipments(): Observable<DetailShipmentModel[]> {
    return this.http.get<DetailShipmentModel[]>(BASE_URL);
  }

  getDetailShipmentById(detailshipmentId: number): Observable<DetailShipmentModel> {
    return this.http.get<DetailShipmentModel>(BASE_URL + `/${detailshipmentId}`);
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id; 
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findDetailShipments(queryParams: QueryParamsDetailShipmentModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParamsDetailShipment(queryParams);
    const url = BASE_URL + 'b2b/shipment/detail';
    
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the detailshipment on the server
  updateDetailShipment(detailshipment: DetailShipmentModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, detailshipment, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForDetailShipment(detailshipments: DetailShipmentModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      detailshipmentsForUpdate: detailshipments,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the detailshipment from the server
  deleteDetailShipment(detailshipmentId: number): Observable<DetailShipmentModel> {
    const url = `${BASE_URL}/${detailshipmentId}`;
    return this.http.delete<DetailShipmentModel>(url);
  }

  deleteDetailShipments(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteDetailShipments';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { detailshipmentIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }

  download(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/export/detail-shipment?hashed_id='+hashed_id; 
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }
}
