import { BaseModel } from '../../_base/crud';

export class AllUserSellModel  extends BaseModel {
	id: string;
	gold_amount: any;
	amount: any;
	invoice: any;
	status: any;
	base_reward: any;
	note: any;
	created_at: string;

	clear() {
		this.id = '';
	    this.gold_amount = '';
	    this.amount = '';
		this.invoice = '';
		this.status = '';
		this.base_reward = '';
		this.note = '';
	    this.created_at = '';
	}
}
