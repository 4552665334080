// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GoldGiftCardModel } from '../_models/goldgiftcard.model';

export enum GoldGiftCardActionTypes {
    GoldGiftCardOnServerCreated = '[Edit GoldGiftCard Dialog] GoldGiftCard On Server Created',
    GoldGiftCardCreated = '[Edit GoldGiftCard Dialog] GoldGiftCard Created',
    GoldGiftCardUpdated = '[Edit GoldGiftCard Dialog] GoldGiftCard Updated',
    GoldGiftCardsStatusUpdated = '[GoldGiftCard List Page] GoldGiftCards Status Updated',
    OneGoldGiftCardDeleted = '[GoldGiftCards List Page] One GoldGiftCard Deleted',
    ManyGoldGiftCardsDeleted = '[GoldGiftCards List Page] Many GoldGiftCard Deleted',
    GoldGiftCardsPageRequested = '[GoldGiftCards List Page] GoldGiftCards Page Requested',
    GoldGiftCardsPageLoaded = '[GoldGiftCards API] GoldGiftCards Page Loaded',
    GoldGiftCardsPageCancelled = '[GoldGiftCards API] GoldGiftCards Page Cancelled',
    GoldGiftCardsPageToggleLoading = '[GoldGiftCards] GoldGiftCards Page Toggle Loading',
    GoldGiftCardActionToggleLoading = '[GoldGiftCards] GoldGiftCards Action Toggle Loading'
}

export class GoldGiftCardOnServerCreated implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardOnServerCreated;
    constructor(public payload: { goldgiftcard: GoldGiftCardModel }) { }
}

export class GoldGiftCardCreated implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardCreated;
    constructor(public payload: { goldgiftcard: GoldGiftCardModel }) { }
}

export class GoldGiftCardUpdated implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardUpdated;
    constructor(public payload: {
        partialGoldGiftCard: Update<GoldGiftCardModel>, // For State update
        goldgiftcard: GoldGiftCardModel // For Server update (through service)
    }) { }
}

export class GoldGiftCardsStatusUpdated implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardsStatusUpdated;
    constructor(public payload: {
        goldgiftcards: GoldGiftCardModel[],
        status: number
    }) { }
}

export class OneGoldGiftCardDeleted implements Action {
    readonly type = GoldGiftCardActionTypes.OneGoldGiftCardDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyGoldGiftCardsDeleted implements Action {
    readonly type = GoldGiftCardActionTypes.ManyGoldGiftCardsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class GoldGiftCardsPageRequested implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class GoldGiftCardsPageLoaded implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardsPageLoaded;
    constructor(public payload: { goldgiftcards: GoldGiftCardModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class GoldGiftCardsPageCancelled implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardsPageCancelled;
}

export class GoldGiftCardsPageToggleLoading implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class GoldGiftCardActionToggleLoading implements Action {
    readonly type = GoldGiftCardActionTypes.GoldGiftCardActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type GoldGiftCardActions = GoldGiftCardOnServerCreated
| GoldGiftCardCreated
| GoldGiftCardUpdated
| GoldGiftCardsStatusUpdated
| OneGoldGiftCardDeleted
| ManyGoldGiftCardsDeleted
| GoldGiftCardsPageRequested
| GoldGiftCardsPageLoaded
| GoldGiftCardsPageCancelled
| GoldGiftCardsPageToggleLoading
| GoldGiftCardActionToggleLoading;
