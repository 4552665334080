import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { SuborganizationAvailableService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    SuborganizationAvailableActionTypes,
    SuborganizationAvailablesPageRequested,
    SuborganizationAvailablesPageLoaded,
    ManySuborganizationAvailablesDeleted,
    OneSuborganizationAvailableDeleted,
    SuborganizationAvailableActionToggleLoading,
    SuborganizationAvailablesPageToggleLoading,
    SuborganizationAvailableUpdated,
    SuborganizationAvailablesStatusUpdated,
    SuborganizationAvailableCreated,
    SuborganizationAvailableOnServerCreated
} from '../_actions/suborganizationavailable.actions';
import { of } from 'rxjs';

@Injectable()
export class SuborganizationAvailableEffects {
    showPageLoadingDistpatcher = new SuborganizationAvailablesPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new SuborganizationAvailableActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new SuborganizationAvailableActionToggleLoading({ isLoading: false });

    @Effect()
    loadSuborganizationAvailablesPage$ = this.actions$.pipe(
        ofType<SuborganizationAvailablesPageRequested>(SuborganizationAvailableActionTypes.SuborganizationAvailablesPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.suborganizationavailablesService.findSuborganizationAvailables(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new SuborganizationAvailablesPageLoaded({
                suborganizationavailables: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteSuborganizationAvailable$ = this.actions$
        .pipe(
            ofType<OneSuborganizationAvailableDeleted>(SuborganizationAvailableActionTypes.OneSuborganizationAvailableDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.suborganizationavailablesService.deleteSuborganizationAvailable(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteSuborganizationAvailables$ = this.actions$
        .pipe(
            ofType<ManySuborganizationAvailablesDeleted>(SuborganizationAvailableActionTypes.ManySuborganizationAvailablesDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.suborganizationavailablesService.deleteSuborganizationAvailables(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateSuborganizationAvailable$ = this.actions$
        .pipe(
            ofType<SuborganizationAvailableUpdated>(SuborganizationAvailableActionTypes.SuborganizationAvailableUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.suborganizationavailablesService.updateSuborganizationAvailable(payload.suborganizationavailable);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateSuborganizationAvailablesStatus$ = this.actions$
        .pipe(
            ofType<SuborganizationAvailablesStatusUpdated>(SuborganizationAvailableActionTypes.SuborganizationAvailablesStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.suborganizationavailablesService.updateStatusForSuborganizationAvailable(payload.suborganizationavailables, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createSuborganizationAvailable$ = this.actions$
        .pipe(
            ofType<SuborganizationAvailableOnServerCreated>(SuborganizationAvailableActionTypes.SuborganizationAvailableOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.suborganizationavailablesService.createSuborganizationAvailable(payload.suborganizationavailable).pipe(
                    tap(res => {
                        this.store.dispatch(new SuborganizationAvailableCreated({ suborganizationavailable: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private suborganizationavailablesService: SuborganizationAvailableService, private store: Store<AppState>) { }
}
