// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { LocationAvailableModel } from '../_models/locationavailable.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class LocationAvailableService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new locationavailable to the server
	createLocationAvailable(locationavailable: LocationAvailableModel): Observable<LocationAvailableModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<LocationAvailableModel>(BASE_URL, locationavailable, { headers: httpHeaders});
	}

	// READ
	getAllLocationAvailables(): Observable<LocationAvailableModel[]> {
		return this.http.get<LocationAvailableModel[]>(BASE_URL);
	}

	getLocationAvailableById(locationavailableId: number): Observable<LocationAvailableModel> {
		return this.http.get<LocationAvailableModel>(BASE_URL + `/${locationavailableId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findLocationAvailables(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'b2b/inventory';
		
    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	// UPDATE => PUT: update the locationavailable on the server
	updateLocationAvailable(locationavailable: LocationAvailableModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(BASE_URL, locationavailable, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForLocationAvailable(locationavailables: LocationAvailableModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			locationavailablesForUpdate: locationavailables,
			newStatus: status
		};
		const url = BASE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the locationavailable from the server
	deleteLocationAvailable(locationavailableId: number): Observable<LocationAvailableModel> {
		const url = `${BASE_URL}/${locationavailableId}`;
		return this.http.delete<LocationAvailableModel>(url);
	}

	deleteLocationAvailables(ids: number[] = []): Observable<any> {
		const url = BASE_URL + '/deleteLocationAvailables';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { locationavailableIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
