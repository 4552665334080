import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import { selectShipmentsInStore, selectUsersInStore, selectUsersPageLoading, selectShipmentsPageLoading, selectShipmentsShowInitWaitingMessage, selectUsersShowInitWaitingMessage } from '../_selectors/user.selectors';

export class UsersDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectUsersPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectUsersShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectUsersInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.data);
		});
	}
}

export class ShipmentsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectShipmentsPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectShipmentsShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectShipmentsInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.data);
		});
	}
}
