// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AllUserSellsState } from '../_reducers/allusersell.reducers';
import { AllUserSellModel } from '../_models/allusersell.model';

export const selectAllUserSellsState = createFeatureSelector<AllUserSellsState>('allusersells');

export const selectAllUserSellById = (allusersellId: number) => createSelector(
    selectAllUserSellsState,
    allusersellsState => allusersellsState.entities[allusersellId]
);

export const selectAllUserSellsPageLoading = createSelector(
    selectAllUserSellsState,
    allusersellsState => allusersellsState.listLoading
);

export const selectAllUserSellsActionLoading = createSelector(
    selectAllUserSellsState,
    allusersellsState => allusersellsState.actionsloading
);

export const selectLastCreatedAllUserSellId = createSelector(
    selectAllUserSellsState,
    allusersellsState => allusersellsState.lastCreatedAllUserSellId
);

export const selectAllUserSellsShowInitWaitingMessage = createSelector(
    selectAllUserSellsState,
    allusersellsState => allusersellsState.showInitWaitingMessage
);

export const selectAllUserSellsInStore = createSelector(
    selectAllUserSellsState,
    allusersellsState => {
        const items: AllUserSellModel[] = [];
        each(allusersellsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AllUserSellModel[] = httpExtension.sortArray(items, allusersellsState.lastQuery.sortField, allusersellsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, allusersellsState.totalCount, '');
    }
);
