import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { AllUserSellService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    AllUserSellActionTypes,
    AllUserSellsPageRequested,
    AllUserSellsPageLoaded,
    ManyAllUserSellsDeleted,
    OneAllUserSellDeleted,
    AllUserSellActionToggleLoading,
    AllUserSellsPageToggleLoading,
    AllUserSellUpdated,
    AllUserSellsStatusUpdated,
    AllUserSellCreated,
    AllUserSellOnServerCreated
} from '../_actions/allusersell.actions';
import { of } from 'rxjs';

@Injectable()
export class AllUserSellEffects {
    showPageLoadingDistpatcher = new AllUserSellsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new AllUserSellActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AllUserSellActionToggleLoading({ isLoading: false });

    @Effect()
    loadAllUserSellsPage$ = this.actions$.pipe(
        ofType<AllUserSellsPageRequested>(AllUserSellActionTypes.AllUserSellsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.allusersellsService.findAllUserSells(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new AllUserSellsPageLoaded({
                allusersells: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteAllUserSell$ = this.actions$
        .pipe(
            ofType<OneAllUserSellDeleted>(AllUserSellActionTypes.OneAllUserSellDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.allusersellsService.deleteAllUserSell(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAllUserSells$ = this.actions$
        .pipe(
            ofType<ManyAllUserSellsDeleted>(AllUserSellActionTypes.ManyAllUserSellsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.allusersellsService.deleteAllUserSells(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAllUserSell$ = this.actions$
        .pipe(
            ofType<AllUserSellUpdated>(AllUserSellActionTypes.AllUserSellUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusersellsService.updateAllUserSell(payload.allusersell);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateAllUserSellsStatus$ = this.actions$
        .pipe(
            ofType<AllUserSellsStatusUpdated>(AllUserSellActionTypes.AllUserSellsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusersellsService.updateStatusForAllUserSell(payload.allusersells, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createAllUserSell$ = this.actions$
        .pipe(
            ofType<AllUserSellOnServerCreated>(AllUserSellActionTypes.AllUserSellOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusersellsService.createAllUserSell(payload.allusersell).pipe(
                    tap(res => {
                        this.store.dispatch(new AllUserSellCreated({ allusersell: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private allusersellsService: AllUserSellService, private store: Store<AppState>) { }
}
