<kt-portlet>
	<kt-portlet-body>
	  <div class="kt-form kt-margin-b-30">
		<div class="kt-form__filtration">
		  <div class="row align-items-center">
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <mat-form-field class="mat-form-field-fluid">
				<input matInput placeholder="Search coin" #searchInput placeholder="Search">
				<mat-hint align="start">
				  <strong>Search</strong> by name or email or phone
				</mat-hint>
			  </mat-form-field>
			</div>
			<div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
			  <mat-form-field>
				<mat-select placeholder="Organization" [(ngModel)]="selectedOrg" (selectionChange)="onSelectChange($event)">
				  <mat-option>None</mat-option>
				  <mat-option *ngFor="let org of organizationList" [value]="org.id">{{org.name}}</mat-option>
				</mat-select>
			  </mat-form-field>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <mat-form-field class="mat-form-field-fluid">
				<input matInput
								  placeholder="Choose a date"
								  [satDatepicker]="picker"
								  [(value)]="dateRange"
								  (dateChange)="onDateChange($event)">
							  <sat-datepicker
								  #picker
								  [rangeMode]="true"
								  orderPeriodLabel="month"
								  [closeAfterSelection]="true">>
							  </sat-datepicker>
				<sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
			  </mat-form-field>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <mat-form-field>
				<mat-select placeholder="Filter By Transaction Status" (selectionChange)="onSelectStatChange($event)">
				  <mat-option value="">None</mat-option>
				  <mat-option value="">None</mat-option>
					<mat-option value="success">Success</mat-option>
					<mat-option value="expired">Expired</mat-option>
					<mat-option value="cancelled">Cancelled</mat-option>
				</mat-select>
			  </mat-form-field>
		  </div>
			<!-- <div class="col-md-3 kt-margin-bottom-10-mobile">
			  <mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button mat-button-base mat-flat-button"></mat-spinner-button>
			</div> -->
		  </div>
		</div>
	  </div>
	  <div class="mat-table__wrapper">
		<mat-table class="lmat-elevation-z8"
				  #table
				  [dataSource]="dataSource"
				  matSort
				  #sort1="matSort"
				  matSortActive="created_at"
				  matSortDirection="desc"
				  matSortDisableClear>
		  <ng-container matColumnDef="created_at">
			  <mat-header-cell *matHeaderCellDef>Transaction Date</mat-header-cell>
			  <mat-cell *matCellDef="let coin">{{coin?.created_at}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="user.name">
			<mat-header-cell *matHeaderCellDef>User Referral</mat-header-cell>
			<mat-cell *matCellDef="let coin">
				<a href="javascript:" (click)="editCoin(coin?.user?.id)">{{coin?.user?.name}}</a>
			</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="amount">
			<mat-header-cell *matHeaderCellDef>Gold Amount</mat-header-cell>
			<mat-cell *matCellDef="let coin">{{coin?.amount}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="rupiah">
			  <mat-header-cell *matHeaderCellDef>IDR Amount</mat-header-cell>
			  <mat-cell *matCellDef="let coin">{{coin?.rupiah}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="insentif">
			  <mat-header-cell *matHeaderCellDef>Basic Reward</mat-header-cell>
			  <mat-cell *matCellDef="let coin">{{coin?.insentif}}</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="status">
			  <mat-header-cell *matHeaderCellDef>Status Transaction</mat-header-cell>
			  <mat-cell *matCellDef="let coin">{{coin?.status}}</mat-cell>
		  </ng-container>
		  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
		<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
		<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
	  </div>
	  <div class="mat-table__bottom">
		<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
		<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
	  </div>
	</kt-portlet-body>
  </kt-portlet>
