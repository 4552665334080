<div class="kt-portlet"
	[ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : viewLoading }">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
		</div>
	</div>
	<form class="kt-form">
		<div class="kt-portlet__body">
			<div class="row">
				<div class="col-lg-12 text-center">
					<h4 *ngIf="detailCard.serial_number">Apakah kamu ingin mengirim nomor urut <b>{{detailCard.serial_number}}</b>?</h4>
					<h4 *ngIf="!detailCard.serial_number">Apakah kamu ingin mencetak <b>{{detailCard}}</b> KartaNU?</h4>
				</div>
			</div>
		</div>

		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
			<div class="kt-form__actions kt-form__actions--solid">
				<div class="row text-left">
					<div class="col-lg-12">
						<button type="button" mat-button color="warn" (click)="onNoClick()">
							Batalkan
						</button>
						<button *ngIf="detailCard.serial_number" type="button" mat-raised-button color="accent" [mat-dialog-close]="detailCard.hashed_id" cdkFocusInitial>
							Lanjutkan
						</button>
						<button *ngIf="!detailCard.serial_number" type="button" mat-raised-button color="accent" [mat-dialog-close]="continue" cdkFocusInitial>
							Lanjutkan
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
