import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { AllUserBuyService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    AllUserBuyActionTypes,
    AllUserBuysPageRequested,
    AllUserBuysPageLoaded,
    ManyAllUserBuysDeleted,
    OneAllUserBuyDeleted,
    AllUserBuyActionToggleLoading,
    AllUserBuysPageToggleLoading,
    AllUserBuyUpdated,
    AllUserBuysStatusUpdated,
    AllUserBuyCreated,
    AllUserBuyOnServerCreated
} from '../_actions/alluserbuy.actions';
import { of } from 'rxjs';

@Injectable()
export class AllUserBuyEffects {
    showPageLoadingDistpatcher = new AllUserBuysPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new AllUserBuyActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AllUserBuyActionToggleLoading({ isLoading: false });

    @Effect()
    loadAllUserBuysPage$ = this.actions$.pipe(
        ofType<AllUserBuysPageRequested>(AllUserBuyActionTypes.AllUserBuysPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.alluserbuysService.findAllUserBuys(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new AllUserBuysPageLoaded({
                alluserbuys: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteAllUserBuy$ = this.actions$
        .pipe(
            ofType<OneAllUserBuyDeleted>(AllUserBuyActionTypes.OneAllUserBuyDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.alluserbuysService.deleteAllUserBuy(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAllUserBuys$ = this.actions$
        .pipe(
            ofType<ManyAllUserBuysDeleted>(AllUserBuyActionTypes.ManyAllUserBuysDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.alluserbuysService.deleteAllUserBuys(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAllUserBuy$ = this.actions$
        .pipe(
            ofType<AllUserBuyUpdated>(AllUserBuyActionTypes.AllUserBuyUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.alluserbuysService.updateAllUserBuy(payload.alluserbuy);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateAllUserBuysStatus$ = this.actions$
        .pipe(
            ofType<AllUserBuysStatusUpdated>(AllUserBuyActionTypes.AllUserBuysStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.alluserbuysService.updateStatusForAllUserBuy(payload.alluserbuys, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createAllUserBuy$ = this.actions$
        .pipe(
            ofType<AllUserBuyOnServerCreated>(AllUserBuyActionTypes.AllUserBuyOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.alluserbuysService.createAllUserBuy(payload.alluserbuy).pipe(
                    tap(res => {
                        this.store.dispatch(new AllUserBuyCreated({ alluserbuy: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private alluserbuysService: AllUserBuyService, private store: Store<AppState>) { }
}
