// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';

// Models
import { BigOrder } from '../_models/bigorder.model';

const BASE_URL = environment.baseUrl;

@Injectable({
	providedIn: 'root'
})
export class BigOrderService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  getProfileAgent() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/profile-agent';
    return this.http
      .get<any[]>(url,{
        headers:httpHeaders,
      })
      .pipe(map(data => data['data']));
  }

  calculate(data) {
	const httpHeaders = this.httpUtils.getHTTPHeaders();
        httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post(BASE_URL + 'b2b/calculate', data, { headers: httpHeaders})
      .pipe(map(data => data['data']));
  }
}
