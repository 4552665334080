// Angular
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { AuthService } from '../../../../../core/auth';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  GoldZakatModel,
  GoldZakatService,
  GoldZakatsDataSource,
  GoldZakatsPageRequested,
  OneGoldZakatDeleted,
  ManyGoldZakatsDeleted,
  GoldZakatsStatusUpdated,
} from '../../../../../core/transaction';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
import { GoldZakatEditComponent } from '../gold-zakat-edit/gold-zakat-edit.dialog.component';
@Component({
  selector: 'kt-goldzakats-list',
  templateUrl: './gold-zakat-list.component.html',
  styleUrls: ['gold-zakat-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class GoldZakatListComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: GoldZakatsDataSource;
  displayedColumns = ['name', 'type', 'amount', 'gold_amount', 'created_at'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
    dateRange: any = {};
    organizationList: any = [];
    selectedOrg: string = '';
    role: boolean = false;

  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  // Selection
  selection = new SelectionModel<GoldZakatModel>(true, []);
  goldzakatsResult: GoldZakatModel[] = [];
  totalZakat: string;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Download',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'accent',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'cloud_download'
    }
  }

  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param dialog: MatDialog
   * @param snackBar: MatSnackBar
   * @param layoutUtilsService: LayoutUtilsService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   */
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public authService : AuthService,
    public goldzakatService: GoldZakatService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadGoldZakatsList())
    )
    .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadGoldZakatsList();
      })
    )
    .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init Org List
    this.loadOrganizationList();
    this.loadUserRole();

    // Init DataSource
    this.dataSource = new GoldZakatsDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.goldzakatsResult = res;
    });
    this.subscriptions.push(entitiesSubscription);

    // First load
    of(undefined).pipe(
      take(1), 
      delay(1000)
    ).subscribe(() => { // Remove this line, just loading imitation
      this.paginator.pageIndex = 0;
      this.loadGoldZakatsList();
    }); // Remove this line, just loading imitation

    this.gettotalZakat();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load GoldZakats List from service through data-source
   */
  loadGoldZakatsList() {
    this.selection.clear();
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      '', // status
      '', // type
      '', // suborganization_id
      this.selectedOrg // organization_id
    );
    // Call request from server
    this.store.dispatch(new GoldZakatsPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  /**
   * Load Organization List
   */
  loadOrganizationList() {
    this.goldzakatService.getAllOrganization()
    .subscribe(
      data => {
        this.organizationList = data;
      }
    )
  }

  /**
   * Load Role
   */
  loadUserRole() {
    this.authService.getUserByToken()
    .subscribe(
      data => {
        this.role = data.data.roles.includes(17);
      }
    )
  }

  editGoldZakat(hashed_id) {
    // Get Detail User
    this.goldzakatService.getDetailUser(hashed_id)
    .subscribe(
      data => {
        const dialogRef = this.dialog.open(GoldZakatEditComponent, { data: { data } });
      }
    )
  }

  onSelectChange(event: any) {
    this.paginator.pageIndex = 0;
    this.loadGoldZakatsList();
    this.gettotalZakat();
  }

  onDateChange(event: any) {
    this.paginator.pageIndex = 0;
    if(event.target.value){
      this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
    }else{
      this.dateRange = {begin: null, end: null};
    }
    this.loadGoldZakatsList();
    this.gettotalZakat();
  }

  download(){
    this.spinnerButtonOptions.active = true;
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
          this.sort.direction,
          this.sort.active,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          '',
          '',
          '', // suborganization_id
          this.selectedOrg // organization_id
    );

    this.goldzakatService.downloadZakats(queryParams)
    .subscribe(
      data => {
        this.spinnerButtonOptions.active = false;
        window.location.href = data;
        }
      )
  }

  gettotalZakat(){
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
          this.sort.direction,
          this.sort.active,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          '[0,1,2,3,4]', // status
          '', // type
          '', // suborganization_id
          this.selectedOrg // organization_id
    );

    this.goldzakatService.totalZakat(queryParams)
    .subscribe(
      data => {
        this.totalZakat = data;
        this.cd.markForCheck();
        }
      )
  }
}
