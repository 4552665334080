// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { ModeratorAllModel } from '../_models/moderatorall.model';

export enum ModeratorAllActionTypes {
    ModeratorAllOnServerCreated = '[Edit ModeratorAll Dialog] ModeratorAll On Server Created',
    ModeratorAllCreated = '[Edit ModeratorAll Dialog] ModeratorAll Created',
    ModeratorAllUpdated = '[Edit ModeratorAll Dialog] ModeratorAll Updated',
    ModeratorAllsStatusUpdated = '[ModeratorAll List Page] ModeratorAlls Status Updated',
    OneModeratorAllDeleted = '[ModeratorAlls List Page] One ModeratorAll Deleted',
    ManyModeratorAllsDeleted = '[ModeratorAlls List Page] Many ModeratorAll Deleted',
    ModeratorAllsPageRequested = '[ModeratorAlls List Page] ModeratorAlls Page Requested',
    ModeratorAllsPageLoaded = '[ModeratorAlls API] ModeratorAlls Page Loaded',
    ModeratorAllsPageCancelled = '[ModeratorAlls API] ModeratorAlls Page Cancelled',
    ModeratorAllsPageToggleLoading = '[ModeratorAlls] ModeratorAlls Page Toggle Loading',
    ModeratorAllActionToggleLoading = '[ModeratorAlls] ModeratorAlls Action Toggle Loading'
}

export class ModeratorAllOnServerCreated implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllOnServerCreated;
    constructor(public payload: { moderatorall: ModeratorAllModel }) { }
}

export class ModeratorAllCreated implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllCreated;
    constructor(public payload: { moderatorall: ModeratorAllModel }) { }
}

export class ModeratorAllUpdated implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllUpdated;
    constructor(public payload: {
        partialModeratorAll: Update<ModeratorAllModel>, // For State update
        moderatorall: ModeratorAllModel // For Server update (through service)
    }) { }
}

export class ModeratorAllsStatusUpdated implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllsStatusUpdated;
    constructor(public payload: {
        moderatoralls: ModeratorAllModel[],
        status: number
    }) { }
}

export class OneModeratorAllDeleted implements Action {
    readonly type = ModeratorAllActionTypes.OneModeratorAllDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyModeratorAllsDeleted implements Action {
    readonly type = ModeratorAllActionTypes.ManyModeratorAllsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ModeratorAllsPageRequested implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllsPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class ModeratorAllsPageLoaded implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllsPageLoaded;
    constructor(public payload: { moderatoralls: ModeratorAllModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class ModeratorAllsPageCancelled implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllsPageCancelled;
}

export class ModeratorAllsPageToggleLoading implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ModeratorAllActionToggleLoading implements Action {
    readonly type = ModeratorAllActionTypes.ModeratorAllActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ModeratorAllActions = ModeratorAllOnServerCreated
| ModeratorAllCreated
| ModeratorAllUpdated
| ModeratorAllsStatusUpdated
| OneModeratorAllDeleted
| ManyModeratorAllsDeleted
| ModeratorAllsPageRequested
| ModeratorAllsPageLoaded
| ModeratorAllsPageCancelled
| ModeratorAllsPageToggleLoading
| ModeratorAllActionToggleLoading;
