// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { SuborganizationAvailableModel } from '../_models/suborganizationavailable.model';

export enum SuborganizationAvailableActionTypes {
    SuborganizationAvailableOnServerCreated = '[Edit SuborganizationAvailable Dialog] SuborganizationAvailable On Server Created',
    SuborganizationAvailableCreated = '[Edit SuborganizationAvailable Dialog] SuborganizationAvailable Created',
    SuborganizationAvailableUpdated = '[Edit SuborganizationAvailable Dialog] SuborganizationAvailable Updated',
    SuborganizationAvailablesStatusUpdated = '[SuborganizationAvailable List Page] SuborganizationAvailables Status Updated',
    OneSuborganizationAvailableDeleted = '[SuborganizationAvailables List Page] One SuborganizationAvailable Deleted',
    ManySuborganizationAvailablesDeleted = '[SuborganizationAvailables List Page] Many SuborganizationAvailable Deleted',
    SuborganizationAvailablesPageRequested = '[SuborganizationAvailables List Page] SuborganizationAvailables Page Requested',
    SuborganizationAvailablesPageLoaded = '[SuborganizationAvailables API] SuborganizationAvailables Page Loaded',
    SuborganizationAvailablesPageCancelled = '[SuborganizationAvailables API] SuborganizationAvailables Page Cancelled',
    SuborganizationAvailablesPageToggleLoading = '[SuborganizationAvailables] SuborganizationAvailables Page Toggle Loading',
    SuborganizationAvailableActionToggleLoading = '[SuborganizationAvailables] SuborganizationAvailables Action Toggle Loading'
}

export class SuborganizationAvailableOnServerCreated implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailableOnServerCreated;
    constructor(public payload: { suborganizationavailable: SuborganizationAvailableModel }) { }
}

export class SuborganizationAvailableCreated implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailableCreated;
    constructor(public payload: { suborganizationavailable: SuborganizationAvailableModel }) { }
}

export class SuborganizationAvailableUpdated implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailableUpdated;
    constructor(public payload: {
        partialSuborganizationAvailable: Update<SuborganizationAvailableModel>, // For State update
        suborganizationavailable: SuborganizationAvailableModel // For Server update (through service)
    }) { }
}

export class SuborganizationAvailablesStatusUpdated implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailablesStatusUpdated;
    constructor(public payload: {
        suborganizationavailables: SuborganizationAvailableModel[],
        status: number
    }) { }
}

export class OneSuborganizationAvailableDeleted implements Action {
    readonly type = SuborganizationAvailableActionTypes.OneSuborganizationAvailableDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManySuborganizationAvailablesDeleted implements Action {
    readonly type = SuborganizationAvailableActionTypes.ManySuborganizationAvailablesDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class SuborganizationAvailablesPageRequested implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailablesPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class SuborganizationAvailablesPageLoaded implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailablesPageLoaded;
    constructor(public payload: { suborganizationavailables: SuborganizationAvailableModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class SuborganizationAvailablesPageCancelled implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailablesPageCancelled;
}

export class SuborganizationAvailablesPageToggleLoading implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailablesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class SuborganizationAvailableActionToggleLoading implements Action {
    readonly type = SuborganizationAvailableActionTypes.SuborganizationAvailableActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type SuborganizationAvailableActions = SuborganizationAvailableOnServerCreated
| SuborganizationAvailableCreated
| SuborganizationAvailableUpdated
| SuborganizationAvailablesStatusUpdated
| OneSuborganizationAvailableDeleted
| ManySuborganizationAvailablesDeleted
| SuborganizationAvailablesPageRequested
| SuborganizationAvailablesPageLoaded
| SuborganizationAvailablesPageCancelled
| SuborganizationAvailablesPageToggleLoading
| SuborganizationAvailableActionToggleLoading;
