// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { SuborganizationAvailableModel } from '../_models/suborganizationavailable.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class SuborganizationAvailableService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new suborganizationavailable to the server
  createSuborganizationAvailable(suborganizationavailable: SuborganizationAvailableModel): Observable<SuborganizationAvailableModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<SuborganizationAvailableModel>(BASE_URL, suborganizationavailable, { headers: httpHeaders});
  }

  // READ
  getAllSuborganizationAvailables(): Observable<SuborganizationAvailableModel[]> {
    return this.http.get<SuborganizationAvailableModel[]>(BASE_URL);
  }

  getSuborganizationAvailableById(suborganizationavailableId: number): Observable<SuborganizationAvailableModel> {
    return this.http.get<SuborganizationAvailableModel>(BASE_URL + `/${suborganizationavailableId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findSuborganizationAvailables(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/inventory';
    
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the suborganizationavailable on the server
  updateSuborganizationAvailable(suborganizationavailable: SuborganizationAvailableModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, suborganizationavailable, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForSuborganizationAvailable(suborganizationavailables: SuborganizationAvailableModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      suborganizationavailablesForUpdate: suborganizationavailables,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the suborganizationavailable from the server
  deleteSuborganizationAvailable(suborganizationavailableId: number): Observable<SuborganizationAvailableModel> {
    const url = `${BASE_URL}/${suborganizationavailableId}`;
    return this.http.delete<SuborganizationAvailableModel>(url);
  }

  deleteSuborganizationAvailables(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteSuborganizationAvailables';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { suborganizationavailableIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
