// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay, finalize, takeUntil, } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Services
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import { User, AuthService, UserLoaded, AuthNoticeService } from '../../../../../core/auth';
// Models
import { AppState } from '../../../../../core/reducers';
import { QueryParamsModel } from '../../../../../core/_base/crud';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../../../auth/register/confirm-password.validator';
import { MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";

@Component({
	selector: 'kt-my-account-list',
	templateUrl: './my-account-list.component.html',
	styleUrls: ['my-account-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountListComponent implements OnInit, OnDestroy {
  	accountData: any = {};
	profileForm: FormGroup;
	passwordForm: FormGroup;
	loading = false;
	errors: any = [];
	user: User;
	private unsubscribe: Subject<any>;
	durationInSeconds = 5;

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param userService: UserService
	 */
	constructor(
		public snackBar: MatSnackBar,
		private fb: FormBuilder,
		public authService: AuthService,
		private store: Store<AppState>,
		private layoutUtilsService: LayoutUtilsService,
		private authNoticeService: AuthNoticeService,
		private cdr: ChangeDetectorRef,
		private _snackBar: MatSnackBar,
    ) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initUpdateProfileForm();
		this.initUpdatePasswordForm();
		this.loadData();
	}

	/**
	 * Load data
	 */
	loadData() {
		this.authService.getUserByToken().subscribe(res => {
			this.user = res.data;
			this.profileForm.get('email').disable();
			this.profileForm.controls['fullname'].setValue(this.user.name);
			this.profileForm.controls['email'].setValue(this.user.email);
			this.passwordForm.controls['passwordOld'].setValue('');
			this.passwordForm.controls['password'].setValue('');
			this.passwordForm.controls['confirmPassword'].setValue('');
			this.loading = false;
		});
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initUpdateProfileForm() {
		this.profileForm = this.fb.group({
			fullname: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			]),
			]
		});
	}

	initUpdatePasswordForm() {
		this.passwordForm = this.fb.group({
			passwordOld: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	isControlProfileHasError(controlName: string, validationType: string): boolean {
		const control = this.profileForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	isControlPasswordHasError(controlName: string, validationType: string): boolean {
		const control = this.passwordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	submitProfile() {
		const controls = this.profileForm.controls;

		// check form
		if (this.profileForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		this.user.name = controls.fullname.value;
		const data = {name : controls.fullname.value}
		this.authService.updateProfile(data)
		.subscribe(
			data => {
				const _user: User = new User();
				_user.name = controls['fullname'].value;
				this.store.dispatch(new UserLoaded({ user: _user }));
				this.loading = false;
				this.layoutUtilsService.showActionNotification("Berhasil disimpan", MessageType.Update);
				this.loadData();
			},
			err => {
				this.loading = false;
				this.loadData();
				this._snackBar.open(err.error.meta.message, '', {duration: this.durationInSeconds * 1000});
			}
		)
	}

	submitPassword() {
		const controls = this.passwordForm.controls;

		// check form
		if (this.passwordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const data = {
			current_password : controls.passwordOld.value,
			password : controls.password.value,
			password_confirmation : controls.confirmPassword.value,
		}
		this.authService.updatePassword(data)
		.subscribe(
			data => {
				this.loading = false;
				this.layoutUtilsService.showActionNotification("Berhasil disimpan", MessageType.Update);
				this.loadData();
			},
			err => {
				this.loading = false;
				this.loadData();
				this._snackBar.open(err.error.meta.message, '', {duration: this.durationInSeconds * 1000});
			}
		)
	}

	updateReceive(hashed_id) {
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
		this.loading = false;
	}


}
