// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../partials/partials.module';
// Auth
import { ModuleGuard } from '../../../core/auth';
// Core => Services
import {
	usersReducer,
	UserEffects,
	UserService,
} from '../../../core/user';
import {
	locationallsReducer,
	LocationAllEffects,
	LocationAllService,
	moderatorallsReducer,
	ModeratorAllEffects,
	ModeratorAllService,
	suborganizationallsReducer,
	SuborganizationAllEffects,
	SuborganizationAllService,
	locationavailablesReducer,
	LocationAvailableEffects,
	LocationAvailableService,
	moderatoravailablesReducer,
	ModeratorAvailableEffects,
	ModeratorAvailableService,
	suborganizationavailablesReducer,
	SuborganizationAvailableEffects,
	SuborganizationAvailableService,
	detailshipmentsReducer,
	DetailShipmentEffects,
	DetailShipmentService,
} from '../../../core/kartanu';
// Core => Utils
import { HttpUtilsService,
	TypesUtilsService,
	InterceptService,
	LayoutUtilsService
} from '../../../core/_base/crud';
// Shared
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from '../../partials/content/crud';
// Components
import { KartanuComponent } from './kartanu.component';
// Approve Card
import { ApproveCardListComponent } from './approve-card/approve-card-list/approve-card-list.component';
import { ApproveCardEditComponent } from './approve-card/approve-card-edit/approve-card-edit.dialog.component';
// Send Card
import { SendCardListComponent } from './send-card/send-card-list/send-card-list.component';
import { SendCardEditComponent } from './send-card/send-card-edit/send-card-edit.dialog.component';
// Receive Card
import { ReceiveCardListComponent } from './receive-card/receive-card-list/receive-card-list.component';
import { ReceiveCardEditComponent } from './receive-card/receive-card-edit/receive-card-edit.dialog.component';
// Request Card
import { RequestCardListComponent } from './request-card/request-card-list/request-card-list.component';
import { RequestCardEditComponent } from './request-card/request-card-edit/request-card-edit.dialog.component';
import { RequestCardConfirmationComponent } from './request-card/request-card-confirmation/request-card-confirmation.dialog.component';
import { RequestCardResultComponent } from './request-card/request-card-result/request-card-result.dialog.component';
// All Card
import { AllCardListComponent } from './all-card/all-card-list/all-card-list.component';
// Available Card
import { AvailableCardListComponent } from './available-card/available-card-list/available-card-list.component';
// History Card
import { HistoryCardListComponent } from './history-card/history-card-list/history-card-list.component';
import { HistoryCardEditComponent } from './history-card/history-card-edit/history-card-edit.dialog.component';
// Detail Shipment
import { DetailShipmentListComponent } from './detail-shipment/detail-shipment-list/detail-shipment-list.component';
import { DetailShipmentEditComponent } from './detail-shipment/detail-shipment-edit/detail-shipment-edit.dialog.component';
// Button Spinner
import { MatProgressButtonsModule } from 'mat-progress-buttons';

// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
  MatFormFieldModule
} from '@angular/material';
// Date Range
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../core/_base/layout';

import { environment } from '../../../../environments/environment';
import { CoreModule } from '../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: KartanuComponent,
		children: [
			{
				path: '',
				redirectTo: 'kartanu',
				pathMatch: 'full'
			},
			{
				path: 'all-card',
				component: AllCardListComponent
			},
			{
				path: 'available-card',
				component: AvailableCardListComponent
			},
			{
				path: 'request-card',
				component: RequestCardListComponent
			},
			{
				path: 'send-card',
				component: SendCardListComponent
			},
			{
				path: 'approve-card',
				component: ApproveCardListComponent
			},
			{
				path: 'receive-card',
				component: ReceiveCardListComponent
			},
			{
				path: 'history-card',
				component: HistoryCardListComponent
			},
			{
				path: 'detail-shipment/:id',
				component: DetailShipmentListComponent
			},
		]
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgxPermissionsModule.forChild(),
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
  	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatProgressButtonsModule,
		StoreModule.forFeature('users', usersReducer),
		EffectsModule.forFeature([UserEffects]),
		StoreModule.forFeature('locationalls', locationallsReducer),
		EffectsModule.forFeature([LocationAllEffects]),
		StoreModule.forFeature('moderatoralls', moderatorallsReducer),
		EffectsModule.forFeature([ModeratorAllEffects]),
		StoreModule.forFeature('suborganizationalls', suborganizationallsReducer),
		EffectsModule.forFeature([SuborganizationAllEffects]),
		StoreModule.forFeature('locationavailables', locationavailablesReducer),
		EffectsModule.forFeature([LocationAvailableEffects]),
		StoreModule.forFeature('moderatoravailables', moderatoravailablesReducer),
		EffectsModule.forFeature([ModeratorAvailableEffects]),
		StoreModule.forFeature('suborganizationavailables', suborganizationavailablesReducer),
		EffectsModule.forFeature([SuborganizationAvailableEffects]),
		StoreModule.forFeature('detailshipments', detailshipmentsReducer),
		EffectsModule.forFeature([DetailShipmentEffects]),
	],
	providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    DatePipe,
		ModuleGuard,
		InterceptService,
      	{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
        	multi: true
      	},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		UserService,
		LocationAllService,
		ModeratorAllService,
		SuborganizationAllService,
		LocationAvailableService,
		ModeratorAvailableService,
		SuborganizationAvailableService,
		DetailShipmentService,
		TypesUtilsService,
		LayoutUtilsService,
	],
	entryComponents: [
		ActionNotificationComponent,
		ApproveCardEditComponent,
		HistoryCardEditComponent,
		DetailShipmentEditComponent,
		SendCardEditComponent,
		ReceiveCardEditComponent,
		RequestCardEditComponent,
		RequestCardConfirmationComponent,
		RequestCardResultComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
	declarations: [
		KartanuComponent,
		AllCardListComponent,
		AvailableCardListComponent,
		HistoryCardListComponent,
		DetailShipmentListComponent,
		DetailShipmentEditComponent,
		ApproveCardListComponent,
		ApproveCardEditComponent,
		HistoryCardEditComponent,
		SendCardListComponent,
		ReceiveCardListComponent,
		SendCardEditComponent,
		ReceiveCardEditComponent,
		RequestCardListComponent,
		RequestCardEditComponent,
		RequestCardConfirmationComponent,
		RequestCardResultComponent,
	]
})
export class KartanuModule { }
