import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { OrganizationService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    OrganizationActionTypes,
    OrganizationsPageRequested,
    OrganizationsPageLoaded,
    ManyOrganizationsDeleted,
    OneOrganizationDeleted,
    OrganizationActionToggleLoading,
    OrganizationsPageToggleLoading,
    OrganizationUpdated,
    OrganizationsStatusUpdated,
    OrganizationCreated,
    OrganizationOnServerCreated
} from '../_actions/organization.actions';
import { of } from 'rxjs';

@Injectable()
export class OrganizationEffects {
    showPageLoadingDistpatcher = new OrganizationsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new OrganizationActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new OrganizationActionToggleLoading({ isLoading: false });

    @Effect()
    loadOrganizationsPage$ = this.actions$.pipe(
        ofType<OrganizationsPageRequested>(OrganizationActionTypes.OrganizationsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.organizationsService.findOrganizations(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new OrganizationsPageLoaded({
                organizations: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteOrganization$ = this.actions$
        .pipe(
            ofType<OneOrganizationDeleted>(OrganizationActionTypes.OneOrganizationDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.organizationsService.deleteOrganization(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteOrganizations$ = this.actions$
        .pipe(
            ofType<ManyOrganizationsDeleted>(OrganizationActionTypes.ManyOrganizationsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.organizationsService.deleteOrganizations(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateOrganization$ = this.actions$
        .pipe(
            ofType<OrganizationUpdated>(OrganizationActionTypes.OrganizationUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.organizationsService.updateOrganization(payload.organization);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateOrganizationsStatus$ = this.actions$
        .pipe(
            ofType<OrganizationsStatusUpdated>(OrganizationActionTypes.OrganizationsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.organizationsService.updateStatusForOrganization(payload.organizations, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createOrganization$ = this.actions$
        .pipe(
            ofType<OrganizationOnServerCreated>(OrganizationActionTypes.OrganizationOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.organizationsService.createOrganization(payload.organization).pipe(
                    tap(res => {
                        this.store.dispatch(new OrganizationCreated({ organization: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private organizationsService: OrganizationService, private store: Store<AppState>) { }
}
