import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { LocationAllService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    LocationAllActionTypes,
    LocationAllsPageRequested,
    LocationAllsPageLoaded,
    ManyLocationAllsDeleted,
    OneLocationAllDeleted,
    LocationAllActionToggleLoading,
    LocationAllsPageToggleLoading,
    LocationAllUpdated,
    LocationAllsStatusUpdated,
    LocationAllCreated,
    LocationAllOnServerCreated
} from '../_actions/locationall.actions';
import { of } from 'rxjs';

@Injectable()
export class LocationAllEffects {
    showPageLoadingDistpatcher = new LocationAllsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new LocationAllActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new LocationAllActionToggleLoading({ isLoading: false });

    @Effect()
    loadLocationAllsPage$ = this.actions$.pipe(
        ofType<LocationAllsPageRequested>(LocationAllActionTypes.LocationAllsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.locationallsService.findLocationAlls(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new LocationAllsPageLoaded({
                locationalls: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteLocationAll$ = this.actions$
        .pipe(
            ofType<OneLocationAllDeleted>(LocationAllActionTypes.OneLocationAllDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.locationallsService.deleteLocationAll(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteLocationAlls$ = this.actions$
        .pipe(
            ofType<ManyLocationAllsDeleted>(LocationAllActionTypes.ManyLocationAllsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.locationallsService.deleteLocationAlls(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateLocationAll$ = this.actions$
        .pipe(
            ofType<LocationAllUpdated>(LocationAllActionTypes.LocationAllUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.locationallsService.updateLocationAll(payload.locationall);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateLocationAllsStatus$ = this.actions$
        .pipe(
            ofType<LocationAllsStatusUpdated>(LocationAllActionTypes.LocationAllsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.locationallsService.updateStatusForLocationAll(payload.locationalls, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createLocationAll$ = this.actions$
        .pipe(
            ofType<LocationAllOnServerCreated>(LocationAllActionTypes.LocationAllOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.locationallsService.createLocationAll(payload.locationall).pipe(
                    tap(res => {
                        this.store.dispatch(new LocationAllCreated({ locationall: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private locationallsService: LocationAllService, private store: Store<AppState>) { }
}
