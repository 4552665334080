// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { NonActiveUserModel } from '../_models/nonactiveuser.model';

export enum NonActiveUserActionTypes {
    NonActiveUserOnServerCreated = '[Edit NonActiveUser Dialog] NonActiveUser On Server Created',
    NonActiveUserCreated = '[Edit NonActiveUser Dialog] NonActiveUser Created',
    NonActiveUserUpdated = '[Edit NonActiveUser Dialog] NonActiveUser Updated',
    NonActiveUsersStatusUpdated = '[NonActiveUser List Page] NonActiveUsers Status Updated',
    OneNonActiveUserDeleted = '[NonActiveUsers List Page] One NonActiveUser Deleted',
    ManyNonActiveUsersDeleted = '[NonActiveUsers List Page] Many NonActiveUser Deleted',
    NonActiveUsersPageRequested = '[NonActiveUsers List Page] NonActiveUsers Page Requested',
    NonActiveUsersPageLoaded = '[NonActiveUsers API] NonActiveUsers Page Loaded',
    NonActiveUsersPageCancelled = '[NonActiveUsers API] NonActiveUsers Page Cancelled',
    NonActiveUsersPageToggleLoading = '[NonActiveUsers] NonActiveUsers Page Toggle Loading',
    NonActiveUserActionToggleLoading = '[NonActiveUsers] NonActiveUsers Action Toggle Loading'
}

export class NonActiveUserOnServerCreated implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUserOnServerCreated;
    constructor(public payload: { nonactiveuser: NonActiveUserModel }) { }
}

export class NonActiveUserCreated implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUserCreated;
    constructor(public payload: { nonactiveuser: NonActiveUserModel }) { }
}

export class NonActiveUserUpdated implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUserUpdated;
    constructor(public payload: {
        partialNonActiveUser: Update<NonActiveUserModel>, // For State update
        nonactiveuser: NonActiveUserModel // For Server update (through service)
    }) { }
}

export class NonActiveUsersStatusUpdated implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUsersStatusUpdated;
    constructor(public payload: {
        nonactiveusers: NonActiveUserModel[],
        status: number
    }) { }
}

export class OneNonActiveUserDeleted implements Action {
    readonly type = NonActiveUserActionTypes.OneNonActiveUserDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyNonActiveUsersDeleted implements Action {
    readonly type = NonActiveUserActionTypes.ManyNonActiveUsersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class NonActiveUsersPageRequested implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUsersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class NonActiveUsersPageLoaded implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUsersPageLoaded;
    constructor(public payload: { nonactiveusers: NonActiveUserModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class NonActiveUsersPageCancelled implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUsersPageCancelled;
}

export class NonActiveUsersPageToggleLoading implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUsersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class NonActiveUserActionToggleLoading implements Action {
    readonly type = NonActiveUserActionTypes.NonActiveUserActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type NonActiveUserActions = NonActiveUserOnServerCreated
| NonActiveUserCreated
| NonActiveUserUpdated
| NonActiveUsersStatusUpdated
| OneNonActiveUserDeleted
| ManyNonActiveUsersDeleted
| NonActiveUsersPageRequested
| NonActiveUsersPageLoaded
| NonActiveUsersPageCancelled
| NonActiveUsersPageToggleLoading
| NonActiveUserActionToggleLoading;
