// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { DetailShipmentActions, DetailShipmentActionTypes } from '../_actions/detailshipment.actions';
// Models
import { DetailShipmentModel } from '../_models/detailshipment.model';
import { QueryParamsDetailShipmentModel } from '../../_base/crud';

export interface DetailShipmentsState extends EntityState<DetailShipmentModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDetailShipmentId: number;
    lastQuery: QueryParamsDetailShipmentModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<DetailShipmentModel> = createEntityAdapter<DetailShipmentModel>();

export const initialDetailShipmentsState: DetailShipmentsState = adapter.getInitialState({
    detailshipmentForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedDetailShipmentId: undefined,
    lastQuery: new QueryParamsDetailShipmentModel({}),
    showInitWaitingMessage: true
});

export function detailshipmentsReducer(state = initialDetailShipmentsState, action: DetailShipmentActions): DetailShipmentsState {
    switch  (action.type) {
        case DetailShipmentActionTypes.DetailShipmentsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDetailShipmentId: undefined
            };
        }
        case DetailShipmentActionTypes.DetailShipmentActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case DetailShipmentActionTypes.DetailShipmentOnServerCreated: return {
            ...state
        };
        // case DetailShipmentActionTypes.DetailShipmentCreated: return adapter.addOne(action.payload.detailshipment, {
        //     ...state, lastCreatedDetailShipmentId: action.payload.detailshipment.id
        // });
        case DetailShipmentActionTypes.DetailShipmentUpdated: return adapter.updateOne(action.payload.partialDetailShipment, state);
       //  case DetailShipmentActionTypes.DetailShipmentsStatusUpdated: {
       //      const _partialDetailShipments: Update<DetailShipmentModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.detailshipments.length; i++) {
       //          _partialDetailShipments.push({
           //  id: action.payload.detailshipments[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialDetailShipments, state);
       //  }
        case DetailShipmentActionTypes.OneDetailShipmentDeleted: return adapter.removeOne(action.payload.id, state);
        case DetailShipmentActionTypes.ManyDetailShipmentsDeleted: return adapter.removeMany(action.payload.ids, state);
        case DetailShipmentActionTypes.DetailShipmentsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsDetailShipmentModel({})
            };
        }
        case DetailShipmentActionTypes.DetailShipmentsPageLoaded: {
            return adapter.addMany(action.payload.detailshipments, {
                ...initialDetailShipmentsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getDetailShipmentState = createFeatureSelector<DetailShipmentModel>('detailshipments');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
