<div class="kt-portlet"
	[ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : viewLoading }">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
		</div>
	</div>
	<form class="kt-form">
		<div class="kt-portlet__body">
			<div class="row">
				<div class="col-lg-12">
					Halo Lazisnu!
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-lg-12">
					<b>Kami sudah menerima permintaan Cetak KartaNU kamu dengan detail sebagai berikut : </b>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-lg-6">
					Nomor Urut Cetak KartaNU
				</div>
				<div class="col-lg-6">
					: {{detailResult.serial_number}}
				</div>
				<div class="col-lg-6">
					Jumlah KartaNU Yang Dicetak
				</div>
				<div class="col-lg-6">
					: {{detailResult.total}} buah
				</div>
				<div class="col-lg-6">
					Alamat Pengiriman
				</div>
				<div class="col-lg-6">
					: Kantor Lazisnu 
				</div>
				<div class="col-lg-6">
					Estimasi Selesai Cetak
				</div>
				<div class="col-lg-6">
					: 30 hari, terhitung sejak permintaan cetak diterima
				</div>
				<div class="col-lg-6">
					Estimasi Sampai Di Alamat
				</div>
				<div class="col-lg-6">
					: 2-5 hari kerja
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-lg-12">
					Semua proses permintaanmu akan diinformasikan secara berkala melalui email. 
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-lg-12">
					Terima kasih!
				</div>
			</div>
		</div>

		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
			<div class="kt-form__actions kt-form__actions--solid">
				<div class="row text-right">
					<div class="col-lg-12">
						<button type="button" mat-button color="accent" (click)="onNoClick()">
							Kembali ke halaman utama
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
