import { BaseModel } from '../../_base/crud';

export class AllUserCoinModel  extends BaseModel {
	id: string;
	user: any;
	idr_total: any;
	invoice: any;
	status: any;
	base_reward: any;
	note: any;
	created_at: string;

	clear() {
		this.id = '';
	    this.user = undefined;
	    this.idr_total = '';
		this.invoice = '';
		this.status = '';
		this.base_reward = '';
		this.note = '';
	    this.created_at = '';
	}
}
