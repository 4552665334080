import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { ActiveUserService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    ActiveUserActionTypes,
    ActiveUsersPageRequested,
    ActiveUsersPageLoaded,
    ManyActiveUsersDeleted,
    OneActiveUserDeleted,
    ActiveUserActionToggleLoading,
    ActiveUsersPageToggleLoading,
    ActiveUserUpdated,
    ActiveUsersStatusUpdated,
    ActiveUserCreated,
    ActiveUserOnServerCreated
} from '../_actions/activeuser.actions';
import { of } from 'rxjs';

@Injectable()
export class ActiveUserEffects {
    showPageLoadingDistpatcher = new ActiveUsersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ActiveUserActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ActiveUserActionToggleLoading({ isLoading: false });

    @Effect()
    loadActiveUsersPage$ = this.actions$.pipe(
        ofType<ActiveUsersPageRequested>(ActiveUserActionTypes.ActiveUsersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.activeusersService.findActiveUsers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new ActiveUsersPageLoaded({
                activeusers: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteActiveUser$ = this.actions$
        .pipe(
            ofType<OneActiveUserDeleted>(ActiveUserActionTypes.OneActiveUserDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.activeusersService.deleteActiveUser(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteActiveUsers$ = this.actions$
        .pipe(
            ofType<ManyActiveUsersDeleted>(ActiveUserActionTypes.ManyActiveUsersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.activeusersService.deleteActiveUsers(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateActiveUser$ = this.actions$
        .pipe(
            ofType<ActiveUserUpdated>(ActiveUserActionTypes.ActiveUserUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.activeusersService.updateActiveUser(payload.activeuser);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateActiveUsersStatus$ = this.actions$
        .pipe(
            ofType<ActiveUsersStatusUpdated>(ActiveUserActionTypes.ActiveUsersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.activeusersService.updateStatusForActiveUser(payload.activeusers, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createActiveUser$ = this.actions$
        .pipe(
            ofType<ActiveUserOnServerCreated>(ActiveUserActionTypes.ActiveUserOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.activeusersService.createActiveUser(payload.activeuser).pipe(
                    tap(res => {
                        this.store.dispatch(new ActiveUserCreated({ activeuser: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private activeusersService: ActiveUserService, private store: Store<AppState>) { }
}
