// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { PwnuUserModel } from '../_models/pwnuuser.model';

export enum PwnuUserActionTypes {
    PwnuUserOnServerCreated = '[Edit PwnuUser Dialog] PwnuUser On Server Created',
    PwnuUserCreated = '[Edit PwnuUser Dialog] PwnuUser Created',
    PwnuUserUpdated = '[Edit PwnuUser Dialog] PwnuUser Updated',
    PwnuUsersStatusUpdated = '[PwnuUser List Page] PwnuUsers Status Updated',
    OnePwnuUserDeleted = '[PwnuUsers List Page] One PwnuUser Deleted',
    ManyPwnuUsersDeleted = '[PwnuUsers List Page] Many PwnuUser Deleted',
    PwnuUsersPageRequested = '[PwnuUsers List Page] PwnuUsers Page Requested',
    PwnuUsersPageLoaded = '[PwnuUsers API] PwnuUsers Page Loaded',
    PwnuUsersPageCancelled = '[PwnuUsers API] PwnuUsers Page Cancelled',
    PwnuUsersPageToggleLoading = '[PwnuUsers] PwnuUsers Page Toggle Loading',
    PwnuUserActionToggleLoading = '[PwnuUsers] PwnuUsers Action Toggle Loading'
}

export class PwnuUserOnServerCreated implements Action {
    readonly type = PwnuUserActionTypes.PwnuUserOnServerCreated;
    constructor(public payload: { pwnuuser: PwnuUserModel }) { }
}

export class PwnuUserCreated implements Action {
    readonly type = PwnuUserActionTypes.PwnuUserCreated;
    constructor(public payload: { pwnuuser: PwnuUserModel }) { }
}

export class PwnuUserUpdated implements Action {
    readonly type = PwnuUserActionTypes.PwnuUserUpdated;
    constructor(public payload: {
        partialPwnuUser: Update<PwnuUserModel>, // For State update
        pwnuuser: PwnuUserModel // For Server update (through service)
    }) { }
}

export class PwnuUsersStatusUpdated implements Action {
    readonly type = PwnuUserActionTypes.PwnuUsersStatusUpdated;
    constructor(public payload: {
        pwnuusers: PwnuUserModel[],
        status: number
    }) { }
}

export class OnePwnuUserDeleted implements Action {
    readonly type = PwnuUserActionTypes.OnePwnuUserDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyPwnuUsersDeleted implements Action {
    readonly type = PwnuUserActionTypes.ManyPwnuUsersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class PwnuUsersPageRequested implements Action {
    readonly type = PwnuUserActionTypes.PwnuUsersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class PwnuUsersPageLoaded implements Action {
    readonly type = PwnuUserActionTypes.PwnuUsersPageLoaded;
    constructor(public payload: { pwnuusers: PwnuUserModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class PwnuUsersPageCancelled implements Action {
    readonly type = PwnuUserActionTypes.PwnuUsersPageCancelled;
}

export class PwnuUsersPageToggleLoading implements Action {
    readonly type = PwnuUserActionTypes.PwnuUsersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PwnuUserActionToggleLoading implements Action {
    readonly type = PwnuUserActionTypes.PwnuUserActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type PwnuUserActions = PwnuUserOnServerCreated
| PwnuUserCreated
| PwnuUserUpdated
| PwnuUsersStatusUpdated
| OnePwnuUserDeleted
| ManyPwnuUsersDeleted
| PwnuUsersPageRequested
| PwnuUsersPageLoaded
| PwnuUsersPageCancelled
| PwnuUsersPageToggleLoading
| PwnuUserActionToggleLoading;
