// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-historycards-edit-dialog',
	templateUrl: './history-card-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class HistoryCardEditComponent implements OnInit, OnDestroy {
	// Public properties
	viewLoading: boolean = false;
    detailUser: any = {};
    receiveData: any = {hashed_id: '', suborganization_id: '', received_by: ''};
    suborganizationList: any = [];
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<GoldBuyEditComponent>
	 * @param data: any
	 */
	constructor(public dialogRef: MatDialogRef<HistoryCardEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.detailUser = this.data.length;
		this.suborganizationList = this.detailUser.suborganizationList;
		this.receiveData.hashed_id = this.detailUser.hashed_id
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
  
  onSelectChange() {

  }

	/**
	 * Returns page title
	 */
	getTitle(): string {
		return 'Terima KartaNU';
	}
}
