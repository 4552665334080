// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { NonActiveUserActions, NonActiveUserActionTypes } from '../_actions/nonactiveuser.actions';
// Models
import { NonActiveUserModel } from '../_models/nonactiveuser.model';
import { QueryParamsModel } from '../../_base/crud';

export interface NonActiveUsersState extends EntityState<NonActiveUserModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedNonActiveUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<NonActiveUserModel> = createEntityAdapter<NonActiveUserModel>();

export const initialNonActiveUsersState: NonActiveUsersState = adapter.getInitialState({
    nonactiveuserForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedNonActiveUserId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function nonactiveusersReducer(state = initialNonActiveUsersState, action: NonActiveUserActions): NonActiveUsersState {
    switch  (action.type) {
        case NonActiveUserActionTypes.NonActiveUsersPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedNonActiveUserId: undefined
            };
        }
        case NonActiveUserActionTypes.NonActiveUserActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case NonActiveUserActionTypes.NonActiveUserOnServerCreated: return {
            ...state
        };
        // case NonActiveUserActionTypes.NonActiveUserCreated: return adapter.addOne(action.payload.nonactiveuser, {
        //     ...state, lastCreatedNonActiveUserId: action.payload.nonactiveuser.id
        // });
        case NonActiveUserActionTypes.NonActiveUserUpdated: return adapter.updateOne(action.payload.partialNonActiveUser, state);
       //  case NonActiveUserActionTypes.NonActiveUsersStatusUpdated: {
       //      const _partialNonActiveUsers: Update<NonActiveUserModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.nonactiveusers.length; i++) {
       //          _partialNonActiveUsers.push({
           //  id: action.payload.nonactiveusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialNonActiveUsers, state);
       //  }
        case NonActiveUserActionTypes.OneNonActiveUserDeleted: return adapter.removeOne(action.payload.id, state);
        case NonActiveUserActionTypes.ManyNonActiveUsersDeleted: return adapter.removeMany(action.payload.ids, state);
        case NonActiveUserActionTypes.NonActiveUsersPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case NonActiveUserActionTypes.NonActiveUsersPageLoaded: {
            return adapter.addMany(action.payload.nonactiveusers, {
                ...initialNonActiveUsersState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getNonActiveUserState = createFeatureSelector<NonActiveUserModel>('nonactiveusers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
