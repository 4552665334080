// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { BigOrdersState } from '../_reducers/bigorder.reducers';
import { BigOrderModel } from '../_models/bigorder.model';

export const selectBigOrdersState = createFeatureSelector<BigOrdersState>('bigorders');

export const selectBigOrderById = (bigorderId: number) => createSelector(
    selectBigOrdersState,
    bigordersState => bigordersState.entities[bigorderId]
);

export const selectBigOrdersPageLoading = createSelector(
    selectBigOrdersState,
    bigordersState => bigordersState.listLoading
);

export const selectBigOrdersActionLoading = createSelector(
    selectBigOrdersState,
    bigordersState => bigordersState.actionsloading
);

export const selectLastCreatedBigOrderId = createSelector(
    selectBigOrdersState,
    bigordersState => bigordersState.lastCreatedBigOrderId
);

export const selectBigOrdersShowInitWaitingMessage = createSelector(
    selectBigOrdersState,
    bigordersState => bigordersState.showInitWaitingMessage
);

export const selectBigOrdersInStore = createSelector(
    selectBigOrdersState,
    bigordersState => {
        const items: BigOrderModel[] = [];
        each(bigordersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BigOrderModel[] = httpExtension.sortArray(items, bigordersState.lastQuery.sortField, bigordersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, bigordersState.totalCount, '');
    }
);
