// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take, map, startWith } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of, Observable } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  UserModel,
  UserService,
  UsersDataSource,
  UsersPageRequested,
  OneUserDeleted,
  ManyUsersDeleted,
  UsersStatusUpdated
} from '../../../../../core/user';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
// Components
import { RequestCardEditComponent } from '../request-card-edit/request-card-edit.dialog.component';
import { RequestCardConfirmationComponent } from '../request-card-confirmation/request-card-confirmation.dialog.component';
import { RequestCardResultComponent } from '../request-card-result/request-card-result.dialog.component';

export interface Shipment {
  id: string;
  serial_number: string;
}

@Component({
	selector: 'kt-requestcards-list',
	templateUrl: './request-card-list.component.html',
	styleUrls: ['request-card-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class RequestCardListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: UsersDataSource;
	displayedColumnsSelect = ['select', 'user.name', 'user.id_member', 'suborganization.moderator', 'user.status', 'transaction.last_transaction'];
	displayedColumns = ['user.name', 'user.id_member', 'suborganization.moderator', 'transaction.last_transaction', 'user.status'];
	displayedColumnsSend = ['user.name', 'user.id_member', 'suborganization.moderator', 'user.status'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	dateRange: any = {};

  nameForm: FormGroup;

  loading = false;  	
	selectedFilter: string = '[1]';
	selectedTabIndex: any;
	
	myControl = new FormControl();
	shipmentList: Shipment[];
	receiveData: any = {hashed_id: '', received_by: ''};
	filteredShipmentList: Observable<Shipment[]>;

	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<UserModel>(true, []);
	requestcardsResult: UserModel[] = [];

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
    private fb: FormBuilder,
		public userService: UserService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
 

	/**
	 * On init
	 */
	ngOnInit() {
    this.initNameForm();
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadRequestCardsList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		this.loadShipmentList();

		// Init DataSource
		this.dataSource = new UsersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.requestcardsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1), 
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadRequestCardsList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load RequestCards List from service through data-source
	 */
	loadRequestCardsList() {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const queryParams = new QueryParamsModel(
	        '',
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			this.selectedFilter, // status
			'', // type
			'' // suborganization_id
		);
		// Call request from server
		this.store.dispatch(new UsersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Load Suborganization List
	 */
	loadShipmentList() {
    this.loading = false;
		this.userService.getShipmentList()
		.subscribe(
			data => {
				this.shipmentList = data;
		    this.filteredShipmentList = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(val => this._filter(val))
        );
			}
		)
	}

	displayFn(shipment?: Shipment): string | undefined {
		return shipment ? shipment.serial_number : undefined;
	}

	private _filter(serial_number) {
		const filterValue = serial_number.toLowerCase();
		return this.shipmentList.filter(option => option.serial_number.toLowerCase().includes(filterValue));
	}

	getShipment_id(hashed_id){
		this.receiveData.hashed_id = hashed_id.id;
	}

	receiveShipment(){
    const controls = this.nameForm.controls;

    // check form
    if (this.nameForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

		this.userService.receivedShipment(this.receiveData)
		.subscribe(
			data => {
        this.myControl.setValue('');
        this.receiveData.hashed_id = '';
        this.loadShipmentList();
				this.snackBar.open("Berhasil Disimpan", "", {duration: 1500});
        this.loading = false
        this.nameForm.controls['name'].setValue('')
        this.nameForm.controls['name'].markAsUntouched()
			},err => {
        this.loading = false;
				this.snackBar.open("Gagal Disimpan", "", {duration: 1500});
			}
		)
	}

	onTabChanged() {
		if(this.selectedTabIndex == 0){
			this.selectedFilter = '[1]';
		}else if(this.selectedTabIndex == 1){
			this.selectedFilter = '[2]';
		}else if(this.selectedTabIndex == 2){
			this.selectedFilter = '[3]';
		}
    this.loadRequestCardsList();
	}

	showResultShipment(datas) {
		this.userService.requestShipment(datas)
		.subscribe(
			data => {
				const dialogResult = this.dialog.open(RequestCardResultComponent, { data: { data } });
				dialogResult.afterClosed().subscribe(res => {
					if (!res) {
						this.loadRequestCardsList();
						return;
					}
				});
			});
	}

	/**
	 * Show Edit requestcard dialog and save after success close result
	 * @param requestcard: UserModel
	 */
	editRequestCard(hashed_id) {
		const _saveMessage = "show";
		const _messageType = MessageType.Update;

		// Get Detail User
		this.userService.getDetailUser(hashed_id)
		.subscribe(
			data => {
				const dialogRef = this.dialog.open(RequestCardEditComponent, { data: { data } });
				dialogRef.afterClosed().subscribe(res => {
					if (!res) {
						return;
					}

					this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
					this.loadRequestCardsList();
				});
			}
		)
	}
	
	/**
	 * Fetch selected requestcards
	 */
	fetchRequestCards() {
		const messages = {id_member: []};
		this.selection.selected.forEach(elem => {
			messages.id_member.push(elem.id.toString());
		});
		const length = this.selection.selected.length;
		const dialogConf = this.dialog.open(RequestCardConfirmationComponent, { data: { length }, width: '500px' });
		dialogConf.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			this.showResultShipment(messages);
		});
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.requestcardsResult.length;
		return numSelected === numRows;
	}

	/**
	 * Toggle selection
	 */
	masterToggle() {
		if (this.selection.selected.length === this.requestcardsResult.length) {
			this.selection.clear();
		} else {
			this.requestcardsResult.forEach(row => this.selection.select(row));
		}
	}

  isControlNameHasError(controlName: string, validationType: string): boolean {
    const control = this.nameForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  initNameForm() {
    this.nameForm = this.fb.group({
      name: ['', Validators.compose([
        Validators.required,
      ]),
      ]
    });
  }
}
