import { BaseModel } from '../../_base/crud';

export class CoinModel  extends BaseModel {
	id: string;
	user: any;
	insentif: any;
	amount: any;
	rupiah: any;
	organization: any;
	suborganization: any;
	transaction: any;
	created_at: string;

	clear() {
		this.id = '';
	    this.user = undefined;
		this.organization = undefined;
		this.insentif = undefined;
		this.amount = undefined;
		this.rupiah = undefined;
	    this.suborganization = undefined;
	    this.transaction = undefined;
	    this.created_at = '';
	}
}
