export class MenuConfig {
	public defaults: any = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [
        {section: 'Menu'},
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/home',
          permission: 'b2b_dashboard',
          bullet: 'dot'
		},
		{
			title: 'Calculator Big Order',
			root: true,
			icon: 'fa fa-money-bill',
			page: '/bigorder',
			permission: 'b2b_big_order',
			bullet: 'dot'
		},
		{
			title: 'Transaction Big Order',
			root: true,
			icon: 'flaticon2-list-2',
			page: '/transaction/big-order',
			permission: 'b2b_big_order',
			bullet: 'dot'
		},
		{
			title: 'Transaction Koin Nusantara',
			root: true,
			icon: 'flaticon2-list-2',
			page: '/transaction/coin',
			permission: 'b2b_big_order',
			bullet: 'dot'
		},
        {
          title: 'Listing User',
          root: true,
          bullet: 'dot',
          icon: 'fa fa-user-alt',
          permission: 'b2b_list_user',
          submenu: [
            {
              title: 'Semua User',
              permission: 'b2b_list_user',
              page: '/user/all-user'
			},
            {
              title: 'Aktif Kartanu',
              permission: 'b2b_users',
              page: '/user/active-user-kartanu'
			},
			{
			title: 'Aktif Transaksi',
			permission: 'b2b_users',
			page: '/user/active-user-transaction'
			},
            {
              title: 'Non-Aktif',
              permission: 'b2b_users',
              page: '/user/non-active-user'
            }
          ]
        },
        {
          title: 'Donasi',
          root: true,
          icon: 'flaticon2-list-2',
          page: '/transaction/gold-donation',
          permission: 'list_donation',
          bullet: 'dot'
		},
		{
			title: 'Gift Card Donasi',
			root: true,
			icon: 'flaticon2-list-2',
			page: '/transaction/gold-giftcard',
			permission: 'list_donation',
			bullet: 'dot'
		},
        {
          title: 'Zakat',
          root: true,
          icon: 'flaticon2-list-2',
          page: '/transaction/gold-zakat',
          permission: 'list_zakat',
          bullet: 'dot'
        },
        {
          title: 'Report',
          root: true,
          icon: 'flaticon2-list-2',
          page: '/report',
          permission: 'list_zakat',
          bullet: 'dot'
        },
        {
          title: 'Akses',
          root: true,
          icon: 'flaticon2-list-2',
          page: '/access',
          permission: 'b2b_setting',
          bullet: 'dot'
        },
        {
          title: 'Transaksi',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          permission: 'b2b_users',
          submenu: [
            {
              title: 'Total Beli Emas',
              permission: 'list_goldbuy',
              page: '/transaction/gold-buy'
            },
            {
              title: 'Total Jual Emas',
              permission: 'b2b_users',
              page: '/transaction/gold-sell'
            }
          ]
        },
        {
          title: 'KartaNU',
          bullet: 'dot',
          permission: 'list_card',
          icon: 'flaticon2-calendar-3',
          root: true,
          submenu: [
            {
              title: 'Permintaan Cetak',
              permission: 'list_cardrequest',
              page: '/kartanu/request-card'
            },
            {
              title: 'Permintaan Cetak',
              permission: 'list_cardapprove',
              page: '/kartanu/approve-card'
            },
            {
              title: 'Pengiriman KartaNU',
              permission: 'list_cardsend',
              page: '/kartanu/send-card'
            },
            {
              title: 'Riwayat Data',
              permission: 'list_cardhistory',
              page: '/kartanu/history-card'
            }
          ]
        },
        {
          title: 'Pengaturan',
          root: true,
          bullet: 'dot',
          permission: 'b2b_setting',
          icon: 'flaticon2-gear',
          submenu: [
            {
              title: 'Informasi User',
              permission: 'b2b_account',
              page: '/user-management/user-info'
            },
            {
              title: 'Riwayat Aktifitas',
              permission: 'b2b_history',
              page: '/user-management/user-activity'
            }
          ]
        },
      ]
    },
  };

	public get configs(): any {
		return this.defaults;
	}
}
