// Angular
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BigOrderService } from '../../../core/big-order';
// Widgets model
import { LayoutConfigService } from '../../../core/_base/layout';
import { Location } from '@angular/common';

import { BigOrder } from '../../../core/big-order';


import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';

// Query Params
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../core/_base/crud';

import { interval, Subscription } from 'rxjs';


@Component({
	selector: 'kt-big-order',
	templateUrl: './big-order.component.html',
	styleUrls: ['big-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BigOrderComponent implements OnInit, OnDestroy {
	exampleLabelPositions;

	result: any = {};
	insentif: any = 'Rp 0';
	discount: any = 'Rp 0';
	rupiah_accumulation: any = 'Rp 0';
	amount: string = undefined;
	tier: any = '0';
	profile: any = {};
	labelPosition: string = 'before';
	private subscriptions: Subscription[] = [];
  constructor(
	private layoutConfigService: LayoutConfigService,
	public bigorderService : BigOrderService,
	public snackBar: MatSnackBar,
	private location: Location,
    private cd: ChangeDetectorRef,
  ){}

	ngOnInit(): void {
		this.loadProfile()
	}

	calculateBigOrder(e) {
		e.preventDefault();
		let val = this.amount
		const search = ',';
		const replaceWith = '';

		const result = val.split(search).join(replaceWith);

		const data = {
			amount: result,
		};

		this.bigorderService.calculate(data)
		.subscribe(
			res => {
				this.result = res
				this.tier = res.tier
				this.insentif = res.insentif
				this.discount = res.discount
				this.rupiah_accumulation = res.rupiah_accumulation
				this.snackBar.open("Berhasil menghitung kalkulasi Big Order", '', {duration: 1500});
				this.cd.markForCheck();
			}, err => {
				this.snackBar.open(err.error.meta.message, '',{duration: 1500})
			}
		)
	}

	loadProfile() {
		this.bigorderService.getProfileAgent()
		.subscribe(
			data => {
				this.profile = data
				this.cd.markForCheck();
			}
		)
	}

	onSearchChange(searchValue: string): void {

		this.amount = this.formatRupiah(searchValue, '')
	}

	formatRupiah(number, prefix){
		var number_string = number.replace(/[^.\d]/g, '').toString(),
		split   		= number_string.split('.'),
		rest     		= split[0].length % 3,
		rupiah     		= split[0].substr(0, rest),
		thousand     		= split[0].substr(rest).match(/\d{3}/gi);

		if(thousand){
			let separator = rest ? ',' : '';
			rupiah += separator + thousand.join(',');
		}

		rupiah = split[1] != undefined ? rupiah + '.' + split[1] : rupiah;
		return prefix == undefined ? rupiah : (rupiah ? '' + rupiah : '');
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	back() {
		this.location.back();
	}
}
