// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ModeratorAllActions, ModeratorAllActionTypes } from '../_actions/moderatorall.actions';
// Models
import { ModeratorAllModel } from '../_models/moderatorall.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface ModeratorAllsState extends EntityState<ModeratorAllModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedModeratorAllId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ModeratorAllModel> = createEntityAdapter<ModeratorAllModel>();

export const initialModeratorAllsState: ModeratorAllsState = adapter.getInitialState({
    moderatorallForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedModeratorAllId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function moderatorallsReducer(state = initialModeratorAllsState, action: ModeratorAllActions): ModeratorAllsState {
    switch  (action.type) {
        case ModeratorAllActionTypes.ModeratorAllsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedModeratorAllId: undefined
            };
        }
        case ModeratorAllActionTypes.ModeratorAllActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ModeratorAllActionTypes.ModeratorAllOnServerCreated: return {
            ...state
        };
        case ModeratorAllActionTypes.ModeratorAllCreated: return adapter.addOne(action.payload.moderatorall, {
            ...state, lastCreatedModeratorAllId: action.payload.moderatorall.id
        });
        case ModeratorAllActionTypes.ModeratorAllUpdated: return adapter.updateOne(action.payload.partialModeratorAll, state);
       //  case ModeratorAllActionTypes.ModeratorAllsStatusUpdated: {
       //      const _partialModeratorAlls: Update<ModeratorAllModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.moderatoralls.length; i++) {
       //          _partialModeratorAlls.push({
           //  id: action.payload.moderatoralls[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialModeratorAlls, state);
       //  }
        case ModeratorAllActionTypes.OneModeratorAllDeleted: return adapter.removeOne(action.payload.id, state);
        case ModeratorAllActionTypes.ManyModeratorAllsDeleted: return adapter.removeMany(action.payload.ids, state);
        case ModeratorAllActionTypes.ModeratorAllsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case ModeratorAllActionTypes.ModeratorAllsPageLoaded: {
            return adapter.addMany(action.payload.moderatoralls, {
                ...initialModeratorAllsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getModeratorAllState = createFeatureSelector<ModeratorAllModel>('moderatoralls');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
