<kt-portlet>

	<kt-portlet-body>
		<div class="row">
			<!-- LOCATION -->
			<div class="col-md-4">
		    	<div class="kt-form kt-margin-b-30">
					<div class="kt-form__filtration">
						<div class="row align-items-center">
							<div class="col-md-12 kt-margin-bottom-10-mobile">
								<mat-form-field class="mat-form-field-fluid">
									<input matInput placeholder="Cari Lokasi" #searchInputLocation>
									<mat-hint align="start">
										Pencarian berdasarkan lokasi
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>

				<div class="mat-table__wrapper">
					<mat-table class="lmat-elevation-z8"
						#table
						[dataSource]="dataSourceLocation"
						matSort
						#sortLocation="matSort"
						matSortActive="id"
						matSortDirection="desc"
						matSortDisableClear>

						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
							<mat-cell *matCellDef="let location">{{location?.name}}<br>{{location?.total}} KartaNU</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumnsLocation"></mat-header-row>

						<mat-row *matRowDef="let row; columns: displayedColumnsLocation"></mat-row>
					</mat-table>
					<div class="mat-table__message" *ngIf="!dataSourceLocation.hasItems">No records found</div>
					<div class="mat-table__message" *ngIf="dataSourceLocation.isPreloadTextViewed$ | async">Please wait....</div>
				</div>

				<div class="mat-table__bottom">
					<mat-spinner [diameter]="20" *ngIf="dataSourceLocation.loading$ | async"></mat-spinner>
					<mat-paginator #paginatorLocation="matPaginator" [pageSize]="3" [pageIndex]="1" [pageSizeOptions]="[3, 10, 20]" [length]="dataSourceLocation.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
				</div>
			</div>

			<!-- MODERATOR -->
			<div class="col-md-4">
		    	<div class="kt-form kt-margin-b-30">
					<div class="kt-form__filtration">
						<div class="row align-items-center">
							<div class="col-md-12 kt-margin-bottom-10-mobile">
								<mat-form-field class="mat-form-field-fluid">
									<input matInput placeholder="Cari PIC" #searchInputModerator>
									<mat-hint align="start">
										Pencarian berdasarkan PIC
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>

				<div class="mat-table__wrapper">
					<mat-table class="lmat-elevation-z8"
						#table
						[dataSource]="dataSourceModerator"
						matSort
						#sortModerator="matSort"
						matSortActive="id"
						matSortDirection="desc"
						matSortDisableClear>

						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
							<mat-cell *matCellDef="let location">{{location.name}}<br>{{location.total}} KartaNU</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumnsModerator"></mat-header-row>

						<mat-row *matRowDef="let row; columns: displayedColumnsModerator"></mat-row>
					</mat-table>
					<div class="mat-table__message" *ngIf="!dataSourceModerator.hasItems">No records found</div>
					<div class="mat-table__message" *ngIf="dataSourceModerator.isPreloadTextViewed$ | async">Please wait....</div>
				</div>

				<div class="mat-table__bottom">
					<mat-spinner [diameter]="20" *ngIf="dataSourceModerator.loading$ | async"></mat-spinner>
					<mat-paginator #paginatorModerator="matPaginator" [pageSize]="3" [pageIndex]="1" [pageSizeOptions]="[3, 10, 20]" [length]="dataSourceModerator.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
				</div>
			</div>

			<!-- SUBORGANIZATION -->
			<div class="col-md-4">
		    	<div class="kt-form kt-margin-b-30">
					<div class="kt-form__filtration">
						<div class="row align-items-center">
							<div class="col-md-12 kt-margin-bottom-10-mobile">
								<mat-form-field class="mat-form-field-fluid">
									<input matInput placeholder="Cari PIC" #searchInputSuborganization>
									<mat-hint align="start">
										Pencarian berdasarkan PIC
									</mat-hint>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>

				<div class="mat-table__wrapper">
					<mat-table class="lmat-elevation-z8"
						#table
						[dataSource]="dataSourceSuborganization"
						matSort
						#sortSuborganization="matSort"
						matSortActive="id"
						matSortDirection="desc"
						matSortDisableClear>

						<ng-container matColumnDef="name">
							<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
							<mat-cell *matCellDef="let location">{{location.name}}<br>{{location.total}} KartaNU</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumnsSuborganization"></mat-header-row>

						<mat-row *matRowDef="let row; columns: displayedColumnsSuborganization"></mat-row>
					</mat-table>
					<div class="mat-table__message" *ngIf="!dataSourceSuborganization.hasItems">No records found</div>
					<div class="mat-table__message" *ngIf="dataSourceSuborganization.isPreloadTextViewed$ | async">Please wait....</div>
				</div>

				<div class="mat-table__bottom">
					<mat-spinner [diameter]="20" *ngIf="dataSourceSuborganization.loading$ | async"></mat-spinner>
					<mat-paginator #paginatorSuborganization="matPaginator" [pageSize]="3" [pageIndex]="1" [pageSizeOptions]="[3, 10, 20]" [length]="dataSourceSuborganization.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
				</div>
			</div>
		</div>


	</kt-portlet-body>

</kt-portlet>
