// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { NonActiveUsersState } from '../_reducers/nonactiveuser.reducers';
import { NonActiveUserModel } from '../_models/nonactiveuser.model';

export const selectNonActiveUsersState = createFeatureSelector<NonActiveUsersState>('nonactiveusers');

export const selectNonActiveUserById = (nonactiveuserId: number) => createSelector(
    selectNonActiveUsersState,
    nonactiveusersState => nonactiveusersState.entities[nonactiveuserId]
);

export const selectNonActiveUsersPageLoading = createSelector(
    selectNonActiveUsersState,
    nonactiveusersState => nonactiveusersState.listLoading
);

export const selectNonActiveUsersActionLoading = createSelector(
    selectNonActiveUsersState,
    nonactiveusersState => nonactiveusersState.actionsloading
);

export const selectLastCreatedNonActiveUserId = createSelector(
    selectNonActiveUsersState,
    nonactiveusersState => nonactiveusersState.lastCreatedNonActiveUserId
);

export const selectNonActiveUsersShowInitWaitingMessage = createSelector(
    selectNonActiveUsersState,
    nonactiveusersState => nonactiveusersState.showInitWaitingMessage
);

export const selectNonActiveUsersInStore = createSelector(
    selectNonActiveUsersState,
    nonactiveusersState => {
        const items: NonActiveUserModel[] = [];
        each(nonactiveusersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: NonActiveUserModel[] = httpExtension.sortArray(items, nonactiveusersState.lastQuery.sortField, nonactiveusersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, nonactiveusersState.totalCount, '');
    }
);
