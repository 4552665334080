// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AllUserSellModel } from '../_models/allusersell.model';

export enum AllUserSellActionTypes {
    AllUserSellOnServerCreated = '[Edit AllUserSell Dialog] AllUserSell On Server Created',
    AllUserSellCreated = '[Edit AllUserSell Dialog] AllUserSell Created',
    AllUserSellUpdated = '[Edit AllUserSell Dialog] AllUserSell Updated',
    AllUserSellsStatusUpdated = '[AllUserSell List Page] AllUserSells Status Updated',
    OneAllUserSellDeleted = '[AllUserSells List Page] One AllUserSell Deleted',
    ManyAllUserSellsDeleted = '[AllUserSells List Page] Many AllUserSell Deleted',
    AllUserSellsPageRequested = '[AllUserSells List Page] AllUserSells Page Requested',
    AllUserSellsPageLoaded = '[AllUserSells API] AllUserSells Page Loaded',
    AllUserSellsPageCancelled = '[AllUserSells API] AllUserSells Page Cancelled',
    AllUserSellsPageToggleLoading = '[AllUserSells] AllUserSells Page Toggle Loading',
    AllUserSellActionToggleLoading = '[AllUserSells] AllUserSells Action Toggle Loading'
}

export class AllUserSellOnServerCreated implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellOnServerCreated;
    constructor(public payload: { allusersell: AllUserSellModel }) { }
}

export class AllUserSellCreated implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellCreated;
    constructor(public payload: { allusersell: AllUserSellModel }) { }
}

export class AllUserSellUpdated implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellUpdated;
    constructor(public payload: {
        partialAllUserSell: Update<AllUserSellModel>, // For State update
        allusersell: AllUserSellModel // For Server update (through service)
    }) { }
}

export class AllUserSellsStatusUpdated implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellsStatusUpdated;
    constructor(public payload: {
        allusersells: AllUserSellModel[],
        status: number
    }) { }
}

export class OneAllUserSellDeleted implements Action {
    readonly type = AllUserSellActionTypes.OneAllUserSellDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAllUserSellsDeleted implements Action {
    readonly type = AllUserSellActionTypes.ManyAllUserSellsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AllUserSellsPageRequested implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class AllUserSellsPageLoaded implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellsPageLoaded;
    constructor(public payload: { allusersells: AllUserSellModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class AllUserSellsPageCancelled implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellsPageCancelled;
}

export class AllUserSellsPageToggleLoading implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllUserSellActionToggleLoading implements Action {
    readonly type = AllUserSellActionTypes.AllUserSellActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AllUserSellActions = AllUserSellOnServerCreated
| AllUserSellCreated
| AllUserSellUpdated
| AllUserSellsStatusUpdated
| OneAllUserSellDeleted
| ManyAllUserSellsDeleted
| AllUserSellsPageRequested
| AllUserSellsPageLoaded
| AllUserSellsPageCancelled
| AllUserSellsPageToggleLoading
| AllUserSellActionToggleLoading;
