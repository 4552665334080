<kt-portlet>

	<kt-portlet-body>
		
    <div class="kt-form kt-margin-b-30">
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field>
						  <mat-select name="banom" placeholder="Badan Otonom" [(ngModel)]="selectedSuborg" (selectionChange)="onSelectChange($event)">
						    <mat-option *ngFor="let suborg of suborganizationList" [value]="suborg.id">{{suborg.name}}</mat-option>
						  </mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search banomuser" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> in all fields
							</mat-hint>
						</mat-form-field>
					</div>
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput
								placeholder="Choose a date"
								[satDatepicker]="picker"
								[(value)]="dateRange"
								(dateChange)="onDateChange($event)">
							<sat-datepicker
								#picker
								[rangeMode]="true"
								orderPeriodLabel="month"
								[closeAfterSelection]="true">>
							</sat-datepicker>
							<sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
						</mat-form-field>
					</div>
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button mat-button-base mat-flat-button"></mat-spinner-button>
					</div>
				</div>
			</div>
		</div>

		<div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="desc"
				matSortDisableClear>

				<ng-container matColumnDef="suborganization.name">
					<mat-header-cell *matHeaderCellDef>Banom</mat-header-cell>
					<mat-cell *matCellDef="let banomuser">{{banomuser?.suborganization?.name}}</mat-cell>
				</ng-container>

		        <ng-container matColumnDef="suborganization.moderator">
		          <mat-header-cell *matHeaderCellDef>Ketua Banom</mat-header-cell>
		          <mat-cell *matCellDef="let banomuser"><a href="javascript:" (click)="editBanomUser(banomuser?.suborganization?.id)">{{banomuser?.suborganization?.moderator}}</a></mat-cell>
		        </ng-container>

				<ng-container matColumnDef="user.name">
					<mat-header-cell *matHeaderCellDef>Nama</mat-header-cell>
					<mat-cell *matCellDef="let banomuser"><a href="javascript:" (click)="editBanomUser(banomuser?.user?.id)">{{banomuser?.user?.name}}</a></mat-cell>
				</ng-container>

		        <ng-container matColumnDef="user.email">
		          <mat-header-cell *matHeaderCellDef class="email-cell">Email</mat-header-cell>
		          <mat-cell *matCellDef="let banomuser" class="email-cell">{{banomuser?.user?.email}}</mat-cell>
		        </ng-container>

				<ng-container matColumnDef="created_at">
					<mat-header-cell *matHeaderCellDef>Tanggal Registrasi</mat-header-cell>
					<mat-cell *matCellDef="let banomuser">{{banomuser?.created_at}}</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
    
	</kt-portlet-body>

</kt-portlet>
