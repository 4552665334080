<div class="kt-portlet"
	[ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : viewLoading }">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
		</div>
	</div>
	<form class="kt-form">
		<div class="kt-portlet__body">
			<div class="row">
				<div class="col-lg-12">
					<mat-form-field class="mat-form-field-fluid">
					  <mat-select name="suborganization_id" placeholder="PIC" [(ngModel)]="receiveData.suborganization_id" (selectionChange)="onSelectChange()">
					    <mat-option *ngFor="let suborg of suborganizationList" [value]="suborg.id">{{suborg.name}}</mat-option>
					  </mat-select>
					</mat-form-field>
				</div>
				<div class="col-lg-12">
					<mat-form-field class="mat-form-field-fluid">
						<input name="received_by" matInput placeholder="Diterima Oleh" [(ngModel)]="receiveData.received_by">
						<mat-hint align="start">
							Nama penerima KartaNU
						</mat-hint>
					</mat-form-field>
				</div>
			</div>
		</div>

		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
			<div class="kt-form__actions kt-form__actions--solid">
				<div class="row text-left">
					<div class="col-lg-12">
						<button type="button" mat-button color="warn" (click)="onNoClick()">
							Batalkan
						</button>
						<button type="button" mat-raised-button color="accent" [mat-dialog-close]="receiveData" cdkFocusInitial>
							Lanjutkan
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
