import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { ModeratorAllService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    ModeratorAllActionTypes,
    ModeratorAllsPageRequested,
    ModeratorAllsPageLoaded,
    ManyModeratorAllsDeleted,
    OneModeratorAllDeleted,
    ModeratorAllActionToggleLoading,
    ModeratorAllsPageToggleLoading,
    ModeratorAllUpdated,
    ModeratorAllsStatusUpdated,
    ModeratorAllCreated,
    ModeratorAllOnServerCreated
} from '../_actions/moderatorall.actions';
import { of } from 'rxjs';

@Injectable()
export class ModeratorAllEffects {
    showPageLoadingDistpatcher = new ModeratorAllsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ModeratorAllActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ModeratorAllActionToggleLoading({ isLoading: false });

    @Effect()
    loadModeratorAllsPage$ = this.actions$.pipe(
        ofType<ModeratorAllsPageRequested>(ModeratorAllActionTypes.ModeratorAllsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.moderatorallsService.findModeratorAlls(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new ModeratorAllsPageLoaded({
                moderatoralls: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteModeratorAll$ = this.actions$
        .pipe(
            ofType<OneModeratorAllDeleted>(ModeratorAllActionTypes.OneModeratorAllDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.moderatorallsService.deleteModeratorAll(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteModeratorAlls$ = this.actions$
        .pipe(
            ofType<ManyModeratorAllsDeleted>(ModeratorAllActionTypes.ManyModeratorAllsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.moderatorallsService.deleteModeratorAlls(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateModeratorAll$ = this.actions$
        .pipe(
            ofType<ModeratorAllUpdated>(ModeratorAllActionTypes.ModeratorAllUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.moderatorallsService.updateModeratorAll(payload.moderatorall);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateModeratorAllsStatus$ = this.actions$
        .pipe(
            ofType<ModeratorAllsStatusUpdated>(ModeratorAllActionTypes.ModeratorAllsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.moderatorallsService.updateStatusForModeratorAll(payload.moderatoralls, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createModeratorAll$ = this.actions$
        .pipe(
            ofType<ModeratorAllOnServerCreated>(ModeratorAllActionTypes.ModeratorAllOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.moderatorallsService.createModeratorAll(payload.moderatorall).pipe(
                    tap(res => {
                        this.store.dispatch(new ModeratorAllCreated({ moderatorall: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private moderatorallsService: ModeratorAllService, private store: Store<AppState>) { }
}
