<kt-portlet>
	<kt-portlet-body>
	  <div class="kt-form kt-margin-b-10">
		<div class="kt-form__filtration">
		  <div class="row align-items-center">
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
				  <div class="kt-widget12__content">
					<div class="kt-widget12__item">
					  <div class="kt-widget12__info">
						<span class="kt-widget12__desc">User Name</span>
						<span class="kt-widget12__value">{{ detailUser.name }}</span>
					  </div>
					</div>
				  </div>
				</div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
				  <div class="kt-widget12__content">
					<div class="kt-widget12__item">
					  <div class="kt-widget12__info">
						<span class="kt-widget12__desc">User Email</span>
						<span class="kt-widget12__value">{{ detailUser.email }}</span>
					  </div>
					</div>
				  </div>
				</div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
				  <div class="kt-widget12__content">
					<div class="kt-widget12__item">
					  <div class="kt-widget12__info">
						<span class="kt-widget12__desc">User Phone</span>
						<span class="kt-widget12__value">{{ detailUser.phone }}</span>
					  </div>
					</div>
				  </div>
				</div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
				  <div class="kt-widget12__content">
					<div class="kt-widget12__item">
					  <div class="kt-widget12__info">
						<span class="kt-widget12__desc">User Address</span>
						<span class="kt-widget12__value">{{ detailUser.address }}</span>
					  </div>
					</div>
				  </div>
				</div>
			</div>
		  </div>
		</div>
	  </div>
	</kt-portlet-body>
</kt-portlet>
