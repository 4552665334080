// Angular
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// Widgets model
import { LayoutConfigService } from '../../../core/_base/layout';
import { OrganizationService } from '../../../core/corporate';

@Component({
	selector: 'kt-tnc',
	templateUrl: './tnc.component.html',
	styleUrls: ['tnc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TncComponent implements OnInit, OnDestroy{

  terms:Array<any> = [];
	
  constructor(
    private layoutConfigService: LayoutConfigService,
    private cd: ChangeDetectorRef,
    public organizationService: OrganizationService,
  ){}

  ngOnInit(): void {
    this.loadTnc()
	}

  ngOnDestroy() {

  }

  loadTnc(){
    this.organizationService.getTnc().subscribe(
      data => {
        this.terms = data;
        this.cd.markForCheck();
      }
    )  
  }
}
