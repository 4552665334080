// Angular
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
// CRUD
import { QueryResultsModel } from '../models/query-models/query-results.model';
import { QueryParamsModel } from '../models/query-models/query-params.model';
import { QueryParamsDetailShipmentModel } from '../models/query-models/query-params-detail-shipment.model';
import { HttpExtenstionsModel } from '../../crud/models/http-extentsions-model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class HttpUtilsService {
	/**
	 * Prepare query http params
	 * @param queryParams: QueryParamsModel
	 * @param queryParamsDetailShipment: QueryParamsDetailShipmentModel
	 */
	getFindHTTPParams(queryParams): HttpParams {
		const params = new HttpParams()
		.set('page', queryParams.pageNumber.toString())
		.set('limit', queryParams.pageSize.toString())
		.set('start_date', queryParams.beginDate)
		.set('end_date', queryParams.endDate)
		.set('order', queryParams.sortOrder)
		.set('sort', queryParams.sortField)
		.set('status', queryParams.status)
		.set('type', queryParams.type)
		.set('filter', queryParams.filter)
		.set('suborganization_id', queryParams.suborganization_id)
		.set('organization_id', queryParams.organization_id)
		.set('transaction', queryParams.transaction)
		.set('hashed', queryParams.hashed);
		return params;
	}

	getFindHTTPParamsDetailShipment(queryParamsDetailShipment): HttpParams {
		const params = new HttpParams()
		.set('page', queryParamsDetailShipment.pageNumber.toString())
		.set('limit', queryParamsDetailShipment.pageSize.toString())
		.set('start_date', queryParamsDetailShipment.beginDate)
		.set('end_date', queryParamsDetailShipment.endDate)
		.set('order', queryParamsDetailShipment.sortOrder)
		.set('sort', queryParamsDetailShipment.sortField)
		.set('status', queryParamsDetailShipment.status)
		.set('type', queryParamsDetailShipment.type)
		.set('filter', queryParamsDetailShipment.filter)
		.set('shipment_id', queryParamsDetailShipment.shipment_id);
		return params;
	}

  serialize = function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

	/**
	 * get standard content-type
	 */
	getHTTPHeaders(): HttpHeaders {
		const result = new HttpHeaders()
		.set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer '+localStorage.getItem(environment.authToken));
		return result;
	}

	baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
	}

	sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
	}

	searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.searchInArray(_incomingArray, _queryObj, _filtrationFields);
	}
}
