<div class="row">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
        <h5>Filter</h5>
        <div class="row">
          <div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
            <mat-form-field>
              <mat-select placeholder="Organization" [(ngModel)]="selectedOrg" (selectionChange)="onSelectChange($event)">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let org of organizationList" [value]="org.id">{{org.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile">
          <mat-form-field>
            <mat-select [(ngModel)]="selected" (selectionChange)="durationType($event)">
              <mat-option *ngFor="let option of options" [value]="option.id">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="monthly">
            <jp-multi-datepicker mode="MONTH" label="Choose a month" (dateChange)="onDateChange($event)" [(ngModel)]="chosenMonthDate" [min]= "'1/2019'"></jp-multi-datepicker>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="customdate">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput
                  placeholder="Choose a date"
                  [satDatepicker]="picker"
                  [min]="minDate"
                  [(value)]="chosenCustomDate"
                  (dateChange)="onDateChange($event)">
              <sat-datepicker
                  #picker
                  [rangeMode]="true"
                  orderPeriodLabel="month"
                  [closeAfterSelection]="true">
              </sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 kt-margin-bottom-10-mobile">
            <button mat-raised-button color="accent" class="btn-block" (click)="downloadGoldBuy()">Download Beli Emas</button>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile">
            <button mat-raised-button color="accent" class="btn-block" (click)="downloadZakat()">Download Zakat</button>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile">
            <button mat-raised-button color="accent" class="btn-block" (click)="downloadDonasi()">Download Donasi</button>
          </div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>