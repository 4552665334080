import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { BigOrderService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    BigOrderActionTypes,
    BigOrdersPageRequested,
    BigOrdersPageLoaded,
    ManyBigOrdersDeleted,
    OneBigOrderDeleted,
    BigOrderActionToggleLoading,
    BigOrdersPageToggleLoading,
    BigOrderUpdated,
    BigOrdersStatusUpdated,
    BigOrderCreated,
    BigOrderOnServerCreated
} from '../_actions/bigorder.actions';
import { of } from 'rxjs';

@Injectable()
export class BigOrderEffects {
    showPageLoadingDistpatcher = new BigOrdersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new BigOrderActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new BigOrderActionToggleLoading({ isLoading: false });

    @Effect()
    loadBigOrdersPage$ = this.actions$.pipe(
        ofType<BigOrdersPageRequested>(BigOrderActionTypes.BigOrdersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.bigordersService.findBigOrders(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new BigOrdersPageLoaded({
                bigorders: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteBigOrder$ = this.actions$
        .pipe(
            ofType<OneBigOrderDeleted>(BigOrderActionTypes.OneBigOrderDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.bigordersService.deleteBigOrder(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteBigOrders$ = this.actions$
        .pipe(
            ofType<ManyBigOrdersDeleted>(BigOrderActionTypes.ManyBigOrdersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.bigordersService.deleteBigOrders(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateBigOrder$ = this.actions$
        .pipe(
            ofType<BigOrderUpdated>(BigOrderActionTypes.BigOrderUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.bigordersService.updateBigOrder(payload.bigorder);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateBigOrdersStatus$ = this.actions$
        .pipe(
            ofType<BigOrdersStatusUpdated>(BigOrderActionTypes.BigOrdersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.bigordersService.updateStatusForBigOrder(payload.bigorders, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createBigOrder$ = this.actions$
        .pipe(
            ofType<BigOrderOnServerCreated>(BigOrderActionTypes.BigOrderOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.bigordersService.createBigOrder(payload.bigorder).pipe(
                    tap(res => {
                        this.store.dispatch(new BigOrderCreated({ bigorder: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private bigordersService: BigOrderService, private store: Store<AppState>) { }
}
