import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { BanomUserService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    BanomUserActionTypes,
    BanomUsersPageRequested,
    BanomUsersPageLoaded,
    ManyBanomUsersDeleted,
    OneBanomUserDeleted,
    BanomUserActionToggleLoading,
    BanomUsersPageToggleLoading,
    BanomUserUpdated,
    BanomUsersStatusUpdated,
    BanomUserCreated,
    BanomUserOnServerCreated
} from '../_actions/banomuser.actions';
import { of } from 'rxjs';

@Injectable()
export class BanomUserEffects {
    showPageLoadingDistpatcher = new BanomUsersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new BanomUserActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new BanomUserActionToggleLoading({ isLoading: false });

    @Effect()
    loadBanomUsersPage$ = this.actions$.pipe(
        ofType<BanomUsersPageRequested>(BanomUserActionTypes.BanomUsersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.banomusersService.findBanomUsers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new BanomUsersPageLoaded({
                banomusers: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteBanomUser$ = this.actions$
        .pipe(
            ofType<OneBanomUserDeleted>(BanomUserActionTypes.OneBanomUserDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.banomusersService.deleteBanomUser(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteBanomUsers$ = this.actions$
        .pipe(
            ofType<ManyBanomUsersDeleted>(BanomUserActionTypes.ManyBanomUsersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.banomusersService.deleteBanomUsers(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateBanomUser$ = this.actions$
        .pipe(
            ofType<BanomUserUpdated>(BanomUserActionTypes.BanomUserUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.banomusersService.updateBanomUser(payload.banomuser);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateBanomUsersStatus$ = this.actions$
        .pipe(
            ofType<BanomUsersStatusUpdated>(BanomUserActionTypes.BanomUsersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.banomusersService.updateStatusForBanomUser(payload.banomusers, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createBanomUser$ = this.actions$
        .pipe(
            ofType<BanomUserOnServerCreated>(BanomUserActionTypes.BanomUserOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.banomusersService.createBanomUser(payload.banomuser).pipe(
                    tap(res => {
                        this.store.dispatch(new BanomUserCreated({ banomuser: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private banomusersService: BanomUserService, private store: Store<AppState>) { }
}
