// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AllUserCoinActions, AllUserCoinActionTypes } from '../_actions/allusercoin.actions';
// Models
import { AllUserCoinModel } from '../_models/allusercoin.model';
import { QueryParamsModel } from '../../_base/crud';

export interface AllUserCoinsState extends EntityState<AllUserCoinModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedAllUserCoinId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AllUserCoinModel> = createEntityAdapter<AllUserCoinModel>();

export const initialAllUserCoinsState: AllUserCoinsState = adapter.getInitialState({
    allusercoinForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedAllUserCoinId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function allusercoinsReducer(state = initialAllUserCoinsState, action: AllUserCoinActions): AllUserCoinsState {
    switch  (action.type) {
        case AllUserCoinActionTypes.AllUserCoinsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAllUserCoinId: undefined
            };
        }
        case AllUserCoinActionTypes.AllUserCoinActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case AllUserCoinActionTypes.AllUserCoinOnServerCreated: return {
            ...state
        };
        // case AllUserActionTypes.AllUserCreated: return adapter.addOne(action.payload.alluser, {
        //     ...state, lastCreatedAllUserId: action.payload.alluser.id
        // });
        case AllUserCoinActionTypes.AllUserCoinUpdated: return adapter.updateOne(action.payload.partialAllUserCoin, state);
       //  case AllUserActionTypes.AllUsersStatusUpdated: {
       //      const _partialAllUsers: Update<AllUserCoinModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.allusers.length; i++) {
       //          _partialAllUsers.push({
           //  id: action.payload.allusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialAllUsers, state);
       //  }
        case AllUserCoinActionTypes.OneAllUserCoinDeleted: return adapter.removeOne(action.payload.id, state);
        case AllUserCoinActionTypes.ManyAllUserCoinsDeleted: return adapter.removeMany(action.payload.ids, state);
        case AllUserCoinActionTypes.AllUserCoinsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AllUserCoinActionTypes.AllUserCoinsPageLoaded: {
            return adapter.addMany(action.payload.allusercoins, {
                ...initialAllUserCoinsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getAllUserCoinState = createFeatureSelector<AllUserCoinModel>('allusercoins');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
