// Angular
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
// Services
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Lodash
import { each } from 'lodash';
// Models
import { AdminService } from '../../../../../core/user-management';
import { AuthService } from '../../../../../core/auth';
import { AppState } from '../../../../../core/reducers';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule, MatCheckboxModule } from "@angular/material";

export interface Admin {
  email: string;
  hashed_id: string;
}

export class User {
  constructor(public email: string, public hashed_id: string) { }
}

@Component({
	selector: 'kt-user-add-list',
	templateUrl: './user-add-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAddListComponent implements OnInit, OnDestroy {

    // Display
  	organization: boolean = false;
    role: boolean = false;
  	suborganization: boolean = false;
    province: boolean = false;
    user: boolean = false;

    // List Data
  	organizationList: any = [];
    roleList: string[] = [];
    suborganizationList: any = [];
    provinceList: any = [];
    userList: any = [];

    // Selected Item
    selectedOrganization: string = '';
  	selectedRole: string;
  	selectedSuborganization: string = '';
  	selectedProvince: any = [];
  	selectedUser: string = '';
    
    // Variable
    filteredOptions: Observable<string[]>;
  	myControl = new FormControl();

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 */
	constructor(
		private store: Store<AppState>,
		public dialog: MatDialog,
		public adminService: AdminService,
    public authService: AuthService,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location,
    private cd: ChangeDetectorRef,
    ) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initAuthRole();
		this.initOrganization();
    this.initRole();
    this.initSuborganization();
		this.initProvince();
		this.initUser();
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

  initAuthRole() {
    this.authService.getUserByToken()
    .subscribe(
      data => {
        this.organization = data.data.roles.includes(17);
        this.role = data.data.roles.includes(18);
        this.cd.markForCheck();
      }
    )
  }

	initOrganization() {
		this.adminService.getAllOrganization()
		.subscribe(
			data => {
				this.organizationList = data;
        this.cd.markForCheck();
			}
		)
	}

	initRole() {
		this.adminService.getAllRoles()
		.subscribe(
			data => {
				this.roleList = data;
        this.selectedRole = data[0].id;
        this.cd.markForCheck();
			}
		)
	}

  initSuborganization(id:string=null) {
    const queryParams = new QueryParamsModel(
       '',
       null,
       null,
       '',
       '',
       1,
       100,
       '',
       '',
       '', 
       id
    );
    this.adminService.getAllSuborganization(queryParams)
    .subscribe(
      data => {
        this.suborganizationList = data.filter(subor => subor.users == null);
      }
    )
  }

	initProvince() {
		this.adminService.getAllProvince()
		.subscribe(
			data => {
				this.provinceList = data;
			}
		)
	}

	initUser() {
		this.adminService.getAdminByEmail()
		.subscribe(
      data => {
        
        each(data, element => {
          var detail = {email: element.email, hashed_id: element.hashed_id}
          this.userList.push(detail);
        });

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(val => this._filter(val))
        );
      }
		)
	}

  organizationChange(event){
    if (event.value == null) {
      this.role = false;
      this.suborganization = false;
      this.province = false;
      this.user = false;
      this.user = false;
    }else{
      this.role = true;
      this.user = true;
      this.initSuborganization(this.selectedOrganization)
    }
  }

  roleChange(event){
    if(event.value == 20){
      this.suborganization = true;
      this.province = false;
      this.selectedUser = "";
      this.myControl.setValue('');
      this.selectedSuborganization = "";
      this.selectedProvince = [];
      this.user = true;
    }else if(event.value == 19){
      this.suborganization = false;
      this.province = true;
      this.selectedUser = "";
      this.myControl.setValue('');
      this.selectedSuborganization = "";
      this.selectedProvince = [];
      this.user = true;
    }else{
      this.suborganization = false;
      this.province = false;
      this.selectedUser = "";
      this.myControl.setValue('');
      this.selectedSuborganization = "";
      this.selectedProvince = [];
      this.user = true;
    }
  }

  suborganizationChange(){

  }

  provinceChange(event){
    if(event.checked) {
      this.selectedProvince.push(event.source.value);
    }else{
      this.selectedProvince = this.arrayRemove(this.selectedProvince, event.source.value)
    }
  }

  getUserId(admin){
    this.selectedUser = admin.hashed_id;
  }

	displayName(admin?: Admin): string | undefined {
		return admin ? admin.email : undefined;
	}

	private _filter(data) {
		if(data){
			const filterValue = data.toLowerCase();
			return this.userList.filter(option => option.email.toLowerCase().includes(filterValue));
		}
	}

	arrayRemove(arr, value) {
	   return arr.filter(function(ele){
	       return ele != value;
	   });
	}

	addUser(){
		const data = {
      user_id: this.selectedUser,
      suborganization_id: this.selectedSuborganization,
      province_id: this.selectedProvince,
      role_id: this.selectedRole
    };
		
    this.adminService.addAdmin(data)
		.subscribe(
			data => {
				this.selectedOrganization = "";
        this.suborganization = false;
				this.province = false;
				this.selectedRole = ""
				this.selectedUser = "";
				this.myControl.setValue('');
				this.selectedSuborganization = "";
				this.selectedProvince = [];
				this.snackBar.open("Berhasil disimpan", '', {duration: 1500});
				this.location.back();
			}, err => {
				this.snackBar.open("Email yang akan anda daftarkan tidak memiliki nomor kartanu.", '', {duration: 1500});
			}
		)
	}

  back() {
    this.location.back();
  }
}
