// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GoldZakatsState } from '../_reducers/goldzakat.reducers';
import { GoldZakatModel } from '../_models/goldzakat.model';

export const selectGoldZakatsState = createFeatureSelector<GoldZakatsState>('goldzakats');

export const selectGoldZakatById = (goldzakatId: number) => createSelector(
    selectGoldZakatsState,
    goldzakatsState => goldzakatsState.entities[goldzakatId]
);

export const selectGoldZakatsPageLoading = createSelector(
    selectGoldZakatsState,
    goldzakatsState => goldzakatsState.listLoading
);

export const selectGoldZakatsActionLoading = createSelector(
    selectGoldZakatsState,
    goldzakatsState => goldzakatsState.actionsloading
);

export const selectLastCreatedGoldZakatId = createSelector(
    selectGoldZakatsState,
    goldzakatsState => goldzakatsState.lastCreatedGoldZakatId
);

export const selectGoldZakatsShowInitWaitingMessage = createSelector(
    selectGoldZakatsState,
    goldzakatsState => goldzakatsState.showInitWaitingMessage
);

export const selectGoldZakatsInStore = createSelector(
    selectGoldZakatsState,
    goldzakatsState => {
        const items: GoldZakatModel[] = [];
        each(goldzakatsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GoldZakatModel[] = httpExtension.sortArray(items, goldzakatsState.lastQuery.sortField, goldzakatsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, goldzakatsState.totalCount, '');
    }
);
