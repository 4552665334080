import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { GoldZakatService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    GoldZakatActionTypes,
    GoldZakatsPageRequested,
    GoldZakatsPageLoaded,
    ManyGoldZakatsDeleted,
    OneGoldZakatDeleted,
    GoldZakatActionToggleLoading,
    GoldZakatsPageToggleLoading,
    GoldZakatUpdated,
    GoldZakatsStatusUpdated,
    GoldZakatCreated,
    GoldZakatOnServerCreated
} from '../_actions/goldzakat.actions';
import { of } from 'rxjs';

@Injectable()
export class GoldZakatEffects {
    showPageLoadingDistpatcher = new GoldZakatsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new GoldZakatActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new GoldZakatActionToggleLoading({ isLoading: false });

    @Effect()
    loadGoldZakatsPage$ = this.actions$.pipe(
        ofType<GoldZakatsPageRequested>(GoldZakatActionTypes.GoldZakatsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.goldzakatsService.findGoldZakats(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new GoldZakatsPageLoaded({
                goldzakats: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteGoldZakat$ = this.actions$
        .pipe(
            ofType<OneGoldZakatDeleted>(GoldZakatActionTypes.OneGoldZakatDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldzakatsService.deleteGoldZakat(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteGoldZakats$ = this.actions$
        .pipe(
            ofType<ManyGoldZakatsDeleted>(GoldZakatActionTypes.ManyGoldZakatsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldzakatsService.deleteGoldZakats(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateGoldZakat$ = this.actions$
        .pipe(
            ofType<GoldZakatUpdated>(GoldZakatActionTypes.GoldZakatUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldzakatsService.updateGoldZakat(payload.goldzakat);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateGoldZakatsStatus$ = this.actions$
        .pipe(
            ofType<GoldZakatsStatusUpdated>(GoldZakatActionTypes.GoldZakatsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldzakatsService.updateStatusForGoldZakat(payload.goldzakats, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createGoldZakat$ = this.actions$
        .pipe(
            ofType<GoldZakatOnServerCreated>(GoldZakatActionTypes.GoldZakatOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldzakatsService.createGoldZakat(payload.goldzakat).pipe(
                    tap(res => {
                        this.store.dispatch(new GoldZakatCreated({ goldzakat: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private goldzakatsService: GoldZakatService, private store: Store<AppState>) { }
}
