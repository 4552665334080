<div class="row" *ngxPermissionsOnly="['b2b_big_order']">
	<div class="col-xl-12">
	  <kt-portlet [class]="'kt-portlet--height-fluid'">
		<kt-portlet-body>
		  <div class="row">
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <div class="kt-widget12">
				<div class="kt-widget12__content">
				  <div class="kt-widget12__item">
					<div class="kt-widget12__info">
					  <span class="kt-widget12__desc pt-5">{{ summary?.profile?.referral_code }} - {{ summary?.profile?.agent_name }} - {{ summary?.profile?.tier }}</span>
					</div>
				  </div>
				</div>
			  </div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <div class="kt-widget12">
				<div class="kt-widget12__content">
				  <div class="kt-widget12__item">
					<div class="kt-widget12__info">
					  <span class="kt-widget12__desc pt-5">Terdaftar sejak {{ summary?.profile?.registered_at }}</span>
					</div>
				  </div>
				</div>
			  </div>
			</div>
			<div class="col-md-6 kt-margin-bottom-10-mobile">
			  <div class="kt-widget12">
				<div class="kt-widget12__content">
				  <div class="kt-widget12__item">
					<div class="kt-widget12__info">
					  <span class="kt-widget12__desc pt-5">Periode berikutnya : {{ summary?.profile?.difference }} hari berikutnya - {{ summary?.profile?.next_periode }}</span>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</kt-portlet-body>
	  </kt-portlet>
	</div>
  </div>
  <div class="row" *ngxPermissionsOnly="['b2b_big_order']">
	<div class="col-xl-12">
	  <kt-portlet [class]="'kt-portlet--height-fluid'">
		<kt-portlet-body>
		  <div class="row">
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <div class="kt-widget12">
				<div class="kt-widget12__content">
				  <div class="kt-widget12__item">
					<div class="kt-widget12__info">
					  <span class="kt-widget12__desc">Basic Reward</span>
					  <span class="kt-widget12__value">{{summary?.profile?.insentif}}</span>
					</div>
				  </div>
				</div>
			  </div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
			  <div class="kt-widget12">
				<div class="kt-widget12__content">
				  <div class="kt-widget12__item">
					<div class="kt-widget12__info">
					  <span class="kt-widget12__desc">Total Cashback</span>
					  <span class="kt-widget12__value">{{summary?.profile?.discount}}</span>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</kt-portlet-body>
	  </kt-portlet>
	</div>
  </div>
<div class="row" *ngxPermissionsOnly="['b2b_averagecheck', 'b2b_donation', 'b2b_zakat', 'b2b_chart']">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
        <h5>Filter</h5>
        <div class="row">
          <div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
            <mat-form-field>
              <mat-select placeholder="Organization" [(ngModel)]="selectedOrg" (selectionChange)="onSelectChange($event)">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let org of organizationList" [value]="org.id">{{org.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile">
          <mat-form-field>
            <mat-select [(ngModel)]="selected2" (selectionChange)="durationType($event)">
              <mat-option *ngFor="let option of options" [value]="option.id">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="yearly">
            <jp-multi-datepicker tabindex="-1"  mode="YEAR" label="Choose a year" (dateChange)="onDateChange($event)" [(ngModel)]="chosenYearDate" [min]="2019" [max]="2020"></jp-multi-datepicker>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="monthly">
            <jp-multi-datepicker mode="MONTH" label="Choose a month" (dateChange)="onDateChange($event)" [(ngModel)]="chosenMonthDate" [min]= "'1/2019'" [max]="'5/2020'"></jp-multi-datepicker>
          </div>
          <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="customdate">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput
                  placeholder="Choose a date"
                  [satDatepicker]="picker"
                  [(value)]="chosenCustomDate"
                  (dateChange)="onDateChange($event)">
              <sat-datepicker
                  #picker
                  [rangeMode]="true"
                  orderPeriodLabel="month"
                  [closeAfterSelection]="true">
              </sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            </mat-form-field>
          </div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>
<div class="row">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
        <div class="row">
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Total Users</span>
                    <span class="kt-widget12__value">{{summary.totalUser}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Total Buy In Gram</span>
                    <span class="kt-widget12__value">{{summary.goldBuy | number:'1.4-4'}} Gram</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Total Sell In Gram</span>
                    <span class="kt-widget12__value">{{summary.goldSell | number:'1.4-4'}} Gram</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 kt-margin-bottom-10-mobile" *ngxPermissionsOnly="['b2b_averagecheck']">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Average Check</span>
                    <span class="kt-widget12__value">{{summary.averageCheck | number:'1.4-4'}} Gram</span>
                  </div>
                </div>
              </div>
            </div>
		  </div>
		  <div class="col-md-3 kt-margin-bottom-10-mobile" *ngxPermissionsOnly="['b2b_big_order']">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Total BO in Gram</span>
                    <span class="kt-widget12__value">{{summary.sumBigOrder}} Gram</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>
<div class="row" *ngxPermissionsOnly="['b2b_donation', 'b2b_zakat']">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
        <div class="row">
          <div class="col-md-3 kt-margin-bottom-10-mobile" *ngxPermissionsOnly="['b2b_donation']">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Total Donasi</span>
                    <span class="kt-widget12__value">{{summary.sumDonation | number:'1.4-4'}} Gram</span>
                  </div>
                </div>
              </div>
            </div>
		  </div>
          <div class="col-md-3 kt-margin-bottom-10-mobile" *ngxPermissionsOnly="['b2b_zakat']">
            <div class="kt-widget12">
              <div class="kt-widget12__content">
                <div class="kt-widget12__item">
                  <div class="kt-widget12__info">
                    <span class="kt-widget12__desc">Total Zakat</span>
                    <span class="kt-widget12__value">{{summary.sumZakat | number:'1.4-4'}} Gram</span>
                  </div>
                </div>
              </div>
            </div>
		  </div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>
<div class="row" *ngxPermissionsOnly="['b2b_chart']">
  <div class="col-xl-4">
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chartGender"
      [(update)]="updateData"
      style="width: 100%; display: block;"
    ></highcharts-chart>
  </div>
  <div class="col-xl-8">
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chartAge"
      [(update)]="updateData"
      style="width: 100%; display: block;"
    ></highcharts-chart>
  </div>
</div>
<div class="row mt-4" *ngxPermissionsOnly="['b2b_chart']">
  <div class="col-xl-12">
    <highcharts-chart
      [Highcharts]="Highcharts"
      [constructorType]="'mapChart'"
      [options]="chartMap"
      [(update)]="updateData"
      style="width: 100%; display: block;"
    ></highcharts-chart>
  </div>
</div>
