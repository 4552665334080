<kt-portlet>

	<kt-portlet-body>
		<mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged()">
		  <mat-tab label="Minta Cetak KartaNU">
			<div class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}"><!-- We show 'Group Actions' div if smth are selected -->
				<div class="col-xl-12">
					<div class="kt-form__group kt-form__group--inline">
						<div class="kt-form__label kt-form__label-no-wrap">
							<label class="kt--font-bold kt-font-danger-">
								Total cetak KartaNU : <b> {{ selection.selected.length }} </b>
							</label>
						</div>
						<div class="kt-form__control kt-form__group--inline">
							<button (click)="fetchRequestCards()" mat-raised-button matTooltip="Request Cetak KartaNU" color="accent" class="mat-button-mt">
								Cetak Sekarang
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="mat-table__wrapper">
				<mat-table class="lmat-elevation-z8"
					#table
					[dataSource]="dataSource"
					matSort
					#sort1="matSort"
					matSortActive="id"
					matSortDirection="desc"
					matSortDisableClear>

					<ng-container matColumnDef="select">
						<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
							<mat-checkbox (change)="$event ? masterToggle() : null"
								[checked]="selection.hasValue() && isAllSelected()"
								[indeterminate]="selection.hasValue() && !isAllSelected()">
							</mat-checkbox>
						</mat-header-cell>
						<mat-cell *matCellDef="let requestcard" class="mat-column-checkbox">
							<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(requestcard) : null" [checked]="selection.isSelected(requestcard)">
							</mat-checkbox>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="user.name">
						<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
						<mat-cell *matCellDef="let requestcard"><a href="javascript:" (click)="editRequestCard(requestcard?.user?.id)">{{requestcard?.user?.name}}</a></mat-cell>
					</ng-container>

			        <ng-container matColumnDef="user.id_member">
			          <mat-header-cell *matHeaderCellDef>Nomor Kartanu</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.user?.id_member}}</mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="suborganization.moderator">
			          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard"><a href="javascript:" (click)="editRequestCard(requestcard?.suborganization?.id)">{{requestcard?.suborganization?.moderator}}</a></mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="user.status">
			          <mat-header-cell *matHeaderCellDef>Status Verifikasi</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.user?.status}}</mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="transaction.last_transaction">
			          <mat-header-cell *matHeaderCellDef>Transaksi Terakhir</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.transaction?.last_transaction}}</mat-cell>
			        </ng-container>

					<mat-header-row *matHeaderRowDef="displayedColumnsSelect"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumnsSelect"></mat-row>
				</mat-table>
				<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
				<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
			</div>

			<div class="mat-table__bottom">
				<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
				<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
			</div>
		  </mat-tab>
		  <mat-tab label="Menunggu Antrian">
			<div class="mat-table__wrapper">
				<mat-table class="lmat-elevation-z8"
					#table
					[dataSource]="dataSource"
					matSort
					#sort1="matSort"
					matSortActive="id"
					matSortDirection="desc"
					matSortDisableClear>

					<ng-container matColumnDef="user.name">
						<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
						<mat-cell *matCellDef="let requestcard"><a href="javascript:" (click)="editRequestCard(requestcard?.user?.id)">{{requestcard?.user?.name}}</a></mat-cell>
					</ng-container>

			        <ng-container matColumnDef="user.id_member">
			          <mat-header-cell *matHeaderCellDef>Nomor Kartanu</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.user?.id_member}}</mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="suborganization.moderator">
			          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard"><a href="javascript:" (click)="editRequestCard(requestcard?.suborganization?.id)">{{requestcard?.suborganization?.moderator}}</a></mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="transaction.last_transaction">
			          <mat-header-cell *matHeaderCellDef>Transaksi Terakhir</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.transaction?.last_transaction}}</mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="user.status">
			          <mat-header-cell *matHeaderCellDef>Status KartaNU</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.user?.status}}</mat-cell>
			        </ng-container>

					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>
				<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
				<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
			</div>

			<div class="mat-table__bottom">
				<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
				<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
			</div>
		  </mat-tab>
		  <mat-tab label="Sedang Dikirim">
			<div class="mat-table__wrapper">
				<mat-table class="lmat-elevation-z8"
					#table
					[dataSource]="dataSource"
					matSort
					#sort1="matSort"
					matSortActive="id"
					matSortDirection="desc"
					matSortDisableClear>

					<ng-container matColumnDef="user.name">
						<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
						<mat-cell *matCellDef="let requestcard"><a href="javascript:" (click)="editRequestCard(requestcard?.user?.id)">{{requestcard?.user?.name}}</a></mat-cell>
					</ng-container>

			        <ng-container matColumnDef="user.id_member">
			          <mat-header-cell *matHeaderCellDef>Nomor Kartanu</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.user?.id_member}}</mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="suborganization.moderator">
			          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard"><a href="javascript:" (click)="editRequestCard(requestcard?.suborganization?.id)">{{requestcard?.suborganization?.moderator}}</a></mat-cell>
			        </ng-container>

			        <ng-container matColumnDef="user.status">
			          <mat-header-cell *matHeaderCellDef>Status KartaNU</mat-header-cell>
			          <mat-cell *matCellDef="let requestcard">{{requestcard?.user?.status}}</mat-cell>
			        </ng-container>

					<mat-header-row *matHeaderRowDef="displayedColumnsSend"></mat-header-row>

					<mat-row *matRowDef="let row; columns: displayedColumnsSend"></mat-row>
				</mat-table>
				<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
				<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
			</div>

			<div class="mat-table__bottom">
				<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
				<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
			</div>
		  </mat-tab>

      <mat-tab label="Sudah Diterima">
      <div class="row">
        <div class="col-lg-12">
          <form class="kt-login__form kt-form" [formGroup]="nameForm" autocomplete="off">
            <div class="form-group">
              <mat-form-field class="mat-form-field-fluid">
              <input type="text" placeholder="Masukkan nomor urut percetakan KartaNU" aria-label="shipment" matInput [formControl]="myControl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='getShipment_id($event.option.value)'>
              <mat-option *ngFor="let shipment of filteredShipmentList | async" [value]="shipment">{{shipment.serial_number}}</mat-option>
              </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="mat-form-field-fluid">
              <input name="received_by" matInput placeholder="Diterima Oleh" formControlName="name" [(ngModel)]="receiveData.received_by">
              <mat-hint align="start">
              Nama penerima KartaNU
              </mat-hint>
              <mat-error *ngIf="isControlNameHasError('name', 'required')">
              <strong>Nama tidak boleh kosong</strong>
              </mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <button type="button" mat-raised-button color="accent" (click)="receiveShipment()">
              Simpan
              </button>
              <br>
              &nbsp;
            </div>
          </form>
        </div>
      </div>
      </mat-tab>
		</mat-tab-group>
	</kt-portlet-body>

</kt-portlet>
