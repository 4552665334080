// Angular
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { AuthService } from '../../../../../core/auth';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  GoldBuyModel,
  GoldBuyService,
  GoldBuysDataSource,
  GoldBuysPageRequested,
  OneGoldBuyDeleted,
  ManyGoldBuysDeleted,
  GoldBuysStatusUpdated
} from '../../../../../core/transaction';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
import { GoldBuyEditComponent } from '../gold-buy-edit/gold-buy-edit.dialog.component';
@Component({
	selector: 'kt-goldbuys-list',
	templateUrl: './gold-buy-list.component.html',
	styleUrls: ['gold-buy-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class GoldBuyListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: GoldBuysDataSource;
	displayedColumns = ['user.name', 'suborganization.moderator', 'transaction.last_buy', 'transaction.total_buy'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};
  	organizationList: any = [];
	selectedOrg: string = '';
	selectedStat: any = { status: ['0', '1', '2', '3', '4'], transaction: ''};
  	role: boolean = false;
	stat: string = '';
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<GoldBuyModel>(true, []);
	goldbuysResult: GoldBuyModel[] = [];
  averageCheck: string;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Download',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'accent',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'cloud_download'
    }
  }

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
    public authService : AuthService,
		public goldBuyService: GoldBuyService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

	    // var date = new Date();
	    // let beginDate = date.setDate(date.getDate() - 7);
	    // this.dateRange = {begin: new Date(beginDate), end: new Date()};

		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadGoldBuysList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadGoldBuysList();
        this.getAverageCheck();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init Org List
		this.loadOrganizationList();
		this.loadUserRole();

		// Init DataSource
		this.dataSource = new GoldBuysDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.goldbuysResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1),
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadGoldBuysList();
		}); // Remove this line, just loading imitation

    this.getAverageCheck();
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load GoldBuys List from service through data-source
	 */
	loadGoldBuysList() {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const searchText: string = this.searchInput.nativeElement.value;
		const queryParams = new QueryParamsModel(
	        searchText,
	        // '1995-01-01',
	        // '2020-12-11',
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'[' + this.selectedStat.status + ']', // status
			'', // type
			'', // suborganization_id
			this.selectedOrg,// organization_id
			this.selectedStat.transaction
		);
		// Call request from server
		this.store.dispatch(new GoldBuysPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Load Organization List
	 */
	loadOrganizationList() {
		this.goldBuyService.getAllOrganization()
		.subscribe(
			data => {
				this.organizationList = data;
			}
		)
	}

	/**
	 * Load Role
	 */
	loadUserRole() {
		this.authService.getUserByToken()
		.subscribe(
			data => {
				this.role = data.data.roles.includes(17);
			}
		)
	}

	editGoldBuy(hashed_id) {
		// Get Detail User
		this.goldBuyService.getDetailUser(hashed_id)
		.subscribe(
			data => {
				const dialogRef = this.dialog.open(GoldBuyEditComponent, { data: { data } });
			}
		)
	}

  onSelectChange(event: any) {
	this.paginator.pageIndex = 0;
    this.loadGoldBuysList();
    this.getAverageCheck();
  }

  onSelectStatChange(event: any) {
	this.paginator.pageIndex = 0;
	if(event.value){
		if (event.value == 'Kartanu') {
			this.selectedStat = {status: ['1', '2', '3', '4'], transaction: ''};
		}else if (event.value == 'Transaction') {
			this.selectedStat = {status: '0', transaction: '1'};
		}else if (event.value == 'Deactive') {
			this.selectedStat = {status: '0', transaction: '0'};
		}else{
			this.selectedStat = {status: ['0', '1', '2', '3', '4'], transaction: ''};
		}
  	}else{
		this.selectedStat = {status: ['0', '1', '2', '3', '4'], transaction: ''};
	}
    this.loadGoldBuysList();
    this.getAverageCheck();
  }

  onDateChange(event: any) {
    this.paginator.pageIndex = 0;
  	if(event.target.value){
    	this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
  	}else{
    	this.dateRange = {begin: null, end: null};
  	}
    this.loadGoldBuysList();
    this.getAverageCheck();
  }

  getAverageCheck(){
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
	      this.sort.direction,
	      this.sort.active,
	      this.paginator.pageIndex,
	      this.paginator.pageSize,
	      '[0,1,2,3,4]', // status
	      '', // type
		  '', // suborganization_id
		  this.selectedOrg // organization_id
    );

    this.goldBuyService.averageCheck(queryParams)
    .subscribe(
      data => {
        this.averageCheck = data;
        this.cd.markForCheck();
        }
      )
  }

  download(){
    this.spinnerButtonOptions.active = true;
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
			searchText,
			begin,
			end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize,
			'[' + this.selectedStat.status + ']', // status
			'', // type
			'', // suborganization_id
			this.selectedOrg,// organization_id
			this.selectedStat.transaction
    );

    this.goldBuyService.downloadGoldBuy(queryParams)
    .subscribe(
      data => {
        this.spinnerButtonOptions.active = false;
        window.location.href = data;
        }
      )
  }
}
