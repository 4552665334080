// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';

// Models
import { AccessModel } from '../_models/access.model';

const BASE_URL = environment.baseUrl;

@Injectable({
	providedIn: 'root'
})
export class AccessService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  getPermissionMenu() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/permission-menu';
    return this.http
      .get<any[]>(url,{
        headers:httpHeaders,
      })
      .pipe(map(data => data['data']));
  }

  updatePermissionMenu(data) {
	const httpHeaders = this.httpUtils.getHTTPHeaders();
        httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<QueryResultsModel>(BASE_URL + 'b2b/menu-update', data, { headers: httpHeaders})
      .pipe(map(data => data['data']));
  }
}
