// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AdminActions, AdminActionTypes } from '../_actions/admin.actions';
// Models
import { AdminModel } from '../_models/admin.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface AdminsState extends EntityState<AdminModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedAdminId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AdminModel> = createEntityAdapter<AdminModel>();

export const initialAdminsState: AdminsState = adapter.getInitialState({
    adminForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedAdminId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function adminsReducer(state = initialAdminsState, action: AdminActions): AdminsState {
    switch  (action.type) {
        case AdminActionTypes.AdminsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAdminId: undefined
            };
        }
        case AdminActionTypes.AdminActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case AdminActionTypes.AdminOnServerCreated: return {
            ...state
        };
        case AdminActionTypes.AdminCreated: return adapter.addOne(action.payload.admin, {
            ...state, lastCreatedAdminId: action.payload.admin.id
        });
        case AdminActionTypes.AdminUpdated: return adapter.updateOne(action.payload.partialAdmin, state);
       //  case AdminActionTypes.AdminsStatusUpdated: {
       //      const _partialAdmins: Update<AdminModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.admins.length; i++) {
       //          _partialAdmins.push({
           //  id: action.payload.admins[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialAdmins, state);
       //  }
        case AdminActionTypes.OneAdminDeleted: return adapter.removeOne(action.payload.id, state);
        case AdminActionTypes.ManyAdminsDeleted: return adapter.removeMany(action.payload.ids, state);
        case AdminActionTypes.AdminsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case AdminActionTypes.AdminsPageLoaded: {
            return adapter.addMany(action.payload.admins, {
                ...initialAdminsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getAdminState = createFeatureSelector<AdminModel>('admins');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
