<kt-portlet>

	<kt-portlet-body>

    <div class="kt-form kt-margin-b-30">
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search goldbuy" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> by name or email
							</mat-hint>
						</mat-form-field>
					</div>
					<div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field>
						  <mat-select placeholder="Organization" [(ngModel)]="selectedOrg" (selectionChange)="onSelectChange($event)">
						    <mat-option>None</mat-option>
						    <mat-option *ngFor="let org of organizationList" [value]="org.id">{{org.name}}</mat-option>
						  </mat-select>
						</mat-form-field>
					</div>
					<div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field>
						  <mat-select placeholder="Filter By User Status" (selectionChange)="onSelectStatChange($event)">
							<mat-option value="None">None</mat-option>
							<mat-option value="Kartanu">Active Kartanu</mat-option>
							<mat-option value="Transaction">Active Transaction</mat-option>
							<mat-option value="Deactive">Deactive</mat-option>
						  </mat-select>
						</mat-form-field>
					</div>
		          <div class="col-md-3 kt-margin-bottom-10-mobile text-right" *ngxPermissionsOnly="['b2b_averagecheck']">
		            <h5 class="kt-subheader__desc ng-star-inserted">Average Check</h5>
		            <h5 class="kt-subheader__desc ng-star-inserted">{{averageCheck}} Gram</h5>
        		  </div>
				</div>
				<div class="row align-items-center">
					<div class="col-md-6 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
						  <input matInput
							  placeholder="Choose a date"
							  [satDatepicker]="picker"
							  [(value)]="dateRange"
							  (dateChange)="onDateChange($event)">
						  <sat-datepicker
							  #picker
							  [rangeMode]="true"
							  orderPeriodLabel="month"
							  [closeAfterSelection]="true">
						  </sat-datepicker>
						  <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
						</mat-form-field>
					  </div>
					  <div class="col-md-6 kt-margin-bottom-10-mobile">
						<mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button mat-button-base mat-flat-button"></mat-spinner-button>
					  </div>
				</div>
			</div>
		</div>

		<div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="desc"
				matSortDisableClear>

				<ng-container matColumnDef="user.name">
					<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
					<mat-cell *matCellDef="let goldbuy"><a href="javascript:" (click)="editGoldBuy(goldbuy?.user?.id)">{{goldbuy?.user?.name}}</a></mat-cell>
				</ng-container>

		        <ng-container matColumnDef="user.id_member">
		          <mat-header-cell *matHeaderCellDef>Nomor Kartanu</mat-header-cell>
		          <mat-cell *matCellDef="let goldbuy">{{goldbuy?.user?.id_member}}</mat-cell>
		        </ng-container>

		        <ng-container matColumnDef="suborganization.moderator">
		          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
		          <mat-cell *matCellDef="let goldbuy"><a href="javascript:" (click)="editGoldBuy(goldbuy?.suborganization?.id)">{{goldbuy?.suborganization?.moderator}}</a></mat-cell>
		        </ng-container>

		        <ng-container matColumnDef="transaction.last_buy">
		          <mat-header-cell *matHeaderCellDef>Pembelian Terakhir</mat-header-cell>
		          <mat-cell *matCellDef="let goldbuy">{{goldbuy?.transaction?.last_buy | number:'1.4-4'}} gram</mat-cell>
		        </ng-container>

		        <ng-container matColumnDef="transaction.total_buy">
		          <mat-header-cell *matHeaderCellDef>Total Pembelian</mat-header-cell>
		          <mat-cell *matCellDef="let goldbuy">{{goldbuy?.transaction?.total_buy | number:'1.4-4'}} gram</mat-cell>
		        </ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>

	</kt-portlet-body>

</kt-portlet>
