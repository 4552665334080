// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ModeratorAllsState } from '../_reducers/moderatorall.reducers';
import { ModeratorAllModel } from '../_models/moderatorall.model';

export const selectModeratorAllsState = createFeatureSelector<ModeratorAllsState>('moderatoralls');

export const selectModeratorAllById = (moderatorallId: number) => createSelector(
    selectModeratorAllsState,
    moderatorallsState => moderatorallsState.entities[moderatorallId]
);

export const selectModeratorAllsPageLoading = createSelector(
    selectModeratorAllsState,
    moderatorallsState => moderatorallsState.listLoading
);

export const selectModeratorAllsActionLoading = createSelector(
    selectModeratorAllsState,
    moderatorallsState => moderatorallsState.actionsloading
);

export const selectLastCreatedModeratorAllId = createSelector(
    selectModeratorAllsState,
    moderatorallsState => moderatorallsState.lastCreatedModeratorAllId
);

export const selectModeratorAllsShowInitWaitingMessage = createSelector(
    selectModeratorAllsState,
    moderatorallsState => moderatorallsState.showInitWaitingMessage
);

export const selectModeratorAllsInStore = createSelector(
    selectModeratorAllsState,
    moderatorallsState => {
        const items: ModeratorAllModel[] = [];
        each(moderatorallsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ModeratorAllModel[] = httpExtension.sortArray(items, moderatorallsState.lastQuery.sortField, moderatorallsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, moderatorallsState.totalCount, '');
    }
);
