// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsDetailShipmentModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  DetailShipmentModel,
  DetailShipmentService,
  DetailShipmentsDataSource,
  DetailShipmentsPageRequested,
  OneDetailShipmentDeleted,
  ManyDetailShipmentsDeleted,
  DetailShipmentsStatusUpdated
} from '../../../../../core/kartanu';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { DatePipe, Location } from '@angular/common';
// Components
import { DetailShipmentEditComponent } from '../detail-shipment-edit/detail-shipment-edit.dialog.component';

@Component({
	selector: 'kt-detailshipments-list',
	templateUrl: './detail-shipment-list.component.html',
	styleUrls: ['detail-shipment-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class DetailShipmentListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: DetailShipmentsDataSource;
	displayedColumns = ['name', 'id_member', 'suborganization.moderator', 'suborganization.name'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};
  	param: any = {};

	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<DetailShipmentModel>(true, []);
	detailshipmentsResult: DetailShipmentModel[] = [];

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		public detailShipmentService: DetailShipmentService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    	private datePipe: DatePipe,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			this.param = params;
		});
    
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadDetailShipmentsList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Init DataSource
		this.dataSource = new DetailShipmentsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.detailshipmentsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1), 
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadDetailShipmentsList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load DetailShipments List from service through data-source
	 */
	loadDetailShipmentsList() {
		this.selection.clear();
		const queryParams = new QueryParamsDetailShipmentModel(
	        '',
	        '',
	        '',
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'', // status
			'', // type
			this.param.id // shipment_id
		);
		// Call request from server
		this.store.dispatch(new DetailShipmentsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	back() {
		this.location.back();
	}

	editDetailShipment(hashed_id) {
		// Get Detail User
		this.detailShipmentService.getDetailUser(hashed_id)
		.subscribe(
			data => {
				const dialogRef = this.dialog.open(DetailShipmentEditComponent, { data: { data } });
			}
		)
	}

  downloadShipment(id){
    this.detailShipmentService.download(id)
    .subscribe(
      data => {
        window.location.href = data;
        }
      )
  }
}
