<kt-portlet>
  <kt-portlet-body>
    <div class="kt-form kt-margin-b-30">
      <div class="kt-form__filtration">
        <div class="row align-items-center">
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Search alluser" #searchInput placeholder="Search">
              <mat-hint align="start">
                <strong>Search</strong> by name or email or phone
              </mat-hint>
            </mat-form-field>
          </div>
          <div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
            <mat-form-field>
              <mat-select placeholder="Organization" [(ngModel)]="selectedOrg" (selectionChange)="onSelectChange($event)">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let org of organizationList" [value]="org.id">{{org.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput
								placeholder="Choose a date"
								[satDatepicker]="picker"
								[(value)]="dateRange"
								(dateChange)="onDateChange($event)">
							<sat-datepicker
								#picker
								[rangeMode]="true"
								orderPeriodLabel="month"
								[closeAfterSelection]="true">>
							</sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            </mat-form-field>
          </div>
          <div class="col-md-3 kt-margin-bottom-10-mobile" *ngxPermissionsOnly="['b2b_users']">
            <mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button mat-button-base mat-flat-button"></mat-spinner-button>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table__wrapper">
      <mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="desc"
				matSortDisableClear>
        <ng-container matColumnDef="user.name">
			<mat-header-cell *matHeaderCellDef>Nama</mat-header-cell>
			<!-- <mat-cell *matCellDef="let alluser"> -->
			<mat-cell *matCellDef="let alluser">{{alluser?.user?.name}}</mat-cell>

            <!-- <a href="javascript:" (click)="editAllUser(alluser?.user?.id)">{{alluser?.user?.name}}</a> -->
        	<!-- </mat-cell> -->
		</ng-container>
        <ng-container matColumnDef="user.id_member">
          <mat-header-cell *matHeaderCellDef>Nomor Member</mat-header-cell>
          <mat-cell *matCellDef="let alluser">{{alluser?.user?.id_member}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="suborganization.referral_code">
		  <mat-header-cell *matHeaderCellDef>Kode Referral</mat-header-cell>
		<mat-cell *matCellDef="let alluser">{{alluser?.suborganization?.referral_code}}</mat-cell>
          <!-- <mat-cell *matCellDef="let alluser">
            <a href="javascript:" (click)="editAllUser(alluser?.suborganization?.id)">{{alluser?.suborganization?.referral_code}}</a>
          </mat-cell> -->
        </ng-container>
        <ng-container matColumnDef="user.email">
			<mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
			<mat-cell *matCellDef="let alluser">{{alluser?.user?.email}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="user.phone">
			<mat-header-cell *matHeaderCellDef>Nomor Hp</mat-header-cell>
			<mat-cell *matCellDef="let alluser">{{alluser?.user?.phone}}</mat-cell>
		</ng-container>
        <ng-container matColumnDef="suborganization.location">
			<mat-header-cell *matHeaderCellDef>Lokasi</mat-header-cell>
			<mat-cell *matCellDef="let alluser">{{alluser?.suborganization?.location}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let alluser">
				<button (click)="editUser(alluser?.user?.id)" mat-icon-button color="primary" matTooltip="Edit user">
					<mat-icon>visibility</mat-icon>
				</button>
				<button (click)="historyBuy(alluser?.user?.id)" mat-icon-button color="primary" matTooltip="History Buy" *ngxPermissionsOnly="['b2b_big_order']">
					<mat-icon>attach_money</mat-icon>
				</button>
				<button (click)="historySell(alluser?.user?.id)" mat-icon-button color="primary" matTooltip="History Sell" *ngxPermissionsOnly="['b2b_big_order']">
					<mat-icon>swap_horizion</mat-icon>
				</button>
				<button (click)="historyCoin(alluser?.user?.id)" mat-icon-button color="primary" matTooltip="History Koin Nusantara" *ngxPermissionsOnly="['b2b_big_order']">
					<mat-icon>shopping_basket</mat-icon>
				</button>
			</mat-cell>
		</ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
    </div>
    <div class="mat-table__bottom">
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
    </div>
  </kt-portlet-body>
</kt-portlet>
