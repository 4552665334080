<kt-portlet>
  <kt-portlet-body>
    <div class="kt-form kt-margin-b-30">
      <div class="kt-form__filtration">
        <div class="row align-items-center">
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <button mat-raised-button color="accent" (click)="back()" class="mr-2">Kembali</button>
            <button mat-raised-button color="warn" (click)="downloadShipment(param.id)">Download</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table__wrapper">
      <mat-table class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        matSortActive="id"
        matSortDirection="desc"
        matSortDisableClear>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Nama</mat-header-cell>
            <mat-cell *matCellDef="let detailshipment"><a href="javascript:" (click)="editDetailShipment(detailshipment.user.id)">{{detailshipment?.user.name}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="id_member">
          <mat-header-cell *matHeaderCellDef>Nomor KartaNU</mat-header-cell>
          <mat-cell *matCellDef="let detailshipment">{{detailshipment?.id_member}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="suborganization.moderator">
          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
          <mat-cell *matCellDef="let detailshipment"><a href="javascript:" (click)="editDetailShipment(detailshipment.suborganization.id)">{{detailshipment?.suborganization.moderator}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="suborganization.name">
          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
          <mat-cell *matCellDef="let detailshipment">{{detailshipment?.suborganization.name}}</mat-cell>
        </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
    </div>
    <div class="mat-table__bottom">
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
    </div>
  </kt-portlet-body>
</kt-portlet>