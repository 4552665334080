// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { ModeratorAllModel } from '../_models/moderatorall.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class ModeratorAllService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new moderatorall to the server
  createModeratorAll(moderatorall: ModeratorAllModel): Observable<ModeratorAllModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<ModeratorAllModel>(BASE_URL, moderatorall, { headers: httpHeaders});
  }

  // READ
  getAllModeratorAlls(): Observable<ModeratorAllModel[]> {
    return this.http.get<ModeratorAllModel[]>(BASE_URL);
  }

  getModeratorAllById(moderatorallId: number): Observable<ModeratorAllModel> {
    return this.http.get<ModeratorAllModel>(BASE_URL + `/${moderatorallId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findModeratorAlls(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/inventory';
    
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the moderatorall on the server
  updateModeratorAll(moderatorall: ModeratorAllModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, moderatorall, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForModeratorAll(moderatoralls: ModeratorAllModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      moderatorallsForUpdate: moderatoralls,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the moderatorall from the server
  deleteModeratorAll(moderatorallId: number): Observable<ModeratorAllModel> {
    const url = `${BASE_URL}/${moderatorallId}`;
    return this.http.delete<ModeratorAllModel>(url);
  }

  deleteModeratorAlls(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteModeratorAlls';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { moderatorallIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
