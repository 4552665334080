// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { LocationAllsState } from '../_reducers/locationall.reducers';
import { LocationAllModel } from '../_models/locationall.model';

export const selectLocationAllsState = createFeatureSelector<LocationAllsState>('locationalls');

export const selectLocationAllById = (locationallId: number) => createSelector(
    selectLocationAllsState,
    locationallsState => locationallsState.entities[locationallId]
);

export const selectLocationAllsPageLoading = createSelector(
    selectLocationAllsState,
    locationallsState => locationallsState.listLoading
);

export const selectLocationAllsActionLoading = createSelector(
    selectLocationAllsState,
    locationallsState => locationallsState.actionsloading
);

export const selectLastCreatedLocationAllId = createSelector(
    selectLocationAllsState,
    locationallsState => locationallsState.lastCreatedLocationAllId
);

export const selectLocationAllsShowInitWaitingMessage = createSelector(
    selectLocationAllsState,
    locationallsState => locationallsState.showInitWaitingMessage
);

export const selectLocationAllsInStore = createSelector(
    selectLocationAllsState,
    locationallsState => {
        const items: LocationAllModel[] = [];
        each(locationallsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: LocationAllModel[] = httpExtension.sortArray(items, locationallsState.lastQuery.sortField, locationallsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, locationallsState.totalCount, '');
    }
);
