// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  PwnuUserModel,
  PwnuUserService,
  PwnuUsersDataSource,
  PwnuUsersPageRequested,
  OnePwnuUserDeleted,
  ManyPwnuUsersDeleted,
  PwnuUsersStatusUpdated
} from '../../../../../core/user';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
import { PwnuUserEditComponent } from '../pwnu-user-edit/pwnu-user-edit.dialog.component';
@Component({
	selector: 'kt-pwnuusers-list',
	templateUrl: './pwnu-user-list.component.html',
	styleUrls: ['pwnu-user-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PwnuUserListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: PwnuUsersDataSource;
	displayedColumns = ['suborganization.moderator', 'user.name', 'user.email', 'created_at', 'suborganization.location'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};
  	suborganizationList: any = [];
  	selectedSuborg: string = '';;

	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<PwnuUserModel>(true, []);
	pwnuusersResult: PwnuUserModel[] = [];

	spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Download',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'accent',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'cloud_download'
    }
	}

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		public userService: PwnuUserService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    private datePipe: DatePipe
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadPwnuUsersList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadPwnuUsersList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init SubOrg List
		this.loadSuborganizationList();

		// Init DataSource
		this.dataSource = new PwnuUsersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.pwnuusersResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1), 
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadPwnuUsersList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load PwnuUsers List from service through data-source
	 */
	loadPwnuUsersList() {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const searchText: string = this.searchInput.nativeElement.value;
		const queryParams = new QueryParamsModel(
	        searchText,
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'[0,1,2,3,4]', // status
			'1', // type
			this.selectedSuborg // suborganization_id
		);
		// Call request from server
		this.store.dispatch(new PwnuUsersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Load Suborganization List
	 */
	loadSuborganizationList() {
		this.userService.getAllSuborganization('1') // 1 = NU, 2 = Banom
		.subscribe(
			data => {
				this.suborganizationList = data;
			}
		)
	}

	editPwnuUser(hashed_id) {
		// Get Detail User
		this.userService.getDetailUser(hashed_id)
		.subscribe(
			data => {
				const dialogRef = this.dialog.open(PwnuUserEditComponent, { data: { data } });
			}
		)
	}

  onSelectChange(event: any) {
    this.loadPwnuUsersList();
  }

  onDateChange(event: any) {
  	if(event.target.value){
    	this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
  	}else{
    	this.dateRange = {begin: null, end: null};
  	}
    this.loadPwnuUsersList();
  }

  download(){
    this.spinnerButtonOptions.active = true;
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      '[0,1,2,3,4]', // status
      '1', // type
      '' // suborganization_id
    );

    this.userService.downloadUsers(queryParams)
    .subscribe(
      data => {
        this.spinnerButtonOptions.active = false;
        window.location.href = data;
        }
      )
  }
}
