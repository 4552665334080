// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-banomusers-edit-dialog',
	templateUrl: './banom-user-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class BanomUserEditComponent implements OnInit, OnDestroy {
	// Public properties
	viewLoading: boolean = false;
    detailUser: any = {};
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<BanomUserEditComponent>
	 * @param data: any
	 */
	constructor(public dialogRef: MatDialogRef<BanomUserEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.detailUser = this.data.data;
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	/**
	 * Returns page title
	 */
	getTitle(): string {
		return 'Informasi Detail';
	}
}
