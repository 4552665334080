// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { OrganizationModel } from '../_models/organization.model';

export enum OrganizationActionTypes {
    OrganizationOnServerCreated = '[Edit Organization Dialog] Organization On Server Created',
    OrganizationCreated = '[Edit Organization Dialog] Organization Created',
    OrganizationUpdated = '[Edit Organization Dialog] Organization Updated',
    OrganizationsStatusUpdated = '[Organization List Page] Organizations Status Updated',
    OneOrganizationDeleted = '[Organizations List Page] One Organization Deleted',
    ManyOrganizationsDeleted = '[Organizations List Page] Many Organization Deleted',
    OrganizationsPageRequested = '[Organizations List Page] Organizations Page Requested',
    OrganizationsPageLoaded = '[Organizations API] Organizations Page Loaded',
    OrganizationsPageCancelled = '[Organizations API] Organizations Page Cancelled',
    OrganizationsPageToggleLoading = '[Organizations] Organizations Page Toggle Loading',
    OrganizationActionToggleLoading = '[Organizations] Organizations Action Toggle Loading'
}

export class OrganizationOnServerCreated implements Action {
    readonly type = OrganizationActionTypes.OrganizationOnServerCreated;
    constructor(public payload: { organization: OrganizationModel }) { }
}

export class OrganizationCreated implements Action {
    readonly type = OrganizationActionTypes.OrganizationCreated;
    constructor(public payload: { organization: OrganizationModel }) { }
}

export class OrganizationUpdated implements Action {
    readonly type = OrganizationActionTypes.OrganizationUpdated;
    constructor(public payload: {
        partialOrganization: Update<OrganizationModel>, // For State update
        organization: OrganizationModel // For Server update (through service)
    }) { }
}

export class OrganizationsStatusUpdated implements Action {
    readonly type = OrganizationActionTypes.OrganizationsStatusUpdated;
    constructor(public payload: {
        organizations: OrganizationModel[],
        status: number
    }) { }
}

export class OneOrganizationDeleted implements Action {
    readonly type = OrganizationActionTypes.OneOrganizationDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyOrganizationsDeleted implements Action {
    readonly type = OrganizationActionTypes.ManyOrganizationsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class OrganizationsPageRequested implements Action {
    readonly type = OrganizationActionTypes.OrganizationsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class OrganizationsPageLoaded implements Action {
    readonly type = OrganizationActionTypes.OrganizationsPageLoaded;
    constructor(public payload: { organizations: OrganizationModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class OrganizationsPageCancelled implements Action {
    readonly type = OrganizationActionTypes.OrganizationsPageCancelled;
}

export class OrganizationsPageToggleLoading implements Action {
    readonly type = OrganizationActionTypes.OrganizationsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class OrganizationActionToggleLoading implements Action {
    readonly type = OrganizationActionTypes.OrganizationActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type OrganizationActions = OrganizationOnServerCreated
| OrganizationCreated
| OrganizationUpdated
| OrganizationsStatusUpdated
| OneOrganizationDeleted
| ManyOrganizationsDeleted
| OrganizationsPageRequested
| OrganizationsPageLoaded
| OrganizationsPageCancelled
| OrganizationsPageToggleLoading
| OrganizationActionToggleLoading;
