// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GoldDonationModel } from '../_models/golddonation.model';

export enum GoldDonationActionTypes {
    GoldDonationOnServerCreated = '[Edit GoldDonation Dialog] GoldDonation On Server Created',
    GoldDonationCreated = '[Edit GoldDonation Dialog] GoldDonation Created',
    GoldDonationUpdated = '[Edit GoldDonation Dialog] GoldDonation Updated',
    GoldDonationsStatusUpdated = '[GoldDonation List Page] GoldDonations Status Updated',
    OneGoldDonationDeleted = '[GoldDonations List Page] One GoldDonation Deleted',
    ManyGoldDonationsDeleted = '[GoldDonations List Page] Many GoldDonation Deleted',
    GoldDonationsPageRequested = '[GoldDonations List Page] GoldDonations Page Requested',
    GoldDonationsPageLoaded = '[GoldDonations API] GoldDonations Page Loaded',
    GoldDonationsPageCancelled = '[GoldDonations API] GoldDonations Page Cancelled',
    GoldDonationsPageToggleLoading = '[GoldDonations] GoldDonations Page Toggle Loading',
    GoldDonationActionToggleLoading = '[GoldDonations] GoldDonations Action Toggle Loading'
}

export class GoldDonationOnServerCreated implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationOnServerCreated;
    constructor(public payload: { golddonation: GoldDonationModel }) { }
}

export class GoldDonationCreated implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationCreated;
    constructor(public payload: { golddonation: GoldDonationModel }) { }
}

export class GoldDonationUpdated implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationUpdated;
    constructor(public payload: {
        partialGoldDonation: Update<GoldDonationModel>, // For State update
        golddonation: GoldDonationModel // For Server update (through service)
    }) { }
}

export class GoldDonationsStatusUpdated implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationsStatusUpdated;
    constructor(public payload: {
        golddonations: GoldDonationModel[],
        status: number
    }) { }
}

export class OneGoldDonationDeleted implements Action {
    readonly type = GoldDonationActionTypes.OneGoldDonationDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyGoldDonationsDeleted implements Action {
    readonly type = GoldDonationActionTypes.ManyGoldDonationsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class GoldDonationsPageRequested implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class GoldDonationsPageLoaded implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationsPageLoaded;
    constructor(public payload: { golddonations: GoldDonationModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class GoldDonationsPageCancelled implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationsPageCancelled;
}

export class GoldDonationsPageToggleLoading implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class GoldDonationActionToggleLoading implements Action {
    readonly type = GoldDonationActionTypes.GoldDonationActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type GoldDonationActions = GoldDonationOnServerCreated
| GoldDonationCreated
| GoldDonationUpdated
| GoldDonationsStatusUpdated
| OneGoldDonationDeleted
| ManyGoldDonationsDeleted
| GoldDonationsPageRequested
| GoldDonationsPageLoaded
| GoldDonationsPageCancelled
| GoldDonationsPageToggleLoading
| GoldDonationActionToggleLoading;
