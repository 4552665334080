// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { LocationAllModel } from '../_models/locationall.model';

export enum LocationAllActionTypes {
    LocationAllOnServerCreated = '[Edit LocationAll Dialog] LocationAll On Server Created',
    LocationAllCreated = '[Edit LocationAll Dialog] LocationAll Created',
    LocationAllUpdated = '[Edit LocationAll Dialog] LocationAll Updated',
    LocationAllsStatusUpdated = '[LocationAll List Page] LocationAlls Status Updated',
    OneLocationAllDeleted = '[LocationAlls List Page] One LocationAll Deleted',
    ManyLocationAllsDeleted = '[LocationAlls List Page] Many LocationAll Deleted',
    LocationAllsPageRequested = '[LocationAlls List Page] LocationAlls Page Requested',
    LocationAllsPageLoaded = '[LocationAlls API] LocationAlls Page Loaded',
    LocationAllsPageCancelled = '[LocationAlls API] LocationAlls Page Cancelled',
    LocationAllsPageToggleLoading = '[LocationAlls] LocationAlls Page Toggle Loading',
    LocationAllActionToggleLoading = '[LocationAlls] LocationAlls Action Toggle Loading'
}

export class LocationAllOnServerCreated implements Action {
    readonly type = LocationAllActionTypes.LocationAllOnServerCreated;
    constructor(public payload: { locationall: LocationAllModel }) { }
}

export class LocationAllCreated implements Action {
    readonly type = LocationAllActionTypes.LocationAllCreated;
    constructor(public payload: { locationall: LocationAllModel }) { }
}

export class LocationAllUpdated implements Action {
    readonly type = LocationAllActionTypes.LocationAllUpdated;
    constructor(public payload: {
        partialLocationAll: Update<LocationAllModel>, // For State update
        locationall: LocationAllModel // For Server update (through service)
    }) { }
}

export class LocationAllsStatusUpdated implements Action {
    readonly type = LocationAllActionTypes.LocationAllsStatusUpdated;
    constructor(public payload: {
        locationalls: LocationAllModel[],
        status: number
    }) { }
}

export class OneLocationAllDeleted implements Action {
    readonly type = LocationAllActionTypes.OneLocationAllDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyLocationAllsDeleted implements Action {
    readonly type = LocationAllActionTypes.ManyLocationAllsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class LocationAllsPageRequested implements Action {
    readonly type = LocationAllActionTypes.LocationAllsPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class LocationAllsPageLoaded implements Action {
    readonly type = LocationAllActionTypes.LocationAllsPageLoaded;
    constructor(public payload: { locationalls: LocationAllModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class LocationAllsPageCancelled implements Action {
    readonly type = LocationAllActionTypes.LocationAllsPageCancelled;
}

export class LocationAllsPageToggleLoading implements Action {
    readonly type = LocationAllActionTypes.LocationAllsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class LocationAllActionToggleLoading implements Action {
    readonly type = LocationAllActionTypes.LocationAllActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type LocationAllActions = LocationAllOnServerCreated
| LocationAllCreated
| LocationAllUpdated
| LocationAllsStatusUpdated
| OneLocationAllDeleted
| ManyLocationAllsDeleted
| LocationAllsPageRequested
| LocationAllsPageLoaded
| LocationAllsPageCancelled
| LocationAllsPageToggleLoading
| LocationAllActionToggleLoading;
