// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { DetailShipmentsState } from '../_reducers/detailshipment.reducers';
import { DetailShipmentModel } from '../_models/detailshipment.model';

export const selectDetailShipmentsState = createFeatureSelector<DetailShipmentsState>('detailshipments');

export const selectDetailShipmentById = (detailshipmentId: number) => createSelector(
    selectDetailShipmentsState,
    detailshipmentsState => detailshipmentsState.entities[detailshipmentId]
);

export const selectDetailShipmentsPageLoading = createSelector(
    selectDetailShipmentsState,
    detailshipmentsState => detailshipmentsState.listLoading
);

export const selectDetailShipmentsActionLoading = createSelector(
    selectDetailShipmentsState,
    detailshipmentsState => detailshipmentsState.actionsloading
);

export const selectLastCreatedDetailShipmentId = createSelector(
    selectDetailShipmentsState,
    detailshipmentsState => detailshipmentsState.lastCreatedDetailShipmentId
);

export const selectDetailShipmentsShowInitWaitingMessage = createSelector(
    selectDetailShipmentsState,
    detailshipmentsState => detailshipmentsState.showInitWaitingMessage
);

export const selectDetailShipmentsInStore = createSelector(
    selectDetailShipmentsState,
    detailshipmentsState => {
        const items: DetailShipmentModel[] = [];
        each(detailshipmentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: DetailShipmentModel[] = httpExtension.sortArray(items, detailshipmentsState.lastQuery.sortField, detailshipmentsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, detailshipmentsState.totalCount, '');
    }
);
