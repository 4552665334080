// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../partials/partials.module';
// Auth
import { ModuleGuard } from '../../../core/auth';
// Core => Services
import {
	goldbuysReducer,
	GoldBuyEffects,
	GoldBuyService,
	bigordersReducer,
	BigOrderEffects,
	BigOrderService,
	coinsReducer,
	CoinEffects,
	CoinService,
	goldsellsReducer,
	GoldSellEffects,
	GoldSellService,
	golddonationsReducer,
	GoldDonationEffects,
	GoldDonationService,
	goldgiftcardsReducer,
	GoldGiftCardEffects,
	GoldGiftCardService,
	goldzakatsReducer,
	GoldZakatEffects,
	GoldZakatService,
} from '../../../core/transaction';
// Core => Utils
import { HttpUtilsService,
	TypesUtilsService,
	InterceptService,
	LayoutUtilsService
} from '../../../core/_base/crud';
// Shared
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from '../../partials/content/crud';
// Components
import { TransactionComponent } from './transaction.component';
// Gold Buy
import { GoldBuyListComponent } from './gold-buy/gold-buy-list/gold-buy-list.component';
import { GoldBuyEditComponent } from './gold-buy/gold-buy-edit/gold-buy-edit.dialog.component';

// Big Order
import { BigOrderListComponent } from './big-order/big-order-list/big-order-list.component';
import { BigOrderEditComponent } from './big-order/big-order-edit/big-order-edit.dialog.component';

// Coin
import { CoinListComponent } from './coin/coin-list/coin-list.component';
import { CoinEditComponent } from './coin/coin-edit/coin-edit.dialog.component';

// Gold Sell
import { GoldSellListComponent } from './gold-sell/gold-sell-list/gold-sell-list.component';
import { GoldSellEditComponent } from './gold-sell/gold-sell-edit/gold-sell-edit.dialog.component';

// Gold Donation
import { GoldDonationListComponent } from './gold-donation/gold-donation-list/gold-donation-list.component';
import { GoldDonationEditComponent } from './gold-donation/gold-donation-edit/gold-donation-edit.dialog.component';

// Gold Donation
import { GoldGiftCardListComponent } from './gold-giftcard/gold-giftcard-list/gold-giftcard-list.component';
import { GoldGiftCardEditComponent } from './gold-giftcard/gold-giftcard-edit/gold-giftcard-edit.dialog.component';

// Gold Zakat
import { GoldZakatListComponent } from './gold-zakat/gold-zakat-list/gold-zakat-list.component';
import { GoldZakatEditComponent } from './gold-zakat/gold-zakat-edit/gold-zakat-edit.dialog.component';

// Button Spinner
import { MatProgressButtonsModule } from 'mat-progress-buttons';

// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
  MatFormFieldModule
} from '@angular/material';
// Date Range
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../core/_base/layout';

import { environment } from '../../../../environments/environment';
import { CoreModule } from '../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: TransactionComponent,
		children: [
			{
				path: '',
				redirectTo: 'transaction',
				pathMatch: 'full'
			},
			{
				path: 'gold-buy',
				component: GoldBuyListComponent
			},
			{
				path: 'big-order',
				component: BigOrderListComponent
			},
			{
				path: 'coin',
				component: CoinListComponent
			},
			{
				path: 'gold-sell',
				component: GoldSellListComponent
			},
			{
				path: 'gold-donation',
				component: GoldDonationListComponent
			},
			{
				path: 'gold-giftcard',
				component: GoldGiftCardListComponent
			},
			{
				path: 'gold-zakat',
				component: GoldZakatListComponent
			},
		]
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgxPermissionsModule.forChild(),
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
  		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		SatDatepickerModule,
		SatNativeDateModule,
		MatProgressButtonsModule,
		StoreModule.forFeature('goldbuys', goldbuysReducer),
		EffectsModule.forFeature([GoldBuyEffects]),
		StoreModule.forFeature('bigorders', bigordersReducer),
		EffectsModule.forFeature([BigOrderEffects]),
		StoreModule.forFeature('coins', coinsReducer),
		EffectsModule.forFeature([CoinEffects]),
		StoreModule.forFeature('goldsells', goldsellsReducer),
		EffectsModule.forFeature([GoldSellEffects]),
		StoreModule.forFeature('golddonations', golddonationsReducer),
		EffectsModule.forFeature([GoldDonationEffects]),
		StoreModule.forFeature('goldgiftcards', goldgiftcardsReducer),
		EffectsModule.forFeature([GoldGiftCardEffects]),
		StoreModule.forFeature('goldzakats', goldzakatsReducer),
		EffectsModule.forFeature([GoldZakatEffects]),
	],
	providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    DatePipe,
		ModuleGuard,
		InterceptService,
      	{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
        	multi: true
      	},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		GoldBuyService,
		BigOrderService,
		CoinService,
		GoldSellService,
		GoldDonationService,
		GoldGiftCardService,
		GoldZakatService,
		TypesUtilsService,
		LayoutUtilsService,
	],
	entryComponents: [
		ActionNotificationComponent,
		GoldBuyEditComponent,
		BigOrderEditComponent,
		CoinEditComponent,
		GoldSellEditComponent,
		GoldDonationEditComponent,
		GoldGiftCardEditComponent,
		GoldZakatEditComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
	declarations: [
		TransactionComponent,
		GoldBuyListComponent,
		GoldBuyEditComponent,
		BigOrderListComponent,
		BigOrderEditComponent,
		CoinListComponent,
		CoinEditComponent,
		GoldSellListComponent,
		GoldSellEditComponent,
		GoldDonationListComponent,
		GoldDonationEditComponent,
		GoldGiftCardListComponent,
		GoldGiftCardEditComponent,
		GoldZakatListComponent,
		GoldZakatEditComponent,
	]
})
export class TransactionModule { }
