import { QueryParamsDetailShipmentModel } from './../../_base/crud/models/query-models/query-params-detail-shipment.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { DetailShipmentService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    DetailShipmentActionTypes,
    DetailShipmentsPageRequested,
    DetailShipmentsPageLoaded,
    ManyDetailShipmentsDeleted,
    OneDetailShipmentDeleted,
    DetailShipmentActionToggleLoading,
    DetailShipmentsPageToggleLoading,
    DetailShipmentUpdated,
    DetailShipmentsStatusUpdated,
    DetailShipmentCreated,
    DetailShipmentOnServerCreated
} from '../_actions/detailshipment.actions';
import { of } from 'rxjs';

@Injectable()
export class DetailShipmentEffects {
    showPageLoadingDistpatcher = new DetailShipmentsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new DetailShipmentActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new DetailShipmentActionToggleLoading({ isLoading: false });

    @Effect()
    loadDetailShipmentsPage$ = this.actions$.pipe(
        ofType<DetailShipmentsPageRequested>(DetailShipmentActionTypes.DetailShipmentsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.detailshipmentsService.findDetailShipments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsDetailShipmentModel = response[1];
            const pageLoadedDispatch = new DetailShipmentsPageLoaded({
                detailshipments: result.data['data'],
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteDetailShipment$ = this.actions$
        .pipe(
            ofType<OneDetailShipmentDeleted>(DetailShipmentActionTypes.OneDetailShipmentDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.detailshipmentsService.deleteDetailShipment(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteDetailShipments$ = this.actions$
        .pipe(
            ofType<ManyDetailShipmentsDeleted>(DetailShipmentActionTypes.ManyDetailShipmentsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.detailshipmentsService.deleteDetailShipments(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateDetailShipment$ = this.actions$
        .pipe(
            ofType<DetailShipmentUpdated>(DetailShipmentActionTypes.DetailShipmentUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.detailshipmentsService.updateDetailShipment(payload.detailshipment);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateDetailShipmentsStatus$ = this.actions$
        .pipe(
            ofType<DetailShipmentsStatusUpdated>(DetailShipmentActionTypes.DetailShipmentsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.detailshipmentsService.updateStatusForDetailShipment(payload.detailshipments, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createDetailShipment$ = this.actions$
        .pipe(
            ofType<DetailShipmentOnServerCreated>(DetailShipmentActionTypes.DetailShipmentOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.detailshipmentsService.createDetailShipment(payload.detailshipment).pipe(
                    tap(res => {
                        this.store.dispatch(new DetailShipmentCreated({ detailshipment: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private detailshipmentsService: DetailShipmentService, private store: Store<AppState>) { }
}
