// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { GoldZakatActions, GoldZakatActionTypes } from '../_actions/goldzakat.actions';
// Models
import { GoldZakatModel } from '../_models/goldzakat.model';
import { QueryParamsModel } from '../../_base/crud';

export interface GoldZakatsState extends EntityState<GoldZakatModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGoldZakatId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GoldZakatModel> = createEntityAdapter<GoldZakatModel>();

export const initialGoldZakatsState: GoldZakatsState = adapter.getInitialState({
    goldzakatForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGoldZakatId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function goldzakatsReducer(state = initialGoldZakatsState, action: GoldZakatActions): GoldZakatsState {
    switch  (action.type) {
        case GoldZakatActionTypes.GoldZakatsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGoldZakatId: undefined
            };
        }
        case GoldZakatActionTypes.GoldZakatActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case GoldZakatActionTypes.GoldZakatOnServerCreated: return {
            ...state
        };
        // case GoldZakatActionTypes.GoldZakatCreated: return adapter.addOne(action.payload.goldzakat, {
        //     ...state, lastCreatedGoldZakatId: action.payload.goldzakat.id
        // });
        case GoldZakatActionTypes.GoldZakatUpdated: return adapter.updateOne(action.payload.partialGoldZakat, state);
       //  case GoldZakatActionTypes.GoldZakatsStatusUpdated: {
       //      const _partialGoldZakats: Update<GoldZakatModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.goldzakats.length; i++) {
       //          _partialGoldZakats.push({
           //  id: action.payload.goldzakats[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialGoldZakats, state);
       //  }
        case GoldZakatActionTypes.OneGoldZakatDeleted: return adapter.removeOne(action.payload.id, state);
        case GoldZakatActionTypes.ManyGoldZakatsDeleted: return adapter.removeMany(action.payload.ids, state);
        case GoldZakatActionTypes.GoldZakatsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case GoldZakatActionTypes.GoldZakatsPageLoaded: {
            return adapter.addMany(action.payload.goldzakats, {
                ...initialGoldZakatsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getGoldZakatState = createFeatureSelector<GoldZakatModel>('goldzakats');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
