// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BanomUserModel } from '../_models/banomuser.model';

export enum BanomUserActionTypes {
    BanomUserOnServerCreated = '[Edit BanomUser Dialog] BanomUser On Server Created',
    BanomUserCreated = '[Edit BanomUser Dialog] BanomUser Created',
    BanomUserUpdated = '[Edit BanomUser Dialog] BanomUser Updated',
    BanomUsersStatusUpdated = '[BanomUser List Page] BanomUsers Status Updated',
    OneBanomUserDeleted = '[BanomUsers List Page] One BanomUser Deleted',
    ManyBanomUsersDeleted = '[BanomUsers List Page] Many BanomUser Deleted',
    BanomUsersPageRequested = '[BanomUsers List Page] BanomUsers Page Requested',
    BanomUsersPageLoaded = '[BanomUsers API] BanomUsers Page Loaded',
    BanomUsersPageCancelled = '[BanomUsers API] BanomUsers Page Cancelled',
    BanomUsersPageToggleLoading = '[BanomUsers] BanomUsers Page Toggle Loading',
    BanomUserActionToggleLoading = '[BanomUsers] BanomUsers Action Toggle Loading'
}

export class BanomUserOnServerCreated implements Action {
    readonly type = BanomUserActionTypes.BanomUserOnServerCreated;
    constructor(public payload: { banomuser: BanomUserModel }) { }
}

export class BanomUserCreated implements Action {
    readonly type = BanomUserActionTypes.BanomUserCreated;
    constructor(public payload: { banomuser: BanomUserModel }) { }
}

export class BanomUserUpdated implements Action {
    readonly type = BanomUserActionTypes.BanomUserUpdated;
    constructor(public payload: {
        partialBanomUser: Update<BanomUserModel>, // For State update
        banomuser: BanomUserModel // For Server update (through service)
    }) { }
}

export class BanomUsersStatusUpdated implements Action {
    readonly type = BanomUserActionTypes.BanomUsersStatusUpdated;
    constructor(public payload: {
        banomusers: BanomUserModel[],
        status: number
    }) { }
}

export class OneBanomUserDeleted implements Action {
    readonly type = BanomUserActionTypes.OneBanomUserDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyBanomUsersDeleted implements Action {
    readonly type = BanomUserActionTypes.ManyBanomUsersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class BanomUsersPageRequested implements Action {
    readonly type = BanomUserActionTypes.BanomUsersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BanomUsersPageLoaded implements Action {
    readonly type = BanomUserActionTypes.BanomUsersPageLoaded;
    constructor(public payload: { banomusers: BanomUserModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class BanomUsersPageCancelled implements Action {
    readonly type = BanomUserActionTypes.BanomUsersPageCancelled;
}

export class BanomUsersPageToggleLoading implements Action {
    readonly type = BanomUserActionTypes.BanomUsersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class BanomUserActionToggleLoading implements Action {
    readonly type = BanomUserActionTypes.BanomUserActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type BanomUserActions = BanomUserOnServerCreated
| BanomUserCreated
| BanomUserUpdated
| BanomUsersStatusUpdated
| OneBanomUserDeleted
| ManyBanomUsersDeleted
| BanomUsersPageRequested
| BanomUsersPageLoaded
| BanomUsersPageCancelled
| BanomUsersPageToggleLoading
| BanomUserActionToggleLoading;
