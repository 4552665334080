export class PageConfig {
	public defaults: any = {
		home: {
			page: {title: 'Home', desc: 'Data statistik terbaru dan grafik'}
		},
		user: {
			'all-user': {
				page: {title: 'Semua User', desc: 'Daftar semua user'}
			},
			'active-user': {
				page: {title: 'Aktif Kartanu', desc: 'Daftar semua user yang aktif kartanu'}
			},
			'active-user-transaction': {
				page: {title: 'Aktif Transaksi', desc: 'Daftar semua user yang aktif transaksi'}
			},
			'non-active-user': {
				page: {title: 'Non-Aktif', desc: 'Daftar semua user yang tidak aktif'}
			},
			'pwnu-user': {
				page: {title: 'Lazisnu', desc: 'Daftar semua user Lazisnu'}
			},
		},
	    transaction: {
			'gold-buy': {
				page: {title: 'Total Beli Emas', desc: 'Daftar total transaksi beli emas'}
			},
			'big-order': {
				page: {title: 'Transaction Big Order', desc: 'Daftar transaksi Big Order'}
			},
			'coin': {
				page: {title: 'Transaction Koin Nusantara', desc: 'Daftar transaksi Koin Nusantara'}
			},
			'gold-sell': {
				page: {title: 'Total Jual Emas', desc: 'Daftar total transaksi jual emas'}
			},
			'gold-donation': {
				page: {title: 'Total Donasi Emas', desc: 'Daftar total donasi emas'}
			},
			'gold-giftcard': {
				page: {title: 'Total Gift Card Donasi Emas', desc: 'Daftar total gift card donasi emas'}
			},
			'gold-zakat': {
				page: {title: 'Total Zakat Emas', desc: 'Daftar total zakat emas'}
			}
	    },
		'kartanu': {
			'all-card': {
				page: {title: 'Semua KartaNU', desc: 'Daftar semua KartaNU'}
			},
			'available-card': {
				page: {title: 'Tersedia', desc: 'Daftar semua KartaNU yang tersedia'}
			},
			'request-card': {
				page: {title: 'Permintaan Cetak', desc: 'Daftar semua KartaNU yang meminta cetak'}
			},
			'accept-card': {
				page: {title: 'Permintaan Kirim', desc: 'Daftar semua KartaNU yang meminta kirim'}
			},
			'receive-card': {
				page: {title: 'Permintaan Terima', desc: 'Daftar semua KartaNU yang meminta terima'}
			},
			'history-card': {
				page: {title: 'Riwayat Data', desc: 'Daftar riwayat data KartaNU'}
			},
      'approve-card': {
        page: {title: 'Permintaan Cetak', desc: 'Daftar permintaan cetak KartaNU'}
      },
      'send-card': {
        page: {title: 'Pengiriman KartaNU', desc: 'Daftar pengiriman KartaNU'}
      }
		},
		'user-management': {
			'user-info': {
				page: {title: 'Informasi User', desc: ''}
			},
			'user-update': {
				page: {title: 'Ubah Tambah Role', desc: ''}
			},
			'user-add': {
				page: {title: 'Tambah Role', desc: ''}
			},
			'user-activity': {
				page: {title: 'Riwayat Aktifitas', desc: ''}
			},
			'my-account': {
				page: {title: 'My Account', desc: ''}
			},
			'users': {
				page: {title: 'Users', desc: ''}
			},
			'roles': {
				page: {title: 'Roles', desc: ''}
			}
		},
    report: {
      page: {title: 'Report', desc: 'Export data'}
    },
    access: {
      page: {title: 'Akses', desc: 'Akses Menu'}
	},
	bigorder: {
		page: {title: 'Calculator Big Order', desc: 'Tools perhitungan rewards dari transaksi Big Order'}
	  },
    tnc: {
      page: {title: 'Terms and Conditions', desc: 'Syarat dan Ketentuan'}
    },
	};

	public get configs(): any {
		return this.defaults;
	}
}
