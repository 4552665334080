export class QueryParamsInventoryModel {
	// fields
	filter: any;
	type: string;
	status: string;
	sortOrder: string;
	sortField: string;
	pageNumber: number;
	pageSize: number;

	// constructor overrides
	constructor(
    _filter: any = null,
		_type: string = '',
		_status: string = '',
    _sortOrder: string = 'desc',
		_sortField: string = '',
		_pageNumber: number = 1,
		_pageSize: number = 3) {
  		this.filter = _filter;
  		this.type = _type;
  		this.status = _status;
  		this.sortOrder = _sortOrder;
  		this.sortField = _sortField;
  		this.pageNumber = _pageNumber;
  		this.pageSize = _pageSize;
	}
}
