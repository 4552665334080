// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AllUserModel } from '../_models/alluser.model';

export enum AllUserActionTypes {
    AllUserOnServerCreated = '[Edit AllUser Dialog] AllUser On Server Created',
    AllUserCreated = '[Edit AllUser Dialog] AllUser Created',
    AllUserUpdated = '[Edit AllUser Dialog] AllUser Updated',
    AllUsersStatusUpdated = '[AllUser List Page] AllUsers Status Updated',
    OneAllUserDeleted = '[AllUsers List Page] One AllUser Deleted',
    ManyAllUsersDeleted = '[AllUsers List Page] Many AllUser Deleted',
    AllUsersPageRequested = '[AllUsers List Page] AllUsers Page Requested',
    AllUsersPageLoaded = '[AllUsers API] AllUsers Page Loaded',
    AllUsersPageCancelled = '[AllUsers API] AllUsers Page Cancelled',
    AllUsersPageToggleLoading = '[AllUsers] AllUsers Page Toggle Loading',
    AllUserActionToggleLoading = '[AllUsers] AllUsers Action Toggle Loading'
}

export class AllUserOnServerCreated implements Action {
    readonly type = AllUserActionTypes.AllUserOnServerCreated;
    constructor(public payload: { alluser: AllUserModel }) { }
}

export class AllUserCreated implements Action {
    readonly type = AllUserActionTypes.AllUserCreated;
    constructor(public payload: { alluser: AllUserModel }) { }
}

export class AllUserUpdated implements Action {
    readonly type = AllUserActionTypes.AllUserUpdated;
    constructor(public payload: {
        partialAllUser: Update<AllUserModel>, // For State update
        alluser: AllUserModel // For Server update (through service)
    }) { }
}

export class AllUsersStatusUpdated implements Action {
    readonly type = AllUserActionTypes.AllUsersStatusUpdated;
    constructor(public payload: {
        allusers: AllUserModel[],
        status: number
    }) { }
}

export class OneAllUserDeleted implements Action {
    readonly type = AllUserActionTypes.OneAllUserDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAllUsersDeleted implements Action {
    readonly type = AllUserActionTypes.ManyAllUsersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AllUsersPageRequested implements Action {
    readonly type = AllUserActionTypes.AllUsersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class AllUsersPageLoaded implements Action {
    readonly type = AllUserActionTypes.AllUsersPageLoaded;
    constructor(public payload: { allusers: AllUserModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class AllUsersPageCancelled implements Action {
    readonly type = AllUserActionTypes.AllUsersPageCancelled;
}

export class AllUsersPageToggleLoading implements Action {
    readonly type = AllUserActionTypes.AllUsersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllUserActionToggleLoading implements Action {
    readonly type = AllUserActionTypes.AllUserActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AllUserActions = AllUserOnServerCreated
| AllUserCreated
| AllUserUpdated
| AllUsersStatusUpdated
| OneAllUserDeleted
| ManyAllUsersDeleted
| AllUsersPageRequested
| AllUsersPageLoaded
| AllUsersPageCancelled
| AllUsersPageToggleLoading
| AllUserActionToggleLoading;
