// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { SuborganizationActions, SuborganizationActionTypes } from '../_actions/suborganization.actions';
// Models
import { SuborganizationModel } from '../_models/suborganization.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface SuborganizationsState extends EntityState<SuborganizationModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSuborganizationId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SuborganizationModel> = createEntityAdapter<SuborganizationModel>();

export const initialSuborganizationsState: SuborganizationsState = adapter.getInitialState({
    suborganizationForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSuborganizationId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function suborganizationsReducer(state = initialSuborganizationsState, action: SuborganizationActions): SuborganizationsState {
    switch  (action.type) {
        case SuborganizationActionTypes.SuborganizationsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSuborganizationId: undefined
            };
        }
        case SuborganizationActionTypes.SuborganizationActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case SuborganizationActionTypes.SuborganizationOnServerCreated: return {
            ...state
        };
        // case SuborganizationActionTypes.SuborganizationCreated: return adapter.addOne(action.payload.suborganization, {
        //     ...state, lastCreatedSuborganizationId: action.payload.suborganization.id
        // });
        case SuborganizationActionTypes.SuborganizationUpdated: return adapter.updateOne(action.payload.partialSuborganization, state);
       //  case SuborganizationActionTypes.SuborganizationsStatusUpdated: {
       //      const _partialSuborganizations: Update<SuborganizationModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.suborganizations.length; i++) {
       //          _partialSuborganizations.push({
           //  id: action.payload.suborganizations[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialSuborganizations, state);
       //  }
        case SuborganizationActionTypes.OneSuborganizationDeleted: return adapter.removeOne(action.payload.id, state);
        case SuborganizationActionTypes.ManySuborganizationsDeleted: return adapter.removeMany(action.payload.ids, state);
        case SuborganizationActionTypes.SuborganizationsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case SuborganizationActionTypes.SuborganizationsPageLoaded: {
            return adapter.addMany(action.payload.suborganizations, {
                ...initialSuborganizationsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getSuborganizationState = createFeatureSelector<SuborganizationModel>('suborganizations');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
