// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { GoldSellModel } from '../_models/goldsell.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class GoldSellService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new goldsell to the server
  createGoldSell(goldsell: GoldSellModel): Observable<GoldSellModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<GoldSellModel>(BASE_URL, goldsell, { headers: httpHeaders});
  }

  // READ
  getAllGoldSells(): Observable<GoldSellModel[]> {
    return this.http.get<GoldSellModel[]>(BASE_URL);
  }

  getGoldSellById(goldsellId: number): Observable<GoldSellModel> {
    return this.http.get<GoldSellModel>(BASE_URL + `/${goldsellId}`);
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  downloadUsers(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/user';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findGoldSells(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/user';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the goldsell on the server
  updateGoldSell(goldsell: GoldSellModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, goldsell, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForGoldSell(goldsells: GoldSellModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      goldsellsForUpdate: goldsells,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the goldsell from the server
  deleteGoldSell(goldsellId: number): Observable<GoldSellModel> {
    const url = `${BASE_URL}/${goldsellId}`;
    return this.http.delete<GoldSellModel>(url);
  }

  deleteGoldSells(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteGoldSells';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { goldsellIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }

  downloadGoldSell(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/sell-transaction';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }
}
