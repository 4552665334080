// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AllUsersState } from '../_reducers/alluser.reducers';
import { AllUserModel } from '../_models/alluser.model';

export const selectAllUsersState = createFeatureSelector<AllUsersState>('allusers');

export const selectAllUserById = (alluserId: number) => createSelector(
    selectAllUsersState,
    allusersState => allusersState.entities[alluserId]
);

export const selectAllUsersPageLoading = createSelector(
    selectAllUsersState,
    allusersState => allusersState.listLoading
);

export const selectAllUsersActionLoading = createSelector(
    selectAllUsersState,
    allusersState => allusersState.actionsloading
);

export const selectLastCreatedAllUserId = createSelector(
    selectAllUsersState,
    allusersState => allusersState.lastCreatedAllUserId
);

export const selectAllUsersShowInitWaitingMessage = createSelector(
    selectAllUsersState,
    allusersState => allusersState.showInitWaitingMessage
);

export const selectAllUsersInStore = createSelector(
    selectAllUsersState,
    allusersState => {
        const items: AllUserModel[] = [];
        each(allusersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AllUserModel[] = httpExtension.sortArray(items, allusersState.lastQuery.sortField, allusersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, allusersState.totalCount, '');
    }
);
