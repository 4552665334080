// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { BanomUserActions, BanomUserActionTypes } from '../_actions/banomuser.actions';
// Models
import { BanomUserModel } from '../_models/banomuser.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface BanomUsersState extends EntityState<BanomUserModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedBanomUserId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BanomUserModel> = createEntityAdapter<BanomUserModel>();

export const initialBanomUsersState: BanomUsersState = adapter.getInitialState({
    banomuserForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedBanomUserId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function banomusersReducer(state = initialBanomUsersState, action: BanomUserActions): BanomUsersState {
    switch  (action.type) {
        case BanomUserActionTypes.BanomUsersPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedBanomUserId: undefined
            };
        }
        case BanomUserActionTypes.BanomUserActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case BanomUserActionTypes.BanomUserOnServerCreated: return {
            ...state
        };
        // case BanomUserActionTypes.BanomUserCreated: return adapter.addOne(action.payload.banomuser, {
        //     ...state, lastCreatedBanomUserId: action.payload.banomuser.id
        // });
        case BanomUserActionTypes.BanomUserUpdated: return adapter.updateOne(action.payload.partialBanomUser, state);
       //  case BanomUserActionTypes.BanomUsersStatusUpdated: {
       //      const _partialBanomUsers: Update<BanomUserModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.banomusers.length; i++) {
       //          _partialBanomUsers.push({
           //  id: action.payload.banomusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialBanomUsers, state);
       //  }
        case BanomUserActionTypes.OneBanomUserDeleted: return adapter.removeOne(action.payload.id, state);
        case BanomUserActionTypes.ManyBanomUsersDeleted: return adapter.removeMany(action.payload.ids, state);
        case BanomUserActionTypes.BanomUsersPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case BanomUserActionTypes.BanomUsersPageLoaded: {
            return adapter.addMany(action.payload.banomusers, {
                ...initialBanomUsersState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getBanomUserState = createFeatureSelector<BanomUserModel>('banomusers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
