// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { LocationAvailablesState } from '../_reducers/locationavailable.reducers';
import { LocationAvailableModel } from '../_models/locationavailable.model';

export const selectLocationAvailablesState = createFeatureSelector<LocationAvailablesState>('locationavailables');

export const selectLocationAvailableById = (locationavailableId: number) => createSelector(
    selectLocationAvailablesState,
    locationavailablesState => locationavailablesState.entities[locationavailableId]
);

export const selectLocationAvailablesPageLoading = createSelector(
    selectLocationAvailablesState,
    locationavailablesState => locationavailablesState.listLoading
);

export const selectLocationAvailablesActionLoading = createSelector(
    selectLocationAvailablesState,
    locationavailablesState => locationavailablesState.actionsloading
);

export const selectLastCreatedLocationAvailableId = createSelector(
    selectLocationAvailablesState,
    locationavailablesState => locationavailablesState.lastCreatedLocationAvailableId
);

export const selectLocationAvailablesShowInitWaitingMessage = createSelector(
    selectLocationAvailablesState,
    locationavailablesState => locationavailablesState.showInitWaitingMessage
);

export const selectLocationAvailablesInStore = createSelector(
    selectLocationAvailablesState,
    locationavailablesState => {
        const items: LocationAvailableModel[] = [];
        each(locationavailablesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: LocationAvailableModel[] = httpExtension.sortArray(items, locationavailablesState.lastQuery.sortField, locationavailablesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, locationavailablesState.totalCount, '');
    }
);
