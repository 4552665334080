// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AllUserCoinsState } from '../_reducers/allusercoin.reducers';
import { AllUserCoinModel } from '../_models/allusercoin.model';

export const selectAllUserCoinsState = createFeatureSelector<AllUserCoinsState>('allusercoins');

export const selectAllUserCoinById = (allusercoinId: number) => createSelector(
    selectAllUserCoinsState,
    allusercoinsState => allusercoinsState.entities[allusercoinId]
);

export const selectAllUserCoinsPageLoading = createSelector(
    selectAllUserCoinsState,
    allusercoinsState => allusercoinsState.listLoading
);

export const selectAllUserCoinsActionLoading = createSelector(
    selectAllUserCoinsState,
    allusercoinsState => allusercoinsState.actionsloading
);

export const selectLastCreatedAllUserCoinId = createSelector(
    selectAllUserCoinsState,
    allusercoinsState => allusercoinsState.lastCreatedAllUserCoinId
);

export const selectAllUserCoinsShowInitWaitingMessage = createSelector(
    selectAllUserCoinsState,
    allusercoinsState => allusercoinsState.showInitWaitingMessage
);

export const selectAllUserCoinsInStore = createSelector(
    selectAllUserCoinsState,
    allusercoinsState => {
        const items: AllUserCoinModel[] = [];
        each(allusercoinsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AllUserCoinModel[] = httpExtension.sortArray(items, allusercoinsState.lastQuery.sortField, allusercoinsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, allusercoinsState.totalCount, '');
    }
);
