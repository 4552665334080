// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { SuborganizationAvailablesState } from '../_reducers/suborganizationavailable.reducers';
import { SuborganizationAvailableModel } from '../_models/suborganizationavailable.model';

export const selectSuborganizationAvailablesState = createFeatureSelector<SuborganizationAvailablesState>('suborganizationavailables');

export const selectSuborganizationAvailableById = (suborganizationavailableId: number) => createSelector(
    selectSuborganizationAvailablesState,
    suborganizationavailablesState => suborganizationavailablesState.entities[suborganizationavailableId]
);

export const selectSuborganizationAvailablesPageLoading = createSelector(
    selectSuborganizationAvailablesState,
    suborganizationavailablesState => suborganizationavailablesState.listLoading
);

export const selectSuborganizationAvailablesActionLoading = createSelector(
    selectSuborganizationAvailablesState,
    suborganizationavailablesState => suborganizationavailablesState.actionsloading
);

export const selectLastCreatedSuborganizationAvailableId = createSelector(
    selectSuborganizationAvailablesState,
    suborganizationavailablesState => suborganizationavailablesState.lastCreatedSuborganizationAvailableId
);

export const selectSuborganizationAvailablesShowInitWaitingMessage = createSelector(
    selectSuborganizationAvailablesState,
    suborganizationavailablesState => suborganizationavailablesState.showInitWaitingMessage
);

export const selectSuborganizationAvailablesInStore = createSelector(
    selectSuborganizationAvailablesState,
    suborganizationavailablesState => {
        const items: SuborganizationAvailableModel[] = [];
        each(suborganizationavailablesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SuborganizationAvailableModel[] = httpExtension.sortArray(items, suborganizationavailablesState.lastQuery.sortField, suborganizationavailablesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, suborganizationavailablesState.totalCount, '');
    }
);
