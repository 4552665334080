// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { ActiveUserTransactionModel } from '../_models/activeusertransaction.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class ActiveUserTransactionService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new activeuser to the server
  createActiveUserTransaction(activeusertransaction: ActiveUserTransactionModel): Observable<ActiveUserTransactionModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<ActiveUserTransactionModel>(BASE_URL, activeusertransaction, { headers: httpHeaders});
  }

  // READ
  getAllActiveUsersTransactions(): Observable<ActiveUserTransactionModel[]> {
    return this.http.get<ActiveUserTransactionModel[]>(BASE_URL);
  }

  getActiveUserTransactionById(activeusertransactionId: number): Observable<ActiveUserTransactionModel> {
    return this.http.get<ActiveUserTransactionModel>(BASE_URL + `/${activeusertransactionId}`);
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  downloadUsers(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/user';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findActiveUsersTransactions(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/user';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the activeuser on the server
  updateActiveUserTransaction(activeusertransaction: ActiveUserTransactionModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, activeusertransaction, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForActiveUserTransaction(activeuserstransactions: ActiveUserTransactionModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      activeuserstransactionsForUpdate: activeuserstransactions,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the activeuser from the server
  deleteActiveUserTransaction(activeusertransactionId: number): Observable<ActiveUserTransactionModel> {
    const url = `${BASE_URL}/${activeusertransactionId}`;
    return this.http.delete<ActiveUserTransactionModel>(url);
  }

  deleteActiveUsersTransactions(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteActiveUsers';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { activeusertransactionIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
