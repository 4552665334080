// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GoldZakatModel } from '../_models/goldzakat.model';

export enum GoldZakatActionTypes {
    GoldZakatOnServerCreated = '[Edit GoldZakat Dialog] GoldZakat On Server Created',
    GoldZakatCreated = '[Edit GoldZakat Dialog] GoldZakat Created',
    GoldZakatUpdated = '[Edit GoldZakat Dialog] GoldZakat Updated',
    GoldZakatsStatusUpdated = '[GoldZakat List Page] GoldZakats Status Updated',
    OneGoldZakatDeleted = '[GoldZakats List Page] One GoldZakat Deleted',
    ManyGoldZakatsDeleted = '[GoldZakats List Page] Many GoldZakat Deleted',
    GoldZakatsPageRequested = '[GoldZakats List Page] GoldZakats Page Requested',
    GoldZakatsPageLoaded = '[GoldZakats API] GoldZakats Page Loaded',
    GoldZakatsPageCancelled = '[GoldZakats API] GoldZakats Page Cancelled',
    GoldZakatsPageToggleLoading = '[GoldZakats] GoldZakats Page Toggle Loading',
    GoldZakatActionToggleLoading = '[GoldZakats] GoldZakats Action Toggle Loading'
}

export class GoldZakatOnServerCreated implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatOnServerCreated;
    constructor(public payload: { goldzakat: GoldZakatModel }) { }
}

export class GoldZakatCreated implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatCreated;
    constructor(public payload: { goldzakat: GoldZakatModel }) { }
}

export class GoldZakatUpdated implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatUpdated;
    constructor(public payload: {
        partialGoldZakat: Update<GoldZakatModel>, // For State update
        goldzakat: GoldZakatModel // For Server update (through service)
    }) { }
}

export class GoldZakatsStatusUpdated implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatsStatusUpdated;
    constructor(public payload: {
        goldzakats: GoldZakatModel[],
        status: number
    }) { }
}

export class OneGoldZakatDeleted implements Action {
    readonly type = GoldZakatActionTypes.OneGoldZakatDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyGoldZakatsDeleted implements Action {
    readonly type = GoldZakatActionTypes.ManyGoldZakatsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class GoldZakatsPageRequested implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class GoldZakatsPageLoaded implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatsPageLoaded;
    constructor(public payload: { goldzakats: GoldZakatModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class GoldZakatsPageCancelled implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatsPageCancelled;
}

export class GoldZakatsPageToggleLoading implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class GoldZakatActionToggleLoading implements Action {
    readonly type = GoldZakatActionTypes.GoldZakatActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type GoldZakatActions = GoldZakatOnServerCreated
| GoldZakatCreated
| GoldZakatUpdated
| GoldZakatsStatusUpdated
| OneGoldZakatDeleted
| ManyGoldZakatsDeleted
| GoldZakatsPageRequested
| GoldZakatsPageLoaded
| GoldZakatsPageCancelled
| GoldZakatsPageToggleLoading
| GoldZakatActionToggleLoading;
