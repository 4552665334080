// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { HomeModule } from './home/home.module';
import { KartanuModule } from './kartanu/kartanu.module';
import { TncModule } from './tnc/tnc.module';
import { ReportModule } from './report/report.module';
import { AccessModule } from './access/access.module';
import { BigOrderModule } from './big-order/big-order.module';
import { TransactionModule } from './transaction/transaction.module';
import { UserModule } from './user/user.module';
import { UserManagementModule } from './user-management/user-management.module';

@NgModule({
	declarations: [],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
    HomeModule,
    KartanuModule,
    TncModule,
    ReportModule,
	AccessModule,
	BigOrderModule,
    TransactionModule,
    UserModule,
    UserManagementModule,
	],
	providers: []
})
export class PagesModule {
}
