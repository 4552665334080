// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AllUserActions, AllUserActionTypes } from '../_actions/alluser.actions';
// Models
import { AllUserModel } from '../_models/alluser.model';
import { QueryParamsModel } from '../../_base/crud';

export interface AllUsersState extends EntityState<AllUserModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedAllUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AllUserModel> = createEntityAdapter<AllUserModel>();

export const initialAllUsersState: AllUsersState = adapter.getInitialState({
    alluserForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedAllUserId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function allusersReducer(state = initialAllUsersState, action: AllUserActions): AllUsersState {
    switch  (action.type) {
        case AllUserActionTypes.AllUsersPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAllUserId: undefined
            };
        }
        case AllUserActionTypes.AllUserActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case AllUserActionTypes.AllUserOnServerCreated: return {
            ...state
        };
        // case AllUserActionTypes.AllUserCreated: return adapter.addOne(action.payload.alluser, {
        //     ...state, lastCreatedAllUserId: action.payload.alluser.id
        // });
        case AllUserActionTypes.AllUserUpdated: return adapter.updateOne(action.payload.partialAllUser, state);
       //  case AllUserActionTypes.AllUsersStatusUpdated: {
       //      const _partialAllUsers: Update<AllUserModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.allusers.length; i++) {
       //          _partialAllUsers.push({
           //  id: action.payload.allusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialAllUsers, state);
       //  }
        case AllUserActionTypes.OneAllUserDeleted: return adapter.removeOne(action.payload.id, state);
        case AllUserActionTypes.ManyAllUsersDeleted: return adapter.removeMany(action.payload.ids, state);
        case AllUserActionTypes.AllUsersPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AllUserActionTypes.AllUsersPageLoaded: {
            return adapter.addMany(action.payload.allusers, {
                ...initialAllUsersState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getAllUserState = createFeatureSelector<AllUserModel>('allusers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
