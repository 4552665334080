// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { SuborganizationModel } from '../_models/suborganization.model';

export enum SuborganizationActionTypes {
    SuborganizationOnServerCreated = '[Edit Suborganization Dialog] Suborganization On Server Created',
    SuborganizationCreated = '[Edit Suborganization Dialog] Suborganization Created',
    SuborganizationUpdated = '[Edit Suborganization Dialog] Suborganization Updated',
    SuborganizationsStatusUpdated = '[Suborganization List Page] Suborganizations Status Updated',
    OneSuborganizationDeleted = '[Suborganizations List Page] One Suborganization Deleted',
    ManySuborganizationsDeleted = '[Suborganizations List Page] Many Suborganization Deleted',
    SuborganizationsPageRequested = '[Suborganizations List Page] Suborganizations Page Requested',
    SuborganizationsPageLoaded = '[Suborganizations API] Suborganizations Page Loaded',
    SuborganizationsPageCancelled = '[Suborganizations API] Suborganizations Page Cancelled',
    SuborganizationsPageToggleLoading = '[Suborganizations] Suborganizations Page Toggle Loading',
    SuborganizationActionToggleLoading = '[Suborganizations] Suborganizations Action Toggle Loading'
}

export class SuborganizationOnServerCreated implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationOnServerCreated;
    constructor(public payload: { suborganization: SuborganizationModel }) { }
}

export class SuborganizationCreated implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationCreated;
    constructor(public payload: { suborganization: SuborganizationModel }) { }
}

export class SuborganizationUpdated implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationUpdated;
    constructor(public payload: {
        partialSuborganization: Update<SuborganizationModel>, // For State update
        suborganization: SuborganizationModel // For Server update (through service)
    }) { }
}

export class SuborganizationsStatusUpdated implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationsStatusUpdated;
    constructor(public payload: {
        suborganizations: SuborganizationModel[],
        status: number
    }) { }
}

export class OneSuborganizationDeleted implements Action {
    readonly type = SuborganizationActionTypes.OneSuborganizationDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManySuborganizationsDeleted implements Action {
    readonly type = SuborganizationActionTypes.ManySuborganizationsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class SuborganizationsPageRequested implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class SuborganizationsPageLoaded implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationsPageLoaded;
    constructor(public payload: { suborganizations: SuborganizationModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class SuborganizationsPageCancelled implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationsPageCancelled;
}

export class SuborganizationsPageToggleLoading implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class SuborganizationActionToggleLoading implements Action {
    readonly type = SuborganizationActionTypes.SuborganizationActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type SuborganizationActions = SuborganizationOnServerCreated
| SuborganizationCreated
| SuborganizationUpdated
| SuborganizationsStatusUpdated
| OneSuborganizationDeleted
| ManySuborganizationsDeleted
| SuborganizationsPageRequested
| SuborganizationsPageLoaded
| SuborganizationsPageCancelled
| SuborganizationsPageToggleLoading
| SuborganizationActionToggleLoading;
