<kt-portlet>
  <kt-portlet-body>
    <div class="kt-form kt-margin-b-30">
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search nonactiveuser" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> by name or email
							</mat-hint>
						</mat-form-field>
					</div>
					<div *ngIf="role" class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field>
						  <mat-select placeholder="Organization" [(ngModel)]="selectedOrg" (selectionChange)="onSelectChange($event)">
						    <mat-option>None</mat-option>
						    <mat-option *ngFor="let org of organizationList" [value]="org.id">{{org.name}}</mat-option>
						  </mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput
								placeholder="Choose a date"
								[satDatepicker]="picker"
								[(value)]="dateRange"
								(dateChange)="onDateChange($event)">
							<sat-datepicker
								#picker
								[rangeMode]="true"
								orderPeriodLabel="month"
								[closeAfterSelection]="true">>
							</sat-datepicker>
							<sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
						</mat-form-field>
					</div>
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-spinner-button (btnClick)="download()" [options]="spinnerButtonOptions" class="mat-button mat-button-base mat-flat-button"></mat-spinner-button>
					</div>
				</div>
			</div>
		</div>
    <div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="desc"
				matSortDisableClear>
        <ng-container matColumnDef="user.name">
					<mat-header-cell *matHeaderCellDef>Nama</mat-header-cell>
					<mat-cell *matCellDef="let activeuser">
            <a href="javascript:" (click)="editNonActiveUser(activeuser?.user?.id)">{{activeuser?.user?.name}}</a>
          </mat-cell>
				</ng-container>
  			<ng-container matColumnDef="user.email">
  				<mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
  				<mat-cell *matCellDef="let activeuser">{{activeuser?.user?.email}}</mat-cell>
  			</ng-container>
        <ng-container matColumnDef="suborganization.referral_code">
          <mat-header-cell *matHeaderCellDef>Kode Referral</mat-header-cell>
          <mat-cell *matCellDef="let activeuser">
            <a href="javascript:" (click)="editNonActiveUser(activeuser?.suborganization?.id)">{{activeuser?.suborganization?.referral_code}}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="user.status">
          <mat-header-cell *matHeaderCellDef>Status Kartanu</mat-header-cell>
          <mat-cell *matCellDef="let activeuser">{{activeuser?.user?.status}}</mat-cell>
        </ng-container>
				<ng-container matColumnDef="suborganization.location">
					<mat-header-cell *matHeaderCellDef>Lokasi</mat-header-cell>
					<mat-cell *matCellDef="let activeuser">{{activeuser?.suborganization?.location}}</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>
		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
	</kt-portlet-body>
</kt-portlet>