// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { SuborganizationAllActions, SuborganizationAllActionTypes } from '../_actions/suborganizationall.actions';
// Models
import { SuborganizationAllModel } from '../_models/suborganizationall.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface SuborganizationAllsState extends EntityState<SuborganizationAllModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSuborganizationAllId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SuborganizationAllModel> = createEntityAdapter<SuborganizationAllModel>();

export const initialSuborganizationAllsState: SuborganizationAllsState = adapter.getInitialState({
    suborganizationallForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSuborganizationAllId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function suborganizationallsReducer(state = initialSuborganizationAllsState, action: SuborganizationAllActions): SuborganizationAllsState {
    switch  (action.type) {
        case SuborganizationAllActionTypes.SuborganizationAllsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSuborganizationAllId: undefined
            };
        }
        case SuborganizationAllActionTypes.SuborganizationAllActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case SuborganizationAllActionTypes.SuborganizationAllOnServerCreated: return {
            ...state
        };
        case SuborganizationAllActionTypes.SuborganizationAllCreated: return adapter.addOne(action.payload.suborganizationall, {
            ...state, lastCreatedSuborganizationAllId: action.payload.suborganizationall.id
        });
        case SuborganizationAllActionTypes.SuborganizationAllUpdated: return adapter.updateOne(action.payload.partialSuborganizationAll, state);
       //  case SuborganizationAllActionTypes.SuborganizationAllsStatusUpdated: {
       //      const _partialSuborganizationAlls: Update<SuborganizationAllModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.suborganizationalls.length; i++) {
       //          _partialSuborganizationAlls.push({
           //  id: action.payload.suborganizationalls[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialSuborganizationAlls, state);
       //  }
        case SuborganizationAllActionTypes.OneSuborganizationAllDeleted: return adapter.removeOne(action.payload.id, state);
        case SuborganizationAllActionTypes.ManySuborganizationAllsDeleted: return adapter.removeMany(action.payload.ids, state);
        case SuborganizationAllActionTypes.SuborganizationAllsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case SuborganizationAllActionTypes.SuborganizationAllsPageLoaded: {
            return adapter.addMany(action.payload.suborganizationalls, {
                ...initialSuborganizationAllsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getSuborganizationAllState = createFeatureSelector<SuborganizationAllModel>('suborganizationalls');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
