import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { NonActiveUserService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    NonActiveUserActionTypes,
    NonActiveUsersPageRequested,
    NonActiveUsersPageLoaded,
    ManyNonActiveUsersDeleted,
    OneNonActiveUserDeleted,
    NonActiveUserActionToggleLoading,
    NonActiveUsersPageToggleLoading,
    NonActiveUserUpdated,
    NonActiveUsersStatusUpdated,
    NonActiveUserCreated,
    NonActiveUserOnServerCreated
} from '../_actions/nonactiveuser.actions';
import { of } from 'rxjs';

@Injectable()
export class NonActiveUserEffects {
    showPageLoadingDistpatcher = new NonActiveUsersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new NonActiveUserActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new NonActiveUserActionToggleLoading({ isLoading: false });

    @Effect()
    loadNonActiveUsersPage$ = this.actions$.pipe(
        ofType<NonActiveUsersPageRequested>(NonActiveUserActionTypes.NonActiveUsersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.nonactiveusersService.findNonActiveUsers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new NonActiveUsersPageLoaded({
                nonactiveusers: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteNonActiveUser$ = this.actions$
        .pipe(
            ofType<OneNonActiveUserDeleted>(NonActiveUserActionTypes.OneNonActiveUserDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.nonactiveusersService.deleteNonActiveUser(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteNonActiveUsers$ = this.actions$
        .pipe(
            ofType<ManyNonActiveUsersDeleted>(NonActiveUserActionTypes.ManyNonActiveUsersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.nonactiveusersService.deleteNonActiveUsers(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateNonActiveUser$ = this.actions$
        .pipe(
            ofType<NonActiveUserUpdated>(NonActiveUserActionTypes.NonActiveUserUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.nonactiveusersService.updateNonActiveUser(payload.nonactiveuser);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateNonActiveUsersStatus$ = this.actions$
        .pipe(
            ofType<NonActiveUsersStatusUpdated>(NonActiveUserActionTypes.NonActiveUsersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.nonactiveusersService.updateStatusForNonActiveUser(payload.nonactiveusers, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createNonActiveUser$ = this.actions$
        .pipe(
            ofType<NonActiveUserOnServerCreated>(NonActiveUserActionTypes.NonActiveUserOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.nonactiveusersService.createNonActiveUser(payload.nonactiveuser).pipe(
                    tap(res => {
                        this.store.dispatch(new NonActiveUserCreated({ nonactiveuser: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private nonactiveusersService: NonActiveUserService, private store: Store<AppState>) { }
}
