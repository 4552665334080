// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ActiveUserTransactionModel } from '../_models/activeusertransaction.model';

export enum ActiveUserTransactionActionTypes {
    ActiveUserTransactionOnServerCreated = '[Edit ActiveUserTransaction Dialog] ActiveUserTransaction On Server Created',
    ActiveUserTransactionCreated = '[Edit ActiveUserTransaction Dialog] ActiveUserTransaction Created',
    ActiveUserTransactionUpdated = '[Edit ActiveUserTransaction Dialog] ActiveUserTransaction Updated',
    ActiveUsersTransactionsStatusUpdated = '[ActiveUserTransaction List Page] ActiveUsersTransactions Status Updated',
    OneActiveUserTransactionDeleted = '[ActiveUsersTransactions List Page] One ActiveUserTransaction Deleted',
    ManyActiveUsersTransactionsDeleted = '[ActiveUsersTransactions List Page] Many ActiveUserTransaction Deleted',
    ActiveUsersTransactionsPageRequested = '[ActiveUsersTransactions List Page] ActiveUsersTransactions Page Requested',
    ActiveUsersTransactionsPageLoaded = '[ActiveUsersTransactions API] ActiveUsersTransactions Page Loaded',
    ActiveUsersTransactionsPageCancelled = '[ActiveUsersTransactions API] ActiveUsersTransactions Page Cancelled',
    ActiveUsersTransactionsPageToggleLoading = '[ActiveUsersTransactions] ActiveUsersTransactions Page Toggle Loading',
    ActiveUserTransactionActionToggleLoading = '[ActiveUsersTransactions] ActiveUsersTransactions Action Toggle Loading'
}

export class ActiveUserTransactionOnServerCreated implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUserTransactionOnServerCreated;
    constructor(public payload: { activeusertransaction: ActiveUserTransactionModel }) { }
}

export class ActiveUserTransactionCreated implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUserTransactionCreated;
    constructor(public payload: { activeusertransaction: ActiveUserTransactionModel }) { }
}

export class ActiveUserTransactionUpdated implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUserTransactionUpdated;
    constructor(public payload: {
        partialActiveUserTransaction: Update<ActiveUserTransactionModel>, // For State update
        activeusertransaction: ActiveUserTransactionModel // For Server update (through service)
    }) { }
}

export class ActiveUsersTransactionsStatusUpdated implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUsersTransactionsStatusUpdated;
    constructor(public payload: {
        activeuserstransaction: ActiveUserTransactionModel[],
        status: number
    }) { }
}

export class OneActiveUserTransactionDeleted implements Action {
    readonly type = ActiveUserTransactionActionTypes.OneActiveUserTransactionDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyActiveUsersTransactionsDeleted implements Action {
    readonly type = ActiveUserTransactionActionTypes.ManyActiveUsersTransactionsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ActiveUsersTransactionsPageRequested implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ActiveUsersTransactionsPageLoaded implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageLoaded;
    constructor(public payload: { activeuserstransactions: ActiveUserTransactionModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class ActiveUsersTransactionsPageCancelled implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageCancelled;
}

export class ActiveUsersTransactionsPageToggleLoading implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ActiveUserTransactionActionToggleLoading implements Action {
    readonly type = ActiveUserTransactionActionTypes.ActiveUserTransactionActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ActiveUserTransactionActions = ActiveUserTransactionOnServerCreated
| ActiveUserTransactionCreated
| ActiveUserTransactionUpdated
| ActiveUsersTransactionsStatusUpdated
| OneActiveUserTransactionDeleted
| ManyActiveUsersTransactionsDeleted
| ActiveUsersTransactionsPageRequested
| ActiveUsersTransactionsPageLoaded
| ActiveUsersTransactionsPageCancelled
| ActiveUsersTransactionsPageToggleLoading
| ActiveUserTransactionActionToggleLoading;
