// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { LocationAllActions, LocationAllActionTypes } from '../_actions/locationall.actions';
// Models
import { LocationAllModel } from '../_models/locationall.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface LocationAllsState extends EntityState<LocationAllModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedLocationAllId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<LocationAllModel> = createEntityAdapter<LocationAllModel>();

export const initialLocationAllsState: LocationAllsState = adapter.getInitialState({
    locationallForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedLocationAllId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function locationallsReducer(state = initialLocationAllsState, action: LocationAllActions): LocationAllsState {
    switch  (action.type) {
        case LocationAllActionTypes.LocationAllsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedLocationAllId: undefined
            };
        }
        case LocationAllActionTypes.LocationAllActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case LocationAllActionTypes.LocationAllOnServerCreated: return {
            ...state
        };
        // case LocationAllActionTypes.LocationAllCreated: return adapter.addOne(action.payload.locationall, {
        //     ...state, lastCreatedLocationAllId: action.payload.locationall.id
        // });
        case LocationAllActionTypes.LocationAllUpdated: return adapter.updateOne(action.payload.partialLocationAll, state);
       //  case LocationAllActionTypes.LocationAllsStatusUpdated: {
       //      const _partialLocationAlls: Update<LocationAllModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.locationalls.length; i++) {
       //          _partialLocationAlls.push({
				   //  id: action.payload.locationalls[i].id,
				   //  changes: {
       //                  status: action.payload.status
       //              }
			    // });
       //      }
       //      return adapter.updateMany(_partialLocationAlls, state);
       //  }
        case LocationAllActionTypes.OneLocationAllDeleted: return adapter.removeOne(action.payload.id, state);
        case LocationAllActionTypes.ManyLocationAllsDeleted: return adapter.removeMany(action.payload.ids, state);
        case LocationAllActionTypes.LocationAllsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case LocationAllActionTypes.LocationAllsPageLoaded: {
            return adapter.addMany(action.payload.locationalls, {
                ...initialLocationAllsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getLocationAllState = createFeatureSelector<LocationAllModel>('locationalls');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
