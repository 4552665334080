// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GoldGiftCardsState } from '../_reducers/goldgiftcard.reducers';
import { GoldGiftCardModel } from '../_models/goldgiftcard.model';

export const selectGoldGiftCardsState = createFeatureSelector<GoldGiftCardsState>('goldgiftcards');

export const selectGoldGiftCardById = (goldgiftcardId: number) => createSelector(
    selectGoldGiftCardsState,
    goldgiftcardsState => goldgiftcardsState.entities[goldgiftcardId]
);

export const selectGoldGiftCardsPageLoading = createSelector(
    selectGoldGiftCardsState,
    goldgiftcardsState => goldgiftcardsState.listLoading
);

export const selectGoldGiftCardsActionLoading = createSelector(
    selectGoldGiftCardsState,
    goldgiftcardsState => goldgiftcardsState.actionsloading
);

export const selectLastCreatedGoldGiftCardId = createSelector(
    selectGoldGiftCardsState,
    goldgiftcardsState => goldgiftcardsState.lastCreatedGoldGiftCardId
);

export const selectGoldGiftCardsShowInitWaitingMessage = createSelector(
    selectGoldGiftCardsState,
    goldgiftcardsState => goldgiftcardsState.showInitWaitingMessage
);

export const selectGoldGiftCardsInStore = createSelector(
    selectGoldGiftCardsState,
    goldgiftcardsState => {
        const items: GoldGiftCardModel[] = [];
        each(goldgiftcardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GoldGiftCardModel[] = httpExtension.sortArray(items, goldgiftcardsState.lastQuery.sortField, goldgiftcardsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, goldgiftcardsState.totalCount, '');
    }
);
