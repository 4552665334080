// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GoldDonationsState } from '../_reducers/golddonation.reducers';
import { GoldDonationModel } from '../_models/golddonation.model';

export const selectGoldDonationsState = createFeatureSelector<GoldDonationsState>('golddonations');

export const selectGoldDonationById = (golddonationId: number) => createSelector(
    selectGoldDonationsState,
    golddonationsState => golddonationsState.entities[golddonationId]
);

export const selectGoldDonationsPageLoading = createSelector(
    selectGoldDonationsState,
    golddonationsState => golddonationsState.listLoading
);

export const selectGoldDonationsActionLoading = createSelector(
    selectGoldDonationsState,
    golddonationsState => golddonationsState.actionsloading
);

export const selectLastCreatedGoldDonationId = createSelector(
    selectGoldDonationsState,
    golddonationsState => golddonationsState.lastCreatedGoldDonationId
);

export const selectGoldDonationsShowInitWaitingMessage = createSelector(
    selectGoldDonationsState,
    golddonationsState => golddonationsState.showInitWaitingMessage
);

export const selectGoldDonationsInStore = createSelector(
    selectGoldDonationsState,
    golddonationsState => {
        const items: GoldDonationModel[] = [];
        each(golddonationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GoldDonationModel[] = httpExtension.sortArray(items, golddonationsState.lastQuery.sortField, golddonationsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, golddonationsState.totalCount, '');
    }
);
