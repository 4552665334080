// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { LocationAvailableActions, LocationAvailableActionTypes } from '../_actions/locationavailable.actions';
// Models
import { LocationAvailableModel } from '../_models/locationavailable.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface LocationAvailablesState extends EntityState<LocationAvailableModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedLocationAvailableId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<LocationAvailableModel> = createEntityAdapter<LocationAvailableModel>();

export const initialLocationAvailablesState: LocationAvailablesState = adapter.getInitialState({
    locationavailableForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedLocationAvailableId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function locationavailablesReducer(state = initialLocationAvailablesState, action: LocationAvailableActions): LocationAvailablesState {
    switch  (action.type) {
        case LocationAvailableActionTypes.LocationAvailablesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedLocationAvailableId: undefined
            };
        }
        case LocationAvailableActionTypes.LocationAvailableActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case LocationAvailableActionTypes.LocationAvailableOnServerCreated: return {
            ...state
        };
        // case LocationAvailableActionTypes.LocationAvailableCreated: return adapter.addOne(action.payload.locationavailable, {
        //     ...state, lastCreatedLocationAvailableId: action.payload.locationavailable.id
        // });
        case LocationAvailableActionTypes.LocationAvailableUpdated: return adapter.updateOne(action.payload.partialLocationAvailable, state);
       //  case LocationAvailableActionTypes.LocationAvailablesStatusUpdated: {
       //      const _partialLocationAvailables: Update<LocationAvailableModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.locationavailables.length; i++) {
       //          _partialLocationAvailables.push({
				   //  id: action.payload.locationavailables[i].id,
				   //  changes: {
       //                  status: action.payload.status
       //              }
			    // });
       //      }
       //      return adapter.updateMany(_partialLocationAvailables, state);
       //  }
        case LocationAvailableActionTypes.OneLocationAvailableDeleted: return adapter.removeOne(action.payload.id, state);
        case LocationAvailableActionTypes.ManyLocationAvailablesDeleted: return adapter.removeMany(action.payload.ids, state);
        case LocationAvailableActionTypes.LocationAvailablesPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case LocationAvailableActionTypes.LocationAvailablesPageLoaded: {
            return adapter.addMany(action.payload.locationavailables, {
                ...initialLocationAvailablesState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getLocationAvailableState = createFeatureSelector<LocationAvailableModel>('locationavailables');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
