import { BaseModel } from '../../_base/crud';

export class UserModel  extends BaseModel {
	id: string;
  user: any;
  suborganization: any;
  transaction: any;
  created_at: string;
  
	clear() {
		this.id = '';
    this.user = undefined;
    this.suborganization = undefined;
    this.transaction = undefined;
    this.created_at = '';
	}
}
