import { BaseModel } from '../../_base/crud';

export class HomeModel  extends BaseModel {
  gram_buy: string;
  amount_buy: string;
  commission_buy: string;
  gram_sell: string;
  amount_sell: string;
  commission_sell: string;

  clear() {
    this.gram_buy = '';
    this.amount_buy = '';
    this.commission_buy = '';
    this.gram_sell = '';
    this.amount_sell = '';
    this.commission_sell = '';
  }
}
