import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { AdminService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    AdminActionTypes,
    AdminsPageRequested,
    AdminsPageLoaded,
    ManyAdminsDeleted,
    OneAdminDeleted,
    AdminActionToggleLoading,
    AdminsPageToggleLoading,
    AdminUpdated,
    AdminsStatusUpdated,
    AdminCreated,
    AdminOnServerCreated
} from '../_actions/admin.actions';
import { of } from 'rxjs';

@Injectable()
export class AdminEffects {
    showPageLoadingDistpatcher = new AdminsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new AdminActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AdminActionToggleLoading({ isLoading: false });

    @Effect()
    loadAdminsPage$ = this.actions$.pipe(
        ofType<AdminsPageRequested>(AdminActionTypes.AdminsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.adminsService.findAdmins(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new AdminsPageLoaded({
                admins: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteAdmin$ = this.actions$
        .pipe(
            ofType<OneAdminDeleted>(AdminActionTypes.OneAdminDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.adminsService.deleteAdmin(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAdmins$ = this.actions$
        .pipe(
            ofType<ManyAdminsDeleted>(AdminActionTypes.ManyAdminsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.adminsService.deleteAdmins(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAdmin$ = this.actions$
        .pipe(
            ofType<AdminUpdated>(AdminActionTypes.AdminUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.adminsService.updateAdmin(payload.admin);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateAdminsStatus$ = this.actions$
        .pipe(
            ofType<AdminsStatusUpdated>(AdminActionTypes.AdminsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.adminsService.updateStatusForAdmin(payload.admins, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createAdmin$ = this.actions$
        .pipe(
            ofType<AdminOnServerCreated>(AdminActionTypes.AdminOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.adminsService.createAdmin(payload.admin).pipe(
                    tap(res => {
                        this.store.dispatch(new AdminCreated({ admin: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private adminsService: AdminService, private store: Store<AppState>) { }
}
