// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { AuthService } from '../../../../../core/auth';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  BigOrderModel,
  BigOrderService,
  BigOrdersDataSource,
  BigOrdersPageRequested,
  OneBigOrderDeleted,
  ManyBigOrdersDeleted,
  BigOrdersStatusUpdated
} from '../../../../../core/transaction';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
import { BigOrderEditComponent } from '../big-order-edit/big-order-edit.dialog.component';
@Component({
	selector: 'kt-bigorders-list',
	templateUrl: './big-order-list.component.html',
	styleUrls: ['big-order-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BigOrderListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: BigOrdersDataSource;
	displayedColumns = ['created_at', 'user.name', 'amount', 'rupiah', 'insentif', 'discount', 'status'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};
  	organizationList: any = [];
  	selectedOrg: string = '';
  	role: boolean = false;
	stat: string = '';
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<BigOrderModel>(true, []);
	bigordersResult: BigOrderModel[] = [];

	spinnerButtonOptions: MatProgressButtonOptions = {
		active: false,
		text: 'Download',
		spinnerSize: 18,
		raised: true,
		stroked: false,
		buttonColor: 'accent',
		spinnerColor: 'accent',
		fullWidth: false,
		disabled: false,
		mode: 'indeterminate',
		buttonIcon: {
			fontIcon: 'cloud_download'
		}
	}

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
    public authService : AuthService,
		public bigordersService: BigOrderService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    private datePipe: DatePipe
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadBigOrdersList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadBigOrdersList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init Org List
		this.loadOrganizationList();
		this.loadUserRole();

		// Init DataSource
		this.dataSource = new BigOrdersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.bigordersResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1),
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadBigOrdersList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load BigOrders List from service through data-source
	 */
	loadBigOrdersList() {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const searchText: string = this.searchInput.nativeElement.value;
		const queryParams = new QueryParamsModel(
	        searchText,
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'[0,1,2,3,4]', // status
			'', // type
			'', // suborganization_id
			this.selectedOrg, // organization_id
			this.stat
		);
		// Call request from server
		this.store.dispatch(new BigOrdersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Load Organization List
	 */
	loadOrganizationList() {
		this.bigordersService.getAllOrganization()
		.subscribe(
			data => {
				this.organizationList = data;
			}
		)
	}

	/**
	 * Load Role
	 */
	loadUserRole() {
		this.authService.getUserByToken()
		.subscribe(
			data => {
				this.role = data.data.roles.includes(17);
			}
		)
	}

	editBigOrder(hashed_id) {
    if (hashed_id == null) {
      const dialogRef = this.dialog.open(BigOrderEditComponent, { data: null });
    }else{
  		this.bigordersService.getDetailUser(hashed_id)
  		.subscribe(
  			data => {
  				const dialogRef = this.dialog.open(BigOrderEditComponent, { data: { data } });
  			}
  		)
    }
	}

  onSelectChange(event: any) {
    this.paginator.pageIndex = 0;
    this.loadBigOrdersList();
  }

  onSelectStatChange(event: any) {
	this.paginator.pageIndex = 0;
	this.stat = event.value
    this.loadBigOrdersList();
  }

  onDateChange(event: any) {
    this.paginator.pageIndex = 0;
  	if(event.target.value){
    	this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
  	}else{
    	this.dateRange = {begin: null, end: null};
  	}
    this.loadBigOrdersList();
  }

  download(){
    this.spinnerButtonOptions.active = true;
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
		  this.sort.direction,
		  this.sort.active,
		  this.paginator.pageIndex,
		  this.paginator.pageSize,
		  '[0,1,2,3,4]', // stasus
		  '', // type
		  '', // suborganization_id
		  this.selectedOrg // organization_id
    );

    this.bigordersService.downloadUsers(queryParams)
    .subscribe(
      data => {
        this.spinnerButtonOptions.active = false;
        window.location.href = data;
        }
      )
  }
}
