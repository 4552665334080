// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AdminsState } from '../_reducers/admin.reducers';
import { AdminModel } from '../_models/admin.model';

export const selectAdminsState = createFeatureSelector<AdminsState>('admins');

export const selectAdminById = (adminId: number) => createSelector(
    selectAdminsState,
    adminsState => adminsState.entities[adminId]
);

export const selectAdminsPageLoading = createSelector(
    selectAdminsState,
    adminsState => adminsState.listLoading
);

export const selectAdminsActionLoading = createSelector(
    selectAdminsState,
    adminsState => adminsState.actionsloading
);

export const selectLastCreatedAdminId = createSelector(
    selectAdminsState,
    adminsState => adminsState.lastCreatedAdminId
);

export const selectAdminsShowInitWaitingMessage = createSelector(
    selectAdminsState,
    adminsState => adminsState.showInitWaitingMessage
);

export const selectAdminsInStore = createSelector(
    selectAdminsState,
    adminsState => {
        const items: AdminModel[] = [];
        each(adminsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AdminModel[] = httpExtension.sortArray(items, adminsState.lastQuery.sortField, adminsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, adminsState.totalCount, '');
    }
);
