// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { LocationAllModel } from '../_models/locationall.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class LocationAllService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new locationall to the server
	createLocationAll(locationall: LocationAllModel): Observable<LocationAllModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<LocationAllModel>(BASE_URL, locationall, { headers: httpHeaders});
	}

	// READ
	getAllLocationAlls(): Observable<LocationAllModel[]> {
		return this.http.get<LocationAllModel[]>(BASE_URL);
	}

	getLocationAllById(locationallId: number): Observable<LocationAllModel> {
		return this.http.get<LocationAllModel>(BASE_URL + `/${locationallId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findLocationAlls(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'b2b/inventory';
		
    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	// UPDATE => PUT: update the locationall on the server
	updateLocationAll(locationall: LocationAllModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(BASE_URL, locationall, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForLocationAll(locationalls: LocationAllModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			locationallsForUpdate: locationalls,
			newStatus: status
		};
		const url = BASE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the locationall from the server
	deleteLocationAll(locationallId: number): Observable<LocationAllModel> {
		const url = `${BASE_URL}/${locationallId}`;
		return this.http.delete<LocationAllModel>(url);
	}

	deleteLocationAlls(ids: number[] = []): Observable<any> {
		const url = BASE_URL + '/deleteLocationAlls';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { locationallIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
