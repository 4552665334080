export class QueryParamsDetailShipmentModel {
	// fields
	filter: any;
  beginDate: any;
  endDate: any;
	sortOrder: string;
	sortField: string;
	pageNumber: number;
	pageSize: number;
	status: string;
	type: string;
	shipment_id: string;

	// constructor overrides
	constructor(
	  _filter: any = null,
		_beginDate: any = new Date(),
	  _endDate: any = new Date(),
	  _sortOrder: string = 'desc',
		_sortField: string = '',
		_pageNumber: number = 1,
		_pageSize: number = 10,
		_status: string = '',
		_type: string = '',
		_shipment_id: string = '') {
  		this.filter = _filter;
  	  this.beginDate = _beginDate;
  	  this.endDate = _endDate;
  		this.sortOrder = _sortOrder;
  		this.sortField = _sortField;
  		this.pageNumber = _pageNumber;
  		this.pageSize = _pageSize;
  		this.status = _status;
  		this.type = _type;
  		this.shipment_id = _shipment_id;
	}
}
