import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { ActivityService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    ActivityActionTypes,
    ActivitysPageRequested,
    ActivitysPageLoaded,
    ManyActivitysDeleted,
    OneActivityDeleted,
    ActivityActionToggleLoading,
    ActivitysPageToggleLoading,
    ActivityUpdated,
    ActivitysStatusUpdated,
    ActivityCreated,
    ActivityOnServerCreated
} from '../_actions/activity.actions';
import { of } from 'rxjs';

@Injectable()
export class ActivityEffects {
    showPageLoadingDistpatcher = new ActivitysPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ActivityActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ActivityActionToggleLoading({ isLoading: false });

    @Effect()
    loadActivitysPage$ = this.actions$.pipe(
        ofType<ActivitysPageRequested>(ActivityActionTypes.ActivitysPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.activitysService.findActivitys(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new ActivitysPageLoaded({
                activitys: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteActivity$ = this.actions$
        .pipe(
            ofType<OneActivityDeleted>(ActivityActionTypes.OneActivityDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.activitysService.deleteActivity(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteActivitys$ = this.actions$
        .pipe(
            ofType<ManyActivitysDeleted>(ActivityActionTypes.ManyActivitysDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.activitysService.deleteActivitys(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateActivity$ = this.actions$
        .pipe(
            ofType<ActivityUpdated>(ActivityActionTypes.ActivityUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.activitysService.updateActivity(payload.activity);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateActivitysStatus$ = this.actions$
        .pipe(
            ofType<ActivitysStatusUpdated>(ActivityActionTypes.ActivitysStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.activitysService.updateStatusForActivity(payload.activitys, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createActivity$ = this.actions$
        .pipe(
            ofType<ActivityOnServerCreated>(ActivityActionTypes.ActivityOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.activitysService.createActivity(payload.activity).pipe(
                    tap(res => {
                        this.store.dispatch(new ActivityCreated({ activity: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private activitysService: ActivityService, private store: Store<AppState>) { }
}
