// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ActiveUserTransactionActions, ActiveUserTransactionActionTypes } from '../_actions/activeusertransaction.actions';
// Models
import { ActiveUserTransactionModel } from '../_models/activeusertransaction.model';
import { QueryParamsModel } from '../../_base/crud';

export interface ActiveUsersTransactionsState extends EntityState<ActiveUserTransactionModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedActiveUserTransactionId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ActiveUserTransactionModel> = createEntityAdapter<ActiveUserTransactionModel>();

export const initialActiveUsersTransactionsState: ActiveUsersTransactionsState = adapter.getInitialState({
    activeusertransactionForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedActiveUserTransactionId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function activeuserstransactionsReducer(state = initialActiveUsersTransactionsState, action: ActiveUserTransactionActions): ActiveUsersTransactionsState {
    switch  (action.type) {
        case ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedActiveUserTransactionId: undefined
            };
        }
        case ActiveUserTransactionActionTypes.ActiveUserTransactionActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ActiveUserTransactionActionTypes.ActiveUserTransactionOnServerCreated: return {
            ...state
        };
        // case ActiveUserTransactionActionTypes.ActiveUserCreated: return adapter.addOne(action.payload.activeuser, {
        //     ...state, lastCreatedActiveUserId: action.payload.activeuser.id
        // });
        case ActiveUserTransactionActionTypes.ActiveUserTransactionUpdated: return adapter.updateOne(action.payload.partialActiveUserTransaction, state);
       //  case ActiveUserTransactionActionTypes.ActiveUsersStatusUpdated: {
       //      const _partialActiveUsers: Update<ActiveUserTransactionModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.activeusers.length; i++) {
       //          _partialActiveUsers.push({
           //  id: action.payload.activeusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialActiveUsers, state);
       //  }
        case ActiveUserTransactionActionTypes.OneActiveUserTransactionDeleted: return adapter.removeOne(action.payload.id, state);
        case ActiveUserTransactionActionTypes.ManyActiveUsersTransactionsDeleted: return adapter.removeMany(action.payload.ids, state);
        case ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case ActiveUserTransactionActionTypes.ActiveUsersTransactionsPageLoaded: {
            return adapter.addMany(action.payload.activeuserstransactions, {
                ...initialActiveUsersTransactionsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getActiveUserTransactionState = createFeatureSelector<ActiveUserTransactionModel>('activeuserstransactions');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
