// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GoldBuyModel } from '../_models/goldbuy.model';

export enum GoldBuyActionTypes {
    GoldBuyOnServerCreated = '[Edit GoldBuy Dialog] GoldBuy On Server Created',
    GoldBuyCreated = '[Edit GoldBuy Dialog] GoldBuy Created',
    GoldBuyUpdated = '[Edit GoldBuy Dialog] GoldBuy Updated',
    GoldBuysStatusUpdated = '[GoldBuy List Page] GoldBuys Status Updated',
    OneGoldBuyDeleted = '[GoldBuys List Page] One GoldBuy Deleted',
    ManyGoldBuysDeleted = '[GoldBuys List Page] Many GoldBuy Deleted',
    GoldBuysPageRequested = '[GoldBuys List Page] GoldBuys Page Requested',
    GoldBuysPageLoaded = '[GoldBuys API] GoldBuys Page Loaded',
    GoldBuysPageCancelled = '[GoldBuys API] GoldBuys Page Cancelled',
    GoldBuysPageToggleLoading = '[GoldBuys] GoldBuys Page Toggle Loading',
    GoldBuyActionToggleLoading = '[GoldBuys] GoldBuys Action Toggle Loading'
}

export class GoldBuyOnServerCreated implements Action {
    readonly type = GoldBuyActionTypes.GoldBuyOnServerCreated;
    constructor(public payload: { goldbuy: GoldBuyModel }) { }
}

export class GoldBuyCreated implements Action {
    readonly type = GoldBuyActionTypes.GoldBuyCreated;
    constructor(public payload: { goldbuy: GoldBuyModel }) { }
}

export class GoldBuyUpdated implements Action {
    readonly type = GoldBuyActionTypes.GoldBuyUpdated;
    constructor(public payload: {
        partialGoldBuy: Update<GoldBuyModel>, // For State update
        goldbuy: GoldBuyModel // For Server update (through service)
    }) { }
}

export class GoldBuysStatusUpdated implements Action {
    readonly type = GoldBuyActionTypes.GoldBuysStatusUpdated;
    constructor(public payload: {
        goldbuys: GoldBuyModel[],
        status: number
    }) { }
}

export class OneGoldBuyDeleted implements Action {
    readonly type = GoldBuyActionTypes.OneGoldBuyDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyGoldBuysDeleted implements Action {
    readonly type = GoldBuyActionTypes.ManyGoldBuysDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class GoldBuysPageRequested implements Action {
    readonly type = GoldBuyActionTypes.GoldBuysPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class GoldBuysPageLoaded implements Action {
    readonly type = GoldBuyActionTypes.GoldBuysPageLoaded;
    constructor(public payload: { goldbuys: GoldBuyModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class GoldBuysPageCancelled implements Action {
    readonly type = GoldBuyActionTypes.GoldBuysPageCancelled;
}

export class GoldBuysPageToggleLoading implements Action {
    readonly type = GoldBuyActionTypes.GoldBuysPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class GoldBuyActionToggleLoading implements Action {
    readonly type = GoldBuyActionTypes.GoldBuyActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type GoldBuyActions = GoldBuyOnServerCreated
| GoldBuyCreated
| GoldBuyUpdated
| GoldBuysStatusUpdated
| OneGoldBuyDeleted
| ManyGoldBuysDeleted
| GoldBuysPageRequested
| GoldBuysPageLoaded
| GoldBuysPageCancelled
| GoldBuysPageToggleLoading
| GoldBuyActionToggleLoading;
