// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { BanomUsersState } from '../_reducers/banomuser.reducers';
import { BanomUserModel } from '../_models/banomuser.model';

export const selectBanomUsersState = createFeatureSelector<BanomUsersState>('banomusers');

export const selectBanomUserById = (banomuserId: number) => createSelector(
    selectBanomUsersState,
    banomusersState => banomusersState.entities[banomuserId]
);

export const selectBanomUsersPageLoading = createSelector(
    selectBanomUsersState,
    banomusersState => banomusersState.listLoading
);

export const selectBanomUsersActionLoading = createSelector(
    selectBanomUsersState,
    banomusersState => banomusersState.actionsloading
);

export const selectLastCreatedBanomUserId = createSelector(
    selectBanomUsersState,
    banomusersState => banomusersState.lastCreatedBanomUserId
);

export const selectBanomUsersShowInitWaitingMessage = createSelector(
    selectBanomUsersState,
    banomusersState => banomusersState.showInitWaitingMessage
);

export const selectBanomUsersInStore = createSelector(
    selectBanomUsersState,
    banomusersState => {
        const items: BanomUserModel[] = [];
        each(banomusersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BanomUserModel[] = httpExtension.sortArray(items, banomusersState.lastQuery.sortField, banomusersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, banomusersState.totalCount, '');
    }
);
