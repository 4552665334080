// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GoldBuysState } from '../_reducers/goldbuy.reducers';
import { GoldBuyModel } from '../_models/goldbuy.model';

export const selectGoldBuysState = createFeatureSelector<GoldBuysState>('goldbuys');

export const selectGoldBuyById = (goldbuyId: number) => createSelector(
    selectGoldBuysState,
    goldbuysState => goldbuysState.entities[goldbuyId]
);

export const selectGoldBuysPageLoading = createSelector(
    selectGoldBuysState,
    goldbuysState => goldbuysState.listLoading
);

export const selectGoldBuysActionLoading = createSelector(
    selectGoldBuysState,
    goldbuysState => goldbuysState.actionsloading
);

export const selectLastCreatedGoldBuyId = createSelector(
    selectGoldBuysState,
    goldbuysState => goldbuysState.lastCreatedGoldBuyId
);

export const selectGoldBuysShowInitWaitingMessage = createSelector(
    selectGoldBuysState,
    goldbuysState => goldbuysState.showInitWaitingMessage
);

export const selectGoldBuysInStore = createSelector(
    selectGoldBuysState,
    goldbuysState => {
        const items: GoldBuyModel[] = [];
        each(goldbuysState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GoldBuyModel[] = httpExtension.sortArray(items, goldbuysState.lastQuery.sortField, goldbuysState.lastQuery.sortOrder);
        return new QueryResultsModel(result, goldbuysState.totalCount, '');
    }
);
