import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { GoldBuyService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    GoldBuyActionTypes,
    GoldBuysPageRequested,
    GoldBuysPageLoaded,
    ManyGoldBuysDeleted,
    OneGoldBuyDeleted,
    GoldBuyActionToggleLoading,
    GoldBuysPageToggleLoading,
    GoldBuyUpdated,
    GoldBuysStatusUpdated,
    GoldBuyCreated,
    GoldBuyOnServerCreated
} from '../_actions/goldbuy.actions';
import { of } from 'rxjs';

@Injectable()
export class GoldBuyEffects {
    showPageLoadingDistpatcher = new GoldBuysPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new GoldBuyActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new GoldBuyActionToggleLoading({ isLoading: false });

    @Effect()
    loadGoldBuysPage$ = this.actions$.pipe(
        ofType<GoldBuysPageRequested>(GoldBuyActionTypes.GoldBuysPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.goldbuysService.findGoldBuys(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new GoldBuysPageLoaded({
                goldbuys: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteGoldBuy$ = this.actions$
        .pipe(
            ofType<OneGoldBuyDeleted>(GoldBuyActionTypes.OneGoldBuyDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldbuysService.deleteGoldBuy(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteGoldBuys$ = this.actions$
        .pipe(
            ofType<ManyGoldBuysDeleted>(GoldBuyActionTypes.ManyGoldBuysDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldbuysService.deleteGoldBuys(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateGoldBuy$ = this.actions$
        .pipe(
            ofType<GoldBuyUpdated>(GoldBuyActionTypes.GoldBuyUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldbuysService.updateGoldBuy(payload.goldbuy);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateGoldBuysStatus$ = this.actions$
        .pipe(
            ofType<GoldBuysStatusUpdated>(GoldBuyActionTypes.GoldBuysStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldbuysService.updateStatusForGoldBuy(payload.goldbuys, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createGoldBuy$ = this.actions$
        .pipe(
            ofType<GoldBuyOnServerCreated>(GoldBuyActionTypes.GoldBuyOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldbuysService.createGoldBuy(payload.goldbuy).pipe(
                    tap(res => {
                        this.store.dispatch(new GoldBuyCreated({ goldbuy: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private goldbuysService: GoldBuyService, private store: Store<AppState>) { }
}
