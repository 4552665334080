// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common';


// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { AuthService } from '../../../../../core/auth';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  AllUserSellModel,
  AllUserSellService,
  AllUserSellsDataSource,
  AllUserSellsPageRequested,
  OneAllUserSellDeleted,
  ManyAllUserSellsDeleted,
  AllUserSellsStatusUpdated
} from '../../../../../core/user';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
@Component({
	selector: 'kt-allusers-history-sell',
	templateUrl: './all-user-history-sell.component.html',
	styleUrls: ['all-user-history-sell.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllUserHistorySellComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: AllUserSellsDataSource;
	displayedColumns = ['created_at', 'id', 'gold_amount', 'amount', 'base_reward', 'status'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};
  	organizationList: any = [];
  	selectedOrg: string = '';
	role: boolean = false;
	detailUser: any = {};
	params: string = '';

	// Filter fields
	// @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<AllUserSellModel>(true, []);
	allusersellsResult: AllUserSellModel[] = [];

	spinnerButtonOptions: MatProgressButtonOptions = {
		active: false,
		text: 'Download',
		spinnerSize: 18,
		raised: true,
		stroked: false,
		buttonColor: 'accent',
		spinnerColor: 'accent',
		fullWidth: false,
		disabled: false,
		mode: 'indeterminate',
		buttonIcon: {
			fontIcon: 'cloud_download'
		}
	}

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
    	public authService : AuthService,
		public usersellService: AllUserSellService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AppState>,
		private datePipe: DatePipe,
		private location: Location,
		private cd: ChangeDetectorRef
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		let hashedEncode = ''
		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			this.params = params.id;
			hashedEncode = params.id
		});
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadAllUsersList(hashedEncode))
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Init Org List
		this.loadOrganizationList();
		this.loadUserRole();

		// Init DataSource
		this.dataSource = new AllUserSellsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.allusersellsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1),
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadAllUsersList(hashedEncode);
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load AllUsers List from service through data-source
	 */
	loadAllUsersList(hashed_id) {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const searchText: string = '';
		const queryParams = new QueryParamsModel(
	        searchText,
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'[0,1,2,3,4]', // status
			'', // type
			'', // suborganization_id
			this.selectedOrg, // organization_id
			'',
			hashed_id
		);
		// Call request from server;
		this.store.dispatch(new AllUserSellsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	backTo() {
		this.location.back()
	}

	/**
	 * Load Organization List
	 */
	loadOrganizationList() {
		this.usersellService.getAllOrganization()
		.subscribe(
			data => {
				this.organizationList = data;
			}
		)
	}

	/**
	 * Load Role
	 */
	loadUserRole() {
		this.authService.getUserByToken()
		.subscribe(
			data => {
				this.role = data.data.roles.includes(17);
			}
		)
	}
}
