import { BaseModel } from '../../_base/crud';

export class AllUserBuyModel  extends BaseModel {
	id: string;
	user: any;
	gold_amount: any;
	amount: any;
	invoice: any;
	base_reward: any;
	status: any;
	created_at: string;

	clear() {
		this.id = '';
	    this.user = undefined;
	    this.gold_amount = '';
	    this.amount = '';
		this.invoice = '';
		this.base_reward = '';
		this.status = '';
	    this.created_at = '';
	}
}
