// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { SuborganizationsState } from '../_reducers/suborganization.reducers';
import { SuborganizationModel } from '../_models/suborganization.model';

export const selectSuborganizationsState = createFeatureSelector<SuborganizationsState>('suborganizations');

export const selectSuborganizationById = (suborganizationId: number) => createSelector(
    selectSuborganizationsState,
    suborganizationsState => suborganizationsState.entities[suborganizationId]
);

export const selectSuborganizationsPageLoading = createSelector(
    selectSuborganizationsState,
    suborganizationsState => suborganizationsState.listLoading
);

export const selectSuborganizationsActionLoading = createSelector(
    selectSuborganizationsState,
    suborganizationsState => suborganizationsState.actionsloading
);

export const selectLastCreatedSuborganizationId = createSelector(
    selectSuborganizationsState,
    suborganizationsState => suborganizationsState.lastCreatedSuborganizationId
);

export const selectSuborganizationsShowInitWaitingMessage = createSelector(
    selectSuborganizationsState,
    suborganizationsState => suborganizationsState.showInitWaitingMessage
);

export const selectSuborganizationsInStore = createSelector(
    selectSuborganizationsState,
    suborganizationsState => {
        const items: SuborganizationModel[] = [];
        each(suborganizationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SuborganizationModel[] = httpExtension.sortArray(items, suborganizationsState.lastQuery.sortField, suborganizationsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, suborganizationsState.totalCount, '');
    }
);
