<div class="row">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
        <h5>Status Menu Kartanu</h5>
        <div class="row">
          <div class="col-md-3 kt-margin-bottom-10-mobile">
			<div class="example-container">
				<mat-slide-toggle [labelPosition]="labelPosition" [(ngModel)]="menuKartanu" (ngModelChange)="onUpdatePermissionMenu($event)">Aktif</mat-slide-toggle>
			</div>
          </div>
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>
