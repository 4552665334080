import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {
    id: number;
    hashed_id: string;
    name: any;
    email: string;
    photo: string;
    phone: string;
    address: string;
    gold_balance: boolean;
    roles: number[];
    username: string;
    password: string;
    access_token: string;
    data: any;

    clear(): void {
        this.id = undefined;
        this.hashed_id = '';
        this.name = '';
        this.email = '';
        this.photo = '';
        this.phone = '';
        this.address = '';
        this.gold_balance = false;
        this.roles = [];
        this.username = '';
        this.password = '';
        this.access_token = 'access-token-' + Math.random();
        this.data = undefined;
    }
}
