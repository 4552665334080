import { BaseModel } from '../../_base/crud';

export class AdminModel  extends BaseModel {
  id: number;
  name: string;
  email: string;
  roles: string;
  organization: string;

  clear() {
    this.id = undefined;
    this.name = undefined;
    this.email = undefined;
    this.roles = undefined;
    this.organization = undefined;
  }
}
