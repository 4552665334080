<kt-portlet>
  <kt-portlet-body>
		<div class="row">
			<div class="col-lg-12">
				<mat-form-field class="mat-form-field-fluid">
					<input name="name" matInput placeholder="Nama" [(ngModel)]="userData.name">
				</mat-form-field>
			</div>
			<div class="col-lg-12">
				<mat-form-field class="mat-form-field-fluid">
					<input name="email" matInput placeholder="Email" [(ngModel)]="userData.email">
				</mat-form-field>
			</div>
      <div class="col-lg-12 mb-4">
        <mat-radio-group class="mat-form-field-fluid" [(ngModel)]="selectedRole">
          <mat-radio-button name="role" *ngFor="let role of roleList" [value]="role.id" (change)="roleChange($event)">{{role.name}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-lg-12" *ngIf="suborganization">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label class="kt-font-bold">Choose a suborganization</mat-label>
          <mat-select name="suborganization" placeholder="PIC" [(ngModel)]="selectedSuborganization">
            <mat-option *ngFor="let suborganization of suborganizationList" [value]="suborganization.id">{{suborganization.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
			<div class="col-lg-12" *ngIf="province">
        <mat-label class="kt-font-bold">Choose province</mat-label>
        <br><br>
        <div class="row">
          <div *ngFor="let province of provinceList" class=col-md-3>
            <mat-checkbox [value]="province.hashed_id" [checked]="province.checked" (change)="provinceChange($event)">{{province.name}}</mat-checkbox>
          </div>
        </div>
      </div>
      <div class="row text-right mt-3">
				<div class="col-lg-12">
          <button mat-raised-button color="accent" (click)="back()" class="mr-2">Kembali</button>
					<button type="button" mat-raised-button color="accent" (click)="updateUser()">Simpan</button>
				</div>
			</div>
		</div>
	</kt-portlet-body>
</kt-portlet>