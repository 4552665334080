import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { SuborganizationAllService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    SuborganizationAllActionTypes,
    SuborganizationAllsPageRequested,
    SuborganizationAllsPageLoaded,
    ManySuborganizationAllsDeleted,
    OneSuborganizationAllDeleted,
    SuborganizationAllActionToggleLoading,
    SuborganizationAllsPageToggleLoading,
    SuborganizationAllUpdated,
    SuborganizationAllsStatusUpdated,
    SuborganizationAllCreated,
    SuborganizationAllOnServerCreated
} from '../_actions/suborganizationall.actions';
import { of } from 'rxjs';

@Injectable()
export class SuborganizationAllEffects {
    showPageLoadingDistpatcher = new SuborganizationAllsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new SuborganizationAllActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new SuborganizationAllActionToggleLoading({ isLoading: false });

    @Effect()
    loadSuborganizationAllsPage$ = this.actions$.pipe(
        ofType<SuborganizationAllsPageRequested>(SuborganizationAllActionTypes.SuborganizationAllsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.suborganizationallsService.findSuborganizationAlls(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new SuborganizationAllsPageLoaded({
                suborganizationalls: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteSuborganizationAll$ = this.actions$
        .pipe(
            ofType<OneSuborganizationAllDeleted>(SuborganizationAllActionTypes.OneSuborganizationAllDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.suborganizationallsService.deleteSuborganizationAll(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteSuborganizationAlls$ = this.actions$
        .pipe(
            ofType<ManySuborganizationAllsDeleted>(SuborganizationAllActionTypes.ManySuborganizationAllsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.suborganizationallsService.deleteSuborganizationAlls(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateSuborganizationAll$ = this.actions$
        .pipe(
            ofType<SuborganizationAllUpdated>(SuborganizationAllActionTypes.SuborganizationAllUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.suborganizationallsService.updateSuborganizationAll(payload.suborganizationall);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateSuborganizationAllsStatus$ = this.actions$
        .pipe(
            ofType<SuborganizationAllsStatusUpdated>(SuborganizationAllActionTypes.SuborganizationAllsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.suborganizationallsService.updateStatusForSuborganizationAll(payload.suborganizationalls, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createSuborganizationAll$ = this.actions$
        .pipe(
            ofType<SuborganizationAllOnServerCreated>(SuborganizationAllActionTypes.SuborganizationAllOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.suborganizationallsService.createSuborganizationAll(payload.suborganizationall).pipe(
                    tap(res => {
                        this.store.dispatch(new SuborganizationAllCreated({ suborganizationall: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private suborganizationallsService: SuborganizationAllService, private store: Store<AppState>) { }
}
