import { BaseModel } from '../../_base/crud';

export class GoldGiftCardModel  extends BaseModel {
  id: string;
  user: string;
  price: string;
  gold_amount: string;
  created_at: string;

  clear() {
    this.id = undefined;
    this.user = undefined;
    this.price = undefined;
    this.gold_amount = undefined;
    this.created_at = undefined;
  }
}
