// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { UsersState, ShipmentsState } from '../_reducers/user.reducers';
import { UserModel } from '../_models/user.model';

export const selectUsersState = createFeatureSelector<UsersState>('users');
export const selectShipmentsState = createFeatureSelector<ShipmentsState>('users');

export const selectUserById = (userId: number) => createSelector(
    selectUsersState,
    usersState => usersState.entities[userId]
);

export const selectUsersPageLoading = createSelector(
    selectUsersState,
    usersState => usersState.listLoading
);

export const selectUsersActionLoading = createSelector(
    selectUsersState,
    usersState => usersState.actionsloading
);

export const selectLastCreatedUserId = createSelector(
    selectUsersState,
    usersState => usersState.lastCreatedUserId
);

export const selectUsersShowInitWaitingMessage = createSelector(
    selectUsersState,
    usersState => usersState.showInitWaitingMessage
);

export const selectShipmentsShowInitWaitingMessage = createSelector(
    selectShipmentsState,
    shipmentsState => shipmentsState.showInitWaitingMessage
);

export const selectShipmentsPageLoading = createSelector(
    selectShipmentsState,
    shipmentsState => shipmentsState.listLoading
);

export const selectShipmentsInStore = createSelector(
    selectShipmentsState,
    shipmentsState => {
        const items: UserModel[] = [];
        each(shipmentsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: UserModel[] = httpExtension.sortArray(items, shipmentsState.lastQuery.sortField, shipmentsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, shipmentsState.totalCount, '');
    }
);

export const selectUsersInStore = createSelector(
    selectUsersState,
    usersState => {
        const items: UserModel[] = [];
        each(usersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: UserModel[] = httpExtension.sortArray(items, usersState.lastQuery.sortField, usersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, usersState.totalCount, '');
    }
);
