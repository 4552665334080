// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../partials/partials.module';
// Auth
import { ModuleGuard } from '../../../core/auth';
// Core => Services
import {
	usersReducer,
	UserEffects,
	UserService,
	allusersReducer,
	AllUserEffects,
	AllUserService,
	allusersellsReducer,
	AllUserSellEffects,
	AllUserSellService,
	alluserbuysReducer,
	AllUserBuyEffects,
	AllUserBuyService,
	allusercoinsReducer,
	AllUserCoinEffects,
	AllUserCoinService,
	activeusersReducer,
	ActiveUserEffects,
	ActiveUserService,
	activeuserstransactionsReducer,
	ActiveUserTransactionEffects,
	ActiveUserTransactionService,
	nonactiveusersReducer,
	NonActiveUserEffects,
	NonActiveUserService,
	pwnuusersReducer,
	PwnuUserEffects,
	PwnuUserService,
	banomusersReducer,
	BanomUserEffects,
	BanomUserService,
} from '../../../core/user';
// Core => Utils
import { HttpUtilsService,
	TypesUtilsService,
	InterceptService,
	LayoutUtilsService
} from '../../../core/_base/crud';
// Shared
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from '../../partials/content/crud';
// Components
import { UserComponent } from './user.component';
// All User
import { AllUserListComponent } from './all-user/all-user-list/all-user-list.component';
import { AllUserDetailComponent } from './all-user/all-user-detail/all-user-detail.component'
import { AllUserHistoryBuyComponent } from './all-user/all-user-history-buy/all-user-history-buy.component'
import { AllUserHistoryCoinComponent } from './all-user/all-user-history-coin/all-user-history-coin.component'
import { AllUserHistorySellComponent } from './all-user/all-user-history-sell/all-user-history-sell.component'
import { AllUserEditComponent } from './all-user/all-user-edit/all-user-edit.dialog.component';

// Active User
import { ActiveUserListComponent } from './active-user/active-user-list/active-user-list.component';
import { ActiveUserEditComponent } from './active-user/active-user-edit/active-user-edit.dialog.component';
// Active Transaction
import { ActiveUserTransactionListComponent } from './active-user-transaction/active-user-transaction-list/active-user-transaction-list.component';
import { ActiveUserTransactionEditComponent } from './active-user-transaction/active-user-transaction-edit/active-user-transaction-edit.dialog.component';
// Non Active User
import { NonActiveUserListComponent } from './non-active-user/non-active-user-list/non-active-user-list.component';
import { NonActiveUserEditComponent } from './non-active-user/non-active-user-edit/non-active-user-edit.dialog.component';
// Pwnu User
import { PwnuUserListComponent } from './pwnu-user/pwnu-user-list/pwnu-user-list.component';
import { PwnuUserEditComponent } from './pwnu-user/pwnu-user-edit/pwnu-user-edit.dialog.component';
// Banom User
import { BanomUserListComponent } from './banom-user/banom-user-list/banom-user-list.component';
import { BanomUserEditComponent } from './banom-user/banom-user-edit/banom-user-edit.dialog.component';
// Button Spinner
import { MatProgressButtonsModule } from 'mat-progress-buttons';

// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
  	MatFormFieldModule
} from '@angular/material';
// Date Range
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../core/_base/layout';

import { environment } from '../../../../environments/environment';
import { CoreModule } from '../../../core/core.module';
import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: UserComponent,
		children: [
			{
				path: '',
				redirectTo: 'user',
				pathMatch: 'full'
			},
			{
				path: 'all-user',
				component: AllUserListComponent
			},
			{
				path: 'all-user/all-user-detail/:id',
				component: AllUserDetailComponent
			},
			{
				path: 'all-user/all-user-history-buy/:id',
				component: AllUserHistoryBuyComponent
			},
			{
				path: 'all-user/all-user-history-coin/:id',
				component: AllUserHistoryCoinComponent
			},
			{
				path: 'all-user/all-user-history-sell/:id',
				component: AllUserHistorySellComponent
			},
			{
				path: 'active-user-kartanu',
				component: ActiveUserListComponent
			},
			{
				path: 'active-user-transaction',
				component: ActiveUserTransactionListComponent
			},
			{
				path: 'non-active-user',
				component: NonActiveUserListComponent
			},
			{
				path: 'pwnu-user',
				component: PwnuUserListComponent
			},
			{
				path: 'banom-user',
				component: BanomUserListComponent
			}
		]
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgxPermissionsModule.forChild(),
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		SatDatepickerModule,
		SatNativeDateModule,
		MatProgressButtonsModule,
		StoreModule.forFeature('users', usersReducer),
		StoreModule.forFeature('allusers', allusersReducer),
		StoreModule.forFeature('alluserbuys', alluserbuysReducer),
		StoreModule.forFeature('allusercoins', allusercoinsReducer),
		StoreModule.forFeature('allusersells', allusersellsReducer),
		StoreModule.forFeature('activeusers', activeusersReducer),
		StoreModule.forFeature('activeuserstransactions', activeuserstransactionsReducer),
		StoreModule.forFeature('nonactiveusers', nonactiveusersReducer),
		StoreModule.forFeature('pwnuusers', pwnuusersReducer),
		StoreModule.forFeature('banomusers', banomusersReducer),
		EffectsModule.forFeature([UserEffects]),
		EffectsModule.forFeature([AllUserEffects]),
		EffectsModule.forFeature([AllUserBuyEffects]),
		EffectsModule.forFeature([AllUserCoinEffects]),
		EffectsModule.forFeature([AllUserSellEffects]),
		EffectsModule.forFeature([ActiveUserEffects]),
		EffectsModule.forFeature([ActiveUserTransactionEffects]),
		EffectsModule.forFeature([NonActiveUserEffects]),
		EffectsModule.forFeature([PwnuUserEffects]),
		EffectsModule.forFeature([BanomUserEffects]),
	],
	providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    DatePipe,
		ModuleGuard,
		InterceptService,
      	{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
        	multi: true
      	},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		UserService,
		AllUserService,
		AllUserBuyService,
		AllUserCoinService,
		AllUserSellService,
		ActiveUserService,
		ActiveUserTransactionService,
		NonActiveUserService,
		PwnuUserService,
		BanomUserService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents: [
		ActionNotificationComponent,
		AllUserEditComponent,
		AllUserDetailComponent,
		AllUserHistoryBuyComponent,
		AllUserHistoryCoinComponent,
		AllUserHistorySellComponent,
		ActiveUserEditComponent,
		ActiveUserTransactionEditComponent,
		NonActiveUserEditComponent,
		PwnuUserEditComponent,
		BanomUserEditComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
	declarations: [
		UserComponent,
		AllUserListComponent,
		AllUserEditComponent,
		AllUserDetailComponent,
		AllUserHistoryBuyComponent,
		AllUserHistoryCoinComponent,
		AllUserHistorySellComponent,
		ActiveUserListComponent,
		ActiveUserEditComponent,
		ActiveUserTransactionListComponent,
		ActiveUserTransactionEditComponent,
		NonActiveUserListComponent,
		NonActiveUserEditComponent,
		PwnuUserListComponent,
		PwnuUserEditComponent,
		BanomUserListComponent,
		BanomUserEditComponent
	]
})
export class UserModule { }
