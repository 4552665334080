// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { GoldDonationActions, GoldDonationActionTypes } from '../_actions/golddonation.actions';
// Models
import { GoldDonationModel } from '../_models/golddonation.model';
import { QueryParamsModel } from '../../_base/crud';

export interface GoldDonationsState extends EntityState<GoldDonationModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGoldDonationId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GoldDonationModel> = createEntityAdapter<GoldDonationModel>();

export const initialGoldDonationsState: GoldDonationsState = adapter.getInitialState({
    golddonationForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGoldDonationId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function golddonationsReducer(state = initialGoldDonationsState, action: GoldDonationActions): GoldDonationsState {
    switch  (action.type) {
        case GoldDonationActionTypes.GoldDonationsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGoldDonationId: undefined
            };
        }
        case GoldDonationActionTypes.GoldDonationActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case GoldDonationActionTypes.GoldDonationOnServerCreated: return {
            ...state
        };
        // case GoldDonationActionTypes.GoldDonationCreated: return adapter.addOne(action.payload.golddonation, {
        //     ...state, lastCreatedGoldDonationId: action.payload.golddonation.id
        // });
        case GoldDonationActionTypes.GoldDonationUpdated: return adapter.updateOne(action.payload.partialGoldDonation, state);
       //  case GoldDonationActionTypes.GoldDonationsStatusUpdated: {
       //      const _partialGoldDonations: Update<GoldDonationModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.golddonations.length; i++) {
       //          _partialGoldDonations.push({
           //  id: action.payload.golddonations[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialGoldDonations, state);
       //  }
        case GoldDonationActionTypes.OneGoldDonationDeleted: return adapter.removeOne(action.payload.id, state);
        case GoldDonationActionTypes.ManyGoldDonationsDeleted: return adapter.removeMany(action.payload.ids, state);
        case GoldDonationActionTypes.GoldDonationsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case GoldDonationActionTypes.GoldDonationsPageLoaded: {
            return adapter.addMany(action.payload.golddonations, {
                ...initialGoldDonationsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getGoldDonationState = createFeatureSelector<GoldDonationModel>('golddonations');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
