// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { OrganizationsState } from '../_reducers/organization.reducers';
import { OrganizationModel } from '../_models/organization.model';

export const selectOrganizationsState = createFeatureSelector<OrganizationsState>('organizations');

export const selectOrganizationById = (organizationId: number) => createSelector(
    selectOrganizationsState,
    organizationsState => organizationsState.entities[organizationId]
);

export const selectOrganizationsPageLoading = createSelector(
    selectOrganizationsState,
    organizationsState => organizationsState.listLoading
);

export const selectOrganizationsActionLoading = createSelector(
    selectOrganizationsState,
    organizationsState => organizationsState.actionsloading
);

export const selectLastCreatedOrganizationId = createSelector(
    selectOrganizationsState,
    organizationsState => organizationsState.lastCreatedOrganizationId
);

export const selectOrganizationsShowInitWaitingMessage = createSelector(
    selectOrganizationsState,
    organizationsState => organizationsState.showInitWaitingMessage
);

export const selectOrganizationsInStore = createSelector(
    selectOrganizationsState,
    organizationsState => {
        const items: OrganizationModel[] = [];
        each(organizationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OrganizationModel[] = httpExtension.sortArray(items, organizationsState.lastQuery.sortField, organizationsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, organizationsState.totalCount, '');
    }
);
