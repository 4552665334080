// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { AdminModel } from '../_models/admin.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class AdminService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new admin to the server
  createAdmin(admin: AdminModel): Observable<AdminModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<AdminModel>(BASE_URL, admin, { headers: httpHeaders});
  }

  // READ
  getAllAdmins(): Observable<AdminModel[]> {
    return this.http.get<AdminModel[]>(BASE_URL);
  }

  getAdminById(adminId: number): Observable<AdminModel> {
    return this.http.get<AdminModel>(BASE_URL + `/${adminId}`);
  }

  getAllRoles() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/auth-role';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data));
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  getAllSuborganization(queryParams: QueryParamsModel) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/suborganization';
    return this.http
      .get<any[]>(url,{
        headers:httpHeaders,
        params:  httpParams
      })
      .pipe(map(data => data['data']));
  }

  getAllProvince() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/province?page=1&limit=100';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  addAdmin(data) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
        httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<QueryResultsModel>(BASE_URL + 'b2b/admin', data, { headers: httpHeaders})
      .pipe(map(data => data['data']));
  }

  getAdminByEmail() {
    const data = {email: ''};
    const httpHeaders = this.httpUtils.getHTTPHeaders();
        httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<QueryResultsModel>(BASE_URL + 'b2b/search', data, { headers: httpHeaders})
      .pipe(map(data => data['data']));
  }

  getAdminByHashedId(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/admin/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  updateDataAdmin(hashed_id: string, data: any) {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL + 'b2b/admin/' + hashed_id, data, { headers: httpHeader });
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findAdmins(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/admin';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the admin on the server
  updateAdmin(admin: AdminModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL + 'b2b/admin/', admin, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForAdmin(admins: AdminModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      adminsForUpdate: admins,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the admin from the server
  deleteRole(hashed_id: string) {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/admin/' + hashed_id;
    return this.http.delete(url, { headers: httpHeader })
      .pipe(map(data => data['data']));
  }

  deleteAdmin(adminId: number): Observable<AdminModel> {
    const url = `${BASE_URL}/${adminId}`;
    return this.http.delete<AdminModel>(url);
  }

  deleteAdmins(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteAdmins';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { adminIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
