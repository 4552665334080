<kt-portlet>

	<kt-portlet-body>

    <div class="kt-form kt-margin-b-30">
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search goldbuy" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> by serial number
							</mat-hint>
						</mat-form-field>
					</div>
          <div class="col-md-3 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput
                  placeholder="Choose a date"
                  [satDatepicker]="picker"
                  [(value)]="dateRange"
                  (dateChange)="onDateChange($event)">
              <sat-datepicker
                  #picker
                  [rangeMode]="true"
                  orderPeriodLabel="month"
                  [closeAfterSelection]="true">>
              </sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            </mat-form-field>
          </div>
				</div>
			</div>
		</div>

		<div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="desc"
				matSortDisableClear>

				<ng-container matColumnDef="serial_number">
					<mat-header-cell *matHeaderCellDef>Nomor Urut</mat-header-cell>
					<mat-cell *matCellDef="let receivecard">{{receivecard?.serial_number}}</mat-cell>
				</ng-container>

		        <ng-container matColumnDef="created_at">
		          <mat-header-cell *matHeaderCellDef>Tanggal</mat-header-cell>
		          <mat-cell *matCellDef="let receivecard">{{receivecard?.created_at}}</mat-cell>
		        </ng-container>

		        <ng-container matColumnDef="total">
		          <mat-header-cell *matHeaderCellDef>Jumlah KartaNU</mat-header-cell>
		          <mat-cell *matCellDef="let receivecard">{{receivecard?.total}} buah</mat-cell>
		        </ng-container>

		        <ng-container matColumnDef="location">
		          <mat-header-cell *matHeaderCellDef>Lokasi</mat-header-cell>
		          <mat-cell *matCellDef="let receivecard">{{receivecard?.location}}</mat-cell>
		        </ng-container>

		        <ng-container matColumnDef="moderator">
		          <mat-header-cell *matHeaderCellDef>PIC</mat-header-cell>
		          <mat-cell *matCellDef="let receivecard">{{receivecard?.moderator}}</mat-cell>
		        </ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>

	</kt-portlet-body>

</kt-portlet>
