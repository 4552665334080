// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  AdminModel,
  AdminService,
  AdminsDataSource,
  AdminsPageRequested,
  OneAdminDeleted,
  ManyAdminsDeleted,
  AdminsStatusUpdated
} from '../../../../../core/user-management';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
import { UserDeleteConfirmationDialogComponent } from '../user-delete-confirmation/user-delete-confirmation.dialog.component';
@Component({
	selector: 'kt-userinfos-list',
	templateUrl: './user-info-list.component.html',
	styleUrls: ['user-info-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class UserInfoListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: AdminsDataSource;
	displayedColumns = ['name', 'email', 'roles', 'organization', 'action'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};

	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<AdminModel>(true, []);
	userinfosResult: AdminModel[] = [];

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		public adminService: AdminService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    	private datePipe: DatePipe,
		private activatedRoute: ActivatedRoute,
		private router: Router,
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
    
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadUserInfosList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadUserInfosList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init DataSource
		this.dataSource = new AdminsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.userinfosResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1), 
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadUserInfosList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load UserInfos List from service through data-source
	 */
	loadUserInfosList() {
		this.selection.clear();
		const searchText: string = this.searchInput.nativeElement.value;
		const queryParams = new QueryParamsModel(
	  		searchText,
			'', // type
			'', // status
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize
		);
		// Call request from server
		this.store.dispatch(new AdminsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	edit(id){
		this.router.navigate(['../user-update/', id], { relativeTo: this.activatedRoute });
	}

	tambah(){
		this.router.navigate(['../user-add/'], { relativeTo: this.activatedRoute });
	}

	delete(id, email, roles){
		const length = {hashed_id: id, email: email, roles: roles};
		const dialogConf = this.dialog.open(UserDeleteConfirmationDialogComponent, { data: { length }, width: '500px' });
		dialogConf.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			this.deleteRoles(res);
		});
	}

	deleteRoles(datas) {
		this.adminService.deleteRole(datas)
		.subscribe(
			data => {
				this.snackBar.open("Berhasil Dihapus", "", {duration: 1500});
				this.loadUserInfosList();
			});
	}
}
