// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { QueryParamsInventoryModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  LocationAvailableModel,
  LocationAvailablesDataSource,
  LocationAvailablesPageRequested,
  LocationAvailablesStatusUpdated,
  ModeratorAvailableModel,
  ModeratorAvailablesDataSource,
  ModeratorAvailablesPageRequested,
  ModeratorAvailablesStatusUpdated,
  SuborganizationAvailableModel,
  SuborganizationAvailablesDataSource,
  SuborganizationAvailablesPageRequested,
  SuborganizationAvailablesStatusUpdated,
} from '../../../../../core/kartanu';

import { MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
@Component({
	selector: 'kt-availablecards-list',
	templateUrl: './available-card-list.component.html',
	styleUrls: ['available-card-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class AvailableCardListComponent implements OnInit, OnDestroy {
	// --------- LOCATION -----------
	// Table fields
	dataSourceLocation: LocationAvailablesDataSource;
	displayedColumnsLocation = ['name'];
	@ViewChild('paginatorLocation', {static: true}) paginatorLocation: MatPaginator;
	@ViewChild('sortLocation', {static: true}) sortLocation: MatSort;
	// Filter fields
	@ViewChild('searchInputLocation', {static: true}) searchInputLocation: ElementRef;
	// Selection
	selectionLocation = new SelectionModel<LocationAvailableModel>(true, []);
	availablecardsResultLocation: LocationAvailableModel[] = [];
	// Subscriptions
	private subscriptionsLocation: Subscription[] = [];

	// --------- MODERATOR -----------
	// Table fields
	dataSourceModerator: ModeratorAvailablesDataSource;
	displayedColumnsModerator = ['name'];
	@ViewChild('paginatorModerator', {static: true}) paginatorModerator: MatPaginator;
	@ViewChild('sortModerator', {static: true}) sortModerator: MatSort;
	// Filter fields
	@ViewChild('searchInputModerator', {static: true}) searchInputModerator: ElementRef;
	// Selection
	selectionModerator = new SelectionModel<ModeratorAvailableModel>(true, []);
	availablecardsResultModerator: ModeratorAvailableModel[] = [];
	// Subscriptions
	private subscriptionsModerator: Subscription[] = [];

	// --------- SUBORGANIZATION -----------
	// Table fields
	dataSourceSuborganization: SuborganizationAvailablesDataSource;
	displayedColumnsSuborganization = ['name'];
	@ViewChild('paginatorSuborganization', {static: true}) paginatorSuborganization: MatPaginator;
	@ViewChild('sortSuborganization', {static: true}) sortSuborganization: MatSort;
	// Filter fields
	@ViewChild('searchInputSuborganization', {static: true}) searchInputSuborganization: ElementRef;
	// Selection
	selectionSuborganization = new SelectionModel<SuborganizationAvailableModel>(true, []);
	availablecardsResultSuborganization: SuborganizationAvailableModel[] = [];
	// Subscriptions
	private subscriptionsSuborganization: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
    
		// --------- LOCATION -----------
		// If the user changes the sortLocation order, reset back to the first page.
		const sortLocationSubscription = this.sortLocation.sortChange.subscribe(() => (this.paginatorLocation.pageIndex = 0));
		this.subscriptionsLocation.push(sortLocationSubscription);
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginatorLocation.page
		- when a sortLocation event occurs => this.sortLocation.sortChange
		**/
		const paginatorLocationSubscriptions = merge(this.sortLocation.sortChange, this.paginatorLocation.page).pipe(
			tap(() => this.loadAvailableCardsListLocation())
		)
		.subscribe();
		this.subscriptionsLocation.push(paginatorLocationSubscriptions);
		// Filtration, bind to searchInputLocation
		const searchSubscriptionLocation = fromEvent(this.searchInputLocation.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginatorLocation.pageIndex = 0;
				this.loadAvailableCardsListLocation();
			})
		)
		.subscribe();
		this.subscriptionsLocation.push(searchSubscriptionLocation);
		// Init DataSource
		this.dataSourceLocation = new LocationAvailablesDataSource(this.store);
		const entitiesSubscriptionLocation = this.dataSourceLocation.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.availablecardsResultLocation = res;
		});
		this.subscriptionsLocation.push(entitiesSubscriptionLocation);
    
		// --------- MODERATOR -----------
		// If the user changes the sortModerator order, reset back to the first page.
		const sortModeratorSubscription = this.sortModerator.sortChange.subscribe(() => (this.paginatorModerator.pageIndex = 0));
		this.subscriptionsModerator.push(sortModeratorSubscription);
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginatorModerator.page
		- when a sortModerator event occurs => this.sortModerator.sortChange
		**/
		const paginatorModeratorSubscriptions = merge(this.sortModerator.sortChange, this.paginatorModerator.page).pipe(
			tap(() => this.loadAvailableCardsListModerator())
		)
		.subscribe();
		this.subscriptionsModerator.push(paginatorModeratorSubscriptions);
		// Filtration, bind to searchInputModerator
		const searchSubscriptionModerator = fromEvent(this.searchInputModerator.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginatorModerator.pageIndex = 0;
				this.loadAvailableCardsListModerator();
			})
		)
		.subscribe();
		this.subscriptionsModerator.push(searchSubscriptionModerator);
		// Init DataSource
		this.dataSourceModerator = new ModeratorAvailablesDataSource(this.store);
		const entitiesSubscriptionModerator = this.dataSourceModerator.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.availablecardsResultModerator = res;
		});
		this.subscriptionsModerator.push(entitiesSubscriptionModerator);
    
		// --------- SUBORGANIZATION -----------
		// If the user changes the sortSuborganization order, reset back to the first page.
		const sortSuborganizationSubscription = this.sortSuborganization.sortChange.subscribe(() => (this.paginatorSuborganization.pageIndex = 0));
		this.subscriptionsSuborganization.push(sortSuborganizationSubscription);
		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginatorSuborganization.page
		- when a sortSuborganization event occurs => this.sortSuborganization.sortChange
		**/
		const paginatorSuborganizationSubscriptions = merge(this.sortSuborganization.sortChange, this.paginatorSuborganization.page).pipe(
			tap(() => this.loadAvailableCardsListSuborganization())
		)
		.subscribe();
		this.subscriptionsSuborganization.push(paginatorSuborganizationSubscriptions);
		// Filtration, bind to searchInputSuborganization
		const searchSubscriptionSuborganization = fromEvent(this.searchInputSuborganization.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginatorSuborganization.pageIndex = 0;
				this.loadAvailableCardsListSuborganization();
			})
		)
		.subscribe();
		this.subscriptionsSuborganization.push(searchSubscriptionSuborganization);
		// Init DataSource
		this.dataSourceSuborganization = new SuborganizationAvailablesDataSource(this.store);
		const entitiesSubscriptionSuborganization = this.dataSourceSuborganization.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.availablecardsResultSuborganization = res;
		});
		this.subscriptionsSuborganization.push(entitiesSubscriptionSuborganization);

		// First load
		of(undefined).pipe(
			take(1), 
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginatorLocation.pageIndex = 0;
			this.paginatorModerator.pageIndex = 0;
			this.paginatorSuborganization.pageIndex = 0;
			this.loadAvailableCardsListLocation();
			this.loadAvailableCardsListModerator();
			this.loadAvailableCardsListSuborganization();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptionsLocation.forEach(el => el.unsubscribe());
		this.subscriptionsModerator.forEach(el => el.unsubscribe());
		this.subscriptionsSuborganization.forEach(el => el.unsubscribe());
	}

	/**
	 * Load AvailableCardsLocation List from service through data-source
	 */
	loadAvailableCardsListLocation() {
		this.selectionLocation.clear();
		const searchText: string = this.searchInputLocation.nativeElement.value;
		const queryParams = new QueryParamsInventoryModel(
			// searchText,
			// '', // startDate
			// '', // endDate
			// this.sortLocation.direction,
			// this.sortLocation.active,
			// this.paginatorLocation.pageIndex,
			// this.paginatorLocation.pageSize,
			// '[1,2,3,4]', // status
			// '[1]', // type
			// '' // suborganization_id
	  		searchText,
			'1', // type
			'[1]', // status
			this.sortLocation.direction,
			this.sortLocation.active,
			this.paginatorLocation.pageIndex + 1,
			this.paginatorLocation.pageSize
		);
		// Call request from server
		this.store.dispatch(new LocationAvailablesPageRequested({ page: queryParams }));
		this.selectionLocation.clear();
	}

	/**
	 * Load AvailableCardsLocation List from service through data-source
	 */
	loadAvailableCardsListModerator() {
		this.selectionModerator.clear();
		const searchText: string = this.searchInputModerator.nativeElement.value;
		const queryParams = new QueryParamsInventoryModel(
	  		searchText,
			'2', // type
			'[1]', // status
			this.sortLocation.direction,
			this.sortLocation.active,
			this.paginatorLocation.pageIndex + 1,
			this.paginatorLocation.pageSize
		);
		// Call request from server
		this.store.dispatch(new ModeratorAvailablesPageRequested({ page: queryParams }));
		this.selectionModerator.clear();
	}

	/**
	 * Load AvailableCardsSuborganization List from service through data-source
	 */
	loadAvailableCardsListSuborganization() {
		this.selectionSuborganization.clear();
		const searchText: string = this.searchInputSuborganization.nativeElement.value;
		const queryParams = new QueryParamsInventoryModel(
	  		searchText,
			'3', // type
			'[1]', // status
			this.sortLocation.direction,
			this.sortLocation.active,
			this.paginatorLocation.pageIndex + 1,
			this.paginatorLocation.pageSize
		);
		// Call request from server
		this.store.dispatch(new SuborganizationAvailablesPageRequested({ page: queryParams }));
		this.selectionSuborganization.clear();
	}

}
