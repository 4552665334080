<kt-portlet>
  <kt-portlet-body>
    <div class="kt-form kt-margin-b-30">
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-3 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search goldbuy" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> by name
							</mat-hint>
						</mat-form-field>
					</div>
					<div class="col-md-3 kt-margin-bottom-10-mobile">
            <button mat-raised-button color="accent" (click)="tambah()">Tambah
            </button>
					</div>
				</div>
			</div>
		</div>
    <div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="desc"
				matSortDisableClear>
        <ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef>Nama</mat-header-cell>
					<mat-cell *matCellDef="let userinfo">{{userinfo.name}}</mat-cell>
				</ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
          <mat-cell *matCellDef="let userinfo">{{userinfo.email}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="roles">
          <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
          <mat-cell *matCellDef="let userinfo">{{userinfo.roles}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="organization">
          <mat-header-cell *matHeaderCellDef>Organization</mat-header-cell>
          <mat-cell *matCellDef="let userinfo">{{userinfo.organization}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let userinfo">
          	<button mat-raised-button color="accent" (click)="edit(userinfo.hashed_id)" style="margin-right: 10px;">Edit
          	</button>
          	<button mat-raised-button color="warn" (click)="delete(userinfo.hashed_id, userinfo.email, userinfo.roles)">Delete
          	</button>
          </mat-cell>
        </ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>
		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageIndex]="1" [pageSizeOptions]="[10, 25, 50]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
	</kt-portlet-body>
</kt-portlet>