declare var require: any;
import { FormControl } from '@angular/forms';
// Angular
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// Services
import { HomeService } from '../../../core/home';
import { GoldBuyService } from '../../../core/transaction';
import { AuthService } from '../../../core/auth';
// Widgets model
import { LayoutConfigService } from '../../../core/_base/layout';
import { interval, Subscription } from 'rxjs';
// AppState
import { AppState } from '../../../core/reducers';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../core/_base/crud';
import * as Highcharts from 'highcharts';
import { DatePipe } from '@angular/common';

import StockModule from 'highcharts/modules/stock';
import MapModule from 'highcharts/modules/map';
import GanttModule from 'highcharts/modules/gantt';
import ExportingModule from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

import AvocadoTheme from 'highcharts/themes/avocado.src.js';

const mapWorld = require('@highcharts/map-collection/countries/id/id-all.geo.json');

import * as HC_customEvents from 'highcharts-custom-events';

StockModule(Highcharts);
MapModule(Highcharts);
GanttModule(Highcharts);
ExportingModule(Highcharts);
AvocadoTheme(Highcharts);

@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HomeComponent implements OnInit, OnDestroy{
  organizationList: any = [];
  selectedOrg: string = '';
  role: boolean = false;
  summary: any = {};
  chartAge: any = {};
  chartGender: any = {};
  chartMap: any = {};
  updateData: boolean = false;
  event: any;
  Highcharts: typeof Highcharts = Highcharts;
  private subscriptions: Subscription[] = [];

  options = [
    {"id": 0, "name": "All the Time"},
    {"id": 1, "name": "Yearly"},
    {"id": 2, "name": "Monthly"},
    {"id": 3, "name": "Custom by duration"}
  ];
  selected2 = this.options[0].id;

  yearly = false;
  monthly = false;
  customdate = false;

  startDate: any;
  endDate: any;
  dateType: any;

  // Default Date
  date = new Date();
  chosenYearDate: Date = new Date();
  chosenMonthDate: Date = new Date();
  beginDate = this.date.setDate(this.date.getDate() - 7);
  chosenCustomDate = {begin: new Date(this.beginDate), end: new Date()};

  constructor(
    private layoutConfigService: LayoutConfigService,
    public homeService : HomeService,
    public authService : AuthService,
    public goldBuyService: GoldBuyService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.loadUserRole();
    this.loadOrganizationList();
    this.loadTotalData();

    // Gender Chart
    this.chartGender = {
      chart: {
         plotBackgroundColor: null,
         plotBorderWidth: null,
         plotShadow: false,
         type: 'pie'
      },
      title : {
         text: 'Gender'
      },
      credits:{
         enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions : {
         pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
               enabled: true,
               format: '<b>{point.name}</b>: {point.y} | {point.percentage:.1f} %'
            },
            showInLegend: true
         }
      },
      tooltip : {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
      },
      series : [{
        name: 'Total pengguna',
        colorByPoint: true,
      }]
    };

    // Age Chart
    this.chartAge = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Age'
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: {
        enabled: false
      },
      credits:{
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'Persentase pengguna aplikasi'
        }
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.total} | {point.y:.1f}%'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.total}</b>'
      },
      series: [{
        name: 'Total Pengguna',
        colorByPoint: true,
      }]
    };

    // Coverage Chart
    this.chartMap = {
      chart: {
        map: mapWorld
      },
      title: {
        text: 'Coverage'
      },
      credits:{
        enabled: false
      },
      exporting: {
        enabled: false
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          alignTo: 'spacingBox'
        }
      },
      legend: {
        enabled: true
      },
      colorAxis: {
        min: 0
      },
      series: [{
        name: 'Data berdasarkan wilayah',
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        },
        allAreas: false
      } as Highcharts.SeriesMapOptions
      ]
    };
  }

  loadOrganizationList() {
    this.goldBuyService.getAllOrganization()
    .subscribe(
      data => {
        this.organizationList = data;
      }
    )
  }

  loadUserRole() {
    this.authService.getUserByToken()
    .subscribe(
      data => {
        this.role = data.data.roles.includes(17);
        this.cd.markForCheck();
      }
    )
  }

  loadTotalData() {
	this.getDate();
    const queryParams = new QueryParamsModel(
      '',
      this.startDate,
      this.endDate,
      '',
      '',
      1,
      1,
      '[1,2,3,4]',
      '',
      '',
      this.selectedOrg
    );
    this.homeService.getSummary(queryParams)
      .subscribe(
        data => {
          this.summary = data;
          this.chartGender.series[0].data = this.summary.basedOnGender;
          this.chartAge.series[0].data = this.summary.basedOnAge;
          this.chartMap.series[0].data = this.summary.basedOnLocation;
          this.updateData = true;
          this.cd.markForCheck();
        }
      )
  }

  durationType(event: any) {
    if (event.value == 0) {
      this.yearly = false;
      this.monthly = false;
      this.customdate = false;
      this.loadTotalData();
    } else if (event.value == 1) {
      this.yearly = true;
      this.monthly = false;
      this.customdate = false;
      this.loadTotalData();
    } else if (event.value == 2) {
      this.yearly = false;
      this.monthly = true;
      this.customdate = false;
      this.loadTotalData();
    } else if (event.value == 3) {
      this.yearly = false;
      this.monthly = false;
      this.customdate = true;
      this.loadTotalData();
    }
  }

  onDateChange(event: any) {
	this.chosenYearDate = event;
	this.chosenMonthDate = event;
	this.chosenCustomDate.begin = event;
	this.chosenCustomDate.end = event;
    this.loadTotalData();
  }

  getDate(){
    if (this.yearly == true) {
      this.startDate = this.chosenYearDate.getFullYear() + '-01-01';
      this.endDate = this.chosenYearDate.getFullYear() + '-12-31';
    } else if (this.monthly == true) {
      this.startDate = new Date(this.chosenMonthDate.getFullYear(), this.chosenMonthDate.getMonth(), 1);
      this.startDate = this.datePipe.transform(this.startDate,"yyyy-MM-dd");
      this.endDate = new Date(this.chosenMonthDate.getFullYear(), this.chosenMonthDate.getMonth() + 1, 0);
      this.endDate = this.datePipe.transform(this.endDate,"yyyy-MM-dd");
    } else if (this.customdate == true) {
      this.startDate = this.datePipe.transform(this.chosenCustomDate.begin,"yyyy-MM-dd");
      this.endDate = this.datePipe.transform(this.chosenCustomDate.end,"yyyy-MM-dd");
    } else {
      this.startDate = ''
      this.endDate = ''
    }
  }

  onSelectChange(event: any) {
    this.loadTotalData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
