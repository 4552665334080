// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { CoinsState } from '../_reducers/coin.reducers';
import { CoinModel } from '../_models/coin.model';

export const selectCoinsState = createFeatureSelector<CoinsState>('coins');

export const selectCoinById = (coinId: number) => createSelector(
    selectCoinsState,
    coinsState => coinsState.entities[coinId]
);

export const selectCoinsPageLoading = createSelector(
    selectCoinsState,
    coinsState => coinsState.listLoading
);

export const selectCoinsActionLoading = createSelector(
    selectCoinsState,
    coinsState => coinsState.actionsloading
);

export const selectLastCreatedCoinId = createSelector(
    selectCoinsState,
    coinsState => coinsState.lastCreatedCoinId
);

export const selectCoinsShowInitWaitingMessage = createSelector(
    selectCoinsState,
    coinsState => coinsState.showInitWaitingMessage
);

export const selectCoinsInStore = createSelector(
    selectCoinsState,
    coinsState => {
        const items: CoinModel[] = [];
        each(coinsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CoinModel[] = httpExtension.sortArray(items, coinsState.lastQuery.sortField, coinsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, coinsState.totalCount, '');
    }
);
