// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ActivityActions, ActivityActionTypes } from '../_actions/activity.actions';
// Models
import { ActivityModel } from '../_models/activity.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface ActivitysState extends EntityState<ActivityModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedActivityId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ActivityModel> = createEntityAdapter<ActivityModel>();

export const initialActivitysState: ActivitysState = adapter.getInitialState({
    activityForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedActivityId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function activitysReducer(state = initialActivitysState, action: ActivityActions): ActivitysState {
    switch  (action.type) {
        case ActivityActionTypes.ActivitysPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedActivityId: undefined
            };
        }
        case ActivityActionTypes.ActivityActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ActivityActionTypes.ActivityOnServerCreated: return {
            ...state
        };
        // case ActivityActionTypes.ActivityCreated: return adapter.addOne(action.payload.activity, {
        //     ...state, lastCreatedActivityId: action.payload.activity.id
        // });
        case ActivityActionTypes.ActivityUpdated: return adapter.updateOne(action.payload.partialActivity, state);
       //  case ActivityActionTypes.ActivitysStatusUpdated: {
       //      const _partialActivitys: Update<ActivityModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.activitys.length; i++) {
       //          _partialActivitys.push({
				   //  id: action.payload.activitys[i].id,
				   //  changes: {
       //                  status: action.payload.status
       //              }
			    // });
       //      }
       //      return adapter.updateMany(_partialActivitys, state);
       //  }
        case ActivityActionTypes.OneActivityDeleted: return adapter.removeOne(action.payload.id, state);
        case ActivityActionTypes.ManyActivitysDeleted: return adapter.removeMany(action.payload.ids, state);
        case ActivityActionTypes.ActivitysPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case ActivityActionTypes.ActivitysPageLoaded: {
            return adapter.addMany(action.payload.activitys, {
                ...initialActivitysState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getActivityState = createFeatureSelector<ActivityModel>('activitys');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
