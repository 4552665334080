// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { AllUserModel } from '../_models/alluser.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class AllUserService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new alluser to the server
  createAllUser(alluser: AllUserModel): Observable<AllUserModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<AllUserModel>(BASE_URL, alluser, { headers: httpHeaders});
  }

  // READ
  getAllAllUsers(): Observable<AllUserModel[]> {
    return this.http.get<AllUserModel[]>(BASE_URL);
  }

  getAllUserById(alluserId: number): Observable<AllUserModel> {
	const httpHeaders = this.httpUtils.getHTTPHeaders();
	return this.http
		.get<AllUserModel>(BASE_URL + `b2b/user/${alluserId}`, {headers: httpHeaders})
		.pipe(map(data => data['data']));
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  downloadUsers(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/user';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findAllUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/user';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the alluser on the server
  updateAllUser(alluser: AllUserModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, alluser, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForAllUser(allusers: AllUserModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      allusersForUpdate: allusers,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the alluser from the server
  deleteAllUser(alluserId: number): Observable<AllUserModel> {
    const url = `${BASE_URL}/${alluserId}`;
    return this.http.delete<AllUserModel>(url);
  }

  deleteAllUsers(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteAllUsers';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { alluserIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
