// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { AccessComponent } from './access.component';
// MultiDatepickerModule
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../core/_base/layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTabsModule,
  MatNativeDateModule,
  MatCardModule,
  MatRadioModule,
  MatIconModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatSnackBarModule,
  MatTooltipModule,
  MatFormFieldModule
} from '@angular/material';

@NgModule({
	imports: [
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
	MatTableModule,
	MatSlideToggleModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
		CommonModule,
		PartialsModule,
		CoreModule,
    SatDatepickerModule,
    SatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
		RouterModule.forChild([
			{
				path: '',
				component: AccessComponent
			},
		]),
	],
	providers: [
    DatePipe
  ],
	declarations: [
		AccessComponent,
	]
})
export class AccessModule {
}
