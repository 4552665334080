import { BaseModel } from '../../_base/crud';

export class GoldZakatModel  extends BaseModel {
  id: string;
  user: string;
  amount: string;
  gold_amount: string;
  type: string;
  created_at: string;
  
  clear() {
    this.id = undefined;
    this.user = undefined;
    this.amount = undefined;
    this.gold_amount = undefined;
    this.type = undefined;
    this.created_at = undefined;
  }
}
