import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { PwnuUserService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    PwnuUserActionTypes,
    PwnuUsersPageRequested,
    PwnuUsersPageLoaded,
    ManyPwnuUsersDeleted,
    OnePwnuUserDeleted,
    PwnuUserActionToggleLoading,
    PwnuUsersPageToggleLoading,
    PwnuUserUpdated,
    PwnuUsersStatusUpdated,
    PwnuUserCreated,
    PwnuUserOnServerCreated
} from '../_actions/pwnuuser.actions';
import { of } from 'rxjs';

@Injectable()
export class PwnuUserEffects {
    showPageLoadingDistpatcher = new PwnuUsersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new PwnuUserActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new PwnuUserActionToggleLoading({ isLoading: false });

    @Effect()
    loadPwnuUsersPage$ = this.actions$.pipe(
        ofType<PwnuUsersPageRequested>(PwnuUserActionTypes.PwnuUsersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.pwnuusersService.findPwnuUsers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new PwnuUsersPageLoaded({
                pwnuusers: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deletePwnuUser$ = this.actions$
        .pipe(
            ofType<OnePwnuUserDeleted>(PwnuUserActionTypes.OnePwnuUserDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.pwnuusersService.deletePwnuUser(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deletePwnuUsers$ = this.actions$
        .pipe(
            ofType<ManyPwnuUsersDeleted>(PwnuUserActionTypes.ManyPwnuUsersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.pwnuusersService.deletePwnuUsers(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updatePwnuUser$ = this.actions$
        .pipe(
            ofType<PwnuUserUpdated>(PwnuUserActionTypes.PwnuUserUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.pwnuusersService.updatePwnuUser(payload.pwnuuser);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updatePwnuUsersStatus$ = this.actions$
        .pipe(
            ofType<PwnuUsersStatusUpdated>(PwnuUserActionTypes.PwnuUsersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.pwnuusersService.updateStatusForPwnuUser(payload.pwnuusers, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createPwnuUser$ = this.actions$
        .pipe(
            ofType<PwnuUserOnServerCreated>(PwnuUserActionTypes.PwnuUserOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.pwnuusersService.createPwnuUser(payload.pwnuuser).pipe(
                    tap(res => {
                        this.store.dispatch(new PwnuUserCreated({ pwnuuser: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private pwnuusersService: PwnuUserService, private store: Store<AppState>) { }
}
