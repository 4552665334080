// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { LocationAvailableModel } from '../_models/locationavailable.model';

export enum LocationAvailableActionTypes {
    LocationAvailableOnServerCreated = '[Edit LocationAvailable Dialog] LocationAvailable On Server Created',
    LocationAvailableCreated = '[Edit LocationAvailable Dialog] LocationAvailable Created',
    LocationAvailableUpdated = '[Edit LocationAvailable Dialog] LocationAvailable Updated',
    LocationAvailablesStatusUpdated = '[LocationAvailable List Page] LocationAvailables Status Updated',
    OneLocationAvailableDeleted = '[LocationAvailables List Page] One LocationAvailable Deleted',
    ManyLocationAvailablesDeleted = '[LocationAvailables List Page] Many LocationAvailable Deleted',
    LocationAvailablesPageRequested = '[LocationAvailables List Page] LocationAvailables Page Requested',
    LocationAvailablesPageLoaded = '[LocationAvailables API] LocationAvailables Page Loaded',
    LocationAvailablesPageCancelled = '[LocationAvailables API] LocationAvailables Page Cancelled',
    LocationAvailablesPageToggleLoading = '[LocationAvailables] LocationAvailables Page Toggle Loading',
    LocationAvailableActionToggleLoading = '[LocationAvailables] LocationAvailables Action Toggle Loading'
}

export class LocationAvailableOnServerCreated implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailableOnServerCreated;
    constructor(public payload: { locationavailable: LocationAvailableModel }) { }
}

export class LocationAvailableCreated implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailableCreated;
    constructor(public payload: { locationavailable: LocationAvailableModel }) { }
}

export class LocationAvailableUpdated implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailableUpdated;
    constructor(public payload: {
        partialLocationAvailable: Update<LocationAvailableModel>, // For State update
        locationavailable: LocationAvailableModel // For Server update (through service)
    }) { }
}

export class LocationAvailablesStatusUpdated implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailablesStatusUpdated;
    constructor(public payload: {
        locationavailables: LocationAvailableModel[],
        status: number
    }) { }
}

export class OneLocationAvailableDeleted implements Action {
    readonly type = LocationAvailableActionTypes.OneLocationAvailableDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyLocationAvailablesDeleted implements Action {
    readonly type = LocationAvailableActionTypes.ManyLocationAvailablesDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class LocationAvailablesPageRequested implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailablesPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class LocationAvailablesPageLoaded implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailablesPageLoaded;
    constructor(public payload: { locationavailables: LocationAvailableModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class LocationAvailablesPageCancelled implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailablesPageCancelled;
}

export class LocationAvailablesPageToggleLoading implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailablesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class LocationAvailableActionToggleLoading implements Action {
    readonly type = LocationAvailableActionTypes.LocationAvailableActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type LocationAvailableActions = LocationAvailableOnServerCreated
| LocationAvailableCreated
| LocationAvailableUpdated
| LocationAvailablesStatusUpdated
| OneLocationAvailableDeleted
| ManyLocationAvailablesDeleted
| LocationAvailablesPageRequested
| LocationAvailablesPageLoaded
| LocationAvailablesPageCancelled
| LocationAvailablesPageToggleLoading
| LocationAvailableActionToggleLoading;
