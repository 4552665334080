import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { AllUserCoinService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    AllUserCoinActionTypes,
    AllUserCoinsPageRequested,
    AllUserCoinsPageLoaded,
    ManyAllUserCoinsDeleted,
    OneAllUserCoinDeleted,
    AllUserCoinActionToggleLoading,
    AllUserCoinsPageToggleLoading,
    AllUserCoinUpdated,
    AllUserCoinsStatusUpdated,
    AllUserCoinCreated,
    AllUserCoinOnServerCreated
} from '../_actions/allusercoin.actions';
import { of } from 'rxjs';

@Injectable()
export class AllUserCoinEffects {
    showPageLoadingDistpatcher = new AllUserCoinsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new AllUserCoinActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AllUserCoinActionToggleLoading({ isLoading: false });

    @Effect()
    loadAllUserCoinsPage$ = this.actions$.pipe(
        ofType<AllUserCoinsPageRequested>(AllUserCoinActionTypes.AllUserCoinsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.allusercoinsService.findAllUserCoins(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new AllUserCoinsPageLoaded({
                allusercoins: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteAllUserCoin$ = this.actions$
        .pipe(
            ofType<OneAllUserCoinDeleted>(AllUserCoinActionTypes.OneAllUserCoinDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.allusercoinsService.deleteAllUserCoin(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAllUserCoins$ = this.actions$
        .pipe(
            ofType<ManyAllUserCoinsDeleted>(AllUserCoinActionTypes.ManyAllUserCoinsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.allusercoinsService.deleteAllUserCoins(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAllUserCoin$ = this.actions$
        .pipe(
            ofType<AllUserCoinUpdated>(AllUserCoinActionTypes.AllUserCoinUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusercoinsService.updateAllUserCoin(payload.allusercoin);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateAllUserCoinsStatus$ = this.actions$
        .pipe(
            ofType<AllUserCoinsStatusUpdated>(AllUserCoinActionTypes.AllUserCoinsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusercoinsService.updateStatusForAllUserCoin(payload.allusercoins, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createAllUserCoin$ = this.actions$
        .pipe(
            ofType<AllUserCoinOnServerCreated>(AllUserCoinActionTypes.AllUserCoinOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusercoinsService.createAllUserCoin(payload.allusercoin).pipe(
                    tap(res => {
                        this.store.dispatch(new AllUserCoinCreated({ allusercoin: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private allusercoinsService: AllUserCoinService, private store: Store<AppState>) { }
}
