// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { HomeModel } from '../_models/home.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class HomeService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  getSummary(queryParams: QueryParamsModel) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/summary';    
    return this.http
      .get<any[]>(url,{
        headers:httpHeaders,
        params:  httpParams
      })
      .pipe(map(data => data['data']));
  }
}
