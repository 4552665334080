// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authToken:'',
  baseUrl: 'https://service.treasury.id/api/v1/',
  client_id: '10937',
  client_secret: 'uP5kmED3O7IxWv7ZWqlAVxIgvLE2Vzt4x0LKSeC6',
  grant_type: 'password',
  scope: '*'
};
// export const environment = {
// 	production: false,
// 	isMockEnabled: true, // You have to switch this, when your real back-end is done
// 	authToken:'',
// 	baseUrl: 'http://treasury-backend.pro/api/v1/',
// 	client_id: '10937',
// 	client_secret: 'uP5kmED3O7IxWv7ZWqlAVxIgvLE2Vzt4x0LKSeC6',
// 	grant_type: 'password',
// 	scope: '*'
//   };
// export const environment = {
// 	production: true,
// 	isMockEnabled: true, // You have to switch this, when your real back-end is done
// 	authToken:'',
// 	baseUrl: 'https://www.treasury.id/api/v1/',
// 	client_id: '5',
// 	client_secret: '6ggXNKR2n1xihfGgoeB4goFXrn8mtquPSsfDmWDE7',
// 	grant_type: 'password',
// 	scope: '*',
//   };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
