// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AllUserBuyModel } from '../_models/alluserbuy.model';

export enum AllUserBuyActionTypes {
    AllUserBuyOnServerCreated = '[Edit AllUserBuy Dialog] AllUserBuy On Server Created',
    AllUserBuyCreated = '[Edit AllUserBuy Dialog] AllUserBuy Created',
    AllUserBuyUpdated = '[Edit AllUserBuy Dialog] AllUserBuy Updated',
    AllUserBuysStatusUpdated = '[AllUserBuy List Page] AllUserBuys Status Updated',
    OneAllUserBuyDeleted = '[AllUserBuys List Page] One AllUserBuy Deleted',
    ManyAllUserBuysDeleted = '[AllUserBuys List Page] Many AllUserBuy Deleted',
    AllUserBuysPageRequested = '[AllUserBuys List Page] AllUserBuys Page Requested',
    AllUserBuysPageLoaded = '[AllUserBuys API] AllUserBuys Page Loaded',
    AllUserBuysPageCancelled = '[AllUserBuys API] AllUserBuys Page Cancelled',
    AllUserBuysPageToggleLoading = '[AllUserBuys] AllUserBuys Page Toggle Loading',
    AllUserBuyActionToggleLoading = '[AllUserBuys] AllUserBuys Action Toggle Loading'
}

export class AllUserBuyOnServerCreated implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuyOnServerCreated;
    constructor(public payload: { alluserbuy: AllUserBuyModel }) { }
}

export class AllUserBuyCreated implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuyCreated;
    constructor(public payload: { alluserbuy: AllUserBuyModel }) { }
}

export class AllUserBuyUpdated implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuyUpdated;
    constructor(public payload: {
        partialAllUserBuy: Update<AllUserBuyModel>, // For State update
        alluserbuy: AllUserBuyModel // For Server update (through service)
    }) { }
}

export class AllUserBuysStatusUpdated implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuysStatusUpdated;
    constructor(public payload: {
        alluserbuys: AllUserBuyModel[],
        status: number
    }) { }
}

export class OneAllUserBuyDeleted implements Action {
    readonly type = AllUserBuyActionTypes.OneAllUserBuyDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAllUserBuysDeleted implements Action {
    readonly type = AllUserBuyActionTypes.ManyAllUserBuysDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AllUserBuysPageRequested implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuysPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class AllUserBuysPageLoaded implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuysPageLoaded;
    constructor(public payload: { alluserbuys: AllUserBuyModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class AllUserBuysPageCancelled implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuysPageCancelled;
}

export class AllUserBuysPageToggleLoading implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuysPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllUserBuyActionToggleLoading implements Action {
    readonly type = AllUserBuyActionTypes.AllUserBuyActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AllUserBuyActions = AllUserBuyOnServerCreated
| AllUserBuyCreated
| AllUserBuyUpdated
| AllUserBuysStatusUpdated
| OneAllUserBuyDeleted
| ManyAllUserBuysDeleted
| AllUserBuysPageRequested
| AllUserBuysPageLoaded
| AllUserBuysPageCancelled
| AllUserBuysPageToggleLoading
| AllUserBuyActionToggleLoading;
