// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { ActivityModel } from '../_models/activity.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class ActivityService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new activity to the server
	createActivity(activity: ActivityModel): Observable<ActivityModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<ActivityModel>(BASE_URL, activity, { headers: httpHeaders});
	}

	// READ
	getAllActivitys(): Observable<ActivityModel[]> {
		return this.http.get<ActivityModel[]>(BASE_URL);
	}

	getActivityById(activityId: number): Observable<ActivityModel> {
		return this.http.get<ActivityModel>(BASE_URL + `/${activityId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findActivitys(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'b2b/activity';
		
    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	// UPDATE => PUT: update the activity on the server
	updateActivity(activity: ActivityModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(BASE_URL, activity, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForActivity(activitys: ActivityModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			activitysForUpdate: activitys,
			newStatus: status
		};
		const url = BASE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the activity from the server
	deleteActivity(activityId: number): Observable<ActivityModel> {
		const url = `${BASE_URL}/${activityId}`;
		return this.http.delete<ActivityModel>(url);
	}

	deleteActivitys(ids: number[] = []): Observable<any> {
		const url = BASE_URL + '/deleteActivitys';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { activityIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}

  downloadActivitys(queryParams: QueryParamsInventoryModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/activity';
    
    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }
}
