<style type="text/css" media="screen">
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }
  li ol > li {
    margin: 0;
  }
  li ol > li:before {
    content: counters(item, ".") " ";
  }
  @media (min-width: 991.98px){
    .row{
      line-height: 2.5rem;
      font-size: 1.2rem;
      margin: 5rem 5rem 5rem 5rem;
    }
  }
  @media (max-width: 991.98px) {
    .row{
      margin: 0.2rem 0.2rem 0.2rem 0.2rem;
    }
  }
  li {
    text-align: justify;
  }
  p {
    font-weight: bold;
  }
</style>

<div class="row" *ngFor="let a of terms">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
        <div class="row" [innerHtml]="a.tnc">
        </div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>