import { BaseModel } from '../../_base/crud';

export class UserDetailModel  extends BaseModel {
	hashed_id: string;
  name: string;
  email: string;
  address: string;
  photo: string;
  birthday: string;
  gender: string;
  
	clear() {
		this.hashed_id = '';
    this.name = '';
    this.email = '';
    this.address = '';
    this.photo = '';
    this.birthday = '';
    this.gender = '';
	}
}
