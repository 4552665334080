// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ActiveUsersState } from '../_reducers/activeuser.reducers';
import { ActiveUserModel } from '../_models/activeuser.model';

export const selectActiveUsersState = createFeatureSelector<ActiveUsersState>('activeusers');

export const selectActiveUserById = (activeuserId: number) => createSelector(
    selectActiveUsersState,
    activeusersState => activeusersState.entities[activeuserId]
);

export const selectActiveUsersPageLoading = createSelector(
    selectActiveUsersState,
    activeusersState => activeusersState.listLoading
);

export const selectActiveUsersActionLoading = createSelector(
    selectActiveUsersState,
    activeusersState => activeusersState.actionsloading
);

export const selectLastCreatedActiveUserId = createSelector(
    selectActiveUsersState,
    activeusersState => activeusersState.lastCreatedActiveUserId
);

export const selectActiveUsersShowInitWaitingMessage = createSelector(
    selectActiveUsersState,
    activeusersState => activeusersState.showInitWaitingMessage
);

export const selectActiveUsersInStore = createSelector(
    selectActiveUsersState,
    activeusersState => {
        const items: ActiveUserModel[] = [];
        each(activeusersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ActiveUserModel[] = httpExtension.sortArray(items, activeusersState.lastQuery.sortField, activeusersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, activeusersState.totalCount, '');
    }
);
