// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { AllUserSellModel } from '../_models/allusersell.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class AllUserSellService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new alluser to the server
  createAllUserSell(allusersell: AllUserSellModel): Observable<AllUserSellModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<AllUserSellModel>(BASE_URL, allusersell, { headers: httpHeaders});
  }

  // READ
  getAllAllUserSells(): Observable<AllUserSellModel[]> {
    return this.http.get<AllUserSellModel[]>(BASE_URL);
  }

  getAllUserSellById(allusersellId: number): Observable<AllUserSellModel> {
	const httpHeaders = this.httpUtils.getHTTPHeaders();
	return this.http
		.get<AllUserSellModel>(BASE_URL + `b2b/user/${allusersellId}`, {headers: httpHeaders})
		.pipe(map(data => data['data']));
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  downloadUsers(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/user';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findAllUserSells(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
	// Note: Add headers if needed (tokens/bearer)
	console.log(queryParams);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/gold-sell';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the alluser on the server
  updateAllUserSell(allusersell: AllUserSellModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, allusersell, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForAllUserSell(allusersells: AllUserSellModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      allusersForUpdate: allusersells,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the alluser from the server
  deleteAllUserSell(allusersellId: number): Observable<AllUserSellModel> {
    const url = `${BASE_URL}/${allusersellId}`;
    return this.http.delete<AllUserSellModel>(url);
  }

  deleteAllUserSells(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteAllUserSells';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { allusersellIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
