// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ModeratorAvailableActions, ModeratorAvailableActionTypes } from '../_actions/moderatoravailable.actions';
// Models
import { ModeratorAvailableModel } from '../_models/moderatoravailable.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface ModeratorAvailablesState extends EntityState<ModeratorAvailableModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedModeratorAvailableId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ModeratorAvailableModel> = createEntityAdapter<ModeratorAvailableModel>();

export const initialModeratorAvailablesState: ModeratorAvailablesState = adapter.getInitialState({
    moderatoravailableForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedModeratorAvailableId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function moderatoravailablesReducer(state = initialModeratorAvailablesState, action: ModeratorAvailableActions): ModeratorAvailablesState {
    switch  (action.type) {
        case ModeratorAvailableActionTypes.ModeratorAvailablesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedModeratorAvailableId: undefined
            };
        }
        case ModeratorAvailableActionTypes.ModeratorAvailableActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ModeratorAvailableActionTypes.ModeratorAvailableOnServerCreated: return {
            ...state
        };
        case ModeratorAvailableActionTypes.ModeratorAvailableCreated: return adapter.addOne(action.payload.moderatoravailable, {
            ...state, lastCreatedModeratorAvailableId: action.payload.moderatoravailable.id
        });
        case ModeratorAvailableActionTypes.ModeratorAvailableUpdated: return adapter.updateOne(action.payload.partialModeratorAvailable, state);
       //  case ModeratorAvailableActionTypes.ModeratorAvailablesStatusUpdated: {
       //      const _partialModeratorAvailables: Update<ModeratorAvailableModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.moderatoravailables.length; i++) {
       //          _partialModeratorAvailables.push({
           //  id: action.payload.moderatoravailables[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialModeratorAvailables, state);
       //  }
        case ModeratorAvailableActionTypes.OneModeratorAvailableDeleted: return adapter.removeOne(action.payload.id, state);
        case ModeratorAvailableActionTypes.ManyModeratorAvailablesDeleted: return adapter.removeMany(action.payload.ids, state);
        case ModeratorAvailableActionTypes.ModeratorAvailablesPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case ModeratorAvailableActionTypes.ModeratorAvailablesPageLoaded: {
            return adapter.addMany(action.payload.moderatoravailables, {
                ...initialModeratorAvailablesState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getModeratorAvailableState = createFeatureSelector<ModeratorAvailableModel>('moderatoravailables');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
