<kt-portlet>
	<kt-portlet-body>
		<mat-tab-group>
		  <mat-tab label="Perbaharui Account">
			<form class="kt-login__form kt-form" [formGroup]="profileForm" autocomplete="off">
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<mat-label>Nama Lengkap</mat-label>
						<input matInput type="text" placeholder="Nama Lengkap" formControlName="fullname"/>
						<mat-error *ngIf="isControlProfileHasError('fullname','required')">
							<strong>Nama Lengkap tidak boleh kosong</strong>
						</mat-error>
						<mat-error *ngIf="isControlProfileHasError('fullname','minlength')">
							<strong>Nama Lengkap minimal 3 karakter</strong>
						</mat-error>
						<mat-error *ngIf="isControlProfileHasError('fullname','maxlength')">
							<strong>Nama Lengkap maksimal 100 karakter</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<mat-label>Email</mat-label>
						<input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off" />
						<mat-error *ngIf="isControlProfileHasError('email','required')">
							<strong>Email tidak boleh kosong</strong>
						</mat-error>
						<mat-error *ngIf="isControlProfileHasError('email','email')">
							<strong>Format Email salah</strong>
						</mat-error>
						<mat-error *ngIf="isControlProfileHasError('email','minlength')">
							<strong>Email minimal 3 karakter</strong>
						</mat-error>
						<mat-error *ngIf="isControlProfileHasError('email','maxlength')">
							<strong>Email maksimal 320 karakter</strong>
						</mat-error>
					</mat-form-field>
				</div>

				<!--begin::Action-->
				<div class="kt-login__actions">
					<button (click)="submitProfile()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">Simpan</button>
				</div>
				<!--end::Action-->
			</form>
		  </mat-tab>
		  <mat-tab label="Ganti Password">
			<form class="kt-login__form kt-form" [formGroup]="passwordForm" autocomplete="off">
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<mat-label>Password lama</mat-label>
						<input matInput type="password" placeholder="Password Lama" formControlName="passwordOld" autocomplete="off"/>
						<mat-error *ngIf="isControlPasswordHasError('passwordOld','required')">
							<strong>Password lama tidak boleh kosong</strong>
						</mat-error>
						<mat-error *ngIf="isControlPasswordHasError('passwordOld','minlength')">
							<strong>Password lama minimal 3 karakter</strong>
						</mat-error>
						<mat-error *ngIf="isControlPasswordHasError('passwordOld','maxlength')">
							<strong>Password lama maksimal 100 karakter</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<mat-label>Password Baru</mat-label>
						<input matInput type="password" placeholder="Password Baru" formControlName="password" autocomplete="off"/>
						<mat-error *ngIf="isControlPasswordHasError('password','required')">
							<strong>Password Baru tidak boleh kosong</strong>
						</mat-error>
						<mat-error *ngIf="isControlPasswordHasError('password','minlength')">
							<strong>Password Baru minimal 3 karakter</strong>
						</mat-error>
						<mat-error *ngIf="isControlPasswordHasError('password','maxlength')">
							<strong>Password Baru maksimal 100 karakter</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<mat-label>Konfirmasi Password Baru</mat-label>
						<input matInput type="password" placeholder="Konfirmasi Password Baru" formControlName="confirmPassword" autocomplete="off"/>
						<mat-error *ngIf="isControlPasswordHasError('confirmPassword','required')">
							<strong>Konfirmasi Password Baru tidak boleh kosong</strong>
						</mat-error>
						<mat-error *ngIf="isControlPasswordHasError('confirmPassword','minlength')">
							<strong>Konfirmasi Password Baru minimal 3 karakter</strong>
						</mat-error>
						<mat-error *ngIf="isControlPasswordHasError('confirmPassword','maxlength')">
							<strong>Konfirmasi Password Baru maksimal 100 karakter</strong>
						</mat-error>
						<mat-error *ngIf="passwordForm.get('confirmPassword').errors && passwordForm.get('confirmPassword').errors.ConfirmPassword">
							<strong>Passsword Baru and Konfirmasi Password Baru tidak sama</strong>
						</mat-error>
					</mat-form-field>
				</div>

				<!--begin::Action-->
				<div class="kt-login__actions">
					<button (click)="submitPassword()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">Simpan</button>
				</div>
				<!--end::Action-->
			</form>
		  </mat-tab>
		</mat-tab-group>
	</kt-portlet-body>
</kt-portlet>
