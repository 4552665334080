// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ActivatedRoute, Router } from '@angular/router';

// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { AuthService } from '../../../../../core/auth';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  AllUserModel,
  AllUserService,
  AllUsersDataSource,
  AllUsersPageRequested,
  OneAllUserDeleted,
  ManyAllUsersDeleted,
  AllUsersStatusUpdated
} from '../../../../../core/user';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
import { DatePipe } from '@angular/common';
// Components
import { AllUserEditComponent } from '../all-user-edit/all-user-edit.dialog.component';
@Component({
	selector: 'kt-allusers-list',
	templateUrl: './all-user-list.component.html',
	styleUrls: ['all-user-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllUserListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: AllUsersDataSource;
	displayedColumns = ['user.name', 'user.id_member', 'suborganization.referral_code', 'user.email', 'user.phone', 'suborganization.location', 'actions'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};
  	organizationList: any = [];
  	selectedOrg: string = '';
  	role: boolean = false;

	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<AllUserModel>(true, []);
	allusersResult: AllUserModel[] = [];

	spinnerButtonOptions: MatProgressButtonOptions = {
		active: false,
		text: 'Download',
		spinnerSize: 18,
		raised: true,
		stroked: false,
		buttonColor: 'accent',
		spinnerColor: 'accent',
		fullWidth: false,
		disabled: false,
		mode: 'indeterminate',
		buttonIcon: {
			fontIcon: 'cloud_download'
		}
	}

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
   		public authService : AuthService,
		public userService: AllUserService,
		private activatedRoute: ActivatedRoute,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private router: Router,
    	private datePipe: DatePipe
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadAllUsersList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadAllUsersList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init Org List
		this.loadOrganizationList();
		this.loadUserRole();

		// Init DataSource
		this.dataSource = new AllUsersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.allusersResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1),
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadAllUsersList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load AllUsers List from service through data-source
	 */
	loadAllUsersList() {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		const searchText: string = this.searchInput.nativeElement.value;
		const queryParams = new QueryParamsModel(
	        searchText,
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'[0,1,2,3,4]', // status
			'', // type
			'', // suborganization_id
			this.selectedOrg, // organization_id
			''
		);
		// Call request from server
		this.store.dispatch(new AllUsersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Load Organization List
	 */
	loadOrganizationList() {
		this.userService.getAllOrganization()
		.subscribe(
			data => {
				this.organizationList = data;
			}
		)
	}

	/**
	 * Load Role
	 */
	loadUserRole() {
		this.authService.getUserByToken()
		.subscribe(
			data => {
				this.role = data.data.roles.includes(17);
			}
		)
	}

	editAllUser(hashed_id) {
    if (hashed_id == null) {
      const dialogRef = this.dialog.open(AllUserEditComponent, { data: null });
    }else{
  		this.userService.getDetailUser(hashed_id)
  		.subscribe(
  			data => {
  				const dialogRef = this.dialog.open(AllUserEditComponent, { data: { data } });
  			}
  		)
    }
	}

  onSelectChange(event: any) {
    this.paginator.pageIndex = 0;
    this.loadAllUsersList();
  }

  onDateChange(event: any) {
    this.paginator.pageIndex = 0;
  	if(event.target.value){
    	this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
  	}else{
    	this.dateRange = {begin: null, end: null};
  	}
    this.loadAllUsersList();
  }

  /**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
		this.router.navigate(['./all-user-detail', id], { relativeTo: this.activatedRoute });
	}

	historyBuy(id) {
		this.router.navigate(['./all-user-history-buy', id], { relativeTo: this.activatedRoute });
	}

	historyCoin(id) {
		this.router.navigate(['./all-user-history-coin', id], { relativeTo: this.activatedRoute });
	}

	historySell(id) {
		this.router.navigate(['./all-user-history-sell', id], { relativeTo: this.activatedRoute });
	}

  download(){
    this.spinnerButtonOptions.active = true;
    const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
    const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
    const searchText: string = this.searchInput.nativeElement.value;
    const queryParams = new QueryParamsModel(
          searchText,
          begin,
          end,
		  this.sort.direction,
		  this.sort.active,
		  this.paginator.pageIndex,
		  this.paginator.pageSize,
		  '[0,1,2,3,4]', // stasus
		  '', // type
		  '', // suborganization_id
		  this.selectedOrg // organization_id
    );

    this.userService.downloadUsers(queryParams)
    .subscribe(
      data => {
        this.spinnerButtonOptions.active = false;
        window.location.href = data;
        }
      )
  }
}
