// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AllUserCoinModel } from '../_models/allusercoin.model';

export enum AllUserCoinActionTypes {
    AllUserCoinOnServerCreated = '[Edit AllUserCoin Dialog] AllUserCoin On Server Created',
    AllUserCoinCreated = '[Edit AllUserCoin Dialog] AllUserCoin Created',
    AllUserCoinUpdated = '[Edit AllUserCoin Dialog] AllUserCoin Updated',
    AllUserCoinsStatusUpdated = '[AllUserCoin List Page] AllUserCoins Status Updated',
    OneAllUserCoinDeleted = '[AllUserCoins List Page] One AllUserCoin Deleted',
    ManyAllUserCoinsDeleted = '[AllUserCoins List Page] Many AllUserCoin Deleted',
    AllUserCoinsPageRequested = '[AllUserCoins List Page] AllUserCoins Page Requested',
    AllUserCoinsPageLoaded = '[AllUserCoins API] AllUserCoins Page Loaded',
    AllUserCoinsPageCancelled = '[AllUserCoins API] AllUserCoins Page Cancelled',
    AllUserCoinsPageToggleLoading = '[AllUserCoins] AllUserCoins Page Toggle Loading',
    AllUserCoinActionToggleLoading = '[AllUserCoins] AllUserCoins Action Toggle Loading'
}

export class AllUserCoinOnServerCreated implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinOnServerCreated;
    constructor(public payload: { allusercoin: AllUserCoinModel }) { }
}

export class AllUserCoinCreated implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinCreated;
    constructor(public payload: { allusercoin: AllUserCoinModel }) { }
}

export class AllUserCoinUpdated implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinUpdated;
    constructor(public payload: {
        partialAllUserCoin: Update<AllUserCoinModel>, // For State update
        allusercoin: AllUserCoinModel // For Server update (through service)
    }) { }
}

export class AllUserCoinsStatusUpdated implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinsStatusUpdated;
    constructor(public payload: {
        allusercoins: AllUserCoinModel[],
        status: number
    }) { }
}

export class OneAllUserCoinDeleted implements Action {
    readonly type = AllUserCoinActionTypes.OneAllUserCoinDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAllUserCoinsDeleted implements Action {
    readonly type = AllUserCoinActionTypes.ManyAllUserCoinsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AllUserCoinsPageRequested implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class AllUserCoinsPageLoaded implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinsPageLoaded;
    constructor(public payload: { allusercoins: AllUserCoinModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class AllUserCoinsPageCancelled implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinsPageCancelled;
}

export class AllUserCoinsPageToggleLoading implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AllUserCoinActionToggleLoading implements Action {
    readonly type = AllUserCoinActionTypes.AllUserCoinActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AllUserCoinActions = AllUserCoinOnServerCreated
| AllUserCoinCreated
| AllUserCoinUpdated
| AllUserCoinsStatusUpdated
| OneAllUserCoinDeleted
| ManyAllUserCoinsDeleted
| AllUserCoinsPageRequested
| AllUserCoinsPageLoaded
| AllUserCoinsPageCancelled
| AllUserCoinsPageToggleLoading
| AllUserCoinActionToggleLoading;
