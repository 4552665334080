// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { UserModel } from '../_models/user.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class UserService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new user to the server
	createUser(user: UserModel): Observable<UserModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<UserModel>(BASE_URL, user, { headers: httpHeaders});
	}

	// READ
	getAllUsers(): Observable<UserModel[]> {
		return this.http.get<UserModel[]>(BASE_URL);
	}

	getUserById(userId: number): Observable<UserModel> {
		return this.http.get<UserModel>(BASE_URL + `/${userId}`);
	}

	getDetailUser(hashed_id: string) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const url = BASE_URL + 'b2b/user/'+hashed_id;
		return this.http
		  .get<any[]>(url,{headers:httpHeaders})
		  .pipe(map(data => data['data']));
	}

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

	getAllSuborganization(type: string) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const url = BASE_URL + 'b2b/suborganization/?page=1&limit=100&order=asc&sort=&filter&type='+type;
		return this.http
		  .get<any[]>(url,{headers:httpHeaders})
		  .pipe(map(data => data['data']));
	}

	getAllProvince() {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const url = BASE_URL + 'b2b/province';
		return this.http
		  .get<any[]>(url,{headers:httpHeaders})
		  .pipe(map(data => data['data']));
	}

	getAllShipment(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'b2b/shipment';

    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	getShipmentList() {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const url = BASE_URL + 'b2b/shipment/?page=1&limit=200&order=&sort&start_date=&end_date=&filter&status=[2]';
		return this.http
		  .get<any[]>(url,{headers:httpHeaders})
		  .pipe(map(data => data['data']));
	}

	requestShipment(id_member) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
      	httpHeaders.set('Content-Type', 'application/json');
		return this.http
		  .post<QueryResultsModel>(BASE_URL + 'b2b/shipment/request', id_member, { headers: httpHeaders})
		  .pipe(map(data => data['data']));
	}

	printShipment(id_member) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
      	httpHeaders.set('Content-Type', 'application/json');
		return this.http
		  .post<QueryResultsModel>(BASE_URL + 'b2b/shipment/print', id_member, { headers: httpHeaders})
		  .pipe(map(data => data['data']));
	}

	sendShipment(data) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
      	httpHeaders.set('Content-Type', 'application/json');
		return this.http
		  .post<QueryResultsModel>(BASE_URL + 'b2b/shipment/send', data, { headers: httpHeaders})
		  .pipe(map(data => data['data']));
	}

	receivedShipment(data) {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
      	httpHeaders.set('Content-Type', 'application/json');
		return this.http
		  .post<QueryResultsModel>(BASE_URL + 'b2b/shipment/received', data, { headers: httpHeaders})
		  .pipe(map(data => data['data']));
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findShipments(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'b2b/shipment';

    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

  downloadUsers(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/user';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  averageCheck(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/average';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
		const url = BASE_URL + 'b2b/user';

    return this.http.get<QueryResultsModel>(url, {
			headers: httpHeaders,
			params:  httpParams
		});
	}

	// UPDATE => PUT: update the user on the server
	updateUser(user: UserModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(BASE_URL, user, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForUser(users: UserModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			usersForUpdate: users,
			newStatus: status
		};
		const url = BASE_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the user from the server
	deleteUser(userId: number): Observable<UserModel> {
		const url = `${BASE_URL}/${userId}`;
		return this.http.delete<UserModel>(url);
	}

	deleteUsers(ids: number[] = []): Observable<any> {
		const url = BASE_URL + '/deleteUsers';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { userIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}

  downloadHistory(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/shipment';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }
}
