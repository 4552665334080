// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AllUserSellActions, AllUserSellActionTypes } from '../_actions/allusersell.actions';
// Models
import { AllUserSellModel } from '../_models/allusersell.model';
import { QueryParamsModel } from '../../_base/crud';

export interface AllUserSellsState extends EntityState<AllUserSellModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedAllUserSellId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AllUserSellModel> = createEntityAdapter<AllUserSellModel>();

export const initialAllUserSellsState: AllUserSellsState = adapter.getInitialState({
    allusersellForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedAllUserSellId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function allusersellsReducer(state = initialAllUserSellsState, action: AllUserSellActions): AllUserSellsState {
    switch  (action.type) {
        case AllUserSellActionTypes.AllUserSellsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAllUserSellId: undefined
            };
        }
        case AllUserSellActionTypes.AllUserSellActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case AllUserSellActionTypes.AllUserSellOnServerCreated: return {
            ...state
        };
        // case AllUserActionTypes.AllUserCreated: return adapter.addOne(action.payload.alluser, {
        //     ...state, lastCreatedAllUserId: action.payload.alluser.id
        // });
        case AllUserSellActionTypes.AllUserSellUpdated: return adapter.updateOne(action.payload.partialAllUserSell, state);
       //  case AllUserActionTypes.AllUsersStatusUpdated: {
       //      const _partialAllUsers: Update<AllUserSellModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.allusers.length; i++) {
       //          _partialAllUsers.push({
           //  id: action.payload.allusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialAllUsers, state);
       //  }
        case AllUserSellActionTypes.OneAllUserSellDeleted: return adapter.removeOne(action.payload.id, state);
        case AllUserSellActionTypes.ManyAllUserSellsDeleted: return adapter.removeMany(action.payload.ids, state);
        case AllUserSellActionTypes.AllUserSellsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AllUserSellActionTypes.AllUserSellsPageLoaded: {
            return adapter.addMany(action.payload.allusersells, {
                ...initialAllUserSellsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getAllUserSellState = createFeatureSelector<AllUserSellModel>('allusersells');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
