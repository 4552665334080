// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { AllUserBuyModel } from '../_models/alluserbuy.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class AllUserBuyService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new alluser to the server
  createAllUserBuy(alluserbuy: AllUserBuyModel): Observable<AllUserBuyModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<AllUserBuyModel>(BASE_URL, alluserbuy, { headers: httpHeaders});
  }

  // READ
  getAllAllUserBuys(): Observable<AllUserBuyModel[]> {
    return this.http.get<AllUserBuyModel[]>(BASE_URL);
  }

  getAllUserBuyById(alluserbuyId: number): Observable<AllUserBuyModel> {
	const httpHeaders = this.httpUtils.getHTTPHeaders();
	return this.http
		.get<AllUserBuyModel>(BASE_URL + `b2b/user/${alluserbuyId}`, {headers: httpHeaders})
		.pipe(map(data => data['data']));
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  downloadUsers(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/user';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findAllUserBuys(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
	// Note: Add headers if needed (tokens/bearer)
	console.log(queryParams);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/gold-buy';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the alluser on the server
  updateAllUserBuy(alluserbuy: AllUserBuyModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, alluserbuy, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForAllUserBuy(alluserbuys: AllUserBuyModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      allusersForUpdate: alluserbuys,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the alluser from the server
  deleteAllUserBuy(alluserbuyId: number): Observable<AllUserBuyModel> {
    const url = `${BASE_URL}/${alluserbuyId}`;
    return this.http.delete<AllUserBuyModel>(url);
  }

  deleteAllUserBuys(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteAllUserBuys';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { alluserbuyIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
