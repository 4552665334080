import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { CoinService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    CoinActionTypes,
    CoinsPageRequested,
    CoinsPageLoaded,
    ManyCoinsDeleted,
    OneCoinDeleted,
    CoinActionToggleLoading,
    CoinsPageToggleLoading,
    CoinUpdated,
    CoinsStatusUpdated,
    CoinCreated,
    CoinOnServerCreated
} from '../_actions/coin.actions';
import { of } from 'rxjs';

@Injectable()
export class CoinEffects {
    showPageLoadingDistpatcher = new CoinsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new CoinActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new CoinActionToggleLoading({ isLoading: false });

    @Effect()
    loadCoinsPage$ = this.actions$.pipe(
        ofType<CoinsPageRequested>(CoinActionTypes.CoinsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.coinsService.findCoins(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new CoinsPageLoaded({
                coins: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteCoin$ = this.actions$
        .pipe(
            ofType<OneCoinDeleted>(CoinActionTypes.OneCoinDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.coinsService.deleteCoin(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteCoins$ = this.actions$
        .pipe(
            ofType<ManyCoinsDeleted>(CoinActionTypes.ManyCoinsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.coinsService.deleteCoins(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateCoin$ = this.actions$
        .pipe(
            ofType<CoinUpdated>(CoinActionTypes.CoinUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.coinsService.updateCoin(payload.coin);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateCoinsStatus$ = this.actions$
        .pipe(
            ofType<CoinsStatusUpdated>(CoinActionTypes.CoinsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.coinsService.updateStatusForCoin(payload.coins, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createCoin$ = this.actions$
        .pipe(
            ofType<CoinOnServerCreated>(CoinActionTypes.CoinOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.coinsService.createCoin(payload.coin).pipe(
                    tap(res => {
                        this.store.dispatch(new CoinCreated({ coin: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private coinsService: CoinService, private store: Store<AppState>) { }
}
