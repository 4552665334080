// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BigOrderModel } from '../_models/bigorder.model';

export enum BigOrderActionTypes {
    BigOrderOnServerCreated = '[Edit BigOrder Dialog] BigOrder On Server Created',
    BigOrderCreated = '[Edit BigOrder Dialog] BigOrder Created',
    BigOrderUpdated = '[Edit BigOrder Dialog] BigOrder Updated',
    BigOrdersStatusUpdated = '[BigOrder List Page] BigOrders Status Updated',
    OneBigOrderDeleted = '[BigOrders List Page] One BigOrder Deleted',
    ManyBigOrdersDeleted = '[BigOrders List Page] Many BigOrder Deleted',
    BigOrdersPageRequested = '[BigOrders List Page] BigOrders Page Requested',
    BigOrdersPageLoaded = '[BigOrders API] BigOrders Page Loaded',
    BigOrdersPageCancelled = '[BigOrders API] BigOrders Page Cancelled',
    BigOrdersPageToggleLoading = '[BigOrders] BigOrders Page Toggle Loading',
    BigOrderActionToggleLoading = '[BigOrders] BigOrders Action Toggle Loading'
}

export class BigOrderOnServerCreated implements Action {
    readonly type = BigOrderActionTypes.BigOrderOnServerCreated;
    constructor(public payload: { bigorder: BigOrderModel }) { }
}

export class BigOrderCreated implements Action {
    readonly type = BigOrderActionTypes.BigOrderCreated;
    constructor(public payload: { bigorder: BigOrderModel }) { }
}

export class BigOrderUpdated implements Action {
    readonly type = BigOrderActionTypes.BigOrderUpdated;
    constructor(public payload: {
        partialBigOrder: Update<BigOrderModel>, // For State update
        bigorder: BigOrderModel // For Server update (through service)
    }) { }
}

export class BigOrdersStatusUpdated implements Action {
    readonly type = BigOrderActionTypes.BigOrdersStatusUpdated;
    constructor(public payload: {
        bigorders: BigOrderModel[],
        status: number
    }) { }
}

export class OneBigOrderDeleted implements Action {
    readonly type = BigOrderActionTypes.OneBigOrderDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyBigOrdersDeleted implements Action {
    readonly type = BigOrderActionTypes.ManyBigOrdersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class BigOrdersPageRequested implements Action {
    readonly type = BigOrderActionTypes.BigOrdersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BigOrdersPageLoaded implements Action {
    readonly type = BigOrderActionTypes.BigOrdersPageLoaded;
    constructor(public payload: { bigorders: BigOrderModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class BigOrdersPageCancelled implements Action {
    readonly type = BigOrderActionTypes.BigOrdersPageCancelled;
}

export class BigOrdersPageToggleLoading implements Action {
    readonly type = BigOrderActionTypes.BigOrdersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class BigOrderActionToggleLoading implements Action {
    readonly type = BigOrderActionTypes.BigOrderActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type BigOrderActions = BigOrderOnServerCreated
| BigOrderCreated
| BigOrderUpdated
| BigOrdersStatusUpdated
| OneBigOrderDeleted
| ManyBigOrdersDeleted
| BigOrdersPageRequested
| BigOrdersPageLoaded
| BigOrdersPageCancelled
| BigOrdersPageToggleLoading
| BigOrderActionToggleLoading;
