<div class="row">
  <div class="col-xl-12">
    <kt-portlet [class]="'kt-portlet--height-fluid'">
      <kt-portlet-body>
		<h5>Calculator Big Order</h5>
		<div class="col-md-3 kt-margin-bottom-10-mobile">
			<div class="kt-widget12">
				<div class="kt-widget12__content">
					<div class="kt-widget12__item">
						<div class="kt-widget12__info">
							<span class="kt-widget12__desc">Anda memiliki simpanan Cashback</span>
							<span class="kt-widget12__value">{{ profile.discount }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-xl-12">
				<mat-form-field class="mat-form-field-fluid">
					<mat-label class="kt-font-bold">Masukan Nilai Transaksi Big Order</mat-label>
					<input type="text" aria-label="Nilai Transaksi" id="rupiah" [(ngModel)]="amount" matInput required pattern="[0-9]" (input)="onSearchChange($event.target.value)">
				  </mat-form-field>
			</div>
			<div class="row text-right ">
				<div class="col-lg-12">
					<button type="button" mat-raised-button color="accent" (click)="calculateBigOrder($event)">Calculate</button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
					<div class="kt-widget12__content">
						<div class="kt-widget12__item">
							<div class="kt-widget12__info">
								<span class="kt-widget12__desc">Tier</span>
								<span class="kt-widget12__value">{{ tier }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
					<div class="kt-widget12__content">
						<div class="kt-widget12__item">
							<div class="kt-widget12__info">
								<span class="kt-widget12__desc">Basic Reward</span>
								<span class="kt-widget12__value">{{ insentif }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-3 kt-margin-bottom-10-mobile">
				<div class="kt-widget12">
					<div class="kt-widget12__content">
						<div class="kt-widget12__item">
							<div class="kt-widget12__info">
								<span class="kt-widget12__desc">Cashback</span>
								<span class="kt-widget12__value">{{ discount }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </kt-portlet-body>
    </kt-portlet>
  </div>
</div>
