// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  UserModel,
  UserService,
  ShipmentsDataSource,
  UsersPageRequested,
  ShipmentsPageRequested,
  OneUserDeleted,
  ManyUsersDeleted,
  UsersStatusUpdated
} from '../../../../../core/user';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { DatePipe } from '@angular/common';
// Components
import { ReceiveCardEditComponent } from '../receive-card-edit/receive-card-edit.dialog.component';
import { RequestCardConfirmationComponent } from '../../request-card/request-card-confirmation/request-card-confirmation.dialog.component';
@Component({
	selector: 'kt-receivecards-list',
	templateUrl: './receive-card-list.component.html',
	styleUrls: ['receive-card-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ReceiveCardListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: ShipmentsDataSource;
	displayedColumns = ['serial_number', 'created_at', 'total', 'location', 'moderator'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  	dateRange: any = {};

	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	// Selection
	selection = new SelectionModel<UserModel>(true, []);
	receivecardsResult: UserModel[] = [];

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		public userService: UserService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
    private datePipe: DatePipe
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

	    // var date = new Date();
	    // let beginDate = date.setDate(date.getDate() - 7);
	    // this.dateRange = {begin: new Date(beginDate), end: new Date()};
    
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadReceiveCardsList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadReceiveCardsList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init DataSource
		this.dataSource = new ShipmentsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.receivecardsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);

		// First load
		of(undefined).pipe(
			take(1), 
			delay(1000)
		).subscribe(() => { // Remove this line, just loading imitation
			this.paginator.pageIndex = 0;
			this.loadReceiveCardsList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load ReceiveCards List from service through data-source
	 */
	loadReceiveCardsList() {
		this.selection.clear();
		const begin = this.dateRange.begin == null ? '' : this.datePipe.transform(this.dateRange.begin,"yyyy-MM-dd")
		const end = this.dateRange.end == null ? '' : this.datePipe.transform(this.dateRange.end,"yyyy-MM-dd")
		// const searchText: string = this.searchInput.nativeElement.value;
		const queryParams = new QueryParamsModel(
	        '',
	        // '1995-01-01',
	        // '2020-12-11',
	        begin,
	        end,
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex + 1,
			this.paginator.pageSize,
			'[1,2]', // status
			'', // type
			'' // suborganization_id
		);
		// Call request from server
		this.store.dispatch(new ShipmentsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	updateDialog(hashed_id, total) {
		const length = {hashed_id: hashed_id, total: total};
		const dialogConf = this.dialog.open(ReceiveCardEditComponent, { data: { length }, width: '500px' });
		dialogConf.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			this.updatePrint(res);
		});
	}

	updatePrint(hashed_id) {
		const data = {hashed_id: hashed_id};
		this.userService.printShipment(data)
		.subscribe(
			data => {
				this.layoutUtilsService.showActionNotification("Berhasil disimpan", MessageType.Update);
				this.loadReceiveCardsList();
			}, err => {
				this.layoutUtilsService.showActionNotification("Gagal", MessageType.Update);
				this.loadReceiveCardsList();
			}
		)
	}

  onDateChange(event: any) {
  	if(event.target.value){
    	this.dateRange = {begin: event.target.value.begin, end: event.target.value.end};
  	}else{
    	this.dateRange = {begin: null, end: null};
  	}
    this.loadReceiveCardsList();
  }
}
