// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ActiveUsersTransactionsState } from '../_reducers/activeusertransaction.reducers';
import { ActiveUserTransactionModel } from '../_models/activeusertransaction.model';

export const selectActiveUsersTransactionsState = createFeatureSelector<ActiveUsersTransactionsState>('activeuserstransactions');

export const selectActiveUserTransactionById = (activeusertransactionId: number) => createSelector(
    selectActiveUsersTransactionsState,
    activeusersTransactionsState => activeusersTransactionsState.entities[activeusertransactionId]
);

export const selectActiveUsersTransactionsPageLoading = createSelector(
    selectActiveUsersTransactionsState,
    activeusersTransactionsState => activeusersTransactionsState.listLoading
);

export const selectActiveUsersTransactionsActionLoading = createSelector(
    selectActiveUsersTransactionsState,
    activeusersTransactionsState => activeusersTransactionsState.actionsloading
);

export const selectLastCreatedActiveUserTransactionId = createSelector(
    selectActiveUsersTransactionsState,
    activeusersTransactionsState => activeusersTransactionsState.lastCreatedActiveUserTransactionId
);

export const selectActiveUsersTransactionsShowInitWaitingMessage = createSelector(
    selectActiveUsersTransactionsState,
    activeusersTransactionsState => activeusersTransactionsState.showInitWaitingMessage
);

export const selectActiveUsersTransactionsInStore = createSelector(
    selectActiveUsersTransactionsState,
    activeusersTransactionsState => {
        const items: ActiveUserTransactionModel[] = [];
        each(activeusersTransactionsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ActiveUserTransactionModel[] = httpExtension.sortArray(items, activeusersTransactionsState.lastQuery.sortField, activeusersTransactionsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, activeusersTransactionsState.totalCount, '');
    }
);
