// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AllUserBuysState } from '../_reducers/alluserbuy.reducers';
import { AllUserBuyModel } from '../_models/alluserbuy.model';

export const selectAllUserBuysState = createFeatureSelector<AllUserBuysState>('alluserbuys');

export const selectAllUserBuyById = (alluserbuyId: number) => createSelector(
    selectAllUserBuysState,
    alluserbuysState => alluserbuysState.entities[alluserbuyId]
);

export const selectAllUserBuysPageLoading = createSelector(
    selectAllUserBuysState,
    alluserbuysState => alluserbuysState.listLoading
);

export const selectAllUserBuysActionLoading = createSelector(
    selectAllUserBuysState,
    alluserbuysState => alluserbuysState.actionsloading
);

export const selectLastCreatedAllUserBuyId = createSelector(
    selectAllUserBuysState,
    alluserbuysState => alluserbuysState.lastCreatedAllUserBuyId
);

export const selectAllUserBuysShowInitWaitingMessage = createSelector(
    selectAllUserBuysState,
    alluserbuysState => alluserbuysState.showInitWaitingMessage
);

export const selectAllUserBuysInStore = createSelector(
    selectAllUserBuysState,
    alluserbuysState => {
        const items: AllUserBuyModel[] = [];
        each(alluserbuysState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AllUserBuyModel[] = httpExtension.sortArray(items, alluserbuysState.lastQuery.sortField, alluserbuysState.lastQuery.sortOrder);
        return new QueryResultsModel(result, alluserbuysState.totalCount, '');
    }
);
