// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ActivitysState } from '../_reducers/activity.reducers';
import { ActivityModel } from '../_models/activity.model';

export const selectActivitysState = createFeatureSelector<ActivitysState>('activitys');

export const selectActivityById = (activityId: number) => createSelector(
    selectActivitysState,
    activitysState => activitysState.entities[activityId]
);

export const selectActivitysPageLoading = createSelector(
    selectActivitysState,
    activitysState => activitysState.listLoading
);

export const selectActivitysActionLoading = createSelector(
    selectActivitysState,
    activitysState => activitysState.actionsloading
);

export const selectLastCreatedActivityId = createSelector(
    selectActivitysState,
    activitysState => activitysState.lastCreatedActivityId
);

export const selectActivitysShowInitWaitingMessage = createSelector(
    selectActivitysState,
    activitysState => activitysState.showInitWaitingMessage
);

export const selectActivitysInStore = createSelector(
    selectActivitysState,
    activitysState => {
        const items: ActivityModel[] = [];
        each(activitysState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ActivityModel[] = httpExtension.sortArray(items, activitysState.lastQuery.sortField, activitysState.lastQuery.sortOrder);
        return new QueryResultsModel(result, activitysState.totalCount, '');
    }
);
