<div class="kt-portlet"
  [ngClass]="{ 'kt-portlet--body-progress kt-portlet--body-progress-overlay' : viewLoading }">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
    </div>
  </div>
  <form class="kt-form">
    <div class="kt-portlet__body">
      <div class="row" *ngIf="!isValid">
        <div class="col-lg-4 text-center">
          <h4>Posisi Ketua Kosong</h4>
        </div>
      </div>
      <div class="row" *ngIf="isValid">
        <div class="col-lg-4">
          <div><b>Foto Profile</b></div>
          <div *ngIf="detailUser.photo">
            <a [attr.href]="detailUser.photo" target="_blank" class="btn btn-primary btn-sm">Preview</a>
          </div>
          <div *ngIf="!detailUser.photo">-</div>
          <br>
          <div><b>Nama Lengkap Sesuai KTP</b></div>
          <div *ngIf="detailUser.name">{{detailUser.name}}</div>
          <div *ngIf="!detailUser.name">-</div>
          <br>
          <div><b>Tanggal Lahir</b></div>
          <div *ngIf="detailUser.birthday">{{detailUser.birthday}}</div>
          <div *ngIf="!detailUser.birthday">-</div>
          <br>
          <div><b>Email</b></div>
          <div *ngIf="detailUser.email">{{detailUser.email}}</div>
          <div *ngIf="!detailUser.email">-</div>
          <br>
          <div><b>Alamat</b></div>
          <div *ngIf="detailUser.address">{{detailUser.address}}</div>
          <div *ngIf="!detailUser.address">-</div>
          <br>
          <div><b>Nomor Kartanu</b></div>
          <div *ngIf="detailUser.id_member">{{detailUser.id_member}}</div>
          <div *ngIf="!detailUser.id_member">-</div>
          <br>
          <div><b>PIC</b></div>
          <div *ngIf="detailUser.moderator">{{detailUser.moderator}}</div>
          <div *ngIf="!detailUser.moderator">-</div>
          <br>
          <div><b>Lokasi</b></div>
          <div *ngIf="detailUser.province">{{detailUser.province}}</div>
          <div *ngIf="!detailUser.province">-</div>
          <br>
        </div>
        <div class="col-lg-4">
          <div><b>Selfie KTP</b></div>
          <div *ngIf="detailUser.selfie_scan">
            <a [attr.href]="detailUser.selfie_scan" target="_blank" class="btn btn-primary btn-sm">Preview</a>
          </div>
          <div *ngIf="!detailUser.selfie_scan">-</div>
          <br>
          <div><b>Foto KTP</b></div>
          <div *ngIf="detailUser.id_card_scan">
            <a [attr.href]="detailUser.id_card_scan" target="_blank" class="btn btn-primary btn-sm">Preview</a>
          </div>
          <div *ngIf="!detailUser.id_card_scan">-</div>
          <br>
        </div>
        <div class="col-lg-4">
          <div><b>Foto KartaNU</b></div>
          <div *ngIf="detailUser.kartanu_photo">
            <a [attr.href]="detailUser.kartanu_photo" target="_blank" class="btn btn-primary btn-sm">Preview</a>
          </div>
          <div *ngIf="!detailUser.kartanu_photo">-</div>
          <br>
          <div *ngIf="detailUser.referral_code_active != null"><b>Kode Referral</b></div>
          <div *ngIf="detailUser.referral_code_active != null">{{detailUser.referral_code}}</div>
          <br>
          <div *ngIf="detailUser.referral_code_active != null"><b>Kode Referral Aktif</b></div>
          <div *ngIf="detailUser.referral_code_active != null">{{detailUser.referral_code_active}}</div>
          <br>
          <div *ngIf="detailUser.referral_code_active != null"><b>Kode Referral Non Aktif</b></div>
          <div *ngIf="detailUser.referral_code_active != null">{{detailUser.referral_code_nonactive}}</div>
          <br>
        </div>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
      <div class="kt-form__actions kt-form__actions--solid">
        <div class="row text-left">
          <div class="col-lg-12">
            <button type="button" mat-raised-button color="accent" [mat-dialog-close]="data" cdkFocusInitial matTooltip="Close informasi detail">
            Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
