// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsDetailShipmentModel } from '../../_base/crud';
// Models
import { DetailShipmentModel } from '../_models/detailshipment.model';

export enum DetailShipmentActionTypes {
    DetailShipmentOnServerCreated = '[Edit DetailShipment Dialog] DetailShipment On Server Created',
    DetailShipmentCreated = '[Edit DetailShipment Dialog] DetailShipment Created',
    DetailShipmentUpdated = '[Edit DetailShipment Dialog] DetailShipment Updated',
    DetailShipmentsStatusUpdated = '[DetailShipment List Page] DetailShipments Status Updated',
    OneDetailShipmentDeleted = '[DetailShipments List Page] One DetailShipment Deleted',
    ManyDetailShipmentsDeleted = '[DetailShipments List Page] Many DetailShipment Deleted',
    DetailShipmentsPageRequested = '[DetailShipments List Page] DetailShipments Page Requested',
    DetailShipmentsPageLoaded = '[DetailShipments API] DetailShipments Page Loaded',
    DetailShipmentsPageCancelled = '[DetailShipments API] DetailShipments Page Cancelled',
    DetailShipmentsPageToggleLoading = '[DetailShipments] DetailShipments Page Toggle Loading',
    DetailShipmentActionToggleLoading = '[DetailShipments] DetailShipments Action Toggle Loading'
}

export class DetailShipmentOnServerCreated implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentOnServerCreated;
    constructor(public payload: { detailshipment: DetailShipmentModel }) { }
}

export class DetailShipmentCreated implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentCreated;
    constructor(public payload: { detailshipment: DetailShipmentModel }) { }
}

export class DetailShipmentUpdated implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentUpdated;
    constructor(public payload: {
        partialDetailShipment: Update<DetailShipmentModel>, // For State update
        detailshipment: DetailShipmentModel // For Server update (through service)
    }) { }
}

export class DetailShipmentsStatusUpdated implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentsStatusUpdated;
    constructor(public payload: {
        detailshipments: DetailShipmentModel[],
        status: number
    }) { }
}

export class OneDetailShipmentDeleted implements Action {
    readonly type = DetailShipmentActionTypes.OneDetailShipmentDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyDetailShipmentsDeleted implements Action {
    readonly type = DetailShipmentActionTypes.ManyDetailShipmentsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class DetailShipmentsPageRequested implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentsPageRequested;
    constructor(public payload: { page: QueryParamsDetailShipmentModel }) { }
}

export class DetailShipmentsPageLoaded implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentsPageLoaded;
    constructor(public payload: { detailshipments: DetailShipmentModel[], totalCount: number, page: QueryParamsDetailShipmentModel }) { }
}

export class DetailShipmentsPageCancelled implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentsPageCancelled;
}

export class DetailShipmentsPageToggleLoading implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class DetailShipmentActionToggleLoading implements Action {
    readonly type = DetailShipmentActionTypes.DetailShipmentActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type DetailShipmentActions = DetailShipmentOnServerCreated
| DetailShipmentCreated
| DetailShipmentUpdated
| DetailShipmentsStatusUpdated
| OneDetailShipmentDeleted
| ManyDetailShipmentsDeleted
| DetailShipmentsPageRequested
| DetailShipmentsPageLoaded
| DetailShipmentsPageCancelled
| DetailShipmentsPageToggleLoading
| DetailShipmentActionToggleLoading;
