// Angular
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, take, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
// Services
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
// Lodash
import { each } from 'lodash';
// Models
import { AdminService } from '../../../../../core/user-management';
import { AppState } from '../../../../../core/reducers';
import { QueryParamsModel } from '../../../../../core/_base/crud';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatButtonModule } from "@angular/material";

export interface Admin {
  email: string;
  hashed_id: string;
}

export class User {
  constructor(
    public email: string,
    public hashed_id: string,
  ) {}
}

@Component({
	selector: 'kt-user-update-list',
	templateUrl: './user-update-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserUpdateListComponent implements OnInit, OnDestroy {

    // Display
  	suborganization: boolean = false;
  	province: boolean = false;

    // Load Data
    userData: any = {};
  	roleList: any = [];
  	suborganizationList: any = [];
  	provinceList: any = [];

    // Selected Item
  	selectedRole: any = {};
  	selectedSuborganization: string = '';
  	selectedProvince: any = [];

  	// Variable
    params: string = '';
  	clicked: boolean = false;
  	myControl = new FormControl();

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 */
	constructor(
		private store: Store<AppState>,
		public dialog: MatDialog,
		public adminService: AdminService,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location,
    	private cd: ChangeDetectorRef
    ) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRole();
		this.initProvince();
		this.initSuborganization();
		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			this.params = params.id;
			this.initUser(params.id);
		});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	initUser(hashed_id) {
		this.adminService.getAdminByHashedId(hashed_id)
		.subscribe(
			data => {
				this.userData = data;
        this.selectedRole = {value:this.userData.roles.id}
				this.roleChange(this.selectedRole);
        if (this.userData.province !== null) {
  		    for (var i=0; i < this.provinceList.length; i++) {
  		    	if(this.userData.province.some(province => province.hashed_id == this.provinceList[i].hashed_id)){
  		    	this.provinceList[i].checked = true;
  					this.selectedProvince.push(this.provinceList[i].hashed_id);
  		    	}else{
  		    		this.provinceList[i].checked = false;
  		    	}
  		    }
        }
        if (this.userData.suborganization == null) {
          this.selectedSuborganization = null;
        } else {
          this.selectedSuborganization = this.userData.suborganization.hashed_id;
          this.cd.markForCheck();
        }
        this.selectedRole = this.userData.roles.id;
        this.cd.markForCheck();
			}
		)
	}

	initRole() {
		this.adminService.getAllRoles()
		.subscribe(
			data => {
				this.roleList = data;
			}
		)
	}

  initSuborganization() {
    const queryParams = new QueryParamsModel(
       '',
       null,
       null,
       '',
       '',
       1,
       100,
       '',
       '',
       '',
       ''
    );
    this.adminService.getAllSuborganization(queryParams)
    .subscribe(
      data => {
        this.suborganizationList = data;
      }
    )
  }

	initProvince() {
		this.adminService.getAllProvince()
		.subscribe(
			data => {
				this.provinceList = data;
			}
		)
	}

	roleChange(role){
    this.selectedRole = role.value;
		if(role.value == 20){
			this.suborganization = true;
			this.province = false;
			this.myControl.setValue('');
		}else if(role.value == 19){
			this.suborganization = false;
			this.province = true;
			this.myControl.setValue('');
		}else{
			this.suborganization = false;
			this.province = false;
			this.myControl.setValue('');
		}
	}

  provinceChange(event){
    this.clicked = true;
    if(event.checked) {
      this.selectedProvince.push(event.source.value);
    }else{
      if(this.clicked){
        this.selectedProvince = this.arrayRemove(this.selectedProvince, event.source.value);
      }
    }
    this.selectedProvince = this.selectedProvince.filter((item, i, ar) => ar.indexOf(item) === i);
  }

	arrayRemove(arr, value) {
	   return arr.filter(function(ele){
	       return ele != value;
	   });
	}

	updateUser(){
		const formData = {
      name: this.userData.name,
      email: this.userData.email,
      role_id: this.selectedRole,
      suborganization_id: this.selectedSuborganization,
      province_id: this.selectedProvince
    };
		this.adminService.updateDataAdmin(this.params, formData)
		.subscribe(
			data => {
				this.snackBar.open("Berhasil disimpan", '', {duration: 1500});
				this.location.back();
			}, err => {
				this.snackBar.open(err.error.meta.message, '', {duration: 1500});
			}
		)
	}

  back() {
    this.location.back();
  }
}
