import { BaseModel } from '../../_base/crud';

export class BigOrder  extends BaseModel {
	amount: number;
	insentif: string;
	discount: string;
	tier: string;

	clear() {
	  this.amount = undefined;
	  this.insentif = 'Rp 0';
	  this.discount = 'Rp 0';
	  this.tier = '-';
	}
  }
