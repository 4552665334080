// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { CoinModel } from '../_models/coin.model';

export enum CoinActionTypes {
    CoinOnServerCreated = '[Edit Coin Dialog] Coin On Server Created',
    CoinCreated = '[Edit Coin Dialog] Coin Created',
    CoinUpdated = '[Edit Coin Dialog] Coin Updated',
    CoinsStatusUpdated = '[Coin List Page] Coins Status Updated',
    OneCoinDeleted = '[Coins List Page] One Coin Deleted',
    ManyCoinsDeleted = '[Coins List Page] Many Coin Deleted',
    CoinsPageRequested = '[Coins List Page] Coins Page Requested',
    CoinsPageLoaded = '[Coins API] Coins Page Loaded',
    CoinsPageCancelled = '[Coins API] Coins Page Cancelled',
    CoinsPageToggleLoading = '[Coins] Coins Page Toggle Loading',
    CoinActionToggleLoading = '[Coins] Coins Action Toggle Loading'
}

export class CoinOnServerCreated implements Action {
    readonly type = CoinActionTypes.CoinOnServerCreated;
    constructor(public payload: { coin: CoinModel }) { }
}

export class CoinCreated implements Action {
    readonly type = CoinActionTypes.CoinCreated;
    constructor(public payload: { coin: CoinModel }) { }
}

export class CoinUpdated implements Action {
    readonly type = CoinActionTypes.CoinUpdated;
    constructor(public payload: {
        partialCoin: Update<CoinModel>, // For State update
        coin: CoinModel // For Server update (through service)
    }) { }
}

export class CoinsStatusUpdated implements Action {
    readonly type = CoinActionTypes.CoinsStatusUpdated;
    constructor(public payload: {
        coins: CoinModel[],
        status: number
    }) { }
}

export class OneCoinDeleted implements Action {
    readonly type = CoinActionTypes.OneCoinDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyCoinsDeleted implements Action {
    readonly type = CoinActionTypes.ManyCoinsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class CoinsPageRequested implements Action {
    readonly type = CoinActionTypes.CoinsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class CoinsPageLoaded implements Action {
    readonly type = CoinActionTypes.CoinsPageLoaded;
    constructor(public payload: { coins: CoinModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class CoinsPageCancelled implements Action {
    readonly type = CoinActionTypes.CoinsPageCancelled;
}

export class CoinsPageToggleLoading implements Action {
    readonly type = CoinActionTypes.CoinsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CoinActionToggleLoading implements Action {
    readonly type = CoinActionTypes.CoinActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type CoinActions = CoinOnServerCreated
| CoinCreated
| CoinUpdated
| CoinsStatusUpdated
| OneCoinDeleted
| ManyCoinsDeleted
| CoinsPageRequested
| CoinsPageLoaded
| CoinsPageCancelled
| CoinsPageToggleLoading
| CoinActionToggleLoading;
