import { BaseModel } from '../../_base/crud';

export class ModeratorAllModel  extends BaseModel {
  id: number;
  name: string;
  total: string;

  clear() {
    this.id = undefined;
    this.name = '';
    this.total = '';
  }
}
