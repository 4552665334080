// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { SuborganizationAvailableActions, SuborganizationAvailableActionTypes } from '../_actions/suborganizationavailable.actions';
// Models
import { SuborganizationAvailableModel } from '../_models/suborganizationavailable.model';
import { QueryParamsInventoryModel } from '../../_base/crud';

export interface SuborganizationAvailablesState extends EntityState<SuborganizationAvailableModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSuborganizationAvailableId: number;
    lastQuery: QueryParamsInventoryModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SuborganizationAvailableModel> = createEntityAdapter<SuborganizationAvailableModel>();

export const initialSuborganizationAvailablesState: SuborganizationAvailablesState = adapter.getInitialState({
    suborganizationavailableForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSuborganizationAvailableId: undefined,
    lastQuery: new QueryParamsInventoryModel({}),
    showInitWaitingMessage: true
});

export function suborganizationavailablesReducer(state = initialSuborganizationAvailablesState, action: SuborganizationAvailableActions): SuborganizationAvailablesState {
    switch  (action.type) {
        case SuborganizationAvailableActionTypes.SuborganizationAvailablesPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSuborganizationAvailableId: undefined
            };
        }
        case SuborganizationAvailableActionTypes.SuborganizationAvailableActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case SuborganizationAvailableActionTypes.SuborganizationAvailableOnServerCreated: return {
            ...state
        };
        case SuborganizationAvailableActionTypes.SuborganizationAvailableCreated: return adapter.addOne(action.payload.suborganizationavailable, {
            ...state, lastCreatedSuborganizationAvailableId: action.payload.suborganizationavailable.id
        });
        case SuborganizationAvailableActionTypes.SuborganizationAvailableUpdated: return adapter.updateOne(action.payload.partialSuborganizationAvailable, state);
       //  case SuborganizationAvailableActionTypes.SuborganizationAvailablesStatusUpdated: {
       //      const _partialSuborganizationAvailables: Update<SuborganizationAvailableModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.suborganizationavailables.length; i++) {
       //          _partialSuborganizationAvailables.push({
           //  id: action.payload.suborganizationavailables[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialSuborganizationAvailables, state);
       //  }
        case SuborganizationAvailableActionTypes.OneSuborganizationAvailableDeleted: return adapter.removeOne(action.payload.id, state);
        case SuborganizationAvailableActionTypes.ManySuborganizationAvailablesDeleted: return adapter.removeMany(action.payload.ids, state);
        case SuborganizationAvailableActionTypes.SuborganizationAvailablesPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsInventoryModel({})
            };
        }
        case SuborganizationAvailableActionTypes.SuborganizationAvailablesPageLoaded: {
            return adapter.addMany(action.payload.suborganizationavailables, {
                ...initialSuborganizationAvailablesState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getSuborganizationAvailableState = createFeatureSelector<SuborganizationAvailableModel>('suborganizationavailables');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
