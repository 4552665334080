// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { PwnuUsersState } from '../_reducers/pwnuuser.reducers';
import { PwnuUserModel } from '../_models/pwnuuser.model';

export const selectPwnuUsersState = createFeatureSelector<PwnuUsersState>('pwnuusers');

export const selectPwnuUserById = (pwnuuserId: number) => createSelector(
    selectPwnuUsersState,
    pwnuusersState => pwnuusersState.entities[pwnuuserId]
);

export const selectPwnuUsersPageLoading = createSelector(
    selectPwnuUsersState,
    pwnuusersState => pwnuusersState.listLoading
);

export const selectPwnuUsersActionLoading = createSelector(
    selectPwnuUsersState,
    pwnuusersState => pwnuusersState.actionsloading
);

export const selectLastCreatedPwnuUserId = createSelector(
    selectPwnuUsersState,
    pwnuusersState => pwnuusersState.lastCreatedPwnuUserId
);

export const selectPwnuUsersShowInitWaitingMessage = createSelector(
    selectPwnuUsersState,
    pwnuusersState => pwnuusersState.showInitWaitingMessage
);

export const selectPwnuUsersInStore = createSelector(
    selectPwnuUsersState,
    pwnuusersState => {
        const items: PwnuUserModel[] = [];
        each(pwnuusersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PwnuUserModel[] = httpExtension.sortArray(items, pwnuusersState.lastQuery.sortField, pwnuusersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, pwnuusersState.totalCount, '');
    }
);
