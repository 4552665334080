// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { SuborganizationAllModel } from '../_models/suborganizationall.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class SuborganizationAllService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new suborganizationall to the server
  createSuborganizationAll(suborganizationall: SuborganizationAllModel): Observable<SuborganizationAllModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<SuborganizationAllModel>(BASE_URL, suborganizationall, { headers: httpHeaders});
  }

  // READ
  getAllSuborganizationAlls(): Observable<SuborganizationAllModel[]> {
    return this.http.get<SuborganizationAllModel[]>(BASE_URL);
  }

  getSuborganizationAllById(suborganizationallId: number): Observable<SuborganizationAllModel> {
    return this.http.get<SuborganizationAllModel>(BASE_URL + `/${suborganizationallId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findSuborganizationAlls(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/inventory';
    
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the suborganizationall on the server
  updateSuborganizationAll(suborganizationall: SuborganizationAllModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, suborganizationall, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForSuborganizationAll(suborganizationalls: SuborganizationAllModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      suborganizationallsForUpdate: suborganizationalls,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the suborganizationall from the server
  deleteSuborganizationAll(suborganizationallId: number): Observable<SuborganizationAllModel> {
    const url = `${BASE_URL}/${suborganizationallId}`;
    return this.http.delete<SuborganizationAllModel>(url);
  }

  deleteSuborganizationAlls(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteSuborganizationAlls';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { suborganizationallIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
