// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsInventoryModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { ModeratorAvailableModel } from '../_models/moderatoravailable.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class ModeratorAvailableService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new moderatoravailable to the server
  createModeratorAvailable(moderatoravailable: ModeratorAvailableModel): Observable<ModeratorAvailableModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<ModeratorAvailableModel>(BASE_URL, moderatoravailable, { headers: httpHeaders});
  }

  // READ
  getAllModeratorAvailables(): Observable<ModeratorAvailableModel[]> {
    return this.http.get<ModeratorAvailableModel[]>(BASE_URL);
  }

  getModeratorAvailableById(moderatoravailableId: number): Observable<ModeratorAvailableModel> {
    return this.http.get<ModeratorAvailableModel>(BASE_URL + `/${moderatoravailableId}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findModeratorAvailables(queryParams: QueryParamsInventoryModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/inventory';
    
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the moderatoravailable on the server
  updateModeratorAvailable(moderatoravailable: ModeratorAvailableModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, moderatoravailable, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForModeratorAvailable(moderatoravailables: ModeratorAvailableModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      moderatoravailablesForUpdate: moderatoravailables,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the moderatoravailable from the server
  deleteModeratorAvailable(moderatoravailableId: number): Observable<ModeratorAvailableModel> {
    const url = `${BASE_URL}/${moderatoravailableId}`;
    return this.http.delete<ModeratorAvailableModel>(url);
  }

  deleteModeratorAvailables(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteModeratorAvailables';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { moderatoravailableIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }
}
