import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { AllUserService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    AllUserActionTypes,
    AllUsersPageRequested,
    AllUsersPageLoaded,
    ManyAllUsersDeleted,
    OneAllUserDeleted,
    AllUserActionToggleLoading,
    AllUsersPageToggleLoading,
    AllUserUpdated,
    AllUsersStatusUpdated,
    AllUserCreated,
    AllUserOnServerCreated
} from '../_actions/alluser.actions';
import { of } from 'rxjs';

@Injectable()
export class AllUserEffects {
    showPageLoadingDistpatcher = new AllUsersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new AllUserActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AllUserActionToggleLoading({ isLoading: false });

    @Effect()
    loadAllUsersPage$ = this.actions$.pipe(
        ofType<AllUsersPageRequested>(AllUserActionTypes.AllUsersPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.allusersService.findAllUsers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new AllUsersPageLoaded({
                allusers: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteAllUser$ = this.actions$
        .pipe(
            ofType<OneAllUserDeleted>(AllUserActionTypes.OneAllUserDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.allusersService.deleteAllUser(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAllUsers$ = this.actions$
        .pipe(
            ofType<ManyAllUsersDeleted>(AllUserActionTypes.ManyAllUsersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.allusersService.deleteAllUsers(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAllUser$ = this.actions$
        .pipe(
            ofType<AllUserUpdated>(AllUserActionTypes.AllUserUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusersService.updateAllUser(payload.alluser);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateAllUsersStatus$ = this.actions$
        .pipe(
            ofType<AllUsersStatusUpdated>(AllUserActionTypes.AllUsersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusersService.updateStatusForAllUser(payload.allusers, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createAllUser$ = this.actions$
        .pipe(
            ofType<AllUserOnServerCreated>(AllUserActionTypes.AllUserOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.allusersService.createAllUser(payload.alluser).pipe(
                    tap(res => {
                        this.store.dispatch(new AllUserCreated({ alluser: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private allusersService: AllUserService, private store: Store<AppState>) { }
}
