// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { ModeratorAvailableModel } from '../_models/moderatoravailable.model';

export enum ModeratorAvailableActionTypes {
    ModeratorAvailableOnServerCreated = '[Edit ModeratorAvailable Dialog] ModeratorAvailable On Server Created',
    ModeratorAvailableCreated = '[Edit ModeratorAvailable Dialog] ModeratorAvailable Created',
    ModeratorAvailableUpdated = '[Edit ModeratorAvailable Dialog] ModeratorAvailable Updated',
    ModeratorAvailablesStatusUpdated = '[ModeratorAvailable List Page] ModeratorAvailables Status Updated',
    OneModeratorAvailableDeleted = '[ModeratorAvailables List Page] One ModeratorAvailable Deleted',
    ManyModeratorAvailablesDeleted = '[ModeratorAvailables List Page] Many ModeratorAvailable Deleted',
    ModeratorAvailablesPageRequested = '[ModeratorAvailables List Page] ModeratorAvailables Page Requested',
    ModeratorAvailablesPageLoaded = '[ModeratorAvailables API] ModeratorAvailables Page Loaded',
    ModeratorAvailablesPageCancelled = '[ModeratorAvailables API] ModeratorAvailables Page Cancelled',
    ModeratorAvailablesPageToggleLoading = '[ModeratorAvailables] ModeratorAvailables Page Toggle Loading',
    ModeratorAvailableActionToggleLoading = '[ModeratorAvailables] ModeratorAvailables Action Toggle Loading'
}

export class ModeratorAvailableOnServerCreated implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailableOnServerCreated;
    constructor(public payload: { moderatoravailable: ModeratorAvailableModel }) { }
}

export class ModeratorAvailableCreated implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailableCreated;
    constructor(public payload: { moderatoravailable: ModeratorAvailableModel }) { }
}

export class ModeratorAvailableUpdated implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailableUpdated;
    constructor(public payload: {
        partialModeratorAvailable: Update<ModeratorAvailableModel>, // For State update
        moderatoravailable: ModeratorAvailableModel // For Server update (through service)
    }) { }
}

export class ModeratorAvailablesStatusUpdated implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailablesStatusUpdated;
    constructor(public payload: {
        moderatoravailables: ModeratorAvailableModel[],
        status: number
    }) { }
}

export class OneModeratorAvailableDeleted implements Action {
    readonly type = ModeratorAvailableActionTypes.OneModeratorAvailableDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyModeratorAvailablesDeleted implements Action {
    readonly type = ModeratorAvailableActionTypes.ManyModeratorAvailablesDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ModeratorAvailablesPageRequested implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailablesPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class ModeratorAvailablesPageLoaded implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailablesPageLoaded;
    constructor(public payload: { moderatoravailables: ModeratorAvailableModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class ModeratorAvailablesPageCancelled implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailablesPageCancelled;
}

export class ModeratorAvailablesPageToggleLoading implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailablesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ModeratorAvailableActionToggleLoading implements Action {
    readonly type = ModeratorAvailableActionTypes.ModeratorAvailableActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ModeratorAvailableActions = ModeratorAvailableOnServerCreated
| ModeratorAvailableCreated
| ModeratorAvailableUpdated
| ModeratorAvailablesStatusUpdated
| OneModeratorAvailableDeleted
| ManyModeratorAvailablesDeleted
| ModeratorAvailablesPageRequested
| ModeratorAvailablesPageLoaded
| ModeratorAvailablesPageCancelled
| ModeratorAvailablesPageToggleLoading
| ModeratorAvailableActionToggleLoading;
