// Angular
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AccessService } from '../../../core/access';
// Widgets model
import { LayoutConfigService } from '../../../core/_base/layout';
import { Location } from '@angular/common';

import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';

// Query Params
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../core/_base/crud';

import { interval, Subscription } from 'rxjs';

const labelPositions = {
	beforeCodeTitle: 'Label positions and \'Change\' event binding examples',
	htmlCode: `
<mat-slide-toggle [labelPosition]="labelPosition">Slide me!</mat-slide-toggle>
<div class="kt-separator kt-separator--dashed"></div>
<mat-slide-toggle (change)="changeLablesPositions()">Slide labels position</mat-slide-toggle>`,
	tsCode: `
import {Component} from '@angular/core';\n
@Component({
selector: 'slide-toggle-label-positions-example',
templateUrl: 'slide-toggle-lable-positions-example.html',
styleUrls: ['slide-toggle-label-positions--example.css'],
})
export class SlideToggleLabelPositionsExample {\n
labelPosition: string = "before";\n
changeLablesPositions() {
this.labelPosition = this.labelPosition == "before" ? "after" : "before";
}
}`,
	cssCode: ``,
	viewCode: ``,
	isCodeVisible: false
};

@Component({
	selector: 'kt-access',
	templateUrl: './access.component.html',
	styleUrls: ['access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessComponent implements OnInit, OnDestroy {
	exampleLabelPositions;

	color = 'accent';
	menuKartanu = false;

	labelPosition: string = 'before';
	private subscriptions: Subscription[] = [];
  constructor(
	private layoutConfigService: LayoutConfigService,
	public accessService : AccessService,
	public snackBar: MatSnackBar,
	private location: Location,
    private cd: ChangeDetectorRef,
  ){}

	ngOnInit(): void {
		this.exampleLabelPositions = labelPositions;
		this.loadPermissionMenu();
	}

	loadPermissionMenu() {
		this.accessService.getPermissionMenu()
		.subscribe(
			data => {
				this.menuKartanu = data.menuKartanu
				this.cd.markForCheck();
			}
		)
	}

	// onUpdatePermissionMenu(value) {

	// }

	onUpdatePermissionMenu(event: any) {
		const data = {
			menuKartanu: event,
		};

		this.accessService.updatePermissionMenu(data)
		.subscribe(
			data => {
				this.snackBar.open("Berhasil disimpan", '', {duration: 1500});
				this.location.getState();
			}
		)
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	back() {
		this.location.back();
	}
}
