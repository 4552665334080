import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { GoldGiftCardService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    GoldGiftCardActionTypes,
    GoldGiftCardsPageRequested,
    GoldGiftCardsPageLoaded,
    ManyGoldGiftCardsDeleted,
    OneGoldGiftCardDeleted,
    GoldGiftCardActionToggleLoading,
    GoldGiftCardsPageToggleLoading,
    GoldGiftCardUpdated,
    GoldGiftCardsStatusUpdated,
    GoldGiftCardCreated,
    GoldGiftCardOnServerCreated
} from '../_actions/goldgiftcard.actions';
import { of } from 'rxjs';

@Injectable()
export class GoldGiftCardEffects {
    showPageLoadingDistpatcher = new GoldGiftCardsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new GoldGiftCardActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new GoldGiftCardActionToggleLoading({ isLoading: false });

    @Effect()
    loadGoldGiftCardsPage$ = this.actions$.pipe(
        ofType<GoldGiftCardsPageRequested>(GoldGiftCardActionTypes.GoldGiftCardsPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.goldgiftcardsService.findGoldGiftCards(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new GoldGiftCardsPageLoaded({
                goldgiftcards: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteGoldGiftCard$ = this.actions$
        .pipe(
            ofType<OneGoldGiftCardDeleted>(GoldGiftCardActionTypes.OneGoldGiftCardDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldgiftcardsService.deleteGoldGiftCard(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteGoldGiftCards$ = this.actions$
        .pipe(
            ofType<ManyGoldGiftCardsDeleted>(GoldGiftCardActionTypes.ManyGoldGiftCardsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.goldgiftcardsService.deleteGoldGiftCards(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateGoldGiftCard$ = this.actions$
        .pipe(
            ofType<GoldGiftCardUpdated>(GoldGiftCardActionTypes.GoldGiftCardUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldgiftcardsService.updateGoldGiftCard(payload.goldgiftcard);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateGoldGiftCardsStatus$ = this.actions$
        .pipe(
            ofType<GoldGiftCardsStatusUpdated>(GoldGiftCardActionTypes.GoldGiftCardsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldgiftcardsService.updateStatusForGoldGiftCard(payload.goldgiftcards, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createGoldGiftCard$ = this.actions$
        .pipe(
            ofType<GoldGiftCardOnServerCreated>(GoldGiftCardActionTypes.GoldGiftCardOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.goldgiftcardsService.createGoldGiftCard(payload.goldgiftcard).pipe(
                    tap(res => {
                        this.store.dispatch(new GoldGiftCardCreated({ goldgiftcard: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private goldgiftcardsService: GoldGiftCardService, private store: Store<AppState>) { }
}
