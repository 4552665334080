// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { SuborganizationAllModel } from '../_models/suborganizationall.model';

export enum SuborganizationAllActionTypes {
    SuborganizationAllOnServerCreated = '[Edit SuborganizationAll Dialog] SuborganizationAll On Server Created',
    SuborganizationAllCreated = '[Edit SuborganizationAll Dialog] SuborganizationAll Created',
    SuborganizationAllUpdated = '[Edit SuborganizationAll Dialog] SuborganizationAll Updated',
    SuborganizationAllsStatusUpdated = '[SuborganizationAll List Page] SuborganizationAlls Status Updated',
    OneSuborganizationAllDeleted = '[SuborganizationAlls List Page] One SuborganizationAll Deleted',
    ManySuborganizationAllsDeleted = '[SuborganizationAlls List Page] Many SuborganizationAll Deleted',
    SuborganizationAllsPageRequested = '[SuborganizationAlls List Page] SuborganizationAlls Page Requested',
    SuborganizationAllsPageLoaded = '[SuborganizationAlls API] SuborganizationAlls Page Loaded',
    SuborganizationAllsPageCancelled = '[SuborganizationAlls API] SuborganizationAlls Page Cancelled',
    SuborganizationAllsPageToggleLoading = '[SuborganizationAlls] SuborganizationAlls Page Toggle Loading',
    SuborganizationAllActionToggleLoading = '[SuborganizationAlls] SuborganizationAlls Action Toggle Loading'
}

export class SuborganizationAllOnServerCreated implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllOnServerCreated;
    constructor(public payload: { suborganizationall: SuborganizationAllModel }) { }
}

export class SuborganizationAllCreated implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllCreated;
    constructor(public payload: { suborganizationall: SuborganizationAllModel }) { }
}

export class SuborganizationAllUpdated implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllUpdated;
    constructor(public payload: {
        partialSuborganizationAll: Update<SuborganizationAllModel>, // For State update
        suborganizationall: SuborganizationAllModel // For Server update (through service)
    }) { }
}

export class SuborganizationAllsStatusUpdated implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllsStatusUpdated;
    constructor(public payload: {
        suborganizationalls: SuborganizationAllModel[],
        status: number
    }) { }
}

export class OneSuborganizationAllDeleted implements Action {
    readonly type = SuborganizationAllActionTypes.OneSuborganizationAllDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManySuborganizationAllsDeleted implements Action {
    readonly type = SuborganizationAllActionTypes.ManySuborganizationAllsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class SuborganizationAllsPageRequested implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllsPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class SuborganizationAllsPageLoaded implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllsPageLoaded;
    constructor(public payload: { suborganizationalls: SuborganizationAllModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class SuborganizationAllsPageCancelled implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllsPageCancelled;
}

export class SuborganizationAllsPageToggleLoading implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class SuborganizationAllActionToggleLoading implements Action {
    readonly type = SuborganizationAllActionTypes.SuborganizationAllActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type SuborganizationAllActions = SuborganizationAllOnServerCreated
| SuborganizationAllCreated
| SuborganizationAllUpdated
| SuborganizationAllsStatusUpdated
| OneSuborganizationAllDeleted
| ManySuborganizationAllsDeleted
| SuborganizationAllsPageRequested
| SuborganizationAllsPageLoaded
| SuborganizationAllsPageCancelled
| SuborganizationAllsPageToggleLoading
| SuborganizationAllActionToggleLoading;
