// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ActiveUserActions, ActiveUserActionTypes } from '../_actions/activeuser.actions';
// Models
import { ActiveUserModel } from '../_models/activeuser.model';
import { QueryParamsModel } from '../../_base/crud';

export interface ActiveUsersState extends EntityState<ActiveUserModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedActiveUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ActiveUserModel> = createEntityAdapter<ActiveUserModel>();

export const initialActiveUsersState: ActiveUsersState = adapter.getInitialState({
    activeuserForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedActiveUserId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function activeusersReducer(state = initialActiveUsersState, action: ActiveUserActions): ActiveUsersState {
    switch  (action.type) {
        case ActiveUserActionTypes.ActiveUsersPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedActiveUserId: undefined
            };
        }
        case ActiveUserActionTypes.ActiveUserActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ActiveUserActionTypes.ActiveUserOnServerCreated: return {
            ...state
        };
        // case ActiveUserActionTypes.ActiveUserCreated: return adapter.addOne(action.payload.activeuser, {
        //     ...state, lastCreatedActiveUserId: action.payload.activeuser.id
        // });
        case ActiveUserActionTypes.ActiveUserUpdated: return adapter.updateOne(action.payload.partialActiveUser, state);
       //  case ActiveUserActionTypes.ActiveUsersStatusUpdated: {
       //      const _partialActiveUsers: Update<ActiveUserModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.activeusers.length; i++) {
       //          _partialActiveUsers.push({
           //  id: action.payload.activeusers[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialActiveUsers, state);
       //  }
        case ActiveUserActionTypes.OneActiveUserDeleted: return adapter.removeOne(action.payload.id, state);
        case ActiveUserActionTypes.ManyActiveUsersDeleted: return adapter.removeMany(action.payload.ids, state);
        case ActiveUserActionTypes.ActiveUsersPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case ActiveUserActionTypes.ActiveUsersPageLoaded: {
            return adapter.addMany(action.payload.activeusers, {
                ...initialActiveUsersState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getActiveUserState = createFeatureSelector<ActiveUserModel>('activeusers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
