// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { CoinActions, CoinActionTypes } from '../_actions/coin.actions';
// Models
import { CoinModel } from '../_models/coin.model';
import { QueryParamsModel } from '../../_base/crud';

export interface CoinsState extends EntityState<CoinModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedCoinId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CoinModel> = createEntityAdapter<CoinModel>();

export const initialCoinsState: CoinsState = adapter.getInitialState({
    alluserForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedCoinId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function coinsReducer(state = initialCoinsState, action: CoinActions): CoinsState {
    switch  (action.type) {
        case CoinActionTypes.CoinsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCoinId: undefined
            };
        }
        case CoinActionTypes.CoinActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case CoinActionTypes.CoinOnServerCreated: return {
            ...state
        };
        // case CoinActionTypes.CoinCreated: return adapter.addOne(action.payload.Coin, {
        //     ...state, lastCreatedCoinId: action.payload.Coin.id
        // });
        case CoinActionTypes.CoinUpdated: return adapter.updateOne(action.payload.partialCoin, state);
       //  case CoinActionTypes.CoinsStatusUpdated: {
       //      const _partialCoins: Update<CoinModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.Coins.length; i++) {
       //          _partialCoins.push({
           //  id: action.payload.Coins[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialCoins, state);
       //  }
        case CoinActionTypes.OneCoinDeleted: return adapter.removeOne(action.payload.id, state);
        case CoinActionTypes.ManyCoinsDeleted: return adapter.removeMany(action.payload.ids, state);
        case CoinActionTypes.CoinsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case CoinActionTypes.CoinsPageLoaded: {
            return adapter.addMany(action.payload.coins, {
                ...initialCoinsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getCoinState = createFeatureSelector<CoinModel>('coins');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
