// Angular
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// Widgets model
import { LayoutConfigService } from '../../../core/_base/layout';
// Query Params
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../core/_base/crud';
// Common modul
import { DatePipe } from '@angular/common';
// Services
import { GoldBuyService, GoldZakatService, GoldDonationService } from '../../../core/transaction';
import { AuthService } from '../../../core/auth';

@Component({
	selector: 'kt-report',
	templateUrl: './report.component.html',
	styleUrls: ['report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportComponent implements OnInit, OnDestroy {
  organizationList: any = [];
  selectedOrg: string = '';
  role: boolean = false;

  options = [
    {"id": 0, "name": "All the Time"},
    {"id": 1, "name": "Monthly"},
    {"id": 2, "name": "Custom by duration"}
  ];
  selected = this.options[0].id;

  monthly = false;
  customdate = false;
  
  // Default Date
  date = new Date();
  chosenMonthDate: Date = new Date();
  beginDate = this.date.setDate(this.date.getDate() - 7);
  chosenCustomDate = {begin: new Date(this.beginDate), end: new Date()};
  minDate = new Date(2019, 0, 1);

  startDate: any;
  endDate: any;
  dateType: any;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    public authService : AuthService,
    public goldBuyService: GoldBuyService,
    public goldzakatService: GoldZakatService,
    public golddonationService: GoldDonationService,
  ){}

	ngOnInit(): void {    
    this.loadUserRole();
    this.loadOrganizationList();
	}

  ngOnDestroy() {

  }

  loadOrganizationList() {
    this.goldBuyService.getAllOrganization()
    .subscribe(
      data => {
        this.organizationList = data;
      }
    )
  }

  loadUserRole() {
    this.authService.getUserByToken()
    .subscribe(
      data => {
        this.role = data.data.roles.includes(17);
        this.cd.markForCheck();
      }
    )
  }

  durationType(event: any) {
    if (event.value == 0) {
      this.monthly = false;
      this.customdate = false;
    } else if (event.value == 1) {
      this.monthly = true;
      this.customdate = false;
    } else if (event.value == 2) {
      this.monthly = false;
      this.customdate = true;
    }
  }

  downloadGoldBuy(){
    this.getDate();
    const queryParams = new QueryParamsModel(
      '',
      this.startDate,
      this.endDate,
      '',
      '',
      1,
      100000,
      '',
      '',
      '',
      this.selectedOrg
    );

    this.goldBuyService.downloadGoldBuy(queryParams)
    .subscribe(
      data => {
        window.location.href = data;
        }
      )
  }

  downloadZakat(){
    this.getDate();
    const queryParams = new QueryParamsModel(
      '',
      this.startDate,
      this.endDate,
      '',
      '',
      1,
      100000,
      '',
      '',
      '',
      this.selectedOrg
    );

    this.goldzakatService.downloadZakats(queryParams)
    .subscribe(
      data => {
        window.location.href = data;
        }
      )
  }

  downloadDonasi(){
    this.getDate();
    const queryParams = new QueryParamsModel(
      '',
      this.startDate,
      this.endDate,
      '',
      '',
      1,
      100000,
      '',
      '',
      '',
      this.selectedOrg
    );

    this.golddonationService.downloadDonations(queryParams)
    .subscribe(
      data => {
        window.location.href = data;
        }
      )
  }

  getDate(){
    if (this.monthly == true) {
      this.startDate = new Date(this.chosenMonthDate.getFullYear(), this.chosenMonthDate.getMonth(), 1);
      this.startDate = this.datePipe.transform(this.startDate,"yyyy-MM-dd");
      this.endDate = new Date(this.chosenMonthDate.getFullYear(), this.chosenMonthDate.getMonth() + 1, 0);
      this.endDate = this.datePipe.transform(this.endDate,"yyyy-MM-dd");
    } else if (this.customdate == true) {
      this.startDate = this.datePipe.transform(this.chosenCustomDate.begin,"yyyy-MM-dd");
      this.endDate = this.datePipe.transform(this.chosenCustomDate.end,"yyyy-MM-dd");
    } else {
      this.startDate = ''
      this.endDate = ''
    }
  }

  onDateChange(event){
    console.log(event)
  }

  onSelectChange(event: any) {
    console.log(event)
  }
}
