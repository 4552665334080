<kt-portlet>
	<kt-portlet-body>
		<div class="row">
      <div class="col-lg-12" *ngIf="organization">
        <mat-form-field>
          <mat-label class="kt-font-bold">Choose a organization</mat-label>
          <mat-select placeholder="Organization" [(ngModel)]="selectedOrganization" (selectionChange)="organizationChange($event)">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let organization of organizationList" [value]="organization.id">{{organization.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
			<div class="col-lg-12" *ngIf="role">
        <mat-label class="kt-font-bold">Choose a role</mat-label>
        <br><br>
        <mat-radio-group class="mat-form-field-fluid" [(ngModel)]="selectedRole">
          <mat-radio-button name="role" *ngFor="let role of roleList" [value]="role.id" (change)="roleChange($event)">{{role.name}}</mat-radio-button>
        </mat-radio-group>
			</div>
			<div class="col-lg-12 mt-4" *ngIf="suborganization">
				<mat-form-field class="mat-form-field-fluid">
          <mat-label class="kt-font-bold">Choose a suborganization</mat-label>
				  <mat-select name="suborganization" [(ngModel)]="selectedSuborganization" (selectionChange)="suborganizationChange()">
				    <mat-option *ngFor="let suborganization of suborganizationList" [value]="suborganization.id">{{suborganization.name}}</mat-option>
				  </mat-select>
				</mat-form-field>
			</div>
			<div class="col-lg-12 mt-4" *ngIf="province">
        <mat-label class="kt-font-bold">Choose province</mat-label>
        <br><br>
        <div class="row">
          <div *ngFor="let province of provinceList" class=col-md-3>
          <mat-checkbox [value]="province.hashed_id" (change)="provinceChange($event)">{{province.name}}</mat-checkbox>
          </div>
        </div>
			</div>
			<div class="col-lg-12 mt-4" *ngIf="user">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label class="kt-font-bold">Choose email user</mat-label>
          <input type="text" aria-label="admin" matInput [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" (optionSelected)='getUserId($event.option.value)'>
            <mat-option *ngFor="let admin of filteredOptions | async" [value]="admin">{{admin.email}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
			</div>

			<div class="row text-right mt-4">
				<div class="col-lg-12">
          <button mat-raised-button color="accent" (click)="back()" class="mr-2">Kembali</button>
					<button type="button" mat-raised-button color="accent" (click)="addUser()">Tambah</button>
				</div>
			</div>
		</div>
	</kt-portlet-body>
</kt-portlet>
