// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ActiveUserModel } from '../_models/activeuser.model';

export enum ActiveUserActionTypes {
    ActiveUserOnServerCreated = '[Edit ActiveUser Dialog] ActiveUser On Server Created',
    ActiveUserCreated = '[Edit ActiveUser Dialog] ActiveUser Created',
    ActiveUserUpdated = '[Edit ActiveUser Dialog] ActiveUser Updated',
    ActiveUsersStatusUpdated = '[ActiveUser List Page] ActiveUsers Status Updated',
    OneActiveUserDeleted = '[ActiveUsers List Page] One ActiveUser Deleted',
    ManyActiveUsersDeleted = '[ActiveUsers List Page] Many ActiveUser Deleted',
    ActiveUsersPageRequested = '[ActiveUsers List Page] ActiveUsers Page Requested',
    ActiveUsersPageLoaded = '[ActiveUsers API] ActiveUsers Page Loaded',
    ActiveUsersPageCancelled = '[ActiveUsers API] ActiveUsers Page Cancelled',
    ActiveUsersPageToggleLoading = '[ActiveUsers] ActiveUsers Page Toggle Loading',
    ActiveUserActionToggleLoading = '[ActiveUsers] ActiveUsers Action Toggle Loading'
}

export class ActiveUserOnServerCreated implements Action {
    readonly type = ActiveUserActionTypes.ActiveUserOnServerCreated;
    constructor(public payload: { activeuser: ActiveUserModel }) { }
}

export class ActiveUserCreated implements Action {
    readonly type = ActiveUserActionTypes.ActiveUserCreated;
    constructor(public payload: { activeuser: ActiveUserModel }) { }
}

export class ActiveUserUpdated implements Action {
    readonly type = ActiveUserActionTypes.ActiveUserUpdated;
    constructor(public payload: {
        partialActiveUser: Update<ActiveUserModel>, // For State update
        activeuser: ActiveUserModel // For Server update (through service)
    }) { }
}

export class ActiveUsersStatusUpdated implements Action {
    readonly type = ActiveUserActionTypes.ActiveUsersStatusUpdated;
    constructor(public payload: {
        activeusers: ActiveUserModel[],
        status: number
    }) { }
}

export class OneActiveUserDeleted implements Action {
    readonly type = ActiveUserActionTypes.OneActiveUserDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyActiveUsersDeleted implements Action {
    readonly type = ActiveUserActionTypes.ManyActiveUsersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ActiveUsersPageRequested implements Action {
    readonly type = ActiveUserActionTypes.ActiveUsersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ActiveUsersPageLoaded implements Action {
    readonly type = ActiveUserActionTypes.ActiveUsersPageLoaded;
    constructor(public payload: { activeusers: ActiveUserModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class ActiveUsersPageCancelled implements Action {
    readonly type = ActiveUserActionTypes.ActiveUsersPageCancelled;
}

export class ActiveUsersPageToggleLoading implements Action {
    readonly type = ActiveUserActionTypes.ActiveUsersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ActiveUserActionToggleLoading implements Action {
    readonly type = ActiveUserActionTypes.ActiveUserActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ActiveUserActions = ActiveUserOnServerCreated
| ActiveUserCreated
| ActiveUserUpdated
| ActiveUsersStatusUpdated
| OneActiveUserDeleted
| ManyActiveUsersDeleted
| ActiveUsersPageRequested
| ActiveUsersPageLoaded
| ActiveUsersPageCancelled
| ActiveUsersPageToggleLoading
| ActiveUserActionToggleLoading;
