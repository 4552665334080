// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ModeratorAvailablesState } from '../_reducers/moderatoravailable.reducers';
import { ModeratorAvailableModel } from '../_models/moderatoravailable.model';

export const selectModeratorAvailablesState = createFeatureSelector<ModeratorAvailablesState>('moderatoravailables');

export const selectModeratorAvailableById = (moderatoravailableId: number) => createSelector(
    selectModeratorAvailablesState,
    moderatoravailablesState => moderatoravailablesState.entities[moderatoravailableId]
);

export const selectModeratorAvailablesPageLoading = createSelector(
    selectModeratorAvailablesState,
    moderatoravailablesState => moderatoravailablesState.listLoading
);

export const selectModeratorAvailablesActionLoading = createSelector(
    selectModeratorAvailablesState,
    moderatoravailablesState => moderatoravailablesState.actionsloading
);

export const selectLastCreatedModeratorAvailableId = createSelector(
    selectModeratorAvailablesState,
    moderatoravailablesState => moderatoravailablesState.lastCreatedModeratorAvailableId
);

export const selectModeratorAvailablesShowInitWaitingMessage = createSelector(
    selectModeratorAvailablesState,
    moderatoravailablesState => moderatoravailablesState.showInitWaitingMessage
);

export const selectModeratorAvailablesInStore = createSelector(
    selectModeratorAvailablesState,
    moderatoravailablesState => {
        const items: ModeratorAvailableModel[] = [];
        each(moderatoravailablesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ModeratorAvailableModel[] = httpExtension.sortArray(items, moderatoravailablesState.lastQuery.sortField, moderatoravailablesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, moderatoravailablesState.totalCount, '');
    }
);
