import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { ModeratorAvailableService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    ModeratorAvailableActionTypes,
    ModeratorAvailablesPageRequested,
    ModeratorAvailablesPageLoaded,
    ManyModeratorAvailablesDeleted,
    OneModeratorAvailableDeleted,
    ModeratorAvailableActionToggleLoading,
    ModeratorAvailablesPageToggleLoading,
    ModeratorAvailableUpdated,
    ModeratorAvailablesStatusUpdated,
    ModeratorAvailableCreated,
    ModeratorAvailableOnServerCreated
} from '../_actions/moderatoravailable.actions';
import { of } from 'rxjs';

@Injectable()
export class ModeratorAvailableEffects {
    showPageLoadingDistpatcher = new ModeratorAvailablesPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ModeratorAvailableActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ModeratorAvailableActionToggleLoading({ isLoading: false });

    @Effect()
    loadModeratorAvailablesPage$ = this.actions$.pipe(
        ofType<ModeratorAvailablesPageRequested>(ModeratorAvailableActionTypes.ModeratorAvailablesPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.moderatoravailablesService.findModeratorAvailables(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new ModeratorAvailablesPageLoaded({
                moderatoravailables: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteModeratorAvailable$ = this.actions$
        .pipe(
            ofType<OneModeratorAvailableDeleted>(ModeratorAvailableActionTypes.OneModeratorAvailableDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.moderatoravailablesService.deleteModeratorAvailable(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteModeratorAvailables$ = this.actions$
        .pipe(
            ofType<ManyModeratorAvailablesDeleted>(ModeratorAvailableActionTypes.ManyModeratorAvailablesDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.moderatoravailablesService.deleteModeratorAvailables(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateModeratorAvailable$ = this.actions$
        .pipe(
            ofType<ModeratorAvailableUpdated>(ModeratorAvailableActionTypes.ModeratorAvailableUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.moderatoravailablesService.updateModeratorAvailable(payload.moderatoravailable);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateModeratorAvailablesStatus$ = this.actions$
        .pipe(
            ofType<ModeratorAvailablesStatusUpdated>(ModeratorAvailableActionTypes.ModeratorAvailablesStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.moderatoravailablesService.updateStatusForModeratorAvailable(payload.moderatoravailables, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createModeratorAvailable$ = this.actions$
        .pipe(
            ofType<ModeratorAvailableOnServerCreated>(ModeratorAvailableActionTypes.ModeratorAvailableOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.moderatoravailablesService.createModeratorAvailable(payload.moderatoravailable).pipe(
                    tap(res => {
                        this.store.dispatch(new ModeratorAvailableCreated({ moderatoravailable: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private moderatoravailablesService: ModeratorAvailableService, private store: Store<AppState>) { }
}
