// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { GoldSellActions, GoldSellActionTypes } from '../_actions/goldsell.actions';
// Models
import { GoldSellModel } from '../_models/goldsell.model';
import { QueryParamsModel } from '../../_base/crud';

export interface GoldSellsState extends EntityState<GoldSellModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGoldSellId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GoldSellModel> = createEntityAdapter<GoldSellModel>();

export const initialGoldSellsState: GoldSellsState = adapter.getInitialState({
    goldsellForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGoldSellId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function goldsellsReducer(state = initialGoldSellsState, action: GoldSellActions): GoldSellsState {
    switch  (action.type) {
        case GoldSellActionTypes.GoldSellsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedGoldSellId: undefined
            };
        }
        case GoldSellActionTypes.GoldSellActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case GoldSellActionTypes.GoldSellOnServerCreated: return {
            ...state
        };
        // case GoldSellActionTypes.GoldSellCreated: return adapter.addOne(action.payload.goldsell, {
        //     ...state, lastCreatedGoldSellId: action.payload.goldsell.id
        // });
        case GoldSellActionTypes.GoldSellUpdated: return adapter.updateOne(action.payload.partialGoldSell, state);
       //  case GoldSellActionTypes.GoldSellsStatusUpdated: {
       //      const _partialGoldSells: Update<GoldSellModel>[] = [];
       //      // tslint:disable-next-line:prefer-const
       //      for (let i = 0; i < action.payload.goldsells.length; i++) {
       //          _partialGoldSells.push({
           //  id: action.payload.goldsells[i].id,
           //  changes: {
       //                  status: action.payload.status
       //              }
          // });
       //      }
       //      return adapter.updateMany(_partialGoldSells, state);
       //  }
        case GoldSellActionTypes.OneGoldSellDeleted: return adapter.removeOne(action.payload.id, state);
        case GoldSellActionTypes.ManyGoldSellsDeleted: return adapter.removeMany(action.payload.ids, state);
        case GoldSellActionTypes.GoldSellsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case GoldSellActionTypes.GoldSellsPageLoaded: {
            return adapter.addMany(action.payload.goldsells, {
                ...initialGoldSellsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getGoldSellState = createFeatureSelector<GoldSellModel>('goldsells');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
