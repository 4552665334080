// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsInventoryModel } from '../../_base/crud';
// Models
import { AdminModel } from '../_models/admin.model';

export enum AdminActionTypes {
    AdminOnServerCreated = '[Edit Admin Dialog] Admin On Server Created',
    AdminCreated = '[Edit Admin Dialog] Admin Created',
    AdminUpdated = '[Edit Admin Dialog] Admin Updated',
    AdminsStatusUpdated = '[Admin List Page] Admins Status Updated',
    OneAdminDeleted = '[Admins List Page] One Admin Deleted',
    ManyAdminsDeleted = '[Admins List Page] Many Admin Deleted',
    AdminsPageRequested = '[Admins List Page] Admins Page Requested',
    AdminsPageLoaded = '[Admins API] Admins Page Loaded',
    AdminsPageCancelled = '[Admins API] Admins Page Cancelled',
    AdminsPageToggleLoading = '[Admins] Admins Page Toggle Loading',
    AdminActionToggleLoading = '[Admins] Admins Action Toggle Loading'
}

export class AdminOnServerCreated implements Action {
    readonly type = AdminActionTypes.AdminOnServerCreated;
    constructor(public payload: { admin: AdminModel }) { }
}

export class AdminCreated implements Action {
    readonly type = AdminActionTypes.AdminCreated;
    constructor(public payload: { admin: AdminModel }) { }
}

export class AdminUpdated implements Action {
    readonly type = AdminActionTypes.AdminUpdated;
    constructor(public payload: {
        partialAdmin: Update<AdminModel>, // For State update
        admin: AdminModel // For Server update (through service)
    }) { }
}

export class AdminsStatusUpdated implements Action {
    readonly type = AdminActionTypes.AdminsStatusUpdated;
    constructor(public payload: {
        admins: AdminModel[],
        status: number
    }) { }
}

export class OneAdminDeleted implements Action {
    readonly type = AdminActionTypes.OneAdminDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAdminsDeleted implements Action {
    readonly type = AdminActionTypes.ManyAdminsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AdminsPageRequested implements Action {
    readonly type = AdminActionTypes.AdminsPageRequested;
    constructor(public payload: { page: QueryParamsInventoryModel }) { }
}

export class AdminsPageLoaded implements Action {
    readonly type = AdminActionTypes.AdminsPageLoaded;
    constructor(public payload: { admins: AdminModel[], totalCount: number, page: QueryParamsInventoryModel }) { }
}

export class AdminsPageCancelled implements Action {
    readonly type = AdminActionTypes.AdminsPageCancelled;
}

export class AdminsPageToggleLoading implements Action {
    readonly type = AdminActionTypes.AdminsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AdminActionToggleLoading implements Action {
    readonly type = AdminActionTypes.AdminActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type AdminActions = AdminOnServerCreated
| AdminCreated
| AdminUpdated
| AdminsStatusUpdated
| OneAdminDeleted
| ManyAdminsDeleted
| AdminsPageRequested
| AdminsPageLoaded
| AdminsPageCancelled
| AdminsPageToggleLoading
| AdminActionToggleLoading;
