import { QueryParamsInventoryModel } from './../../_base/crud/models/query-models/query-params-inventory.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { LocationAvailableService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    LocationAvailableActionTypes,
    LocationAvailablesPageRequested,
    LocationAvailablesPageLoaded,
    ManyLocationAvailablesDeleted,
    OneLocationAvailableDeleted,
    LocationAvailableActionToggleLoading,
    LocationAvailablesPageToggleLoading,
    LocationAvailableUpdated,
    LocationAvailablesStatusUpdated,
    LocationAvailableCreated,
    LocationAvailableOnServerCreated
} from '../_actions/locationavailable.actions';
import { of } from 'rxjs';

@Injectable()
export class LocationAvailableEffects {
    showPageLoadingDistpatcher = new LocationAvailablesPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new LocationAvailableActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new LocationAvailableActionToggleLoading({ isLoading: false });

    @Effect()
    loadLocationAvailablesPage$ = this.actions$.pipe(
        ofType<LocationAvailablesPageRequested>(LocationAvailableActionTypes.LocationAvailablesPageRequested),
        mergeMap(( { payload } ) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.locationavailablesService.findLocationAvailables(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsInventoryModel = response[1];
            const pageLoadedDispatch = new LocationAvailablesPageLoaded({
                locationavailables: result.data,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteLocationAvailable$ = this.actions$
        .pipe(
            ofType<OneLocationAvailableDeleted>(LocationAvailableActionTypes.OneLocationAvailableDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.locationavailablesService.deleteLocationAvailable(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteLocationAvailables$ = this.actions$
        .pipe(
            ofType<ManyLocationAvailablesDeleted>(LocationAvailableActionTypes.ManyLocationAvailablesDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.locationavailablesService.deleteLocationAvailables(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateLocationAvailable$ = this.actions$
        .pipe(
            ofType<LocationAvailableUpdated>(LocationAvailableActionTypes.LocationAvailableUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.locationavailablesService.updateLocationAvailable(payload.locationavailable);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateLocationAvailablesStatus$ = this.actions$
        .pipe(
            ofType<LocationAvailablesStatusUpdated>(LocationAvailableActionTypes.LocationAvailablesStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.locationavailablesService.updateStatusForLocationAvailable(payload.locationavailables, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createLocationAvailable$ = this.actions$
        .pipe(
            ofType<LocationAvailableOnServerCreated>(LocationAvailableActionTypes.LocationAvailableOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.locationavailablesService.createLocationAvailable(payload.locationavailable).pipe(
                    tap(res => {
                        this.store.dispatch(new LocationAvailableCreated({ locationavailable: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private locationavailablesService: LocationAvailableService, private store: Store<AppState>) { }
}
