// Angular
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
// Models
import { GoldZakatModel } from '../_models/goldzakat.model';

const BASE_URL = environment.baseUrl;

@Injectable()
export class GoldZakatService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  // CREATE =>  POST: add a new goldzakat to the server
  createGoldZakat(goldzakat: GoldZakatModel): Observable<GoldZakatModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<GoldZakatModel>(BASE_URL, goldzakat, { headers: httpHeaders});
  }

  // READ
  getAllGoldZakats(): Observable<GoldZakatModel[]> {
    return this.http.get<GoldZakatModel[]>(BASE_URL);
  }

  getGoldZakatById(goldzakatId: number): Observable<GoldZakatModel> {
    return this.http.get<GoldZakatModel>(BASE_URL + `/${goldzakatId}`);
  }

  getAllOrganization() {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/organization?page=1&limit=100&order=asc&sort=&filter&type=';
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  getDetailUser(hashed_id: string) {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const url = BASE_URL + 'b2b/user/'+hashed_id;
    return this.http
      .get<any[]>(url,{headers:httpHeaders})
      .pipe(map(data => data['data']));
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  // Server should return filtered/sorted result
  findGoldZakats(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/zakat';

    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params:  httpParams
    });
  }

  // UPDATE => PUT: update the goldzakat on the server
  updateGoldZakat(goldzakat: GoldZakatModel): Observable<any> {
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.put(BASE_URL, goldzakat, { headers: httpHeader });
  }

  // UPDATE Status
  updateStatusForGoldZakat(goldzakats: GoldZakatModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      goldzakatsForUpdate: goldzakats,
      newStatus: status
    };
    const url = BASE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the goldzakat from the server
  deleteGoldZakat(goldzakatId: number): Observable<GoldZakatModel> {
    const url = `${BASE_URL}/${goldzakatId}`;
    return this.http.delete<GoldZakatModel>(url);
  }

  deleteGoldZakats(ids: number[] = []): Observable<any> {
    const url = BASE_URL + '/deleteGoldZakats';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { goldzakatIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
  }

  downloadZakats(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/export/zakat';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }

  totalZakat(queryParams: QueryParamsModel){
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = BASE_URL + 'b2b/sum/zakat';

    return this.http.get<any[]>(url, {
      headers: httpHeaders,
      params:  httpParams
    }).pipe(map(data => data['data']));
  }
}
